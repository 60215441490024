import { Grid, Tab, Tabs } from '@material-ui/core';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortOptionByLabel } from '../../../../common/helpers/options';
import usePrevious from '../../../../hooks/usePrevious';
import { KeyboardDateTimePickerField, NumberField, SelectField, TextField, TextInput } from '../../../../layout/fields';
import { Option } from '../../../../layout/fields/SelectInput';
import { ResourceTypeEnum } from '../../../enums';
import { EventSubtypeEnum } from '../../../enums/EventSubtypeEnum';
import {
  defaultEfficiency,
  getNewEndDateFromVolume,
  getTaskDurationInHours,
  setDatesFromDuration,
  setEndDateFromStartDateAndDuration,
} from '../../../helpers/eventHelpers';
import { EventValues } from '../../../model';
import { EventEditorContext } from '../EventEditorContext';
import AvailableDoors from '../common/AvailableDoors';
import AvailableRails from '../common/AvailableRails';
import OutfeedLocations from '../common/OutfeedLocations';
import tabStyles from '../tabStyles';
import AvailableProducts from './AvailableProducts';

//J'ai ajouté ça juste pour empêcher VS d'effacer le React dans la ligne "import React, ...".
type _react = typeof React

enum TransferEventTabsEnum {
  lots,
  rails,
  doors,
}

type TransferEventEditorFieldsProps = {
  disabled?: boolean;
  isCreating: boolean;
};

const TransferEventEditorFields = ({ disabled = false, isCreating }: TransferEventEditorFieldsProps): ReactElement => {
  const tabClasses = tabStyles();
  const { t } = useTranslation('planning');
  const { setFieldValue, values } = useFormikContext<EventValues>();
  const { resources, setSelectedLocationIdsMemory } = useContext(EventEditorContext);
  
  const previousSubtype = usePrevious(values.eventSubtype);

  const [currentTab, setCurrentTab] = useState<TransferEventTabsEnum>(TransferEventTabsEnum.lots);

  const resourceOptions = useMemo(() => {
    const filteredResources = resources.filter((resource) => resource.type === ResourceTypeEnum.Transfer);
    return filteredResources.map((x) => ({ key: x.id, label: x.name, value: x.id })).sort(sortOptionByLabel);
  }, [resources]);

  const getTransferSubtypeOptions = useCallback((t: (key: string) => string): Option[] => {
    const { dry, green } = EventSubtypeEnum;
    return Object.entries({ dry, green }).map(([key, value]) => ({ key, label: t(key), value }));
  }, []);

  const handleEndDateChange = (newEndDate: string | null): void => setDuration(values.startDate, newEndDate);

  const handleStartDateChange = (newStartDate: string | null): void => {
    setEndDateFromStartDateAndDuration(setFieldValue, newStartDate, values.duration || 0, values.eventType);
  };

  const handleWoodQuantityChange = (newQuantity: string): void => {
    const newEndDate = getNewEndDateFromVolume(values.startDate, values.endDate, +newQuantity);
    setFieldValue('endDate', newEndDate);
    setDuration(values.startDate, newEndDate);
  };

  const handleDurationChange = (newDuration: string | number = 0): void => {
    if ((dayjs(values.startDate).isValid() || !values.startDate) && (dayjs(values.endDate).isValid() || !values.endDate))
      setDatesFromDuration(setFieldValue, values.startDate, values.endDate, newDuration, values.eventType);
  };
/*
  useEffect(() => {

    const filteredInfeeds = values.infeedProducts;
    if (filteredInfeeds.length !== values.infeedProducts.length) 
    setFieldValue('infeedProducts', filteredInfeeds);

    const filteredOutfeeds = values.outfeedProducts;
    if (filteredOutfeeds.length !== values.outfeedProducts.length) 
    setFieldValue('outfeedProducts', filteredOutfeeds);

  }, [values.infeedProducts, values.outfeedProducts, setFieldValue]);
*/
  const setDuration = (startDate: string | Date | null, endDate: string | Date | null) => {
    if (dayjs(startDate).isValid() && dayjs(endDate).isValid()) {
      setFieldValue('duration', getTaskDurationInHours(startDate, endDate));
    }
  };

  useEffect(() => {
    if (values.eventSubtype === previousSubtype) return;

    setFieldValue('infeedProducts', []);
    setFieldValue('outfeedProducts', []);
  }, [values.eventSubtype, previousSubtype, setFieldValue]);

  useEffect(() => {
    if (currentTab !== TransferEventTabsEnum.lots) setSelectedLocationIdsMemory([]);
  }, [currentTab, setSelectedLocationIdsMemory]);

  return (
    <>
      <Grid container spacing={3}>

        <Grid item md={2}>
          <SelectField label={t('eventSubtype')} name="eventSubtype" options={getTransferSubtypeOptions(t)} disabled={!isCreating} clearable={false} />
        </Grid>

        <Grid item md={2}>
          <SelectField label={t('transferLine')} name="resourceId" options={resourceOptions} disabled={true} />
        </Grid>

        <Grid item md={4}>
          <NumberField
            label={t('woodQuantity')}
            name="woodQuantity"
            min={0}
            disabled={disabled || !!values.infeedProducts.length}
            onChange={handleWoodQuantityChange}
          />
        </Grid>

        <Grid item md={4}>
          <NumberField
            label={t('maxQuantity')}
            name="maxQuantity"
            min={0}
          />
        </Grid>

        <Grid item md={4}>
          <KeyboardDateTimePickerField name="startDate" label={t('startDate')} disabled={disabled} onChange={handleStartDateChange} />
        </Grid>

        <Grid item md={4}>
          <KeyboardDateTimePickerField
            name="endDate"
            label={t('endDate')}
            minDate={values.startDate && dayjs(values.startDate).add(1, 'd')}
            disabled={disabled}
            onChange={handleEndDateChange}
          />
        </Grid>

        <Grid item md={2}>
          <NumberField label={t('processDuration')} name="duration" decimalScale={2} onChange={handleDurationChange} disabled={disabled} />
        </Grid>

        <Grid item md={1}>
          <NumberField label={t('workOrder')} name="workOrder" disabled />
        </Grid>

        <Grid item md={1}>
          <TextInput label={t('efficiency')} name="efficiency" disabled value={defaultEfficiency} />
        </Grid>

        <Grid item md={6}>
          <TextField label={t('tooltipNote')} name="tooltipNote" disabled={disabled} />
        </Grid>
        <Grid item md={6}>
          <TextField label={t('detailedComment')} name="detailedComment" disabled={disabled} />
        </Grid>
        <Grid item md={6}>
          <Tabs
            classes={{
              root: tabClasses.tabsRoot,
            }}
            value={currentTab}
            onChange={(_: unknown, value: TransferEventTabsEnum) => setCurrentTab(value)}
            indicatorColor="primary">
            <Tab
              label={values.eventSubtype === EventSubtypeEnum.green ? t('lots') : t('indoorLots')}
              value={TransferEventTabsEnum.lots}
              className={currentTab == TransferEventTabsEnum.lots ? tabClasses.selectedTab : tabClasses.tab}
            />
            {values.eventSubtype === EventSubtypeEnum.green ? (
              <Tab
                label={t('rails')}
                value={TransferEventTabsEnum.rails}
                className={currentTab === TransferEventTabsEnum.rails ? tabClasses.selectedTab : tabClasses.tab}
              />
            ) : (
              <Tab
                label={t('doors')}
                value={TransferEventTabsEnum.doors}
                className={currentTab === TransferEventTabsEnum.doors ? tabClasses.selectedTab : tabClasses.tab}
              />
            )}
          </Tabs>
        </Grid>
        <Grid item md={6} className={tabClasses.outfeedChips}>
          {currentTab === TransferEventTabsEnum.lots && values.eventSubtype === EventSubtypeEnum.green && (
            <OutfeedLocations label={t('selectedRails')} className={tabClasses.outfeedChip} />
          )}
          {currentTab === TransferEventTabsEnum.lots && values.eventSubtype === EventSubtypeEnum.dry && (
            <OutfeedLocations label={t('selectedDoors')} className={tabClasses.outfeedChip} />
          )}
        </Grid>
      </Grid>

      {currentTab === TransferEventTabsEnum.lots && <AvailableProducts disabled={disabled} />}
      {currentTab === TransferEventTabsEnum.rails && <AvailableRails disabled={disabled} />}
      {currentTab === TransferEventTabsEnum.doors && <AvailableDoors disabled={disabled} />}
    </>
  );
};

export default TransferEventEditorFields;
