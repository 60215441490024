import DayJsUtils from '@date-io/dayjs';
import { Box, Grid, makeStyles, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePickerField, NumberField, SliderField, ToggleField } from '../../../layout/fields';
import { horizonDaysOptions, kilnToProductionDelayOptions, stepDaysOption } from '../../helpers/optimizationSettingsHelpers';

const useStyles = makeStyles(() => ({
  icons: {
    marginLeft: 10,
  },
  frame: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  toggleBoxes: {
    textAlign: 'end',
  },
}));

const OptimizationFormFields = (): ReactElement => {
  const { t } = useTranslation('planning');
  const classes = useStyles();

  return (
    <Grid container spacing={2} alignItems="center" className={classes.frame}>
      <Grid item md={6}>
        <span>{t('optimization.startDate')}</span>
        <Tooltip title={t('optimization.startDateTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid md={6} item>
        <MuiPickersUtilsProvider utils={DayJsUtils}>
          <KeyboardDatePickerField name="startDate" label="" classes={{}} />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.horizonLength')}</span>
        <Tooltip title={t('optimization.horizonLengthTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <Box className={classes.toggleBoxes}>
          <ToggleField name="horizonDays" options={horizonDaysOptions(t)} />
        </Box>
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.timeLimit')}</span>
        <Tooltip title={t('optimization.timeLimitTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <Box className={classes.toggleBoxes}>
          <NumberField label="" min={0} max={719} decimalScale={0} name="timeLimit"/>
        </Box>
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.stepDays')}</span>
        <Tooltip title={t('optimization.stepDaysTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <Box className={classes.toggleBoxes}>
          <ToggleField name="stepDays" options={stepDaysOption()} />
        </Box>
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.optionFanCount')}</span>
        <Tooltip title={t('optimization.optionFanCountTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <SliderField min={1} max={8} name="optionFanCount" />
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.greenPenalty')}</span>
        <Tooltip title={t('optimization.greenPenaltyTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <SliderField min={1} max={10} name="greenPenalty" />
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.driedPenalty')}</span>
        <Tooltip title={t('optimization.driedPenaltyTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <SliderField min={1} max={10} name="driedPenalty" />
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.greenInventoryPenalty')}</span>
        <Tooltip title={t('optimization.greenInventoryPenaltyTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <SliderField min={1} max={10} name="greenInventoryPenalty" />
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.driedInventoryPenalty')}</span>
        <Tooltip title={t('optimization.driedInventoryPenaltyTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <SliderField min={1} max={10} name="driedInventoryPenalty" />
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.weeklyPenalty')}</span>
        <Tooltip title={t('optimization.weeklyPenaltyTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <SliderField min={1} max={10} name="weeklyPenalty" />
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.standardDryPenalty')}</span>
        <Tooltip title={t('optimization.standardDryPenaltyTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <SliderField min={1} max={10} name="standardDryPenalty" />
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.fanDryPenalty')}</span>
        <Tooltip title={t('optimization.fanDryPenaltyTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <SliderField min={1} max={10} name="fanDryPenalty" />
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.airDryPenalty')}</span>
        <Tooltip title={t('optimization.airDryPenaltyTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <SliderField min={1} max={10} name="airDryPenalty" />
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.lowInventoryPenalty')}</span>
        <Tooltip title={t('optimization.lowInventoryPenaltyTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <SliderField min={1} max={10} step={1} name="lowInventoryPenalty" />
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.minimumKilnUsageHard')}</span>
        <Tooltip title={t('optimization.minimumKilnUsageHardTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <SliderField min={0} max={100} step={10} name="minimumKilnUsageHard" suffix="%" />
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.minimumKilnUsageSoft')}</span>
        <Tooltip title={t('optimization.minimumKilnUsageSoftTooltip') as string}>
          <InfoOutlinedIcon fontSize="inherit" className={classes.icons} />
        </Tooltip>
      </Grid>
      <Grid item md={6}>
        <SliderField min={0} max={100} step={10} name="minimumKilnUsageSoft" suffix="%" />
      </Grid>

      <Grid item md={6}>
        <span>{t('optimization.kilnToProductionDelay')}</span>
      </Grid>
      <Grid item md={6}>
        <Box className={classes.toggleBoxes}>
          <ToggleField name="kilnToProductionDelay" options={kilnToProductionDelayOptions} />
        </Box>
      </Grid>
    </Grid>
  );
};
export default OptimizationFormFields;
