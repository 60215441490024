import { makeStyles, Theme } from '@material-ui/core';

const tabStyles = makeStyles((theme: Theme) => ({
  tabsRoot: {
    marginTop: theme.spacing(4),
    height: 24,
    minHeight: 24,
  },
  tab: {
    height: 24,
    minHeight: 24,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.primary.dark,
  },
  selectedTab: {
    height: 24,
    minHeight: 24,
    backgroundColor: '#434343',
    color: theme.palette.getContrastText(theme.palette.primary.dark),
  },
  outfeedChips: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'baseline',
    marginTop: 'auto',
  },
  outfeedChip: {
    marginLeft: theme.spacing(0.5),
  },
  gridNoWrap: {
    whiteSpace: 'nowrap',
  },
  hidden: {
    display: 'none',
  },
}));

export default tabStyles;
