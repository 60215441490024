import * as yup from 'yup';

export const getOptimizationSchema = (t: (key: string, values?: { number: number }) => string): yup.AnySchema =>
  yup.object().shape({
    timeLimit: yup
      .number()
      .nullable()
      .min(1, t('validation.equalOrHigherThan', { number: 1 }))
      .max(719, t('validation.equalOrLesserThan', { number: 719 }))
      .required(t('validation.required')),
  });
