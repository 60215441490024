import { AbstractCrudManager, SchedulerConfig } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { TFunction } from 'i18next';
import React from 'react';
import { InventoryResource, InventoryResourceModel } from '../model/InventoryResource';
import InventoryChart from './chart/InventoryChart';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1/inventory-view`;

type ExtendedSchedulerConfig = SchedulerConfig & {
  cellMenuFeaure: boolean;
  timeAxisHeaderMenuFeature: boolean;
  groupFeature: string;
  timeRangesFeature: { showCurrentTimeLine: boolean };
  regionResizeFeature: boolean;
  scheduleTooltipFeature: boolean;
};

type loadFailParameters = {
  response: {
    messages: string[];
  };
  source: AbstractCrudManager;
};

const getSchedulerConfig = (
  t: TFunction,
  projectListeners: { loadFail: ({ response, source }: loadFailParameters) => void }
): Partial<ExtendedSchedulerConfig> => ({
  project: {
    autoLoad: false,
    resourceModelClass: InventoryResourceModel,
    transport: {
      load: {
        url: baseUrl,
      },
    },
    listeners: projectListeners,
  },
  rowHeight: 28,
  viewPreset: {
    base: 'weekAndDayLetter',
    headers: [
      {
        unit: 'week',
        dateFormat: 'DD/MM/YYYY',
      },
      {
        unit: 'day',
        dateFormat: 'D',
      },
    ],
  },
  readOnly: true,
  cellMenuFeaure: false,
  timeAxisHeaderMenuFeature: false,
  zoomOnTimeAxisDoubleClick: false,
  groupFeature: 'type',
  timeRangesFeature: {
    showCurrentTimeLine: true,
  },
  regionResizeFeature: false,
  scheduleTooltipFeature: false,

  features: {
    sort: "displayIndex",
  },

  columns: [
    {
      text: '',
      field: 'displayIndex',
      width: 2,
      hidden: true,
    },
    {
      text: '',
      field: 'displayName',
      width: 130,
      enableHeaderContextMenu: false,
      sortable: true,
      renderer(things: { record: { originalData: { name: string } } }) {
        return things.record.originalData.name;
      }
    },
    {
      field: 'type',
      hidden: true,
      groupRenderer: ({ groupRowFor }: { groupRowFor: string }): string => t(`inventory:${groupRowFor}`, { count: 2 }),
    },
    {
      type: 'timeAxis',
      id: 'chartColumn',
      renderer({
        record,
        grid,
      }: {
        record: InventoryResource;
        grid: { timeAxis: { startDate: Date }[]; timeAxisViewModel: { totalSize: number } };
      }): JSX.Element {
        return <InventoryChart record={record} dates={grid.timeAxis.map((x) => x.startDate)} totalSize={grid.timeAxisViewModel.totalSize} />;
      },
    },
  ],
});

export default getSchedulerConfig;
