import { AppBar, Box, Button, Drawer, makeStyles, PropTypes, SvgIconProps, Theme, Toolbar, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from '../../hooks/useModal';

const useStyles = makeStyles((theme: Theme) => ({
  paper: (props: { isSmall: boolean }) => ({
    width: props.isSmall ? '40%' : '85%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
  }),
  content: { overflow: 'auto', padding: 10 },
  bar: { paddingLeft: theme.spacing(0), paddingRight: theme.spacing(2), backgroundColor: theme.palette.primary.light },
  title: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'baseline',
    marginLeft: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  confirmButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#434343',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.getContrastText(theme.palette.primary.dark),
      backgroundColor: theme.palette.primary.dark,
    },
  },
  actions: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

export type SchwoopAction = {
  icon: ComponentType<SvgIconProps>;
  handler: () => void;
  label: string;
  labelColor?: PropTypes.Color | undefined;
  iconColor?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error' | undefined;
  disabled: boolean;
  tooltipText: string;
  testId?: string;
};

type SchwoopProps = {
  children: ReactElement;
  customAction?: ReactElement | null;
  disabled?: boolean;
  open?: boolean;
  title: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  actions?: SchwoopAction[];
  isSmall?: boolean;
};

const Schwoop = ({
  children,
  customAction = null,
  open = false,
  title = '',
  onCancel = () => void 0,
  onConfirm = () => void 0,
  actions = [],
  disabled = false,
  isSmall = false,
}: SchwoopProps): ReactElement => {
  const { t } = useTranslation('layout');
  const [isModalOpen, openModal, closeModal] = useModal();
  const classes = useStyles({ isSmall });

  useEffect(() => {
    if (open) openModal();
    else closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- openModal and closeModal changes too often
  }, [open]);

  return (
    <Drawer
      anchor="right"
      open={isModalOpen}
      disableEnforceFocus
      onClose={close}
      classes={{
        paper: clsx(classes.paper),
      }}>
      <AppBar position="static" color="inherit" elevation={0}>
        <Toolbar className={classes.bar}>
          <Box className={classes.title}>
            <Typography color="inherit" variant="h4">
              {title}
            </Typography>
          </Box>
          <Box>{customAction}</Box>
          <Box className={classes.actions}>
            {actions.map((action) => (
              <Tooltip title={action.tooltipText} key={action.label}>
                <span>
                  <Button
                    key={action.label}
                    className={classes.button}
                    color={action.labelColor}
                    startIcon={<action.icon color={action.iconColor} />}
                    onClick={action.handler}
                    disabled={action.disabled}
                    data-testid={action.testId}>
                    {action.label}
                  </Button>
                </span>
              </Tooltip>
            ))}
            <Button className={classes.button} autoFocus variant="outlined" onClick={onCancel} data-testid="schwoop-cancel">
              {t('cancel')}
            </Button>
            <Button className={classes.confirmButton} variant="contained" color="primary" onClick={onConfirm} disabled={disabled} data-testid="schwoop-submit">
              {t('confirm')}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box className={classes.content}>{children}</Box>
    </Drawer>
  );
};

export default Schwoop;
