import { Checkbox, Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _get from 'lodash.get';
import React, { Ref, useCallback, useMemo } from 'react';

type CheckboxFieldProps = {
  disabled?: boolean;
  inputRef?: ((el: HTMLInputElement) => void) | Ref<HTMLInputElement> | undefined;
  label: string;
  name: string;
  onChange?: (checked: boolean) => void;
};

const CheckboxField = ({ label = '', name = '', onChange = () => undefined, disabled = false, inputRef }: CheckboxFieldProps): JSX.Element => {
  const { setFieldValue, values = {} } = useFormikContext();
  const checked = useMemo(() => _get(values, name), [values, name]);

  const handleChange = useCallback(
    ({ target: { checked } }) => {
      onChange(checked);
      setFieldValue(name, checked);
    },
    [onChange, setFieldValue, name]
  );

  return (
    <Grid container direction="row" alignItems="center">
      <Checkbox id={name} name={name} onChange={handleChange} checked={checked ?? false} disabled={disabled} inputRef={inputRef} data-testid={name} />
      <Typography variant="body2">{label}</Typography>
    </Grid>
  );
};

export default CheckboxField;
