import { Form, Formik } from 'formik';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Schwoop from '../../../layout/schwoop/Schwoop';
import { mapLumberVarietyToValues } from '../../helpers/lumberVarietyHelpers';
import { LumberVariety, LumberVarietyValues } from '../../model';
import { getLumberVarietySchema } from '../../schemas/LumberVarietySchema';
import LumberVarietyEditorFormFields from './LumberVarietyEditorFormFields';

type LumberVarietyEditorProps = {
  lumberVariety: LumberVariety;
  onClose: () => void;
  onSubmit: (values: LumberVarietyValues) => void;
};

const LumberVarietyEditor = ({ lumberVariety, onClose, onSubmit }: LumberVarietyEditorProps): ReactElement => {
  const { t } = useTranslation('settings');
  const isNewVariety: boolean = useMemo(() => !lumberVariety.id, [lumberVariety]);

  return (
    <Formik enableReinitialize validationSchema={getLumberVarietySchema(t)} initialValues={mapLumberVarietyToValues(lumberVariety)} onSubmit={onSubmit}>
      {({ submitForm }) => (
        <Form>
          <Schwoop open onCancel={onClose} onConfirm={submitForm} title={!isNewVariety ? t('varieties.editVariety') : t('varieties.newVariety')}>
            <LumberVarietyEditorFormFields isNewVariety={isNewVariety} />
          </Schwoop>
        </Form>
      )}
    </Formik>
  );
};

export default LumberVarietyEditor;
