import { SchedulerConfig } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { TFunction } from 'react-i18next';
import shouldDisplayFeature from '../../common/helpers/featureToggleHelper';
import { EventTypeEnum } from '../enums';
import { EventSubtypeEnum } from '../enums/EventSubtypeEnum';
import { areAnyEventEditorFieldsNotFilled, eventHasDependencies, getRecipeColors, getSecondaryRecipeColors } from '../helpers/eventHelpers';
import { PlanifEvent, PlanifEventModel } from '../model/Event';
import viewPresets from './viewPresets';

const lockIcon = 'b-fa-lock';
const questionIcon = 'b-fa-question-circle';
const exclamationIcon = 'b-fa-exclamation-circle';
const infoIcon = 'b-fa-info-circle';
const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1/scheduler`;

type ExtendedSchedulerConfig = SchedulerConfig & {
  cellMenuFeature: boolean;
  timeAxisHeaderMenuFeature: boolean;
  groupFeature: string;
  timeRangesFeature: { showCurrentTimeLine: boolean };
  resourceTimeRangesFeature: boolean;
  eventTooltipFeature: unknown;
  dependenciesFeature: { allowCreate: boolean; disabled: boolean };
  eventMenuFeature: {
    items: {
      deleteEvent: boolean;
      copyEvent: boolean;
      cutEvent: boolean;
      unassignEvent: boolean;
      dependenciesEvent: {
        text: string;
        icon: string;
      };
      alignTasksEvent: {
        text: string;
        icon: string;
      };
    };
  };
};

const getSchedulerConfig = (
  t: TFunction,
  // eslint-disable-next-line @typescript-eslint/ban-types -- same as bryntum type
  listeners: object,
  projectListeners: unknown,
  eventTooltipFeature: unknown
): Partial<ExtendedSchedulerConfig> => ({
  eventBodyTemplate: (eventRecord: PlanifEvent) => {
    const { isLocked, eventType, tooltipNote, eventSubtype } = eventRecord;
    const beforeIcons = [];
    const afterIcons = [];
    if (isLocked) beforeIcons.push(lockIcon);
    if (eventType !== EventTypeEnum.Maintenance && areAnyEventEditorFieldsNotFilled(eventRecord)) beforeIcons.push(questionIcon);
    if (eventType !== EventTypeEnum.Stacking && eventType != EventTypeEnum.Maintenance && !eventHasDependencies(eventRecord)) beforeIcons.push(exclamationIcon);
    if (tooltipNote) afterIcons.push(infoIcon);
    const recipeColors = eventSubtype === EventSubtypeEnum.balancing ? getRecipeColors(eventRecord) : getSecondaryRecipeColors(eventRecord);

    return `
              <div>
                ${beforeIcons.map((icon: string) => `<div class="b-fa ${icon}"></div>`).join('')}
                ${eventRecord.name}
              </div>
              <div>
                ${recipeColors.map((x) => `<div class="b-fa b-fa-circle" style="color: #${x};"></div>`).join('')}
                ${afterIcons.length > 0 ? `<div class="b-fa ${afterIcons}"></div>` : ''}
              </div>
          `;
  },
  listeners,
  project: {
    stm: {
      autoRecord: true,
    },
    assignmentStore: {
      syncDataOnLoad: false,
    },
    eventStore: {
      syncDataOnLoad: false, // FIX BOA-479, Bryntum says we won't need this in their next version
    },
    autoLoad: false,
    autoSync: true,
    writeAllFields: true,
    eventModelClass: PlanifEventModel,
    transport: {
      load: {
        url: `${baseUrl}/load`,
      },
      sync: {
        url: `${baseUrl}/sync`,
      },
    },
    listeners: projectListeners,
    validateResponse: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  },
  enableUndoRedoKeys: true,
  resourceImagePath: 'users/',
  rowHeight: 35,
  resourceMargin: 8,
  presets: viewPresets,
  eventStyle: 'border',
  enableDeleteKey: false,
  timeRangesFeature: {
    showCurrentTimeLine: true,
  },
  resourceTimeRangesFeature: true,
  dependenciesFeature: {
    disabled: true,
    allowCreate: false,
  },
  eventMenuFeature: {
    items: {
      deleteEvent: false,
      copyEvent: false,
      cutEvent: false,
      unassignEvent: false,
      dependenciesEvent: {
        text: t('planning:showLinkedTask'),
        icon: 'b-fa b-fa-fw b-fa-eye',
      },
      alignTasksEvent: {
        text: t('planning:alignTasks'),
        icon: 'b-fa b-fa-fw b-fa-align-left',
      },
    },
  },
  eventTooltipFeature: shouldDisplayFeature('FEATURE_SCHEDULER_TOOLTIP') ? eventTooltipFeature : false,
  cellMenuFeature: false,
  timeAxisHeaderMenuFeature: false,
  zoomOnTimeAxisDoubleClick: false,
  columns: [
    { text: '', field: 'name', width: 130, enableHeaderContextMenu: false, sortable: false, enableCellContextMenu: false },
    {
      text: t('planning:information'),
      field: 'information',
      autoWidth: true,
      minWidth: 130,
      align: 'center',
      enableHeaderContextMenu: false,
      sortable: false,
      enableCellContextMenu: false,
    },
    {
      field: 'type',
      hidden: true,
      groupRenderer: ({ groupRowFor }: { groupRowFor: string }) => t(`planning:${groupRowFor}`, { count: 2 }),
    },
  ],
});

export default getSchedulerConfig;
