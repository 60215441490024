const transfers = {
  arrivalFactory: "Usine d'arrivé",
  arrivalLocation: "Location d'arrivé",
  arrivalLocationType: "Type de location d'arrivé",
  dateAndTime: 'Jour et heure',
  departureFactory: 'Usine de départ',
  departureLocation: 'Location de départ',
  departureLocationType: 'Type de location de départ',
  door: 'Porte',
  dryingLine: 'Séchage',
  factoryTransfer: 'Transfert d’usine',
  fmb: 'PMP',
  productCharacteristic: 'Caractéristique',
  productDescription: 'Description du produit',
  productionLine: 'Production',
  quantityToMove: 'Quantité à bouger',
  rail: 'Rail',
  relatedWorkOrder: 'OF relié',
  stackingLine: 'Lattage',
  totalQuantityToMove: 'Quantité totale de bois à bouger',
  transferLine: 'Transfert'
};
export default transfers;
