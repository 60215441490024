import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Rail } from '../../model';

const url = '/api/v1/rails';

export type editRailResponse = { rail: Rail };

const editRail = (updateRequest: Rail): Promise<AxiosResponse<editRailResponse>> => axios.put(url, updateRequest);

const useEditRail = (): [Agent<editRailResponse>, editRailResponse | undefined, boolean, () => void] =>
  useAgent<editRailResponse>((rail: Rail) => editRail(rail));

export default useEditRail;
