import { AccountInfo } from '@azure/msal-common';
import { Ability, AbilityBuilder } from '@casl/ability';
import appUserAbility from '../appUserAbility';
import inventoryUserAbility from '../inventory/inventoryUserAbility';
import settingsUserAbility from '../settings/settingsUserAbility';

export function defineAbilitiesFor(account: AccountInfo | null): Ability {
  if (!account) return new Ability([]);

  const { rules } = new AbilityBuilder<Ability>(Ability);

  const allRules = rules.concat(settingsUserAbility(account), appUserAbility(account), inventoryUserAbility(account));

  return new Ability(allRules);
}
