import { Chip, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _groupBy from 'lodash.groupby';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import formatNumberWithAccounting from '../../../../common/helpers/numberFormatHelpers';
import { EventValues } from '../../../model';

type OutfeedLocationsProps = {
  label: string;
  className?: string;
};

const OutfeedLocations = ({ label, className }: OutfeedLocationsProps): ReactElement => {
  const { t } = useTranslation('planning');
  const { values } = useFormikContext<EventValues>();

  const outfeedLocations = useMemo(() => {
    return Object.entries(_groupBy(values.outfeedProducts, 'locationName')).map(([key, outfeeds]) => ({
      name: key,
      quantity: outfeeds.reduce((acc, outfeed) => acc + outfeed.quantity, 0),
    }));
  }, [values.outfeedProducts]);

  return (
    <>
      <Typography>{`${label}:`}</Typography>
      {outfeedLocations.map((x) => (
        <Chip className={className} key={x.name} label={`${x.name}: ${formatNumberWithAccounting(x.quantity, 0)} ${t('fmb')}`} variant="outlined" />
      ))}
    </>
  );
};

export default OutfeedLocations;
