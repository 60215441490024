import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { ConfirmationDialogConfigs } from './ConfirmationDialog';

const useConfirmationDialog = (): { getConfirmation: (config: ConfirmationDialogConfigs) => Promise<boolean> } => {
  const { openDialog } = useContext(AppContext);

  const getConfirmation = (config: Partial<ConfirmationDialogConfigs>): Promise<boolean> =>
    new Promise<boolean>((resolve: (value: boolean) => void) => {
      openDialog({ ...config, actionCallback: resolve });
    });

  return { getConfirmation };
};

export default useConfirmationDialog;
