import { Grid } from '@material-ui/core';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sortOptionByLabel } from '../../../../common/helpers/options';
import { KeyboardDateTimePickerField, NumberField, SelectField, TextField } from '../../../../layout/fields';
import { getTaskDurationInDays, setDatesFromDuration } from '../../../helpers/eventHelpers';
import { EventValues } from '../../../model';
import { EventEditorContext } from '../EventEditorContext';

type MaintenanceEventEditorFieldsProps = {
  disabled?: boolean;
};

const MaintenanceEventEditorFields = ({ disabled = false }: MaintenanceEventEditorFieldsProps): ReactElement => {
  const { t } = useTranslation('planning');
  const { setFieldValue, values } = useFormikContext<EventValues>();
  const { resources } = useContext(EventEditorContext);

  const resourceOptions = useMemo(() => {
    return resources.map((x) => ({ key: x.id, label: x.name, value: x.id })).sort(sortOptionByLabel);
  }, [resources]);

  const handleEndDateChange = (value: string | null): void => setDuration(setFieldValue, values.startDate, value);

  const handleStartDateChange = (value: string | null) => {
    setDuration(setFieldValue, value, values.endDate);
  };

  const handleDurationChange = (newDuration: string | number = 0): void => {
    if ((dayjs(values.startDate).isValid() || !values.startDate) && (dayjs(values.endDate).isValid() || !values.endDate))
      setDatesFromDuration(setFieldValue, values.startDate, values.endDate, newDuration, values.eventType);
  };

  const setDuration = (setFieldValue: (name: string, value: number) => void, startDate: string | Date | null, endDate: string | Date | null) => {
    if (dayjs(startDate).isValid() && dayjs(endDate).isValid()) {
      setFieldValue('duration', getTaskDurationInDays(startDate, endDate));
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={6}>
        <SelectField label={t('resource')} name="resourceId" options={resourceOptions} disabled={disabled} />
      </Grid>

      <Grid item md={6}>
        <KeyboardDateTimePickerField name="startDate" label={t('startDate')} disabled={disabled} onChange={handleStartDateChange} />
      </Grid>

      <Grid item md={6}>
        <KeyboardDateTimePickerField
          name="endDate"
          label={t('endDate')}
          minDate={values.startDate && dayjs(values.startDate).add(1, 'd')}
          disabled={disabled}
          onChange={handleEndDateChange}
        />
      </Grid>

      <Grid item md={6}>
        <NumberField label={t('durationInDays')} name="duration" onChange={handleDurationChange} disabled={disabled} />
      </Grid>

      <Grid item md={6}>
        <TextField label={t('tooltipNote')} name="tooltipNote" disabled={disabled} />
      </Grid>

      <Grid item md={6}>
        <TextField label={t('detailedComment')} name="detailedComment" disabled={disabled} />
      </Grid>
    </Grid>
  );
};

export default MaintenanceEventEditorFields;
