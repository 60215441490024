import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useDryingDistributionBarStyles = (fanDryPercentage: number): (() => ClassNameMap) =>
  makeStyles((theme: Theme) =>
    createStyles({
      bar: {
        display: 'flex',
        height: theme.spacing(4),
      },
      fanDryBar: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        width: fanDryPercentage + '%',
        background: '#686868',
        marginRight: theme.spacing(0.25),
        borderTopLeftRadius: theme.spacing(0.5),
        borderBottomLeftRadius: theme.spacing(0.5),
        whiteSpace: 'nowrap',
      },
      standardDryBar: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        width: 100 - fanDryPercentage + '%',
        background: '#686868',
        borderTopRightRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
        borderTopLeftRadius: fanDryPercentage === 0 ? theme.spacing(0.5) : 0,
        borderBottomLeftRadius: fanDryPercentage === 0 ? theme.spacing(0.5) : 0,
        whiteSpace: 'nowrap',
      },
      errorBar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        background: '#686868',
        borderRadius: theme.spacing(0.5),
      },
      dryingTypeText: {
        paddingLeft: theme.spacing(0.5),
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      dryingDetailText: {
        paddingLeft: theme.spacing(0.5),
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    })
  );

export default useDryingDistributionBarStyles;
