import { CssBaseline, ThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { enUS, frFR } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import React, { ReactElement, useMemo } from 'react';
import { frenchLocalesCode } from './i18n';

const secondaryMain = '#D03236';
const primaryMain = '#2b2b2f';
const primaryLight = '#c0c1c2';
const primaryDark = '#171717';
const primaryPaper = '#44444a';

const getTheme = (languageCode: string) =>
  createMuiTheme(
    {
      palette: {
        type: 'dark',
        primary: {
          main: primaryLight,
          light: primaryMain,
          dark: primaryDark,
        },
        secondary: {
          main: secondaryMain,
        },
        background: {
          default: primaryMain,
          paper: primaryPaper,
        },
        contrastThreshold: 4,
      },
      overrides: {
        MuiDialog: {
          paper: {
            backgroundColor: primaryMain,
            color: primaryLight,
          },
        },
      },
      typography: {
        fontFamily: 'Barlow, sans-serif',
        body1: {
          fontWeight: 600,
        },
      },
    },
    languageCode === frenchLocalesCode ? frFR : enUS
  );

const CssTemplate = ({ children, languageCode }: { children: ReactElement; languageCode: string }): ReactElement => {
  const theme = useMemo(() => getTheme(languageCode), [languageCode]);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

CssTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node]).isRequired,
};

export default CssTemplate;
