import i18next, { i18n, InitOptions, TFunction } from 'i18next';
import { useCallback, useState } from 'react';
import { initReactI18next } from 'react-i18next';

const useI18next = (): [(config: InitOptions) => Promise<TFunction>, boolean, i18n] => {
  const [isConfigurated, setState] = useState(false);

  const init = useCallback((config) => i18next.use(initReactI18next).init(config, () => setState(true)), []);

  return [init, isConfigurated, i18next];
};

export default useI18next;
