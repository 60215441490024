import { Box, Button, Card, CardContent, Collapse, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField } from '../../layout/fields';
import { LumberVariety } from '../../planning/model';
import { ResourceLumberWeight } from '../model';
import RailLumberWeight from '../model/RailLumberWeight';

const useStyles = makeStyles(() =>
  createStyles({
    collapseRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    lumberLabel: {
      maxWidth: '75%',
      whiteSpace: 'pre-line',
    },
    numberField: {
      maxWidth: '25%',
    },
  })
);

type LumberWeightFieldProps = {
  lumberVarieties: LumberVariety[];
  lumberWeights: ResourceLumberWeight[] | RailLumberWeight[];
  prefix: string;
};

const LumberWeightField = ({ lumberVarieties, prefix, lumberWeights }: LumberWeightFieldProps): ReactElement => {
  const styles = useStyles();
  const { t } = useTranslation('settings');
  const [isCollapseBoxOpen, setIsCollapseBoxOpen] = useState(false);

  const getLumberVarietyName = useCallback(
    (varietyId) => {
      const currentVariety = lumberVarieties.find((x) => x.id === varietyId);
      return currentVariety ? `${currentVariety.englishName}/${currentVariety.frenchName}` : '';
    },
    [lumberVarieties]
  );

  return (
    <>
      <Grid item md={12} sm={12} className={styles.collapseRow}>
        <Typography variant="body1">{t('lumberWeights')}</Typography>
        <Button onClick={() => setIsCollapseBoxOpen(!isCollapseBoxOpen)}>{isCollapseBoxOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Button>
      </Grid>

      <Collapse in={isCollapseBoxOpen}>
        <Grid container spacing={2}>
          {lumberWeights.map((lumberWeight, index) => (
            <Grid key={index} item md={6} sm={12}>
              <Card variant="outlined">
                <CardContent className={styles.collapseRow}>
                  <Typography className={styles.lumberLabel} noWrap>
                    {getLumberVarietyName(lumberWeight.lumberVarietyId)}
                  </Typography>
                  <Box className={styles.numberField}>
                    <NumberField name={`${prefix}.${index}.weight`} label={t('weight')} decimalScale={0} max={5} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </>
  );
};

export default LumberWeightField;
