import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Recipe } from '../../model';

const url = '/api/v1/recipes';

export type getRecipesResponse = { recipes: Recipe[] };

const getRecipes = (hideArchivedRecipes = true, recipeType: string | undefined = undefined): Promise<AxiosResponse<getRecipesResponse>> =>
  axios.get(url, { params: { hideArchivedRecipes, recipeType } });

const useGetRecipes = (): [Agent<getRecipesResponse>, getRecipesResponse | undefined, boolean, () => void] => useAgent<getRecipesResponse>(getRecipes);

export default useGetRecipes;
