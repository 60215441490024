import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { splitDateAndTime } from '../../common/helpers/date';
import ConfirmationDialog from '../../layout/dialog/ConfirmationDialog';
import { Alert } from '../Alert';
import { useAcknowledgeAlert, useGetActiveAlert } from '../hooks';

const Alerts = (): ReactElement | null => {
  const [getActiveAlert, alert = {} as Alert] = useGetActiveAlert();
  const [acknowledgeAlert] = useAcknowledgeAlert();
  const { t } = useTranslation('alerts');
  const [isDialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    getActiveAlert();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only fetch on mount
  }, []);

  useEffect(() => {
    if (alert.id && alert.requiresUserAcknowledgement) setDialogOpen(true);
  }, [alert]);

  const handleConfirm = useCallback(() => {
    setDialogOpen(false);
    acknowledgeAlert(alert.id);
  }, [alert.id, setDialogOpen, acknowledgeAlert]);

  // return (
  //   <Dialog open={isDialogOpen}>
  //     <DialogTitle>{t('title')}</DialogTitle>
  //     <DialogContent className={classes.contentText}>{t('dataImportFailureMessage', { ...splitDateAndTime(alert.date) })}</DialogContent>
  //     <DialogActions className={classes.actions}>
  //       <Button onClick={handleConfirm} className={classes.confirmButton}>
  //         {t('common:confirm')}
  //       </Button>
  //     </DialogActions>
  //   </Dialog>
  // );
  return (
    <ConfirmationDialog
      onDismiss={() => undefined}
      open={isDialogOpen}
      onConfirm={handleConfirm}
      hideCancel
      title={t('title')}
      messages={[t('dataImportFailureMessage', { ...splitDateAndTime(alert.date) })]}
    />
  );
};

export default Alerts;
