/* eslint-disable react/display-name -- inline render cells without names */
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import React from 'react';
import { Door } from '../../model';

export const getColumns = (
  t: (key: string) => string,
  canUpdate: boolean,
  onEditClick: (row: Door) => void,
): GridColDef[] => {
  const columns: GridColDef[] = [];

  if (canUpdate) {
    columns.push({
      field: '',
      headerName: t('common:actions'),
      width: 100,
      sortable: false,
      editable: false,
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          {canUpdate && (
            <Button onClick={() => onEditClick(row as Door)}>
              <EditIcon fontSize="small" />
            </Button>
          )}
        </>
      ),
    });
  }

  columns.push(
    {
      field: 'name',
      headerName: t('doors.name'),
      width: 125,
      sortable: false,
      editable: false,
    },
    {
      field: 'active',
      headerName: t('doors.active'),
      width: 100,
      sortable: false,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => (params.value ? t('common:yes') : t('common:no')),
      type: 'singleSelect',
      valueOptions: [t('common:yes'), t('common:no')],
    },
    {
      field: 'factoryName',
      headerName: t('doors.factory'),
      width: 125,
      sortable: false,
      editable: false,
    },
    {
      field: 'identifier',
      headerName: t('doors.identifier'),
      width: 125,
      sortable: false,
      editable: false,
    },
    {
      field: 'capacity',
      headerName: t('doors.capacity'),
      width: 170,
      sortable: false,
      editable: false,
    },
    {
      field: 'displayIndex',
      headerName: t('doors.displayIndex'),
      flex: 1,
      sortable: true,
      editable: false,
    },
    {
      field: 'comment',
      headerName: t('doors.comment'),
      flex: 1,
      sortable: false,
      editable: false,
    },
  );

  return columns;
};

/* eslint-enable react/display-name -- inline render cells without names */

export default { getColumns };
