import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import useAgent, { Agent } from '../../hooks/useAgent';
import { AvailableRail } from '../model';

const url = '/api/v1/available-rails';

export type getAvailableRailsResponse = { availableRails: AvailableRail[] };

const getAvailableRails = (eventEndDate: Date, productIds: string[], recipeIds: number[]): Promise<AxiosResponse<getAvailableRailsResponse>> =>
  axios.get(url, { params: { eventEndDate, productIds, recipeIds }, paramsSerializer: (params) => qs.stringify(params) });

const useGetAvailableRails = (): [Agent<getAvailableRailsResponse>, getAvailableRailsResponse | undefined, boolean, () => void] =>
  useAgent<getAvailableRailsResponse>(getAvailableRails);

export default useGetAvailableRails;
