import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Recipe } from '../../model';

const url = '/api/v1/recipes';

export type createRecipeResponse = { recipe: Recipe };

const createRecipe = (recipeRequest: Recipe): Promise<AxiosResponse<createRecipeResponse>> => axios.post(url, recipeRequest);

const useCreateRecipe = (): [Agent<createRecipeResponse>, createRecipeResponse | undefined, boolean, () => void] =>
  useAgent<createRecipeResponse>((recipe: Recipe) => createRecipe(recipe));

export default useCreateRecipe;
