export type SeverityEnumValues = 'Severe' | 'Medium' | 'Light';
export const SeverityEnum = {
  Severe: 'Severe' as SeverityEnumValues,
  Medium: 'Medium' as SeverityEnumValues,
  Light: 'Light' as SeverityEnumValues,
};

export type EventFlag = {
  severity: SeverityEnumValues;
  description: string;
};

export const getSeverityColor = (severity: SeverityEnumValues): string => {
  switch (severity) {
    case SeverityEnum.Severe:
      return 'red';
    case SeverityEnum.Medium:
      return 'orange';
    case SeverityEnum.Light:
      return 'white';
    default:
      return 'black';
  }
};

export default EventFlag;
