import { ClassNameMap } from '@material-ui/styles';
import { useFormikContext } from 'formik';
import _get from 'lodash.get';
import React, { ReactElement, RefObject } from 'react';
import SelectInput, { Option } from './SelectInput';

type SelectFieldProps = {
  disabled?: boolean;
  clearable?: boolean;
  classes?: ClassNameMap<string>;
  label?: string;
  inputRef?: RefObject<ReactElement>;
  name?: string;
  onChange?: (arg: Record<string, unknown> | string | number) => void;
  options?: Option[];
  missingOptionFormatter?: (value: string | number) => string;
};

const SelectField = ({
  disabled = false,
  clearable = true,
  classes = {},
  inputRef = undefined,
  label = '',
  name = '',
  options = [],
  onChange = () => void 0,
  missingOptionFormatter = (value: string | number) => String(value),
  ...props
}: SelectFieldProps): ReactElement => {
  const { errors, handleChange, touched, values } = useFormikContext();

  const isFieldTouched = _get(touched, name);
  const error = _get(errors, name);

  const handleValueChange = ({ target }: { target: Record<string, unknown> | string | number }): void => {
    onChange(target);
    handleChange({ target });
  };

  return (
    <SelectInput
      {...props}
      classes={classes}
      inputRef={inputRef}
      missingOptionFormatter={missingOptionFormatter}
      label={label}
      name={name}
      onChange={handleValueChange}
      options={options}
      value={_get(values, name)}
      error={isFieldTouched && Boolean(error)}
      helperText={isFieldTouched && error}
      disabled={disabled}
      clearable={clearable}
    />
  );
};

export default SelectField;
