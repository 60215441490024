import { GridColDef } from '@mui/x-data-grid';

export const getColumns = (t: (key: string) => string): GridColDef[] => {
  const columns: GridColDef[] = [];

  columns.push(
    {
      field: 'month',
      headerName: t('month'),
      width: 125,
      sortable: false,
      editable: false,
    },
    {
      field: 'averageTemperature',
      headerName: t('averageTemperature'),
      width: 295,
      sortable: false,
      editable: false,
      align: 'right',
      headerAlign: 'right',
    }
  );

  return columns;
};

export default { getColumns };
