import { Button, Tooltip } from '@material-ui/core';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React from 'react';
import { formatDate } from '../../../../common/helpers/date';
import formatNumberWithAccounting from '../../../../common/helpers/numberFormatHelpers';
import { InfeedProduct, InfeedValues } from '../../../model';

/* eslint-disable react/display-name -- inline render cells without names */
export const getSelectedGreenProductsColumns = (
  t: (key: string) => string,
  buttonClass: string,
  onRemoveSelectedDriedProduct: (infeedProduct: InfeedProduct) => void,
  getFieldFromAvailableGreenProducts: (params: InfeedValues, field: string) => string | undefined | number | Date,
  disabled: boolean
): GridColDef[] => {
  const columns = [] as GridColDef[];

  if (!disabled)
    columns.push({
      field: '',
      headerName: '',
      width: 50,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Button className={buttonClass} onClick={() => onRemoveSelectedDriedProduct(row as InfeedProduct)}>
          <RemoveCircle fontSize="small" />
        </Button>
      ),
      sortable: false,
      editable: false,
    });

  columns.push(
    {
      field: 'lotId',
      headerName: t('lotId'),
      width: 125,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'productDescription',
      headerName: t('productDescription'),
      minWidth: 350,
      editable: false,
      renderCell: (params: GridValueFormatterParams) => {
        console.log("params:", params);
        return (
          <Tooltip title={params.value || ''}>
            <div className="monospace">{params.value}</div>
          </Tooltip>
        )
      },
    },
    {
      field: 'productCharacteristic',
      headerName: t('productCharacteristic'),
      minWidth: 170,
    },
    {
      field: 'locationName',
      headerName: t('location'),
      width: 130,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'factoryName',
      headerName: t('site'),
      editable: false,
    },
    {
      field: 'vendorName',
      headerName: t('vendor'),
      width: 200,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
      valueGetter: (params: GridValueGetterParams) => getFieldFromAvailableGreenProducts(params.row as InfeedValues, 'vendorName'),
    },
    {
      field: 'inLocationSince',
      headerName: t('inLocationSince'),
      width: 200,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => (dayjs(String(params.value)).isValid() ? formatDate(String(params.value)) : 'N/A'),
      valueGetter: (params: GridValueGetterParams) => getFieldFromAvailableGreenProducts(params.row as InfeedValues, 'inLocationSince'),
    },
    {
      field: 'availableQuantity',
      headerName: t('availableQuantity'),
      width: 195,
      valueFormatter: ({ value }: GridValueFormatterParams) => (Number(value) !== 0 ? `${formatNumberWithAccounting(Number(value), 0)}` : 'N/A'),
      valueGetter: (params: GridValueGetterParams) => getFieldFromAvailableGreenProducts(params.row as InfeedValues, 'availableQuantity'),
    },
    {
      field: 'quantity',
      headerName: t('quantity'),
      width: 165,
      editable: !disabled,
      type: 'number',
      valueFormatter: ({ value }: GridValueFormatterParams) => `${formatNumberWithAccounting(Number(value), 0)}`,
    }
  );

  return columns;
};
/* eslint-enable react/display-name -- inline render cells without names */
