import { TFunction } from 'i18next';
import _ from 'lodash';
import { Recipe, RecipeValues } from '../model';
import RecipeType from '../model/RecipeType';

export type RecipeOption = {
  isStarred: boolean;
  key: number;
  label: string;
  color: string;
  value: number;
};

const emptyRecipe = {
  isArchived: false,
  lumberVarietyId: null,
  name: null,
  recipeCode: null,
  recipeType: RecipeType.Standard,
  color: 'FFFFFF',
  maxMoistureDelta: 10,
  expectedBatchSize: 0,
};

export const mapRecipeToValues = (recipe: Recipe): RecipeValues => ({ ...emptyRecipe, ...recipe } as RecipeValues);

export const mapRecipesToOptions = (recipes: Recipe[]): RecipeOption[] => {
  // No translation are available for recipes at the moment, will include them at a later time
  const mappedRecipes = recipes.map((recipe: Recipe) => ({
    key: recipe.id,
    label: recipe.name,
    value: recipe.id,
    isStarred: recipe.isStarred,
    color: recipe.color,
  }));
  return _.orderBy(mappedRecipes, ['isStarred', 'label'], ['desc', 'asc']);
};

export const getRecipeGroups = (option: RecipeOption, t: TFunction): string =>
  option.isStarred ? t('settings:recipes:favorites') : t('settings:recipes:others');

export default { mapRecipesToOptions };
