import React, { CSSProperties, ReactElement } from 'react';
import Logo from './Logo';
import svg from './Logo-B.svg';

type LogoBProps = {
  width: number;
  height: number;
  styles?: CSSProperties;
};

const LogoB = ({ width, height, styles }: LogoBProps): ReactElement => (
  <Logo width={width} height={height} styles={styles}>
    <img alt="" src={svg} />
  </Logo>
);

export default LogoB;
