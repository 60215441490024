import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useSettingsTabStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row-reverse',
      paddingBottom: '1rem',
    },
    airDryHeader: {
      display: 'flex',
      padding: '1rem',
    },
    button: {
      width: '1em',
      height: '1em',
    },
    grid: {
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderDraggableContainer, .MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer':
        {
          flexDirection: 'row',
        },
      '& .Mui-error': {
        color: '#ff4343',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        overflow: 'visible',
        lineHeight: '1rem',
        whiteSpace: 'normal',
      },
    },
    gridFields: {
      margin: '0',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
    },
  })
);

export default useSettingsTabStyles;
