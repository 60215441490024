import { Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ClassNameMap } from '@material-ui/styles';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import _get from 'lodash.get';
import React from 'react';

type KeyboardDatePickerProps = {
  classes: ClassNameMap<string>;
  label: string;
  labelClasses?: Partial<ClassNameMap<string>>;
  maxDate?: string | null;
  minDate?: string | null;
  name: string;
  onChange?: (date: string | null) => void;
};

const KeyboardDatePickerField = ({
  classes = {},
  label = '',
  labelClasses = {},
  maxDate = null,
  minDate = null,
  name,
  onChange = () => undefined,
}: KeyboardDatePickerProps): JSX.Element => {
  const { errors = {}, setFieldValue, touched = {}, values = {} } = useFormikContext();

  const currentError = _get(errors, name);
  const isFieldTouched = _get(touched, name);

  const maxDateAsObject = maxDate ? dayjs(maxDate, 'YYYY-MM-DD') : undefined;
  const minDateAsObject = minDate ? dayjs(minDate, 'YYYY-MM-DD') : undefined;

  return (
    <KeyboardDatePicker
      maxDate={maxDateAsObject}
      minDate={minDateAsObject}
      className={classes.root}
      helperText={isFieldTouched && currentError}
      error={isFieldTouched && Boolean(currentError)}
      format="YYYY-MM-DD"
      label={
        <Typography classes={labelClasses} variant="body2" component="label" htmlFor={name}>
          {label}
        </Typography>
      }
      onChange={(date) => {
        const formattedDate = date ? dayjs(date).format() : null;
        setFieldValue(name, formattedDate, true);
        onChange(formattedDate);
      }}
      value={_get(values, name)}
      id={name}
      InputProps={{
        disableUnderline: true,
      }}
    />
  );
};

export default KeyboardDatePickerField;
