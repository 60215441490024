import { Door, DoorValues, LumberVariety } from '../model';

const emptyDoor = {
  name: null,
  active: true,
  factoryId: null,
  identifier: null,
  capacity: null,
  comment: null,
  doorLumberWeights: []
};

export const mapDoorToValues = (door: Door, lumberVarieties: LumberVariety[]): DoorValues => {
  const values = { ...emptyDoor, ...door } as DoorValues;

  if (!door.doorLumberWeights?.length) {  // Tableau "Poids des essences" est vide, on initialise un tableau avec des valeurs par d�faut
    const newMappedLumberWeights = lumberVarieties.map((x) => ({ lumberVarietyId: x.id, weight: 5, printOrder: (x.printOrder !== undefined ? x.printOrder : 0) }));  
    const sortedMappedLumberWeights = newMappedLumberWeights.sort((a, b) => a.printOrder - b.printOrder);

    return { ...values, doorLumberWeights: sortedMappedLumberWeights };
  }
  else {  // Si cette porte poss�de d�j� des �l�ments dans son array Poids desssences
    for (const dlw of door.doorLumberWeights) {  // on boucle sur chaque �l�ment pour aller r�cup�rer le printOrder de la vari�t� de bois
      const lookupValue = lumberVarieties.find((lv) => lv.id === dlw.lumberVarietyId);  // Trouver la vari�t� de bois dans le array
      if (lookupValue !== null && lookupValue !== undefined) {
        dlw.printOrder = lookupValue.printOrder;
      }
      else {
        dlw.printOrder = 0;
      }
    }
    // Maintenant que le printOrder est rempli dans tous les �l�ments, on peut trier le tableau.
    const sortedMappedLumberWeights = door.doorLumberWeights.sort((a, b) => (a.printOrder && b.printOrder ? a.printOrder - b.printOrder : 0));

    return { ...values, doorLumberWeights: sortedMappedLumberWeights };
  }

  return values;
};

export default { mapDoorToValues };
