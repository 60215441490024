import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import useAgent, { Agent } from '../../hooks/useAgent';
import { AvailableProduct } from '../model';

const url = '/api/v1/available-green-products';

export type getAvailableGreenProductsResponse = { availableProducts: AvailableProduct[] };

const getAvailableGreenProducts = (
  recipeIds: number[],
  eventStartDate: string | Date,
  eventId: number
): Promise<AxiosResponse<getAvailableGreenProductsResponse>> =>
  axios.get(url, {
    params: { eventStartDate, recipeIds, eventId: Number.isInteger(eventId) ? eventId : null },
    paramsSerializer: (params) => qs.stringify(params),
  });

const useGetAvailableGreenProducts = (): [Agent<getAvailableGreenProductsResponse>, getAvailableGreenProductsResponse | undefined, boolean, () => void] =>
  useAgent<getAvailableGreenProductsResponse>(getAvailableGreenProducts);

export default useGetAvailableGreenProducts;
