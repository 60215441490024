import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Schwoop from '../../../layout/schwoop/Schwoop';
import { mapRailToValues } from '../../helpers/railHelpers';
import { useGetLumberVarieties } from '../../hooks/lumberVarieties';
import { Rail, RailValues } from '../../model';
import { getRailSchema } from '../../schemas/RailSchema';
import LumberWeightField from '../LumberWeightField';
import useSettingsTabStyles from '../SettingsTabStyles';
import RailEditorFormFields from './RailEditorFormFields';

type RailEditorProps = {
  rail: Rail;
  onClose: () => void;
  onSubmit: (values: RailValues) => void;
};

const RailEditor = ({ rail, onClose, onSubmit }: RailEditorProps): ReactElement => {
  const [getLumberVarieties, { lumberVarieties = [] } = {}] = useGetLumberVarieties();
  const classes = useSettingsTabStyles();
  const { t } = useTranslation('settings');
  const isNewRail: boolean = useMemo(() => !rail.id, [rail]);

  useEffect(() => {
    getLumberVarieties({ hideIsArchived: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- agents aren't memoized, only fetch on mount
  }, []);

  return (
    <Formik enableReinitialize initialValues={mapRailToValues(rail, lumberVarieties)} onSubmit={onSubmit} validationSchema={getRailSchema(t)}>
      {({ submitForm, values }) => {
        console.log("values:", values);
        return (<Form>
          <Schwoop open onCancel={onClose} onConfirm={submitForm} title={!isNewRail ? t('rails.editRail') : t('rails.newRail')}>
            <Grid container spacing={2} className={classes.gridFields}>
              <RailEditorFormFields />
              <LumberWeightField lumberVarieties={lumberVarieties} lumberWeights={values.railLumberWeights} prefix="railLumberWeights" />
            </Grid>
          </Schwoop>
        </Form>)
      }}
    </Formik>
  );
};

export default RailEditor;
