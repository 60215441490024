import React, { FunctionComponent } from 'react';
import LayoutPage from '../../layout/LayoutPage';
import Scheduler from './Scheduler';

const PlanningPage: FunctionComponent = () => (
  <LayoutPage>
    <Scheduler />
  </LayoutPage>
);

export default PlanningPage;
