const common = {
  actions: 'Actions',
  cancel: 'Cancel',
  confirm: 'Confirm',
  delete: 'Delete',
  messages: {
    accessDenied: 'Oops, you do not have permission to access this application.',
    somethingWrong: 'Something went wrong',
  },
  no: 'No',
  returnToPreviousPage: 'Return to previous page',
  yes: 'Yes',
};

export default common;
