import { createStyles, makeStyles } from '@material-ui/core';

const useSchedulerStyles = makeStyles(() =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dateFilters: {
      display: 'inherit',
    },
    viewPresetSelector: {
      marginRight: '20px',
    },
    headerActions: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
    },
    headerActionNewTask: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      height: '37px',
      borderRadius: '2px',
      backgroundColor: '#434343',
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
      color: '#FFFFFF',
      margin: '1em',
      border: 'none',
      paddingLeft: 16,
      paddingRight: 16,
    },
    completedButton: {
      backgroundColor: 'white',
      borderRadius: '2px',
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
      color: '#434343',
      margin: '1em',
      paddingLeft: 16,
      paddingRight: 16,
      border: 'none',
      whiteSpace: 'nowrap',
    },
    checkIcon: {
      color: 'green',
    },
    errorIcon: {
      color: 'red',
    },
    calculationInProgressButton: {
      height: '37px',
      paddingLeft: 16,
      paddingRight: 16,
      borderRadius: '2px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
      color: '#000000',
      margin: '1em',
      border: 'none',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },
    undoRedo: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      border: 'none',
    },
    resources: {
      fontWeight: 500,
    },
    newTaskButton: {
      marginRight: 10,
      whiteSpace: 'nowrap',
    },
  })
);
export default useSchedulerStyles;
