import { Grid, TextField, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _get from 'lodash.get';
import { Color, ColorPicker, createColor } from 'material-ui-color';
import React, { ReactElement, useState } from 'react';

type ColorPickerFieldProps = {
  label: string;
  name: string;
};

const ColorPickerField = ({ label, name }: ColorPickerFieldProps): ReactElement => {
  const { errors, setFieldValue, touched, values = {} } = useFormikContext();
  const [color, setColor] = useState(createColor(`#${_get(values, name)}`));

  const currentError = _get(errors, name);
  const isFieldTouched = _get(touched, name);

  const handleChange = (color: Color) => {
    setFieldValue(name, color.hex);
    setColor(color);
  };

  return (
    <>
      <Typography variant="body2">{label}</Typography>
      <Grid container direction="row" alignItems="flex-end">
        <ColorPicker value={color} onChange={handleChange} disableAlpha hideTextfield />
        <TextField value={color.hex} disabled error={isFieldTouched && Boolean(currentError)} helperText={isFieldTouched && currentError} />
      </Grid>
    </>
  );
};

export default ColorPickerField;
