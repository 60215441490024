import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import useAgent, { Agent } from '../../hooks/useAgent';
import { AvailableProduct } from '../model';

const url = '/api/v1/available-stacked-products';

export type getAvailableStackedProductsResponse = { availableProducts: AvailableProduct[] };

const getAvailableStackedProducts = (
  recipeIds: number[],
  eventStartDate: string | Date,
  eventId: number
): Promise<AxiosResponse<getAvailableStackedProductsResponse>> =>
  axios.get(url, {
    params: { eventStartDate, recipeIds, eventId: Number.isInteger(eventId) ? eventId : null },
    paramsSerializer: (params) => qs.stringify(params),
  });

const useGetAvailableStackedProducts = (): [Agent<getAvailableStackedProductsResponse>, getAvailableStackedProductsResponse | undefined, boolean, () => void] =>
  useAgent<getAvailableStackedProductsResponse>(getAvailableStackedProducts);

export default useGetAvailableStackedProducts;
