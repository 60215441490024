import { AbstractCrudManager, ColumnStore, SchedulerPro } from '@bryntum/schedulerpro';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react';
import { Column, DateHelper, ProjectCrudManagerClass, SchedulerResourceStore } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { Box, Button } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import axios from 'axios';
import { Formik } from 'formik';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, getFirstDayOfWeek, getLastDayOfWeek } from '../../common/helpers/date';
import useBearerToken from '../../hooks/useBearerToken';
import useErrorStore from '../../hooks/useErrorStore';
import useSignalr from '../../hooks/useSignalr';
import { DateRangePickerField } from '../../layout/fields';
import useSchedulerStyles from '../../layout/scheduler/schedulerStyles';
import usePostInventoryImport from '../hooks/usePostImportInventory';
import ResourceFilters from './filters/ResourceFilters';
import getSchedulerConfig from './schedulerConfig';

const InventoryScheduler = (): JSX.Element => {
  const today = new Date();
  const hubUrl = `${process.env.REACT_APP_API_URL}/api/v1/notifications`;
  const initialSchedulerDates = { startDate: DateHelper.add(today, -2, 'days'), endDate: DateHelper.add(today, 2, 'weeks') };
  const [schedulerDates, setSchedulerDates] = useState(initialSchedulerDates);
  const [resourceStore, setResourceStore] = useState<SchedulerResourceStore | null>(null);
  const [project, setProject] = useState<ProjectCrudManagerClass | null>(null);
  const [scheduler, setScheduler] = useState<SchedulerPro | null>(null);
  const [latestBearerToken, getBearerToken] = useBearerToken();
  const [postInventoryImport] = usePostInventoryImport();
  const { on } = useSignalr(hubUrl);
  const [, errorActions] = useErrorStore();

  const classes = useSchedulerStyles();
  const { t } = useTranslation();

  const formattedSchedulerDates = useMemo(() => {
    return { startDate: formatDate(schedulerDates.startDate), endDate: formatDate(schedulerDates.endDate) };
  }, [schedulerDates.startDate, schedulerDates.endDate]);

  const schedulerRef = useCallback((node) => {
    if (node !== null) {
      setResourceStore(node.instance.resourceStore);
      setProject(node.instance.project);
      setScheduler(node.instance);
    }
  }, []);

  const getLoadRequest = () => {
    return {
      request: {
        params: { startDate: getFirstDayOfWeek(schedulerDates.startDate), endDate: getLastDayOfWeek(schedulerDates.endDate) },
      },
    };
  };

  useEffect(() => {
    if (project && latestBearerToken) {
      project.on('beforeSend', ({ requestConfig }: { requestConfig: { headers: { Authorization: string | undefined; 'Accept-Language': string } } }) => {
        getBearerToken();
        requestConfig.headers = requestConfig.headers || {};
        requestConfig.headers.Authorization = latestBearerToken || '';
        requestConfig.headers['Accept-Language'] = axios.defaults.headers['Accept-Language'];
      });
    }
  }, [project, latestBearerToken, getBearerToken]);

  useEffect(() => {
    if (project && scheduler && latestBearerToken) {
      project.load(getLoadRequest()).then(() => {
          if (scheduler && scheduler.columns) {
              const columnStore = scheduler.columns as ColumnStore; 
              scheduler.refreshColumn(columnStore.getById('chartColumn') as Column);
          }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- hook not memoized
  }, [latestBearerToken, project, scheduler, schedulerDates.startDate, schedulerDates.endDate]);

  const handleSchedulerDateChange = (firstDate: string, lastDate: string) => {
    const startDate = new Date(firstDate);
    const endDate = new Date(lastDate);
    if (!DateHelper.isValidDate(startDate) || !DateHelper.isValidDate(endDate)) return;
    setSchedulerDates({ startDate, endDate });
  };

  const reinitializeSchedulerDates = (setFieldValue: (field: string, value: string) => void) => {
    setFieldValue('startDate', formatDate(initialSchedulerDates.startDate));
    setFieldValue('endDate', formatDate(initialSchedulerDates.endDate));
    setSchedulerDates(initialSchedulerDates);
  };
    

  const handleInventoryImportSuccess = useCallback(
    async () => {
      if (project && scheduler && latestBearerToken) {
        project.load(getLoadRequest()).then(() => {
            if (scheduler && scheduler.columns) {
                const columnStore = scheduler.columns as ColumnStore; 
                scheduler.refreshColumn(columnStore.getById('chartColumn') as Column);
            }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- getLoadRequest not memoized
    [project, scheduler, latestBearerToken]
  );

  useEffect(() => {
    const sub = on('inventoryImportSuccess').subscribe(() => {
      if (project && latestBearerToken) handleInventoryImportSuccess();
    });

    return () => sub.unsubscribe();
  }, [latestBearerToken, project, handleInventoryImportSuccess, on]);

  const handleInventoryImportError = useCallback(async () => {
    errorActions.setErrorMessage(t('planning:messages.inventoryImportError'));
  }, [errorActions, t]);

  useEffect(() => {
    const sub = on('inventoryImportError').subscribe(() => {
      if (project && latestBearerToken) handleInventoryImportError();
    });

    return () => sub.unsubscribe();
  }, [latestBearerToken, project, handleInventoryImportError, on]);

  const projectListeners = useMemo(
    () => ({
      loadFail: ({ response, source }: { response: { messages: string[] }; source: AbstractCrudManager }) => {
        if (!response) {
          getBearerToken();
          source.load(getLoadRequest());
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- getBearerToken
    [errorActions]
  );

  return (
    <Fragment>
      <Box className={classes.header}>
        <Formik initialValues={formattedSchedulerDates} onSubmit={() => undefined}>
          {({ setFieldValue }) => (
            <>
              <Box className={classes.headerActions}>
                <Box className={classes.dateFilters}>
                  <DateRangePickerField
                    startDateName="startDate"
                    startDateLabel={t('planning:from')}
                    endDateLabel={t('planning:to')}
                    endDateName="endDate"
                    onChange={handleSchedulerDateChange}
                  />
                  <Button color="primary" className={classes.button} onClick={() => reinitializeSchedulerDates(setFieldValue)}>
                    {t('planning:today')}
                  </Button>
                </Box>
                <Box className={classes.headerActions}>
                  <Box>{resourceStore && <ResourceFilters resourceStore={resourceStore} />}</Box>
                </Box>
              </Box>
              <Box className={classes.headerActionNewTask}>
                <Button color="primary" className={classes.button} startIcon={<CachedIcon />} onClick={() => postInventoryImport()}>
                  {t('planning:refresh')}
                </Button>
              </Box>
            </>
          )}
        </Formik>
      </Box>
      <BryntumSchedulerPro
        id={'InventoryScheduler'}
        ref={schedulerRef}
        {...getSchedulerConfig(t, projectListeners)}
        startDate={schedulerDates.startDate}
        endDate={schedulerDates.endDate}
        barMargin={3}
      />
    </Fragment>
  );
};

export default InventoryScheduler;
