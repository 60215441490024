import { GridCellValue } from '@mui/x-data-grid';
import _get from 'lodash.get';
import orderby from 'lodash.orderby';
import { AirDryKiln } from '../model';

export type AirDryFunctionData = {
  id: number;
  [key: number]: number;
};

export type AirDryKilnFunctionsOption = {
  key: number;
  label: string;
  value: number;
};
const functionIds = Array.from({ length: 4 }, (_, i) => i + 1);

export const getAirDryKilnChartData = (airDryKilns: AirDryKiln[]): AirDryFunctionData[] => {
  const weeks = Array.from({ length: 52 }, (_, i) => i + 1);

  const data: AirDryFunctionData[] = functionIds.map((n) => ({ id: n }));
  data.forEach((d) => {
    weeks.forEach((w) => {
      const kilnData = airDryKilns.find((a) => a.id == w);
      d[w] = kilnData ? _get(kilnData, `humidityValue${d.id}`) : 0;
    });
  });
  return data;
};

export const mapAirDryKilnFunctionToOptions = (): AirDryKilnFunctionsOption[] => {
  const mappedAirDryKilnFunctions = functionIds.map((functionId: number) => ({
    key: functionId,
    label: String(functionId),
    value: functionId,
  }));
  return orderby(mappedAirDryKilnFunctions, ['label'], ['asc']);
};

export const isHumidityLossValid = (value: GridCellValue): boolean => value !== null && typeof value === 'number' && Number(value) >= 0 && Number(value) < 10;
