import { Option } from '../../common/helpers/options';
import { LumberVariety, LumberVarietyValues } from '../model';

const emptyLumberVariety = {
  frenchName: null,
  englishName: null,
  printOrder: 0,
  initialMoisture: null,
  targetMoisture: null,
  heatingDegreesPerHour: 2,
  fanDryMoistureLossPerHourOverThirtyPercent: null,
  standardDryMoistureLossPerHourUnderThirtyPercent: null,
  standardDryMoistureLossPerHourOverThirtyPercent: null,
  fanDryTemperature: 20,
  standardDryTemperature: 75,
  minimalHoursUnderThirtyPercentMoisture: null,
  moistureBalancingHours: null,
  isArchived: false,
  factory1FunctionId: null,
  factory2FunctionId: null,
  targetFanDryMoisturePercentage: 30,
};

export const mapLumberVarietyToValues = (lumberVariety: LumberVariety): LumberVarietyValues =>
  ({ ...emptyLumberVariety, ...lumberVariety } as LumberVarietyValues);

export const toOption = (lumberVariety: LumberVariety): Option => ({
  key: lumberVariety.id,
  label: `${lumberVariety.englishName}/${lumberVariety.frenchName}`,
  value: lumberVariety.id,
});
