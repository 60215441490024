import React, { FunctionComponent, ReactElement } from 'react';
import LayoutPage from '../../layout/LayoutPage';
import SettingsRouter from './SettingsRouter';

const SettingsPage: FunctionComponent = (): ReactElement => (
  <LayoutPage>
    <SettingsRouter />
  </LayoutPage>
);

export default SettingsPage;
