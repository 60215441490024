import { SchedulerAssignmentStore } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import _get from 'lodash.get';
import { ConfirmationDialogConfigs } from '../../layout/dialog/ConfirmationDialog';
import { PlanifEvent } from '../model/Event';
import { PlanifResource } from '../model/Resource';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const validateDryingEventDependencies = (draggedEvent: PlanifEvent, endDate: Date): boolean => {
  const productionTasks = draggedEvent.dependencyStore
    .getEventDependencies(draggedEvent)
    .filter((x) => x.from === draggedEvent.id)
    .map((x) => x.toEvent as PlanifEvent);
  if (!productionTasks || !productionTasks.length) return true;
  return productionTasks.every((productionTask) => dayjs(endDate).isSameOrBefore(productionTask.startDate));
};

export const validateOrderEventDependencies = (draggedEvent: PlanifEvent, startDate: Date, getMaxEndDate: (events: PlanifEvent[]) => Date): boolean => {
  const dryingTasks = draggedEvent.dependencyStore
    .getEventDependencies(draggedEvent)
    .filter((x) => x.to === draggedEvent.id)
    .map((x) => x.fromEvent as PlanifEvent);
  if (!dryingTasks || !dryingTasks.length) return true;
  const minStartDate = getMaxEndDate(dryingTasks);
  return dayjs(startDate).isSameOrAfter(minStartDate);
};

export const getResourceIdFromAssigmentStore = (
  assignmentStore: SchedulerAssignmentStore | undefined,
  eventRecord: PlanifEvent | undefined
): number | undefined => {
  if (eventRecord && assignmentStore) {
    const [resource] = assignmentStore.getResourcesForEvent(eventRecord) as PlanifResource[];
    return (resource?.id as number) || undefined;
  }
  return;
};

const optimizationStatusValues = {
  ERROR: 'error',
  UNFEASIBLE: 'unfeasible',
};

export const handleOptimizationFail = async (
  status: string,
  getConfirmation: (config: ConfirmationDialogConfigs) => Promise<boolean>,
  t: (key: string) => string
): Promise<boolean> => {
  const confirmationValue = _get(optimizationStatusValues, status);

  return await getConfirmation({
    confirmationButtonProps: { autoFocus: true },
    messages: [t(`planning:optimization.${confirmationValue}Response`)],
    title: t(`planning:optimization.${confirmationValue}`),
    confirmationText: 'Ok',
    hideCancel: true,
  });
};
