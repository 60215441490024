import { ViewPreset } from '@bryntum/schedulerpro/schedulerpro.umd.js';

type ExtendedViewPreset = {
  base: string;
} & ViewPreset;

export const CustomViewPresetLocalStorageKey = 'CustomViewPreset';
export type CustomViewPreset = 'weekCustom' | 'weekAndDayCustom' | 'dayAndHourCustom';
export const CustomViewPreset = {
  weekCustom: 'weekCustom' as CustomViewPreset,
  weekAndDayCustom: 'weekAndDayCustom' as CustomViewPreset,
  dayAndHourCustom: 'dayAndHourCustom' as CustomViewPreset,
};

const viewPresets: Partial<ExtendedViewPreset>[] = [
  {
    id: CustomViewPreset.weekCustom,
    base: 'weekAndDayLetter',
    tickWidth: 15,
    headers: [
      {
        unit: 'week',
        dateFormat: 'DD-MMM',
      },
      {
        unit: 'day',
        dateFormat: 'D',
      },
    ],
  },
  {
    id: CustomViewPreset.weekAndDayCustom,
    base: 'weekAndDayLetter',
    tickWidth: 50,
    headers: [
      {
        unit: 'week',
        dateFormat: 'DD-MMM',
      },
      {
        unit: 'day',
        dateFormat: 'D',
      },
    ],
  },
  {
    id: CustomViewPreset.dayAndHourCustom,
    shiftIncrement: 1,
    shiftUnit: 'hour',
    tickWidth: 20,
    timeResolution: {
      unit: 'hour',
      increment: 1,
    },
    displayDateFormat: 'lll',
    headers: [
      {
        unit: 'day',
        dateFormat: 'DD-MMM',
      },
      {
        unit: 'hour',
        dateFormat: 'HH',
      },
    ],
  },
];

export default viewPresets;
