import { Grid, Tab, Tabs } from '@material-ui/core';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import _uniq from 'lodash.uniq';
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePrevious from '../../../../../hooks/usePrevious';
import { KeyboardDateTimePickerField, NumberField, SelectField, TextField } from '../../../../../layout/fields';
import { useGetMonthlyAverageTemperature } from '../../../../../settings/hooks/averageTemperature';
import { EventSubtypeEnum } from '../../../../enums/EventSubtypeEnum';
import { KilnEditorTabsEnum } from '../../../../enums/KilnEditorTabsEnum';
import { getLumberVarietyBalancingDuration, getLumberVarietyDryingDuration } from '../../../../helpers/eventHelpers';
import { EventValues } from '../../../../model';
import { PlanifResource } from '../../../../model/Resource';
import { EventEditorContext } from '../../EventEditorContext';
import RecipeMultiSelect from '../../RecipeMultiSelect';
import AvailableDoors from '../../common/AvailableDoors';
import OutfeedLocations from '../../common/OutfeedLocations';
import tabStyles from '../../tabStyles';
import AvailableStackedProducts from '../AvailableStackedProducts';
import DryingDistributionBar from '../DryingDistributionBar';
import DurationField from '../DurationField';
import { KilnEventEditorProps } from '../KilnEventEditor';

//J'ai ajouté ça juste pour empêcher VS d'effacer le React dans la ligne "import React, ...".
type _rddeact = typeof React

const KilnEventEditorFields = ({ disabled = false, recipes = [], resourceOptions }: KilnEventEditorProps): ReactElement => {
  const { setFieldValue, values } = useFormikContext<EventValues>();
  const { recipeIds, lumberVarieties } = useContext(EventEditorContext);
  const [getAverageTemperature, { monthlyAverageTemperature = [] } = {}] = useGetMonthlyAverageTemperature();
  const [currentTab, setCurrentTab] = useState<KilnEditorTabsEnum>(KilnEditorTabsEnum.rails);
  const { resources } = useContext(EventEditorContext);
  const { t } = useTranslation('planning');
  const classes = tabStyles();
  const previousDuration = usePrevious(values.duration);
  const previousInitialWoodMoisture = usePrevious(values.initialWoodMoisture);
  const previousTargetWoodMoisture = usePrevious(values.targetWoodMoisture);
  const [isStandard, setIsStandard] = useState(values.eventSubtype === EventSubtypeEnum.standard);

  const selectedLumberVarieties = useMemo(() => {
    if (!recipeIds.length) return [];
    const filteredRecipes = recipes.filter((recipe) => recipeIds.includes(recipe.id));
    if (!filteredRecipes.length) return [];
    const lumberVarietyIds = _uniq(filteredRecipes.map((x) => x.lumberVarietyId));
    return lumberVarieties.filter((x) => lumberVarietyIds.includes(x.id));
  }, [lumberVarieties, recipes, recipeIds]);

  useEffect(() => {
    getAverageTemperature(resources.find((x) => x.id === values.resourceId)?.factoryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, [values.resourceId]);

  const lumberVarietyDryingDuration = useMemo(() => {
    if (!selectedLumberVarieties.length || values.eventSubtype === EventSubtypeEnum.balancing) return null;
    const startDateToDate = new Date(values.startDate);
    const currentMonthAverageTemperature = startDateToDate && monthlyAverageTemperature.find((x) => x.month === startDateToDate.getMonth())?.averageTemperature;
    return getLumberVarietyDryingDuration(
      selectedLumberVarieties,
      values.initialWoodMoisture,
      values.targetWoodMoisture || 0,
      currentMonthAverageTemperature || 0
    );
  }, [monthlyAverageTemperature, selectedLumberVarieties, values.eventSubtype, values.initialWoodMoisture, values.startDate, values.targetWoodMoisture]);

  useEffect(() => setIsStandard(values.eventSubtype === EventSubtypeEnum.standard), [values.eventSubtype]);

  useEffect(() => {
    if (!selectedLumberVarieties.length) return;
    const targetWoodMoisture = selectedLumberVarieties.length ? Math.min(...selectedLumberVarieties.map((x) => x.targetMoisture)) : 70;
    if (values.targetWoodMoisture !== targetWoodMoisture) setFieldValue('targetWoodMoisture', targetWoodMoisture);
  }, [selectedLumberVarieties, setFieldValue, values.targetWoodMoisture]);

  const infeedInitialMoisture = useMemo(() => {
    const humidityValues = values.infeedProducts.map((x) => x.woodMoisture);
    return Math.max(...humidityValues, 0);
  }, [values.infeedProducts]);

  useEffect(() => {
    let newInitialWoodMoisture = infeedInitialMoisture;
    if (newInitialWoodMoisture === 0 && selectedLumberVarieties.length)
      newInitialWoodMoisture = Math.min(...selectedLumberVarieties.map((x) => x.initialMoisture));
    if (values.initialWoodMoisture !== newInitialWoodMoisture) setFieldValue('initialWoodMoisture', newInitialWoodMoisture);
  }, [selectedLumberVarieties, setFieldValue, values.initialWoodMoisture, infeedInitialMoisture]);

  useEffect(() => {
    if (
      !lumberVarietyDryingDuration ||
      (values.initialWoodMoisture === previousInitialWoodMoisture && values.targetWoodMoisture === previousTargetWoodMoisture)
    )
      return;
    const newDuration = lumberVarietyDryingDuration?.recommended;
    if (newDuration !== previousDuration) setFieldValue('duration', newDuration);
  }, [
    previousInitialWoodMoisture,
    previousTargetWoodMoisture,
    previousDuration,
    values.initialWoodMoisture,
    values.targetWoodMoisture,
    lumberVarietyDryingDuration,
    setFieldValue,
  ]);

  useEffect(() => (isStandard ? setCurrentTab(KilnEditorTabsEnum.rails) : setCurrentTab(KilnEditorTabsEnum.dryStock)), [isStandard]);

  useEffect(() => {
    if (isStandard || !selectedLumberVarieties.length) return;
    const newBalancingTaskDryingEndDate = dayjs(values.startDate).add(getLumberVarietyBalancingDuration(selectedLumberVarieties), 'day').toDate();
    setFieldValue('endDate', newBalancingTaskDryingEndDate);
  }, [isStandard, selectedLumberVarieties, setFieldValue, values.startDate]);

  return (
    <>
      <Grid item md={2}>
        <SelectField label={t('kiln')} name="resourceId" options={resourceOptions} disabled={disabled} />
      </Grid>

      <Grid item md={4}>
        <RecipeMultiSelect recipes={recipes} disabled={disabled} />
      </Grid>

      <Grid item md={1} className={classes.gridNoWrap}>
        <NumberField label={t('woodMoisture', { context: 'initial' })} name="initialWoodMoisture" disabled={!isStandard || disabled} />
      </Grid>

      <Grid item md={1} className={classes.gridNoWrap}>
        <NumberField label={t('woodMoisture', { context: 'target' })} name="targetWoodMoisture" disabled={!isStandard || disabled} />
      </Grid>

      <Grid item md={2}>
        <NumberField label={t('woodQuantity')} name="woodQuantity" min={0} disabled />
      </Grid>

      <Grid item md={4}>
        <KeyboardDateTimePickerField name="startDate" label={t('dryingStart')} disabled={disabled} />
      </Grid>

      <Grid item md={4}>
        <KeyboardDateTimePickerField
          name="endDate"
          label={t('dryingStop')}
          minDate={values.startDate && dayjs(values.startDate).add(1, 'd')}
          disabled={disabled}
        />
      </Grid>

      <Grid item md={2}>
        <DurationField
          label={t('dryingDuration')}
          name="duration"
          disabled={disabled}
          duration={values.duration || 0}
          recommendedDuration={(isStandard && lumberVarietyDryingDuration?.recommended) || 0}
          minimalDuration={(isStandard && lumberVarietyDryingDuration?.minimum) || 0}
        />
      </Grid>

      <Grid item md={2}>
        <NumberField label={t('workOrder')} name="workOrder" disabled />
      </Grid>

      <Grid item md={6}>
        <TextField label={t('tooltipNote')} name="tooltipNote" disabled={disabled} />
      </Grid>

      <Grid item md={6}>
        <TextField label={t('detailedComment')} name="detailedComment" disabled={disabled} />
      </Grid>

      <Grid item md={12}>
        <DryingDistributionBar duration={values.duration || 0} fanDryPercentage={isStandard ? lumberVarietyDryingDuration?.fanDryPercentage || 0 : 0} />
      </Grid>

      <Grid item md={6}>
        <Tabs
          classes={{
            root: classes.tabsRoot,
          }}
          value={currentTab}
          onChange={(_: unknown, value: KilnEditorTabsEnum) => setCurrentTab(value)}
          indicatorColor="primary">
          <Tab
            label={t('rails')}
            value={KilnEditorTabsEnum.rails}
            className={isStandard ? (currentTab === KilnEditorTabsEnum.dryStock ? classes.selectedTab : classes.tab) : classes.hidden}
          />
          <Tab
            label={t('dryStock')}
            value={KilnEditorTabsEnum.dryStock}
            className={isStandard ? classes.hidden : currentTab === KilnEditorTabsEnum.dryStock ? classes.selectedTab : classes.tab}
          />
          <Tab label={t('doors')} value={KilnEditorTabsEnum.doors} className={currentTab === KilnEditorTabsEnum.doors ? classes.selectedTab : classes.tab} />
        </Tabs>
      </Grid>

      <Grid item md={6} className={classes.outfeedChips}>
        {currentTab === KilnEditorTabsEnum.rails && <OutfeedLocations label={t('selectedDoors')} className={classes.outfeedChip} />}
      </Grid>

      <Grid item md={12}>
        {(currentTab === KilnEditorTabsEnum.dryStock || currentTab === KilnEditorTabsEnum.rails) && (
          <AvailableStackedProducts resourceCapacity={resources.find((x: PlanifResource) => x.id === values.resourceId)?.capacity} disabled={disabled} recipes={recipes} />
        )}
        {currentTab === KilnEditorTabsEnum.doors && <AvailableDoors disabled={disabled} />}
      </Grid>
    </>
  );
};

export default KilnEventEditorFields;
