import { MenuItem } from '@material-ui/core';
import React, { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../context/AppContext';
import { englishLocalesCode } from '../../../i18n';

const LanguageToggler: FunctionComponent = () => {
  const { t } = useTranslation('layout');
  const { toggleLanguage, languageCode } = useContext(AppContext);
  return <MenuItem onClick={() => toggleLanguage()}>{languageCode === englishLocalesCode ? t('french') : t('english')}</MenuItem>;
};

export default LanguageToggler;
