import * as yup from 'yup';
import RecipeType from '../model/RecipeType';

export const getRecipeSchema = (t: (key: string, values?: { number: number }) => string): yup.AnySchema =>
  yup.object().shape({
    color: yup.string().nullable().required(t('required')),
    name: yup.string().nullable().required(t('required')),
    recipeCode: yup
      .string()
      .nullable()
      .when('recipeType', {
        is: (value: string) => value == RecipeType.Standard,
        then: (schema) => schema.required(t('required')),
      }),
    recipeType: yup.string().nullable().required(t('required')),
    lumberVarietyId: yup
      .number()
      .nullable()
      .when('recipeType', {
        is: (value: string) => value == RecipeType.Standard,
        then: (schema) => schema.required(t('required')),
      }),
    maxMoistureDelta: yup
      .number()
      .min(0, t('equalOrHigherThan', { number: 0 }))
      .max(100, t('equalOrLesserThan', { number: 100 }))
      .nullable()
      .required(t('required')),
    expectedBatchSize: yup
      .number()
      .nullable()
      .when('recipeType', {
        is: (value: string) => value == RecipeType.Standard,
        then: (schema) => schema.positive(t('recipes.expectedBatchSizeRequired')).required(t('required')),
      }),
  });
