import { Typography } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { useFormikContext } from 'formik';
import _get from 'lodash.get';
import React, { ReactElement, useMemo, useState } from 'react';

type SliderFieldProps = {
  label?: string;
  max: number;
  min: number;
  name: string;
  onChange?: (value: number) => void;
  step?: number;
  suffix?: string;
};

const SliderField = ({ label = '', max, min, name, onChange = () => undefined, step = 1, suffix = '' }: SliderFieldProps): ReactElement => {
  const { setFieldValue, values } = useFormikContext();
  const [currentValue, setCurrentValue] = useState(_get(values, name) || min);
  const marks = useMemo(() => {
    const marks = [
      { value: min, label: `${min}${suffix}` },
      { value: max, label: `${max}${suffix}` },
    ];

    if (currentValue !== min && currentValue !== max) marks.push({ value: currentValue, label: `${currentValue}${suffix}` });

    return marks;
  }, [currentValue, max, min, suffix]);

  const handleChangeCommit = (value: number) => {
    onChange(value);
    setFieldValue(name, value);
  };

  return (
    <>
      <Typography variant="body2">{label}</Typography>
      <Slider
        max={max}
        min={min}
        marks={marks}
        onChange={(_, value: number | number[]) => setCurrentValue(value as number)}
        onChangeCommitted={(_, value: number | number[]) => handleChangeCommit(value as number)}
        step={step}
        value={currentValue}
        valueLabelDisplay="auto"
      />
    </>
  );
};

export default SliderField;
