import { Box } from '@material-ui/core';
import { DataGrid, GridCellEditCommitParams, GridEditRowsModel } from '@mui/x-data-grid';
import _get from 'lodash.get';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Can } from '../../../abilities/AbilityContext';
import { CommonAbilityEnum } from '../../../abilities/CommonAbilityEnum';
import { ResourceEnum } from '../../../abilities/ResourceEnum';
import { getAirDryKilnChartData, isHumidityLossValid } from '../../helpers/airDryChartHelper';
import { useGetAirDryKilns, useUpdateAirDryKilns } from '../../hooks/airDryKilns';
import { AirDryKiln } from '../../model';
import useSettingsTabStyles from '../SettingsTabStyles';
import { getAirDryKilnChartColumns } from './AirDryChartColumns';

const AirDryChartPage = (): React.ReactElement => {
  const classes = useSettingsTabStyles();
  const [getAirDryKilns, { airDryKilns = [] } = {}, isFetching] = useGetAirDryKilns();
  const [updateAirDryKilns, , isUpdating] = useUpdateAirDryKilns();
  const [editRowsModel, setEditRowsModel] = useState<GridEditRowsModel>({});
  const { t } = useTranslation('settings');

  useEffect(() => {
    getAirDryKilns();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  const rowData = useMemo(() => (airDryKilns ? getAirDryKilnChartData(airDryKilns) : []), [airDryKilns]);

  const handleEditRowsModelChange = useCallback((newModel: GridEditRowsModel) => {
    const updatedModel = { ...newModel };
    Object.values(updatedModel).forEach((value) => {
      Object.keys(value).forEach((week) => {
        const humidityValue = value[week].value;
        const isValid = isHumidityLossValid(humidityValue);
        value[week] = { ...value[week], error: !isValid };
      });
    });
    setEditRowsModel(updatedModel);
  }, []);

  const handleCommit = (values: GridCellEditCommitParams) => {
    const newValue = Number(values.value) || 0.0;
    const airDryKiln = airDryKilns.find((x) => String(x.id) === values.field);
    const needUpdate = airDryKiln && _get(airDryKiln, `humidityValue${values.id}`) !== newValue;

    if (needUpdate) {
      const airDryKilnToUpdate: AirDryKiln = { id: Number(values.field) };
      airDryKilnToUpdate[`humidityValue${values.id}`] = newValue;
      updateAirDryKilns(airDryKilnToUpdate).then(() => {
        getAirDryKilns();
      });
    }
  };

  return (
    <>
      <Box className={classes.airDryHeader}>{t('airDry.chartNote')}</Box>
      <Can I={CommonAbilityEnum.Update} a={ResourceEnum.Settings} passThrough>
        {(canUpdate: boolean) => (
          <DataGrid
            density="compact"
            rows={rowData}
            columns={getAirDryKilnChartColumns(canUpdate)}
            loading={isFetching || isUpdating}
            autoHeight
            disableColumnFilter
            hideFooter
            disableColumnSelector
            disableSelectionOnClick
            className={classes.grid}
            onCellEditCommit={handleCommit}
            editRowsModel={editRowsModel}
            onEditRowsModelChange={handleEditRowsModelChange}
          />
        )}
      </Can>
    </>
  );
};

export default AirDryChartPage;
