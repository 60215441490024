import { InputBaseComponentProps, TextField, Typography } from '@material-ui/core';
import React, { ReactElement, Ref, useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { nonBreakingSpace } from '../../common/helpers/numberFormatHelpers';

type DecimalNumberFormatProps = {
  inputRef?: ((el: HTMLInputElement) => void) | Ref<HTMLDivElement> | undefined;
  name?: string;
  suffix?: string;
  prefix?: string;
  min?: number;
  max?: number;
  decimalScale?: number;
  onChange?: ({ target: { name, value } }: { target: { name: string; value: string } }) => void;
};

const DecimalNumberFormat = ({
  inputRef,
  name = '',
  onChange,
  prefix,
  suffix,
  min,
  max,
  decimalScale = 0,
  ...other
}: DecimalNumberFormatProps): ReactElement => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (onChange) {
          onChange({
            target: {
              name: name,
              value: values.value,
            },
          });
        }
      }}
      allowNegative={false}
      suffix={suffix}
      prefix={prefix}
      thousandSeparator={nonBreakingSpace}
      isAllowed={({ floatValue = 0 }) => {
        let result = true;

        if (min) {
          result = result && floatValue >= min;
        }
        if (max) {
          result = result && floatValue <= max;
        }

        return result;
      }}
      isNumericString
      decimalScale={decimalScale}
      fixedDecimalScale
    />
  );
};

type NumberInputProps = DecimalNumberFormatProps & {
  error: boolean;
  disabled?: boolean;
  helperText?: string;
  label: string;
  onBlur?: ({ target: { name, value } }: { target: { name: string; value: string | number | null } }) => void;
  value: string;
};

const NumberInput = ({
  error = false,
  helperText,
  inputRef,
  label = '',
  name = '',
  onBlur,
  onChange,
  value = '',
  disabled = false,
  ...rest
}: NumberInputProps): JSX.Element => {
  const handleBlur = useCallback(
    (event) => {
      if (onBlur) onBlur({ target: { name, value: event.target.value } });
    },
    [name, onBlur]
  );

  return (
    <TextField
      disabled={disabled}
      helperText={helperText}
      error={error}
      id={name}
      inputRef={inputRef}
      autoComplete="off"
      fullWidth
      label={<Typography variant="body2">{label}</Typography>}
      name={name}
      onBlur={handleBlur}
      onChange={onChange}
      value={value}
      inputProps={{ ...rest }}
      InputProps={{
        inputComponent: DecimalNumberFormat as React.ElementType<InputBaseComponentProps>,
      }}
    />
  );
};

export default NumberInput;
