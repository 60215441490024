import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import useAgent, { Agent } from '../../hooks/useAgent';
import { AvailableProduct } from '../model';

const url = 'api/v1/available-products-to-balance';

export type getAvailableProductsToBalanceResponse = { availableProducts: AvailableProduct[] };

const getAvailableProductsToBalance = (
  recipeIds: number[],
  eventStartDate: string | Date,
  eventId: number
): Promise<AxiosResponse<getAvailableProductsToBalanceResponse>> =>
  axios.get(url, {
    params: { eventStartDate, recipeIds, eventId: Number.isInteger(eventId) ? eventId : null },
    paramsSerializer: (params) => qs.stringify(params),
  });

const useGetAvailableProductsToBalance = (): [
  Agent<getAvailableProductsToBalanceResponse>,
  getAvailableProductsToBalanceResponse | undefined,
  boolean,
  () => void
] => useAgent<getAvailableProductsToBalanceResponse>(getAvailableProductsToBalance);

export default useGetAvailableProductsToBalance;
