import { Button } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import StopIcon from '@material-ui/icons/Stop';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useConfirmationDialog from '../../../layout/dialog/useConfirmationDialog';
import useSchedulerStyles from '../../../layout/scheduler/schedulerStyles';
import { useTerminateOptimization } from '../../hooks';

const OptimizationInProgressButton = (): JSX.Element => {
  const [isCancelOptimizationShown, setIsCancelOptimizationShown] = useState(false);
  const classes = useSchedulerStyles();
  const { t } = useTranslation();
  const [terminateOptimization] = useTerminateOptimization();
  const { getConfirmation } = useConfirmationDialog();

  const handleTerminateOptimization = useCallback(async () => {
    const confirm = await getConfirmation({
      confirmationButtonProps: { autoFocus: true },
      messages: [t('planning:optimization.terminateCalculation')],
      title: t('planning:optimization.terminate'),
      cancellationText: t('planning:cancel'),
    });
    if (confirm) terminateOptimization();
  }, [getConfirmation, t, terminateOptimization]);

  return (
    <>
      {isCancelOptimizationShown ? (
        <Button
          onMouseLeave={() => setIsCancelOptimizationShown(false)}
          onClick={handleTerminateOptimization}
          startIcon={<StopIcon color={'secondary'} />}
          className={classes.calculationInProgressButton}>
          {t('planning:stopCalculation')}
        </Button>
      ) : (
        <Button
          onMouseEnter={() => setIsCancelOptimizationShown(true)}
          onMouseLeave={() => setIsCancelOptimizationShown(false)}
          startIcon={<CachedIcon color={'secondary'} />}
          className={classes.calculationInProgressButton}>
          {t('planning:calculationInProgress')}
        </Button>
      )}
    </>
  );
};

export default OptimizationInProgressButton;
