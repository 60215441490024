import { Chip, Grid, Typography } from '@material-ui/core';
import { DataGrid, GridCellEditCommitParams, GridCellValue, GridEditRowsModel } from '@mui/x-data-grid';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import _uniq from 'lodash.uniq';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import formatNumberWithAccounting from '../../../../common/helpers/numberFormatHelpers';
import customDateColumnType from '../../../../layout/grid/CustomDateColumnType';
import { useGetAvailableDoors } from '../../../hooks';
import { EventValues } from '../../../model';
import useListStyles from '../listStyles';
import { getAvailableDoorsColumns } from './AvailableDoorsColumns';
import { getSelectedDoorsColumns } from './SelectedDoorsColumns';
import { LocationTypeEnum, useAvailableLocationsHelpers } from './useAvailableLocationsHelpers';

type AvailableDoorsProps = {
  disabled: boolean;
};

const AvailableDoors = ({ disabled = false }: AvailableDoorsProps): ReactElement => {
  const classes = useListStyles();
  const { t } = useTranslation('planning');
  const { setFieldValue, values } = useFormikContext<EventValues>();
  const [editRowsModel, setEditRowsModel] = React.useState<GridEditRowsModel>({});
  const [getAvailableDoors, { availableDoors = [] } = {}, isFetchingDoors, clearAvailableDoors] = useGetAvailableDoors();
  const { handleSelectLocation } = useAvailableLocationsHelpers(LocationTypeEnum.Door);

  const infeedQuantity = useMemo(() => {
    return values.infeedProducts.reduce((acc: number, infeedProduct) => acc + infeedProduct.quantity, 0);
  }, [values.infeedProducts]);

  const outfeedQuantity = useMemo(() => {
    return values.outfeedProducts.reduce((acc: number, outfeedProduct) => acc + outfeedProduct.quantity, 0);
  }, [values.outfeedProducts]);

  const selectableDoors = useMemo(() => {
    const tmpAvailableDoors = availableDoors.filter((x) => !values.outfeedProducts.some((y) => y.locationId === x.id));
    const filteredAvailableDoors = tmpAvailableDoors.filter((x) => x.active === true);
    return filteredAvailableDoors.sort((a, b) => a.displayIndex - b.displayIndex);    
  }, [availableDoors, values.outfeedProducts]);

  useEffect(() => {
    if (!values.infeedProducts.length) clearAvailableDoors();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- cannot include getter because useAgent is not memoized
  }, [values.infeedProducts]);

  useEffect(() => {
    if (values.endDate && values.infeedProducts.length)
      getAvailableDoors(values.endDate, _uniq(values.infeedProducts.map((x) => x.nextProductId)), _uniq(values.infeedProducts.map((x) => x.recipeId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- cannot include getter because useAgent is not memoized
  }, [values.endDate, values.eventSubtype, values.infeedProducts]);

  const handleRemoveDoor = (doorId: number): void =>
  {
    // Récupère la porte à effacer
    const outfeedProductToRemove = values.outfeedProducts.find((x) => x.locationId === doorId);

    // Efface la porte
    setFieldValue(
      'outfeedProducts',
      values.outfeedProducts.filter((x) => x.locationId !== doorId)
    );

    // Copie les quantités de la porte effacé dans la dernière porte, s'il y en a une
    const lastOutfeedProducts = _.last(values.outfeedProducts.filter((x) => x.locationId !== doorId));
    if (lastOutfeedProducts && outfeedProductToRemove)
    {
      lastOutfeedProducts.quantity += outfeedProductToRemove.quantity;
      lastOutfeedProducts.remainingCapacity -= outfeedProductToRemove.quantity;
    }

  }

  useEffect(() => {
    if (availableDoors.length) {
      let updateNeeded = false;
      const updatedOutfeedProducts = values.outfeedProducts.map((outfeedProduct) => {
        const outfeedProductDoor = availableDoors.find((x) => x.id === outfeedProduct.locationId) || { capacity: 0, quantity: 0 };
        const doorOutfeedProductsQuantity = values.outfeedProducts
          .filter((x) => x.locationId === outfeedProduct.locationId)
          .reduce((acc, p) => acc + p.quantity, 0);
        const remainingCapacity = outfeedProductDoor?.capacity - outfeedProductDoor?.quantity - doorOutfeedProductsQuantity;
        const totalCapacity = outfeedProductDoor?.capacity;
        if (outfeedProduct.totalCapacity !== totalCapacity || outfeedProduct.remainingCapacity !== remainingCapacity) updateNeeded = true;
        return {
          ...outfeedProduct,
          totalCapacity,
          remainingCapacity,
        };
      });

      if (updateNeeded) setFieldValue('outfeedProducts', updatedOutfeedProducts);
    }
  }, [setFieldValue, availableDoors, values.outfeedProducts]);

  const handleEditRowsModelChange = useCallback(
    (newModel: GridEditRowsModel) => {
      const updatedModel = { ...newModel };
      let value = 0;

      Object.entries(updatedModel).forEach(([id, row]) => {
        if (row.quantity?.value) value = Number(row.quantity?.value);
        row.quantity = {
          ...updatedModel[id].quantity,
          error: !(Number.isInteger(value) && value >= 0),
        };
      });

      setEditRowsModel(updatedModel);
    },
    [setEditRowsModel]
  );

  const updateFormikOnCellCommit = useCallback(
    (id: string | number, value: GridCellValue): void => {
      const formikIndex = values.outfeedProducts.findIndex((x) => x.id === id);
      setFieldValue(`outfeedProducts.${formikIndex}.quantity`, value);
    },
    [values.outfeedProducts, setFieldValue]
  );

  return (
    <>
      <Grid container justifyContent="space-between" className={classes.listTitle}>
        <Grid item>
          <Typography variant="h6">{t('selectedDoors')}</Typography>
        </Grid>
        <Grid item>
          <Chip label={`${formatNumberWithAccounting(outfeedQuantity, 0)} / ${formatNumberWithAccounting(infeedQuantity, 0)} PMP`} variant="outlined" />
        </Grid>
      </Grid>

      <DataGrid
        editRowsModel={editRowsModel}
        onEditRowsModelChange={handleEditRowsModelChange}
        columnTypes={{ customDate: customDateColumnType }}
        density="compact"
        rows={values.outfeedProducts}
        className={classes.grid}
        loading={isFetchingDoors}
        columns={getSelectedDoorsColumns(t, classes.button, handleRemoveDoor, disabled)}
        onCellEditCommit={({ id, value }: GridCellEditCommitParams) => updateFormikOnCellCommit(id, value)}
        autoHeight
        hideFooter
        disableColumnSelector
        disableSelectionOnClick
      />

      {!disabled && (
        <>
          <Typography variant="h6" className={classes.listTitle}>
            {t('availableDoors')}
          </Typography>
          <DataGrid
            density="compact"
            columnTypes={{ customDate: customDateColumnType }}
            rows={selectableDoors}
            className={classes.grid}
            loading={isFetchingDoors}
            columns={getAvailableDoorsColumns(t, classes.button, handleSelectLocation)}
            autoHeight
            hideFooter
            disableColumnSelector
            disableSelectionOnClick
          />
        </>
      )}
    </>
  );
};

export default AvailableDoors;
