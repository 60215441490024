import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { LumberVariety } from '../../model/LumberVariety';

const url = '/api/v1/lumber-varieties';

export type getVarietiesResponse = { lumberVarieties: LumberVariety[] };
type getVarietiesParams = { hideArchivedVarieties: boolean };

const getLumberVarieties = (params: getVarietiesParams = { hideArchivedVarieties: true }): Promise<AxiosResponse<getVarietiesResponse>> =>
  axios.get(url, { params });

const useGetLumberVarieties = (): [Agent<getVarietiesResponse>, getVarietiesResponse | undefined, boolean, () => void] =>
  useAgent<getVarietiesResponse>(getLumberVarieties);

export default useGetLumberVarieties;
