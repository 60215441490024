import { Grid } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxField, NumberField, SelectField, TextField } from '../../../layout/fields';
import { mapAirDryKilnFunctionToOptions } from '../../helpers/airDryChartHelper';
import useSettingsTabStyles from '../SettingsTabStyles';

const LumberVarietyEditorFormFields = ({ isNewVariety }: { isNewVariety: boolean }): ReactElement => {
  const { t } = useTranslation('settings');
  const classes = useSettingsTabStyles();
  const airDryKilnsFunctionsToOptions = mapAirDryKilnFunctionToOptions();

  return (
    <Grid container spacing={2} className={classes.gridFields}>
      <Grid item md={6} sm={6}>
        <TextField label={t('varieties.englishName')} name="englishName" />
      </Grid>
      <Grid item md={6} sm={6}>
        <TextField label={t('varieties.frenchName')} name="frenchName" />
      </Grid>
      <Grid item md={6} sm={6}>
        <NumberField label={t('varieties.printOrder')} name="printOrder" />
      </Grid>
      <Grid item md={6} sm={6}>
        <NumberField label={t('varieties.initialMoisture')} name="initialMoisture" />
      </Grid>
      <Grid item md={6} sm={6}>
        <NumberField label={t('varieties.targetMoisture')} name="targetMoisture" />
      </Grid>
      <Grid item md={6} sm={6}>
        <NumberField label={t('varieties.targetFanDryMoisture')} name="targetFanDryMoisturePercentage" />
      </Grid>
      <Grid item md={6} sm={6}>
        <NumberField label={t('varieties.fanDryTemperature')} name="fanDryTemperature" suffix="°C" disabled />
      </Grid>
      <Grid item md={6} sm={6}>
        <NumberField label={t('varieties.standardDryTemperature')} name="standardDryTemperature" suffix="°C" disabled />
      </Grid>
      <Grid item md={6} sm={12}>
        <NumberField label={t('varieties.fanDryMoistureLossPerHourOverThirtyPercent')} name="fanDryMoistureLossPerHourOverThirtyPercent" decimalScale={3} />
      </Grid>
      <Grid item md={6} sm={12}>
        <NumberField
          label={t('varieties.standardDryMoistureLossPerHourUnderThirtyPercent')}
          name="standardDryMoistureLossPerHourUnderThirtyPercent"
          decimalScale={3}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <NumberField
          label={t('varieties.standardDryMoistureLossPerHourOverThirtyPercent')}
          name="standardDryMoistureLossPerHourOverThirtyPercent"
          decimalScale={3}
        />
      </Grid>
      <Grid item md={6} sm={12}>
        <NumberField label={t('varieties.minimalHoursUnderThirtyPercentMoisture')} name="minimalHoursUnderThirtyPercentMoisture" />
      </Grid>
      <Grid item md={6}>
        <NumberField label={t('varieties.moistureBalancingHours')} name="moistureBalancingHours" />
      </Grid>
      <Grid item md={6}>
        <NumberField label={t('varieties.heatingDegreesPerHour')} name="heatingDegreesPerHour" disabled />
      </Grid>
      <Grid item md={3}>
        <SelectField label={t('varieties.factory1Function')} name="factory1FunctionId" options={airDryKilnsFunctionsToOptions} />
      </Grid>
      <Grid item md={3}>
        <SelectField label={t('varieties.factory2Function')} name="factory2FunctionId" options={airDryKilnsFunctionsToOptions} />
      </Grid>
      {!isNewVariety && (
        <Grid item md={6} sm={6}>
          <CheckboxField label={t('isArchived')} name="isArchived" />
        </Grid>
      )}
    </Grid>
  );
};

export default LumberVarietyEditorFormFields;
