import EnLocale from '@bryntum/schedulerpro/locales/schedulerpro.locale.En.js';
import { LocaleHelper } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import fr from '../../__i18n__/fr';

// Use english as base for french
const locale = LocaleHelper.mergeLocales(EnLocale, {
  SchedulerBase: {
    'Add event': fr.newTask,
  },
  UndoRedo: {
    UndoLastAction: fr.undoLastAction,
    RedoLastAction: fr.redoLastAction,
  },
});

export default locale;
