import * as yup from 'yup';
import { RailValues } from '../model';

export const getRailSchema = (t: (key: string, values?: { number: number }) => string): yup.SchemaOf<RailValues> =>
  yup.object().shape({
    id: yup.number().nullable(),
    name: yup.string().nullable().required(t('required')),
    identifier: yup.string().nullable().required(t('required')),
    factoryId: yup.number().nullable().required(t('required')),
    comment: yup.string().nullable(),
    capacity: yup
      .number()
      .min(0, t('equalOrHigherThan', { number: 0 }))
      .max(250, t('equalOrLesserThan', { number: 250 }))
      .nullable()
      .required(t('required')),
    railLumberWeights: yup.array().of(
      yup.object().shape({
        id: yup.number().nullable(),
        lumberVarietyId: yup.number().required(t('required')),
        railId: yup.number().nullable(),
        weight: yup.number().required(t('required')),
        printOrder: yup.number().nullable(),
      })
    ),
    displayId: yup.number().default(0),
  });
