import { InitOptions } from 'i18next';
import AlertsEn from './alerts/__i18n__/en';
import AlertsFr from './alerts/__i18n__/fr';
import CommonEn from './common/__i18n__/en';
import CommonFr from './common/__i18n__/fr';
import InventoryEn from './inventory/__i18n__/en';
import InventoryFr from './inventory/__i18n__/fr';
import LayoutEn from './layout/__i18n__/en';
import LayoutFr from './layout/__i18n__/fr';
import PlanningEn from './planning/__i18n__/en';
import PlanningFr from './planning/__i18n__/fr';
import SettingsEn from './settings/__i18n__/en';
import SettingsFr from './settings/__i18n__/fr';
import TransfersEn from './transfers/__i18n__/en';
import TransfersFr from './transfers/__i18n__/fr';

export const lngLocalStorageKey = 'lng';
export const englishLocalesCode = 'en';
export const frenchLocalesCode = 'fr-CA';

const config = (lng: string): InitOptions => {
  return {
    lng,
    fallbackLng: 'en',
    defaultNS: 'layout',
    resources: {
      en: {
        alerts: AlertsEn,
        common: CommonEn,
        layout: LayoutEn,
        planning: PlanningEn,
        settings: SettingsEn,
        inventory: InventoryEn,
        transfers: TransfersEn,
      },
      fr: {
        alerts: AlertsFr,
        common: CommonFr,
        layout: LayoutFr,
        planning: PlanningFr,
        settings: SettingsFr,
        inventory: InventoryFr,
        transfers: TransfersFr,
      },
    },
  };
};

export default config;
