import * as Sentry from '@sentry/react';
import React, { ReactElement } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import AbilityContext, { Abilities } from './abilities/AbilityContext';
import ErrorFallback from './ErrorFallback';
import LayoutPage from './layout/LayoutPage';
import { getRoutes } from './Routes';
import UnauthorizedPage from './UnauthorizedPage';

const SentryRoute = Sentry.withSentryRouting(Route);

const Router = (): ReactElement => {
  return (
    <BrowserRouter>
      <Switch>
        <>
          <AbilityContext.Consumer>
            {(abilities: Abilities) => {
              const routes = getRoutes(abilities);
              return routes.length ? (
                <Sentry.ErrorBoundary fallback={({ error, resetError }) => <ErrorFallback error={error} resetError={resetError} />} showDialog>
                  {routes.map((route) => (
                    <SentryRoute
                      key={route.path}
                      path={route.path}
                      render={() => <SentryRoute key={route.path} path={route.path} component={route.component} />}
                    />
                  ))}
                  <SentryRoute exact path="/">
                    <LayoutPage>
                      <div />
                    </LayoutPage>
                  </SentryRoute>
                </Sentry.ErrorBoundary>
              ) : (
                <SentryRoute path="*">
                  <Redirect to="/403" />
                </SentryRoute>
              );
            }}
          </AbilityContext.Consumer>
          <SentryRoute path="/403" component={UnauthorizedPage} />
        </>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
