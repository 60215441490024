import { Button } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import ErrorIcon from '@material-ui/icons/Error';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React from 'react';
import { formatDate } from '../../../../common/helpers/date';
import formatNumberWithAccounting from '../../../../common/helpers/numberFormatHelpers';
import { EventSubtypeEnum } from '../../../enums/EventSubtypeEnum';
import { InfeedValues } from '../../../model';

/* eslint-disable react/display-name -- inline render cells without names */
export const getSelectedStackedProductsColumns = (
  t: (key: string) => string,
  classes: ClassNameMap,
  onRemoveSelectedProduct: (productId: number) => void,
  getFieldFromAvailableStackedProducts: (params: InfeedValues, field: string) => string | null | number | Date,
  isSelectedProductAvailable: (params: InfeedValues) => boolean,
  disabled: boolean,
  subtype: EventSubtypeEnum
): GridColDef[] => {
  const columns: GridColDef[] = [];

  if (!disabled) {
    columns.push({
      field: '',
      headerName: '',
      width: 50,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Button className={classes.button} onClick={() => onRemoveSelectedProduct(row.id)}>
          <RemoveCircle fontSize="small" />
        </Button>
      ),
      sortable: false,
      editable: false,
    });
  }

  columns.push(
    {
      field: 'productDescription',
      headerName: t('productDescription'),
      minWidth: 350,
      renderCell: ({ row }: GridRenderCellParams) => (
        <div className={"monospace"}>
          {row.productDescription}
          {!isSelectedProductAvailable(row as InfeedValues) && <ErrorIcon className={classes.missingIcon} fontSize="small" color="secondary" />}
        </div>
      ),
    },
    {
      field: 'productCharacteristic',
      headerName: t('productCharacteristic'),
      minWidth: 170,
    },
    {
      field: 'woodMoisture',
      headerName: t('moisture'),
      width: 155,
      type: 'number',
      valueFormatter: (params: GridValueFormatterParams) => (params.value ? Number(params.value).toFixed(0) : 'N/A'),
    },
    {
      field: 'locationName',
      headerName: t('location'),
      width: 130,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'factoryName',
      headerName: t('factory'),
      width: 125,
    },
    {
      field: 'inLocationSince',
      headerName: subtype === EventSubtypeEnum.balancing ? t('date') : t('putInTShedDate'),
      width: 190,
      type: 'customDate',
      valueFormatter: (params: GridValueFormatterParams) => (dayjs(String(params.value)).isValid() ? formatDate(String(params.value)) : 'N/A'),
      valueGetter: (params: GridValueGetterParams) => getFieldFromAvailableStackedProducts(params.row as InfeedValues, 'inLocationSince'),
    },
    {
      field: 'quantity',
      headerName: t('quantity'),
      width: 150,
      editable: !disabled,
      type: 'number',
      valueFormatter: ({ value }: GridValueFormatterParams) => `${formatNumberWithAccounting(Number(value), 0)}`,
    }
  );

  return columns;
};
/* eslint-enable react/display-name -- inline render cells without names */
