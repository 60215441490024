import React from 'react';
import globalHook, { Store } from 'use-global-hook';

type State = { successMessage?: string | null };
export type Action = {
  setSuccessMessage: (successMessage: string) => void;
  removeSuccessMessage: () => void;
};

const initialState = { successMessage: null };

export const setSuccessMessage = (store: Store<State, Action>, successMessage: string): void => store.setState({ successMessage });

const removeSuccessMessage = (store: Store<State, Action>) => store.setState(initialState);
const actions = { setSuccessMessage, removeSuccessMessage };

// eslint-disable-next-line react-hooks/rules-of-hooks -- global hook requirement
const useSuccessStore = globalHook<State, Action>(React, initialState, actions);

export default useSuccessStore;
