const confirmDialog = {
  defaultMessage: 'Are you sure?',
};

const grid = {
  customDateLabel: 'Date',
  customDatePlaceholder: 'Filter date',
  invalidDateMessage: 'Invalid Date Format',
};

const layout = {
  confirmDialog,
  grid,
  home: 'Home',
  english: 'English',
  french: 'Français',
  inventory: 'Inventory',
  planning: 'Planning',
  dashboard: 'Dashboard',
  kilns: 'Kilns',
  programs: 'Programs',
  profile: 'Profile',
  settings: 'Settings',
  signout: 'Sign Out',
  close: 'Close',
  cancel: 'Cancel',
  confirm: 'Confirm',
  warning: 'Warning',
  transfers: 'Material Transfer',
};

export default layout;
