import { Icon as MuiIcon, makeStyles } from '@material-ui/core';
import React, { CSSProperties, ReactElement } from 'react';

type LogoProps = {
  children: ReactElement;
  width: number;
  height: number;
  styles?: CSSProperties;
};

const logoStyles = (width: number, height: number, styles?: CSSProperties) =>
  makeStyles({
    root: {
      width,
      height//,  todo  compile pas ??
      //...styles,
    },
  });

const Logo = ({ children, width, height, styles }: LogoProps): ReactElement => <MuiIcon classes={logoStyles(width, height, styles)()}>{children}</MuiIcon>;

export default Logo;
