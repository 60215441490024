import React, { CSSProperties, ReactElement } from 'react';
import Logo from './Logo';
import svg from './Logo-BoaFranc.svg';

type LogoBoaFrancProps = {
  width: number;
  height: number;
  styles?: CSSProperties;
};

const LogoBoaFranc = ({ width, height, styles }: LogoBoaFrancProps): ReactElement => (
  <Logo width={width} height={height} styles={styles}>
    <img alt="" src={svg} />
  </Logo>
);

export default LogoBoaFranc;
