import { ChartOptions } from 'chart.js';
import { inventoryTooltipHandler } from '../tooltip/InventoryTooltip';
const getChartOptions = (capacity: number): ChartOptions<'bar'> => ({
  responsive: false,
  animation: {
    duration: 0,
  },
  elements: {
    point: {
      radius: 0,
      hoverRadius: 0,
    },
  },
  layout: {
    padding: {
      top: 0,
      left: -10,
      bottom: -8,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      external: inventoryTooltipHandler,
    },
    annotation: {
      annotations: {
        line1: {
          type: 'line' as 'box' | undefined,
          yMin: capacity,
          yMax: capacity,
          borderColor: 'rgba(255, 255, 255, 0.5)',
          borderWidth: 0.5,
          borderDash: [2, 2],
        },
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      stacked: true,
      min: 0,
      suggestedMax: capacity * 2,
      ticks: {
        display: false,
      },
      grid: {
        borderWidth: 0,
        display: false,
      },
    },
  },
  interaction: {
    intersect: false,
    axis: 'x' as 'x' | 'y' | 'xy' | undefined,
  },
});

export default getChartOptions;
