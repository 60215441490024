import { deref, of, Store, swap } from '@known-as-bmf/store';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { useCallback, useEffect, useMemo } from 'react';
import { fromEventPattern, Observable, ObservableInput } from 'rxjs';
import { catchError, share, shareReplay, switchMap, take } from 'rxjs/operators';

interface ConnectionCacheState {
  [hubUrl: string]: Observable<HubConnection>;
}

type OnFunction = <T = unknown>(methodName: string) => Observable<T>;

const connectionCacheStore: Store<ConnectionCacheState> = of<ConnectionCacheState>({});

const addToCache = (hubUrl: string, entry: Observable<HubConnection>): void =>
  swap(connectionCacheStore, (state: ConnectionCacheState) => {
    state[hubUrl] = entry;
    return state;
  });

const getFromCache = (hubUrl: string): Observable<HubConnection> => {
  const { [hubUrl]: entry } = deref(connectionCacheStore);
  return entry;
};

const removeFromCache = (hubUrl: string): void =>
  swap(connectionCacheStore, (state: ConnectionCacheState) => {
    delete state[hubUrl];
    return state;
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- This is what the RXJS library actually returns
const handleCatchError = (error: any, caught: any): ObservableInput<any> => {
  console.error(error);
  return caught;
};

const createConnection = (url: string, options: IHttpConnectionOptions = {}): HubConnection => new HubConnectionBuilder().withUrl(url, options).build();

const getOrSetupConnection = (hubUrl: string, options?: IHttpConnectionOptions): Observable<HubConnection> => {
  let connection = getFromCache(hubUrl);

  if (!connection) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- copied from library
    connection = new Observable<HubConnection>((observer: any) => {
      const connection = createConnection(hubUrl, options);
      connection.onclose(() => {
        removeFromCache(hubUrl);
        observer.complete();
      });

      connection
        .start()
        .then(() => {
          observer.next(connection);
        })
        .catch(() => {
          removeFromCache(hubUrl);
          console.error('SignalR failed to connect, connection deleted');
        });

      return () => {
        void connection.stop();
      };
    }).pipe(shareReplay({ refCount: true, bufferSize: 1 }), catchError(handleCatchError));

    addToCache(hubUrl, connection);
  }

  return connection;
};

export const useSignalr = (hubUrl: string, options?: IHttpConnectionOptions): { on: OnFunction } => {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- Ignore changes on hubUrl and options as another instance of useSignalr would be used for another connection
  const connection = useMemo(() => getOrSetupConnection(hubUrl, options), []);

  useEffect(() => {
    const subscription = connection.subscribe();

    return () => subscription.unsubscribe();
  }, [connection]);

  const on = useCallback<OnFunction>(
    <T>(methodName: string) => {
      return connection
        .pipe(
          take(1),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- copied from library
          switchMap((connection: any) =>
            fromEventPattern<T>(
              (handler: (...args: unknown[]) => void) => connection.on(methodName, handler),
              (handler: (...args: unknown[]) => void) => connection.off(methodName, handler)
            )
          ),
          catchError(handleCatchError)
        )
        .pipe(share());
    },
    [connection]
  );

  return { on };
};

export default useSignalr;
