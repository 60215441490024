import { Grid, Tab, Tabs } from '@material-ui/core';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortOptionByLabel } from '../../../../common/helpers/options';
import usePrevious from '../../../../hooks/usePrevious';
import { KeyboardDateTimePickerField, NumberField, SelectField, TextField, TextInput } from '../../../../layout/fields';
import { Option } from '../../../../layout/fields/SelectInput';
import { useGetRecipes } from '../../../../settings/hooks/recipes';
import RecipeType from '../../../../settings/model/RecipeType';
import { ResourceTypeEnum } from '../../../enums';
import { EventSubtypeEnum } from '../../../enums/EventSubtypeEnum';
import {
  defaultEfficiency,
  getNewEndDateFromVolume,
  getTaskDurationInHours,
  setDatesFromDuration,
  setEndDateFromStartDateAndDuration,
} from '../../../helpers/eventHelpers';
import { EventValues } from '../../../model';
import AvailableDoors from '../common/AvailableDoors';
import AvailableRails from '../common/AvailableRails';
import OutfeedLocations from '../common/OutfeedLocations';
import { EventEditorContext } from '../EventEditorContext';
import RecipeSingleSelect from '../RecipeSingleSelect';
import tabStyles from '../tabStyles';
import AvailableProducts from './AvailableProducts';

enum StackingEventTabsEnum {
  lots,
  rails,
  doors,
}

type StackingEventEditorFieldsProps = {
  disabled?: boolean;
  isCreating: boolean;
};

const StackingEventEditorFields = ({ disabled = false, isCreating }: StackingEventEditorFieldsProps): ReactElement => {
  const tabClasses = tabStyles();
  const { t } = useTranslation('planning');
  const { setFieldValue, values } = useFormikContext<EventValues>();
  const { recipeIds, resources, setSelectedLocationIdsMemory } = useContext(EventEditorContext);
  const [getRecipes, { recipes = [] } = {}] = useGetRecipes();

  const previousSubtype = usePrevious(values.eventSubtype);

  const [currentTab, setCurrentTab] = useState<StackingEventTabsEnum>(StackingEventTabsEnum.lots);

  const resourceOptions = useMemo(() => {
    const filteredResources = resources.filter((resource) => resource.type === ResourceTypeEnum.Stacking);
    return filteredResources.map((x) => ({ key: x.id, label: x.name, value: x.id })).sort(sortOptionByLabel);
  }, [resources]);

  const getStackingSubtypeOptions = useCallback((t: (key: string) => string): Option[] => {
    const { dry, green } = EventSubtypeEnum;
    return Object.entries({ dry, green }).map(([key, value]) => ({ key, label: t(key), value }));
  }, []);

  useEffect(() => {
    getRecipes(true, RecipeType.Standard);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  const handleEndDateChange = (newEndDate: string | null): void => setDuration(values.startDate, newEndDate);

  const handleStartDateChange = (newStartDate: string | null): void => {
    setEndDateFromStartDateAndDuration(setFieldValue, newStartDate, values.duration || 0, values.eventType);
  };

  const handleWoodQuantityChange = (newQuantity: string): void => {
    const newEndDate = getNewEndDateFromVolume(values.startDate, values.endDate, +newQuantity);
    setFieldValue('endDate', newEndDate);
    setDuration(values.startDate, newEndDate);
  };

  const handleDurationChange = (newDuration: string | number = 0): void => {
    if ((dayjs(values.startDate).isValid() || !values.startDate) && (dayjs(values.endDate).isValid() || !values.endDate))
      setDatesFromDuration(setFieldValue, values.startDate, values.endDate, newDuration, values.eventType);
  };

  useEffect(() => {
    const filteredInfeeds = values.infeedProducts.filter((x) => recipeIds.includes(x.recipeId));
    if (filteredInfeeds.length !== values.infeedProducts.length) setFieldValue('infeedProducts', filteredInfeeds);
    const filteredOutfeeds = values.outfeedProducts.filter((x) => recipeIds.includes(x.recipeId));
    if (filteredOutfeeds.length !== values.outfeedProducts.length) setFieldValue('outfeedProducts', filteredOutfeeds);
  }, [recipeIds, values.infeedProducts, values.outfeedProducts, setFieldValue]);

  const setDuration = (startDate: string | Date | null, endDate: string | Date | null) => {
    if (dayjs(startDate).isValid() && dayjs(endDate).isValid()) {
      setFieldValue('duration', getTaskDurationInHours(startDate, endDate));
    }
  };

  useEffect(() => {
    if (values.eventSubtype === previousSubtype) return;

    setFieldValue('infeedProducts', []);
    setFieldValue('outfeedProducts', []);
  }, [values.eventSubtype, previousSubtype, setFieldValue]);

  useEffect(() => {
    if (currentTab !== StackingEventTabsEnum.lots) setSelectedLocationIdsMemory([]);
  }, [currentTab, setSelectedLocationIdsMemory]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={2}>
          <SelectField label={t('eventSubtype')} name="eventSubtype" options={getStackingSubtypeOptions(t)} disabled={!isCreating} clearable={false} />
        </Grid>
        <Grid item md={2}>
          <SelectField label={t('stackingLine')} name="resourceId" options={resourceOptions} disabled={disabled} />
        </Grid>

        <Grid item md={4}>
          <RecipeSingleSelect recipes={recipes} disabled={disabled} />
        </Grid>

        <Grid item md={4}>
          <NumberField
            label={t('woodQuantity')}
            name="woodQuantity"
            min={0}
            disabled={disabled || !!values.infeedProducts.length}
            onChange={handleWoodQuantityChange}
          />
        </Grid>

        <Grid item md={4}>
          <KeyboardDateTimePickerField name="startDate" label={t('startDate')} disabled={disabled} onChange={handleStartDateChange} />
        </Grid>

        <Grid item md={4}>
          <KeyboardDateTimePickerField
            name="endDate"
            label={t('endDate')}
            minDate={values.startDate && dayjs(values.startDate).add(1, 'd')}
            disabled={disabled}
            onChange={handleEndDateChange}
          />
        </Grid>

        <Grid item md={2}>
          <NumberField label={t('processDuration')} name="duration" decimalScale={2} onChange={handleDurationChange} disabled={disabled} />
        </Grid>

        <Grid item md={1}>
          <NumberField label={t('workOrder')} name="workOrder" disabled />
        </Grid>

        <Grid item md={1}>
          <TextInput label={t('efficiency')} name="efficiency" disabled value={defaultEfficiency} />
        </Grid>

        <Grid item md={6}>
          <TextField label={t('tooltipNote')} name="tooltipNote" disabled={disabled} />
        </Grid>
        <Grid item md={6}>
          <TextField label={t('detailedComment')} name="detailedComment" disabled={disabled} />
        </Grid>
        <Grid item md={6}>
          <Tabs
            classes={{
              root: tabClasses.tabsRoot,
            }}
            value={currentTab}
            onChange={(_: unknown, value: StackingEventTabsEnum) => setCurrentTab(value)}
            indicatorColor="primary">
            <Tab
              label={values.eventSubtype === EventSubtypeEnum.green ? t('lots') : t('indoorLots')}
              value={StackingEventTabsEnum.lots}
              className={currentTab == StackingEventTabsEnum.lots ? tabClasses.selectedTab : tabClasses.tab}
            />
            {values.eventSubtype === EventSubtypeEnum.green ? (
              <Tab
                label={t('rails')}
                value={StackingEventTabsEnum.rails}
                className={currentTab === StackingEventTabsEnum.rails ? tabClasses.selectedTab : tabClasses.tab}
              />
            ) : (
              <Tab
                label={t('doors')}
                value={StackingEventTabsEnum.doors}
                className={currentTab === StackingEventTabsEnum.doors ? tabClasses.selectedTab : tabClasses.tab}
              />
            )}
          </Tabs>
        </Grid>
        <Grid item md={6} className={tabClasses.outfeedChips}>
          {currentTab === StackingEventTabsEnum.lots && values.eventSubtype === EventSubtypeEnum.green && (
            <OutfeedLocations label={t('selectedRails')} className={tabClasses.outfeedChip} />
          )}
          {currentTab === StackingEventTabsEnum.lots && values.eventSubtype === EventSubtypeEnum.dry && (
            <OutfeedLocations label={t('selectedDoors')} className={tabClasses.outfeedChip} />
          )}
        </Grid>
      </Grid>

      {currentTab === StackingEventTabsEnum.lots && <AvailableProducts disabled={disabled} />}
      {currentTab === StackingEventTabsEnum.rails && <AvailableRails disabled={disabled} />}
      {currentTab === StackingEventTabsEnum.doors && <AvailableDoors disabled={disabled} />}
    </>
  );
};

export default StackingEventEditorFields;
