import { Grid } from '@material-ui/core';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sortOptionByLabel } from '../../../../common/helpers/options';
import { KeyboardDateTimePickerField, NumberField, SelectField, TextField, TextInput } from '../../../../layout/fields';
import { useGetRecipes } from '../../../../settings/hooks/recipes';
import RecipeType from '../../../../settings/model/RecipeType';
import { ResourceTypeEnum } from '../../../enums';
import {
  defaultEfficiency,
  getNewEndDateFromVolume,
  getTaskDurationInHours,
  setDatesFromDuration,
  setEndDateFromStartDateAndDuration,
} from '../../../helpers/eventHelpers';
import { EventValues } from '../../../model';
import { EventEditorContext } from '../EventEditorContext';
import RecipeSingleSelect from '../RecipeSingleSelect';
import AvailableDriedProducts from './AvailableDriedProducts';

type OrderEventEditorFieldsProps = {
  disabled?: boolean;
};

const OrderEventEditorFields = ({ disabled = false }: OrderEventEditorFieldsProps): ReactElement => {
  const { t } = useTranslation('planning');
  const { setFieldValue, values } = useFormikContext<EventValues>();
  const [getRecipes, { recipes = [] } = {}] = useGetRecipes();
  const { recipeIds, resources } = useContext(EventEditorContext);

  const resourceOptions = useMemo(() => {
    const filteredResources = resources.filter((resource) => resource.type === ResourceTypeEnum.Order);
    return filteredResources.map((x) => ({ key: x.id, label: x.name, value: x.id })).sort(sortOptionByLabel);
  }, [resources]);

  useEffect(() => {
    getRecipes(true, RecipeType.Standard);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  const handleEndDateChange = (newEndDate: string | null): void => setDuration(values.startDate, newEndDate);

  const handleStartDateChange = (newStartDate: string | null) => {
    setEndDateFromStartDateAndDuration(setFieldValue, newStartDate, values.duration || 0, values.eventType);
  };

  const handleWoodQuantityChange = (newQuantity: string): void => {
    const newEndDate = getNewEndDateFromVolume(values.startDate, values.endDate, +newQuantity);
    setFieldValue('endDate', newEndDate);
    setDuration(values.startDate, newEndDate);
  };

  const handleDurationChange = (newDuration: string | number = 0): void => {
    if ((dayjs(values.startDate).isValid() || !values.startDate) && (dayjs(values.endDate).isValid() || !values.endDate))
      setDatesFromDuration(setFieldValue, values.startDate, values.endDate, newDuration, values.eventType);
  };

  useEffect(() => {
    const filteredInfeeds = values.infeedProducts.filter((x) => recipeIds.includes(x.recipeId));
    if (filteredInfeeds.length !== values.infeedProducts.length) setFieldValue('infeedProducts', filteredInfeeds);
  }, [recipeIds, values.infeedProducts, setFieldValue]);

  const setDuration = (startDate: string | Date | null, endDate: string | Date | null) => {
    if (dayjs(startDate).isValid() && dayjs(endDate).isValid()) {
      setFieldValue('duration', getTaskDurationInHours(startDate, endDate));
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <>
          <Grid item md={3}>
            <SelectField label={t('productionLine')} name="resourceId" options={resourceOptions} disabled={disabled} />
          </Grid>

          <Grid item md={3}>
            <RecipeSingleSelect recipes={recipes} disabled={disabled} />
          </Grid>

          <Grid item md={3}>
            <TextInput label={t('efficiency')} name="efficiency" disabled value={defaultEfficiency} />
          </Grid>

          <Grid item md={3}>
            <NumberField
              label={t('woodQuantity')}
              name="woodQuantity"
              min={0}
              disabled={disabled || !!values.infeedProducts.length}
              onChange={handleWoodQuantityChange}
            />
          </Grid>

          <Grid item md={3}>
            <KeyboardDateTimePickerField name="startDate" label={t('startDate')} disabled={disabled} onChange={handleStartDateChange} />
          </Grid>

          <Grid item md={3}>
            <KeyboardDateTimePickerField
              name="endDate"
              label={t('endDate')}
              minDate={values.startDate && dayjs(values.startDate).add(1, 'd')}
              disabled={disabled}
              onChange={handleEndDateChange}
            />
          </Grid>

          <Grid item md={3}>
            <NumberField label={t('processDuration')} name="duration" decimalScale={2} onChange={handleDurationChange} disabled={disabled} />
          </Grid>

          <Grid item md={3}>
            <NumberField label={t('workOrder')} name="workOrder" disabled />
          </Grid>

          <Grid item md={6}>
            <TextField label={t('tooltipNote')} name="tooltipNote" disabled={disabled} />
          </Grid>

          <Grid item md={6}>
            <TextField label={t('detailedComment')} name="detailedComment" disabled={disabled} />
          </Grid>
        </>
      </Grid>

      <AvailableDriedProducts disabled={disabled} />
    </>
  );
};

export default OrderEventEditorFields;
