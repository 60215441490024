import { GridColDef, GridColumns, GridRenderEditCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import React from 'react';
import CustomNumberInputValue from './CustomNumberInputValue';

export const getAirDryKilnChartColumns = (canUpdate: boolean): GridColDef[] =>
  [
    { field: 'id', sortable: false, editable: false },
    ...Array.from({ length: 52 }, (_, i) => i + 1).map((week) => ({
      field: String(week),
      sortable: false,
      editable: canUpdate,
      type: 'number',
      valueFormatter: ({ value }: GridValueFormatterParams) => Number(value).toFixed(3),
      // eslint-disable-next-line react/display-name -- inline render cells without names
      renderEditCell: (params: GridRenderEditCellParams) => <CustomNumberInputValue {...params} />,
    })),
  ] as GridColumns;
