import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Schwoop from '../../../layout/schwoop/Schwoop';
import { mapKilnToValues } from '../../helpers/kilnHelpers';
import { useGetLumberVarieties } from '../../hooks/lumberVarieties';
import { Kiln, KilnValues } from '../../model';
import { getKilnSchema } from '../../schemas/KilnSchema';
import LumberWeightField from '../LumberWeightField';
import useSettingsTabStyles from '../SettingsTabStyles';
import KilnEditorFormFields from './KilnEditorFormFields';

type KilnEditorProps = {
  kiln: Kiln;
  onClose: () => void;
  onSubmit: (values: KilnValues) => void;
};

const KilnEditor = ({ kiln, onClose, onSubmit }: KilnEditorProps): ReactElement => {
  const [getLumberVarieties, { lumberVarieties = [] } = {}] = useGetLumberVarieties();
  const classes = useSettingsTabStyles();
  const { t } = useTranslation('settings');
  const isNewKiln: boolean = useMemo(() => !kiln.id, [kiln]);

  useEffect(() => {
    getLumberVarieties({ hideIsArchived: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  return (
    <Formik enableReinitialize initialValues={mapKilnToValues(kiln, lumberVarieties)} onSubmit={onSubmit} validationSchema={getKilnSchema(t)}>
      {({ submitForm, values }) => (
        <Form>
          <Schwoop open onCancel={onClose} onConfirm={submitForm} title={!isNewKiln ? t('kilns.editKiln') : t('kilns.newKiln')}>
            <Grid container spacing={2} className={classes.gridFields}>
              <KilnEditorFormFields />
              <LumberWeightField lumberVarieties={lumberVarieties} lumberWeights={values.resourceLumberWeights} prefix="resourceLumberWeights" />
            </Grid>
          </Schwoop>
        </Form>
      )}
    </Formik>
  );
};

export default KilnEditor;
