import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Chart, ChartDataset, ScriptableTooltipContext, TooltipItem, TooltipModel } from 'chart.js';
import React, { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../common/helpers/date';
import formatNumberWithAccounting from '../../../common/helpers/numberFormatHelpers';
import CssTemplate from '../../../CssTemplate';
import { englishLocalesCode } from '../../../i18n';

type _react = typeof React

const tooltipWidth = 1000;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      width: tooltipWidth + 'px',
      backgroundColor: theme.palette.primary.light,
      borderRadius: 5,
      padding: theme.spacing(1),
      marginTop: '8px',
    },
    divider: {
      background: theme.palette.primary.dark,
      height: '2px',
    },
    text: {
      color: theme.palette.text.secondary,
      display: 'inline',
    },
    title: {
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
    },
    color: {
      height: '14px',
      width: '14px',
      display: 'inline-block',
      marginRight: theme.spacing(1),
    },
  })
);

// On partitionne une liste de T par une fonction qui va assigner, � chaque T,
// un group index� par I. string est hardcoded, parce que tsc est pas capable
// d'utiliser string | number comme index.
function partition<T>(a: T[], f: (a: T) => string): { [index: string]: T[] } {
  const partitions: { [index: string]: T[] } = {};
  for (const v of a) {
    const i = f(v);
    if (!partitions[i])
      partitions[i] = [];
    partitions[i].push(v);
  }
  return partitions;
}

type ExtendRawData = {
  products: {
    name: string;
    quantity: number;
    date: string;
    fromResources: string[];
    toResources: string[];
    events: string[];
  }[]
};

type ExtendedDataset = {
  capacity: number;
  hexColor: string;
  rawData: ExtendRawData[]
} & ChartDataset<'bar'>;

type ExtendedDataPoint = {
  dataset: ExtendedDataset
} & TooltipItem<'bar'>;

type InventoryTooltipProductData = {
  name: string;
  quantity: number;
  color: string;
  date: string;
  fromResources: string[];
  toResources: string[];
  events: string[]
};

type InventoryTooltipData = {
  date: string;
  capacity: number;
  quantity: number;
  products: InventoryTooltipProductData[];
};

const InventoryTooltip = ({ tooltipData }: { tooltipData: InventoryTooltipData }): ReactElement => {
  const { t } = useTranslation('inventory');
  const classes = useStyles();
  const formatLabel = (label: string): string => `${t(label)}:`;
  const widths: Array<2 | 3 | 4> = [4, 3, 3, 2];


  // deux lignes trop similaires adjacentes devraient �tre fusionn�es en une
  // seule ligne afin de faciliter la lecture
  function mergeProducts(products: InventoryTooltipProductData[]): InventoryTooltipProductData[] {

    function setA(a: string[]): string[] {
      return Object.keys(Object.fromEntries(a.map(x => [x, x])));
    }

    function arraysEqual(a: string[], b: string[]): boolean { // https://stackoverflow.com/a/16436975
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;


      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }

    function setEquals(a: string[], b: string[]): boolean {
      // Je suis profond�ment d�sol�
      return arraysEqual(setA(a), setA(b));
    }

    type State = {
      passed: InventoryTooltipProductData[],
      current: InventoryTooltipProductData,
    } | null
    function merge(s: State, n: InventoryTooltipProductData): State {
      if (s === null) {
        return {
          passed: [],
          current: n,
        };
      } else {
        // check de compatibilit�: data, nom de produit, provenance
        if (s.current.date == n.date && s.current.name == n.name && setEquals(s.current.fromResources, n.fromResources)) {
          return {
            passed: s.passed,
            current: {
              name: n.name,
              quantity: s.current.quantity + n.quantity,

              // On h�rite de la couleur de celui qui arrive avant. Les couleurs
              // n'ont pas besoin d'�tre identiques.

              color: s.current.color,
              date: n.date,
              fromResources: n.fromResources,
              // pas utilis� dans l'affichage
              toResources: n.toResources,
              events: n.events
            },
          };
        } else {
          return {
            passed: [...s.passed, s.current],
            current: n,
          }
        }
      }
    }

    const finalState = products.reduce(merge, null);
    if (finalState != null)
      return [...finalState!.passed, finalState!.current]
    return [];
  }

  function TableLine({ product }: { product: InventoryTooltipProductData }): ReactElement {

    return (
      <Grid container item direction="row">
        <Grid item sm={widths[0]}>
          <span className={classes.color} style={{ backgroundColor: product.color }}></span>
          <Typography className={classes.text} variant="body2">
            {product.name}
          </Typography>
        </Grid>
        <Grid item sm={widths[1]}>
          <Typography className={classes.text} variant="body2">
            {`${formatNumberWithAccounting(product.quantity, 0)} ${t('fmb')}`}
          </Typography>
        </Grid>
        <Grid item sm={widths[2]}>
          <Typography className={classes.text} variant="body2">
            {formatDate(product.date)}
          </Typography>
        </Grid>

        <Grid item sm={widths[3]} direction="column">
          <Typography className={classes.text} variant="body2"> 
            {product.fromResources.concat(product.events.map(x => '#' + x)).join(", ")} 
          </Typography>              
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="row" className={classes.grid}>
      <Grid item container spacing={1} direction="row">
        <Grid item sm={12}>
          <Typography className={classes.title} variant="h6">
            {tooltipData.date}
          </Typography>
        </Grid>
        <Grid container item direction="row">
          <Grid item sm={6}>
            <Typography className={classes.text}>{formatLabel('levelOfStock')}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography className={classes.text} variant="body2">{` ${formatNumberWithAccounting(tooltipData.quantity, 0)} / ${formatNumberWithAccounting(
              tooltipData.capacity,
              0
            )}`}</Typography>
          </Grid>
        </Grid>
        <Grid container item direction="row">
          <Grid item sm={6}>
            <Typography className={classes.text}>{formatLabel('usage')}</Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography className={classes.text} variant="body2">{` ${((tooltipData.quantity / tooltipData.capacity) * 100).toFixed(0)} %`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* la t�te de la table. Ici, on a tous les noms des valeurs */}
      <Grid item container direction="column" spacing={2}>
        <Grid item container direction="row">
          <Grid item sm={widths[0]}>
            <Typography className={classes.text}>
              {formatLabel("product")}
            </Typography></Grid>

          <Grid item sm={widths[1]}>
            <Typography className={classes.text}>
              {formatLabel("quantity")}
            </Typography></Grid>

          <Grid item sm={widths[2]}>
            <Typography className={classes.text}>
              {formatLabel("productionDate")}
            </Typography></Grid>

          <Grid item sm={widths[3]}>
            <Typography className={classes.text}>
              {formatLabel("incomingFrom")}
            </Typography></Grid>

        </Grid>
        {/* Et i�i, on construit les lignes de la table */}
        {(x => x)(
          tooltipData
            .products
            .filter(x => x.quantity > 0)
            .sort((a, b) => a.name.localeCompare(b.name))
            .sort((a, b) => Date.parse(a.date) - Date.parse(b.date)))
          .map((product: InventoryTooltipProductData, index: number) =>
            <TableLine key={index} product={product} />
          )}
      </Grid>
    </Grid>)

};

const chartTooltipToInventoryTooltipData = (chartTooltipData: TooltipModel<'bar'>): InventoryTooltipData | null => {
  if (!chartTooltipData.dataPoints.length) return null;

  const date = chartTooltipData.title.length ? chartTooltipData.title[0] : '';
  const firstDataPoint = (chartTooltipData.dataPoints.find(Boolean) || { capacity: 0 }) as ExtendedDataPoint;
  const capacity = firstDataPoint.dataset.capacity;
  const quantity = chartTooltipData.dataPoints.reduce((acc, dataPoint) => acc + (dataPoint.raw as number), 0);

  const products = chartTooltipData.dataPoints.reduce((acc, dataPoint) => {
    const dataProducts = (dataPoint.dataset as ExtendedDataset).rawData[dataPoint.dataIndex]?.products || [];
    const recipeProducts: InventoryTooltipProductData[] = dataProducts.map((x) => ({
      ...x,
      color: (dataPoint.dataset as ExtendedDataset).hexColor,
    }));
    acc.push(...recipeProducts);
    return acc;
  }, [] as InventoryTooltipProductData[]);

  return { date, capacity, quantity, products };
};

const getOrCreateTooltipElement = () => {
  let tooltipElement = window.document.getElementById('myCustomTooltip');

  if (!tooltipElement) {
    tooltipElement = document.createElement('div');
    tooltipElement.id = 'myCustomTooltip';
    tooltipElement.style.borderRadius = '3px';
    tooltipElement.style.color = 'white';
    tooltipElement.style.opacity = '1';
    tooltipElement.style.pointerEvents = 'none';
    tooltipElement.style.position = 'absolute';
    tooltipElement.style.transform = 'translate(-50%, 0)';
    tooltipElement.style.transition = 'all .1s ease';
    tooltipElement.style.zIndex = '3';
    window.document.body.appendChild(tooltipElement);
  }

  return tooltipElement;
};

const getInventoryTooltip = (chartTooltipData: TooltipModel<'bar'>): HTMLElement => {
  const inventoryTooltipData = chartTooltipToInventoryTooltipData(chartTooltipData);
  const tooltipElement = getOrCreateTooltipElement();
  tooltipElement.innerHTML = inventoryTooltipData
    ? ReactDOMServer.renderToString(
        <CssTemplate languageCode={englishLocalesCode}>
          <InventoryTooltip tooltipData={inventoryTooltipData} />
        </CssTemplate>
      )
    : '';
  return tooltipElement;
};

const manageTooltipPosition = (tooltipElement: HTMLElement, chart: Chart<'bar'>, tooltip: TooltipModel<'bar'>): void => {
  const { x: chartX, y: chartY, width } = chart.canvas.getBoundingClientRect();
  const top = chartY + window.pageYOffset + 24;

  let arrowClass = 'arrow';
  const halfTooltipWidth = tooltipWidth / 2;
  let offsetX = 50;
  if (tooltip.caretX < halfTooltipWidth) {
    offsetX = 5;
  } else if (tooltip.caretX > width - halfTooltipWidth) {
    offsetX = 95;
  }

  const inventoryScheduler = window.document.getElementById('InventoryScheduler');
  const { bottom: schedulerBottom, top: schedulerTop } = inventoryScheduler?.getBoundingClientRect() || { bottom: 0, top: 0 };
  const { height } = tooltipElement.getBoundingClientRect();

  let offsetY = 0;
  if (top + height > schedulerBottom && top - height > schedulerTop) {
    arrowClass = 'arrow-down';
    offsetY = height + 24;
  }

  ['arrow-5', 'arrow-95', 'arrow-50', 'arrow-down-5', 'arrow-down-95', 'arrow-down-50'].forEach((x) => tooltipElement.classList.remove(x));
  tooltipElement.classList.add(`${arrowClass}-${offsetX}`);
  tooltipElement.style.transform = `translate(-${offsetX}%, -${offsetY}px)`;

  tooltipElement.style.left = chartX + window.pageXOffset + tooltip.caretX + 'px';
  tooltipElement.style.top = top + 'px';
};

export const inventoryTooltipHandler = (context: ScriptableTooltipContext<'bar'>): void => {
  const { chart, tooltip } = context;
  const tooltipElement = getInventoryTooltip(tooltip);

  if (tooltip.opacity === 0) {
    tooltipElement.style.opacity = '0';
    return;
  }

  tooltipElement.style.opacity = '1';
  manageTooltipPosition(tooltipElement, chart, tooltip);
};
