import { TextField, Typography } from '@material-ui/core';
import React, { Ref, useCallback } from 'react';

type TextInputProps = {
  disabled: boolean;
  error?: boolean;
  helperText?: string;
  inputRef?: ((el: HTMLInputElement) => void) | Ref<HTMLInputElement> | undefined;
  label: string;
  name?: string;
  onBlur?: ({ target: { name, value } }: { target: { name: string; value: string | number | null } }) => void;
  onChange?: ({ target: { name, value } }: { target: { name: string; value: string } }) => void;
  value: string | number;
};

const TextInput = ({
  error = false,
  helperText,
  label = '',
  name = '',
  onBlur,
  onChange,
  value = '',
  disabled = false,
  inputRef,
}: TextInputProps): JSX.Element => {
  const handleBlur = useCallback(
    (event) => {
      if (onBlur) onBlur({ target: { name, value: event.target.value } });
    },
    [name, onBlur]
  );

  return (
    <TextField
      helperText={helperText}
      error={error}
      id={name}
      autoComplete="off"
      fullWidth
      label={<Typography variant="body2">{label}</Typography>}
      name={name}
      onBlur={handleBlur}
      onChange={onChange}
      value={value}
      disabled={disabled}
      inputRef={inputRef}
    />
  );
};

export default TextInput;
