import React, { ReactElement, useMemo } from 'react';
import { SelectField } from '../../../layout/fields';
import { toOption } from '../../helpers/lumberVarietyHelpers';
import { LumberVariety } from '../../model';

type LumberVarietyFieldProps = {
  disabled?: boolean;
  label: string;
  name: string;
  onChange?: (arg: Record<string, unknown> | string | number) => void;
  lumberVarieties: LumberVariety[];
};

const LumberVarietyField = ({ disabled = false, label, name, onChange = () => undefined, lumberVarieties = [] }: LumberVarietyFieldProps): ReactElement => {
  const sortField = "printOrder";

  const sortedLumberVarieties = useMemo(() => {
    const sorted = [...lumberVarieties];
    sorted.sort((a, b) => (a[sortField] > b[sortField] ? 1 : -1));
    return sorted;
  }, [lumberVarieties, sortField]);

  const lumberVarietyOptions = useMemo(() => sortedLumberVarieties.map(toOption), [sortedLumberVarieties]);


  return <SelectField label={label} name={name} options={lumberVarietyOptions} onChange={onChange} disabled={disabled} />;
};

export default LumberVarietyField;
