import { Typography } from '@material-ui/core';
import { DataGrid, GridCellEditCommitParams, GridCellValue, GridEditRowsModel } from '@mui/x-data-grid';
import { useFormikContext } from 'formik';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import customDateColumnType from '../../../../layout/grid/CustomDateColumnType';
import { useGetAvailableDriedProducts } from '../../../hooks';
import { AvailableProduct, EventValues, InfeedProduct, InfeedValues } from '../../../model';
import { EventEditorContext } from '../EventEditorContext';
import useListStyles from '../listStyles';
import { getAvailableDriedProductsColumns } from './AvailableDriedProductsColumns';
import { getSelectedDriedProductsColumns } from './SelectedDriedProductsColumns';

type productKey = {
  sourceWorkOrder: string | undefined;
  factoryId: number;
  locationId: number;
  locationType: string;
  productId: number;
};

const isSameProduct = (x: productKey, infeedProduct: productKey) => {
  return (
    x.locationId === infeedProduct.locationId &&
    x.locationType === infeedProduct.locationType &&
    x.productId === infeedProduct.productId &&
    x.factoryId === infeedProduct.factoryId &&
    x.sourceWorkOrder === infeedProduct.sourceWorkOrder
  );
};

type AvailableDriedProductsProps = {
  disabled?: boolean;
};

const AvailableDriedProducts = ({ disabled = false }: AvailableDriedProductsProps): ReactElement => {
  const classes = useListStyles();
  const { t } = useTranslation('planning');
  const { recipeIds } = useContext(EventEditorContext);
  const { setFieldValue, values } = useFormikContext<EventValues>();
  const [editRowsModel, setEditRowsModel] = React.useState<GridEditRowsModel>({});
  const [getAvailableDriedProducts, { availableProducts: availableDriedProducts = [] } = {}, isFetching, clearAvailableProducts] =
    useGetAvailableDriedProducts();
  const totalSelectedQuantity = useMemo(() => values.infeedProducts.reduce((acc, product) => acc + product.quantity, 0), [values.infeedProducts]);

  useEffect(() => {
    if (values.woodQuantity !== totalSelectedQuantity && values.infeedProducts.length) setFieldValue('woodQuantity', totalSelectedQuantity);
  }, [values.woodQuantity, values.infeedProducts, totalSelectedQuantity, setFieldValue]);

  useEffect(() => {
    if (!recipeIds.length) clearAvailableProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- cannot include getter because useAgent is not memoized
  }, [recipeIds]);

  useEffect(() => {
    if (recipeIds.length && values.startDate) getAvailableDriedProducts(recipeIds, values.startDate, values.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- cannot include getter because useAgent is not memoized
  }, [recipeIds, values.startDate, values.id]);

  const handleRemoveSelectedDriedProduct = (infeedProduct: InfeedProduct): void =>
    setFieldValue(
      'infeedProducts',
      values.infeedProducts.filter((x) => !isSameProduct(x, infeedProduct))
    );

  const selectableDriedProducts = useMemo(
    () => availableDriedProducts.filter((x) => !values.infeedProducts.some((y) => isSameProduct(x, y))),
    [availableDriedProducts, values.infeedProducts]
  );

  const handleAddSelectedDriedProduct = (availableProduct: AvailableProduct): void => {
    let maxId = Math.max(...values.infeedProducts.map((x) => x.id), 0);
    setFieldValue('infeedProducts', [
      {
        ...availableProduct,
        id: ++maxId,
        location: availableProduct.locationName,
        availableQuantity: availableProduct.quantity,
        quantity: availableProduct.quantity,
      },
      ...values.infeedProducts,
    ]);
  };

  const updateFormikOnCellCommit = useCallback(
    (id: string | number, value: GridCellValue): void => {
      const formikIndex = values.infeedProducts.findIndex((x) => x.id === id);
      setFieldValue(`infeedProducts.${formikIndex}.quantity`, value);
    },
    [values.infeedProducts, setFieldValue]
  );

  const handleEditRowsModelChange = useCallback(
    (newModel: GridEditRowsModel) => {
      const updatedModel = { ...newModel };
      let value = 0;

      Object.entries(updatedModel).forEach(([id, row]) => {
        if (row.quantity?.value) value = Number(row.quantity?.value);
        row.quantity = {
          ...updatedModel[id].quantity,
          error: !(Number.isInteger(value) && value >= 0),
        };
      });

      setEditRowsModel(updatedModel);
    },
    [setEditRowsModel]
  );

  const getFieldValuesFromAvailableDriedProducts = useCallback(
    (infeedValues: InfeedValues, field: string): string | null | number | Date => {
      const availableProduct = availableDriedProducts.find((x) => isSameProduct(x, infeedValues));

      if (!availableProduct) return null;

      switch (field) {
        case 'availableQuantity':
          return availableProduct.quantity;
        default:
          return null;
      }
    },
    [availableDriedProducts]
  );

  const isSelectedProductAvailable = useCallback(
    (selectedProduct: InfeedValues) => {
      if (isFetching) return true;
      return availableDriedProducts.some((x) => isSameProduct(x, selectedProduct) && x.quantity >= selectedProduct.quantity);
    },
    [availableDriedProducts, isFetching]
  );

  return (
    <>
      <Typography variant="h6" className={classes.listTitle}>
        {t('selectedProducts')}
      </Typography>

      <DataGrid
        style={{height:200}}
        editRowsModel={editRowsModel}
        onEditRowsModelChange={handleEditRowsModelChange}
        columnTypes={{ customDate: customDateColumnType }}
        density="compact"
        rows={values.infeedProducts}
        className={classes.grid}
        loading={isFetching}
        getRowClassName={({ row }) => (!isSelectedProductAvailable(row as InfeedValues) ? 'kplanner-infeed-missing' : '')}
        columns={getSelectedDriedProductsColumns(
          t,
          classes,
          handleRemoveSelectedDriedProduct,
          getFieldValuesFromAvailableDriedProducts,
          isSelectedProductAvailable,
          disabled
        )}
        onCellEditCommit={({ id, value }: GridCellEditCommitParams) => updateFormikOnCellCommit(id, value)}        
        hideFooter
        disableColumnSelector
        disableSelectionOnClick
      />

      {!disabled && (
        <>
          <Typography variant="h6" className={classes.listTitle}>
            {t('availableProducts')}
          </Typography>

          <DataGrid
            style={{height:250}}
            density="compact"
            rows={selectableDriedProducts}
            className={classes.grid}
            columnTypes={{ customDate: customDateColumnType }}
            loading={isFetching}
            columns={getAvailableDriedProductsColumns(t, classes.button, handleAddSelectedDriedProduct)}          
            hideFooter
            disableColumnSelector
            disableSelectionOnClick
          />
        </>
      )}
    </>
  );
};

export default AvailableDriedProducts;
