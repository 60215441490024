import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sortOptionByLabel } from '../../../../common/helpers/options';
import usePrevious from '../../../../hooks/usePrevious';
import { SelectField } from '../../../../layout/fields';
import { Option } from '../../../../layout/fields/SelectInput';
import { useGetRecipes } from '../../../../settings/hooks/recipes';
import { LumberVariety, Recipe } from '../../../../settings/model';
import { ResourceTypeEnum } from '../../../enums';
import { EventSubtypeEnum } from '../../../enums/EventSubtypeEnum';
import { setDatesFromDuration, setDurationFromDate, setEndDateFromStartDateAndDuration } from '../../../helpers/eventHelpers';
import { EventValues } from '../../../model';
import { EventEditorContext } from '../EventEditorContext';
import KilnEventEditorFields from './subtype/KilnEventEditorFields';
import KilnSubstrateEventEditorFields from './subtype/KilnSubstrateEventEditorFields';

export type KilnEventEditorProps = {
  disabled?: boolean;
  recipes?: Recipe[];
  isCreating?: boolean;
  resourceOptions?: Option[];
  selectedLumberVarieties?: LumberVariety[];
};

const KilnEventEditor = ({ disabled = false, isCreating }: KilnEventEditorProps): ReactElement => {
  const { t } = useTranslation('planning');
  const { setFieldValue, values } = useFormikContext<EventValues>();
  const previousStartDate = usePrevious(values.startDate);
  const previousEndDate = usePrevious(values.endDate);
  const previousDuration = usePrevious(values.duration);
  const previousSubtype = usePrevious(values.eventSubtype);
  const [getRecipes, { recipes = [] } = {}] = useGetRecipes();
  const { setRecipeIds, recipeIds, resources } = useContext(EventEditorContext);

  useEffect(() => {
    getRecipes(true, values.eventSubtype === EventSubtypeEnum.balancing ? EventSubtypeEnum.standard : values.eventSubtype);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, [values.eventSubtype]);

  const resourceOptions = useMemo(() => {
    const filteredResources = resources.filter((resource) => resource.type === ResourceTypeEnum.Kiln);
    return filteredResources.map((x) => ({ key: x.id, label: x.name, value: x.id })).sort(sortOptionByLabel);
  }, [resources]);

  const getKilnSubtypeOptions = useCallback((t: (key: string) => string): Option[] => {
    const { standard, substrate, balancing } = EventSubtypeEnum;
    return Object.entries({ standard, substrate, balancing }).map(([key, value]) => ({ key, label: t(key), value }));
  }, []);

  useEffect(() => {
    if (values.startDate === previousStartDate) return;
    setEndDateFromStartDateAndDuration(setFieldValue, values.startDate, values.duration || 0, values.eventType);
  }, [previousStartDate, setFieldValue, values.duration, values.eventType, values.startDate]);

  useEffect(() => {
    if (values.endDate === previousEndDate) return;
    setDurationFromDate(setFieldValue, values.startDate, values.endDate);
  }, [values.startDate, values.endDate, previousEndDate, setFieldValue]);

  useEffect(() => {
    if (values.duration === previousDuration || values.eventSubtype === EventSubtypeEnum.balancing) return;
    setDatesFromDuration(setFieldValue, values.startDate, values.endDate, values.duration || 0, values.eventType);
  }, [values.startDate, values.endDate, previousDuration, values.duration, values.eventType, setFieldValue, values.eventSubtype]);

  useEffect(() => {
    if (values.eventSubtype === previousSubtype) return;
    setFieldValue('infeedProducts', []);
    setFieldValue('outfeedProducts', []);
    setRecipeIds([]);
  }, [values.eventSubtype, previousSubtype, setFieldValue, setRecipeIds]);

  useEffect(() => {
    const filteredInfeeds = values.infeedProducts.filter((x) => recipeIds.includes(x.recipeId));
    if (filteredInfeeds.length < values.infeedProducts.length) setFieldValue('infeedProducts', filteredInfeeds);
    const filteredOutfeeds = values.outfeedProducts.filter((x) => recipeIds.includes(x.recipeId));
    if (filteredOutfeeds.length < values.outfeedProducts.length) setFieldValue('outfeedProducts', filteredOutfeeds);
  }, [recipeIds, values.infeedProducts, values.outfeedProducts, setFieldValue]);

  return (
    <Grid container spacing={3}>
      <Grid item md={2}>
        <SelectField label={t('eventSubtype')} name="eventSubtype" options={getKilnSubtypeOptions(t)} clearable={false} disabled={!isCreating} />
      </Grid>
      {values.eventSubtype === EventSubtypeEnum.substrate ? (
        <KilnSubstrateEventEditorFields resourceOptions={resourceOptions} disabled={disabled} recipes={recipes} />
      ) : (
        <KilnEventEditorFields recipes={recipes} resourceOptions={resourceOptions} disabled={disabled} />
      )}
    </Grid>
  );
};

export default KilnEventEditor;
