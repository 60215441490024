import { Button, Tooltip } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import ErrorIcon from '@material-ui/icons/Error';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import React from 'react';
import formatNumberWithAccounting from '../../../../common/helpers/numberFormatHelpers';
import { InfeedProduct, InfeedValues } from '../../../model';

/* eslint-disable react/display-name -- inline render cells without names */
export const getSelectedDriedProductsColumns = (
  t: (key: string) => string,
  classes: ClassNameMap,
  onRemoveSelectedDriedProduct: (infeedProduct: InfeedProduct) => void,
  getFieldFromAvailableDriedProducts: (params: InfeedValues, field: string) => string | null | number | Date,
  isSelectedProductAvailable: (params: InfeedValues) => boolean,
  disabled: boolean
): GridColDef[] => {
  const columns = [] as GridColDef[];

  if (!disabled)
    columns.push({
      field: '',
      headerName: '',
      width: 50,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Button className={classes.button} onClick={() => onRemoveSelectedDriedProduct(row as InfeedProduct)}>
          <RemoveCircle fontSize="small" />
        </Button>
      ),
      sortable: false,
      editable: false,
    });

  columns.push(
    {
      field: 'sourceEventId',
      headerName: t('sourceNumber'),
      width: 125,
      sortable: false,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'sourceResource',
      headerName: t('resource'),
      width: 125,
      sortable: false,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'sourceWorkOrder',
      headerName: t('ref'),
      sortable: true,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'productDescription',
      headerName: t('productDescription'),
      minWidth: 350,
      renderCell: ({ row, value }: GridRenderCellParams) => (
        <div className={classes.productDescription}>
          <Tooltip title={value || ''}>
            <div className="monospace">{value}</div>
          </Tooltip>
          {!isSelectedProductAvailable(row as InfeedValues) && <ErrorIcon className={classes.missingIcon} fontSize="small" color="secondary" />}
        </div>
      ),
    },
    {
      field: 'productCharacteristic',
      headerName: t('productCharacteristic'),
      minWidth: 170,
    },
    {
      field: 'factoryName',
      headerName: t('site'),
      width: 125,
    },
    {
      field: 'locationName',
      headerName: t('door'),
      width: 110,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'availableQuantity',
      headerName: t('availableQuantity'),
      width: 200,
      valueFormatter: ({ value }: GridValueFormatterParams) => (Number(value) !== 0 ? `${formatNumberWithAccounting(Number(value), 0)}` : 'N/A'),
      valueGetter: (params: GridValueGetterParams) => getFieldFromAvailableDriedProducts(params.row as InfeedValues, 'availableQuantity'),
    },
    {
      field: 'quantity',
      headerName: t('quantity'),
      width: 150,
      editable: !disabled,
      type: 'number',
      valueFormatter: ({ value }: GridValueFormatterParams) => `${formatNumberWithAccounting(Number(value), 0)}`,
    }
  );

  return columns;
};
/* eslint-enable react/display-name -- inline render cells without names */
