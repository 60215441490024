import DayjsUtils from '@date-io/dayjs';
import { Typography } from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ClassNameMap } from '@material-ui/styles';
import dayjs, { Dayjs } from 'dayjs';
import { useFormikContext } from 'formik';
import _get from 'lodash.get';
import React from 'react';

type KeyboardDateTimePickerField = {
  classes?: ClassNameMap<string>;
  disabled?: boolean;
  label: string;
  labelClasses?: Partial<ClassNameMap<string>>;
  maxDate?: string | Date | Dayjs;
  minDate?: string | Date | Dayjs;
  name: string;
  onChange?: (date: string | null) => void;
};

const KeyboardDateTimePickerField = ({
  classes = {},
  disabled = false,
  label = '',
  labelClasses = {},
  maxDate = undefined,
  minDate = undefined,
  name,
  onChange = () => undefined,
}: KeyboardDateTimePickerField): JSX.Element => {
  const { errors = {}, setFieldValue, touched = {}, values = {} } = useFormikContext();

  const currentError = _get(errors, name);
  const isFieldTouched = _get(touched, name);

  const parsedMaxDate = maxDate ? dayjs(maxDate).format('YYYY-MM-DD') : undefined;
  const parsedMinDate = minDate ? dayjs(minDate).format('YYYY-MM-DD') : undefined;

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <KeyboardDateTimePicker
        disabled={disabled}
        maxDate={parsedMaxDate}
        minDate={parsedMinDate}
        className={classes.root}
        clearable
        helperText={isFieldTouched && currentError}
        error={isFieldTouched && Boolean(currentError)}
        format="YYYY-MM-DD HH:mm"
        ampm={false}
        label={
          <Typography classes={labelClasses} variant="body2" component="label" htmlFor={name}>
            {label}
          </Typography>
        }
        onChange={(date) => {
          const formattedDate = date ? dayjs(date).format() : null;
          setFieldValue(name, formattedDate, true);
          onChange(formattedDate);
        }}
        value={_get(values, name, null)}
        fullWidth
        id={name}
      />
    </MuiPickersUtilsProvider>
  );
};

export default KeyboardDateTimePickerField;
