const airDry = {
  chartNote: 'Note : The numbers shown in the table are in humidity loss in % per day. We cannot air-dry to less than 20% moisture content.',
};

const varieties = {
  addNewVariety: 'New Variety',
  deleteMessage: 'Are you sure you want to delete this lumber variety?',
  editVariety: 'Edit Variety',
  englishName: 'English Name',
  printOrder: 'Order',
  fanDryMoistureLossPerHourOverThirtyPercent: 'Fan Dry Moisture Loss > 30% / Hour (%)',
  fanDryTemperature: 'Fan Dry Temperature',
  frenchName: 'French Name',
  heatingDegreesPerHour: 'Heating Degrees / Hour',
  initialMoisture: 'Initial Moisture (%)',
  lumberVarieties: 'Lumber Varieties',
  minimalHoursUnderThirtyPercentMoisture: 'Minimal Hours < 30% Moisture',
  moistureBalancingHours: 'Moisture Balancing Hours',
  newVariety: 'New Variety',
  standardDryMoistureLossPerHourUnderThirtyPercent: 'Standard Dry Moisture Loss < 30% / Hour (%)',
  standardDryMoistureLossPerHourOverThirtyPercent: 'Standard Dry Moisture Loss > 30% / Hour (%)',
  standardDryTemperature: 'Standard Dry Temperature',
  targetMoisture: 'Target Moisture (%)',
  targetFanDryMoisture: 'Target Fan Dry Moisture (%)',
  factory1Function: 'STG1 Function',
  factory2Function: 'STG2 Function',
};

const recipeTypes = {
  Standard: 'Standard',
  Substrate: 'Substrate',
};

const recipes = {
  addNewRecipe: 'Add New Recipe',
  archived: 'Archived',
  colorInScheduler: 'Color in Scheduler',
  deleteMessage: 'Are you sure you want to delete this recipe?',
  editRecipe: 'Edit Recipe',
  expectedBatchSize: 'Expected Batch Size',
  expectedBatchSizeRequired: 'The value of the expected batch size must be a positive number',
  favorites: 'Favorites',
  lumberVariety: 'Lumber Variety',
  maxMoistureDelta: 'Maximum moisture delta (%)',
  name: 'Name',
  newRecipe: 'New Recipe',
  others: 'Others',
  '365Code': '365 Code',
  recipes: 'Recipes',
  type: 'Type',
  recipeTypes,
};

const doors = {
  addNewDoor: 'Add New Door',
  capacity: 'Capacity',
  comment: 'Comment',
  deleteMessage: 'Are you sure you want to delete this door?',
  cannotDeleteDoor: 'The door has transactions and cannot be deleted.',
  doors: 'Doors',
  editDoor: 'Edit Door',
  factory: 'Factory',
  identifier: 'Identifier',
  name: 'Name',
  newDoor: 'New Door',
  displayIndex: "Display index",
  active: "Active",
};

const kilns = {
  addNewKiln: 'Add New Kiln',
  capacityPmpInThousands: 'PMP Capacity (Thousands)',
  deleteMessage: 'Are you sure you want to delete this kiln?',
  displayIndex: 'Display Index',
  editKiln: 'Edit Kiln',
  factory: 'Factory',
  kilns: 'Kilns',
  name: 'Name',
  newKiln: 'New Kiln',
  remark: 'Remark',
  active: "Active",
  cantdisabled: "[Upcoming events exist for this kiln. Impossible to disabled.]",
};

const rails = {
  addNewRail: 'Add New Rail',
  capacity: 'Capacity',
  comment: 'Comment',
  deleteMessage: 'Are you sure you want to delete this rail?',
  editRail: 'Edit Rail',
  factory: 'Factory',
  identifier: 'Identifier',
  name: 'Name',
  newRail: 'New Rail',
  rails: 'Rails',
  displayIndex: "Display index",
};

const settings = {
  airDry,
  averageTemperature: 'Monthly Average Temperature (°C)',
  deleteSetting: 'Delete',
  equalOrHigherThan: 'Value must be equal or higher than {{number}}',
  equalOrLesserThan: 'Value must be equal or lesser than {{number}}',
  isArchived: 'Is Archived?',
  lumberWeights: "Lumber Weights (5 = Priority, 1 = Less as possible, 0 = Not allowed)",
  month: 'Month',
  monthlyAverageTemperature: 'Monthly Average Temperature',
  required: 'Required',
  settings: 'Settings',
  weight: 'Weight',
  doors,
  kilns,
  rails,
  recipes,
  varieties,
  airDryChart: 'Air Dry Chart',
};

export default settings;
