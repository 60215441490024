import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../hooks/useAgent';
import { Alert } from '../Alert';

const url = 'api/v1/alerts/active';

const getActiveAlert = (): Promise<AxiosResponse<Alert>> => axios.get(url);

const useGetActiveAlert = (): [Agent<Alert>, Alert | undefined, boolean, () => void] => useAgent<Alert>(getActiveAlert);

export default useGetActiveAlert;
