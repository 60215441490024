import { Box, FormControlLabel, Switch } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import UnlockIcon from '@material-ui/icons/LockOpen';
import { Form, Formik, FormikHelpers } from 'formik';
import _uniq from 'lodash.uniq';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Schwoop, { SchwoopAction } from '../../../layout/schwoop/Schwoop';
import { useGetLumberVarieties } from '../../../settings/hooks/lumberVarieties';
import { EventTypeEnum } from '../../enums';
import { getEventTypeFromResource, getTaskDurationInHours, mapEventDataToValues } from '../../helpers/eventHelpers';
import { EventValues } from '../../model';
import { PlanifEvent } from '../../model/Event';
import { PlanifResource } from '../../model/Resource';
import { getEventSchema } from '../../schemas/EventSchemas';
import EventEditorContextProvider from './EventEditorContext';
import KilnEventEditor from './kiln/KilnEventEditor';
import MaintenanceEventEditorFields from './maintenance/MaintenanceEventEditorFields';
import OrderEventEditorFields from './order/OrderEventEditorFields';
import StackingEventEditorFields from './stacking/StackingEventEditorFields';
import TransferEventEditorFields from './transfer/TransferEventEditorFields';

type EventEditorProps = {
  eventRecord: PlanifEvent;
  isReadonly?: boolean;
  resources?: PlanifResource[];
  resourceId?: number;
  onClose: () => void;
  onDelete: (eventRecord: PlanifEvent) => Promise<boolean>;
  onSubmit: (eventRecord: PlanifEvent, resetForm: (eventRecord: PlanifEvent) => void) => void;
};

const EventEditor = ({ eventRecord, isReadonly = false, onClose, resources = [], resourceId, onDelete, onSubmit }: EventEditorProps): ReactElement => {
  const { t } = useTranslation('planning');
  const [getLumberVarieties, { lumberVarieties = [] } = {}] = useGetLumberVarieties();
  const [isEventLocked, setIsEventLocked] = useState<boolean>(eventRecord.isLocked);
  const eventResource = resources.find((x) => x.id === resourceId) as PlanifResource;
  const initialRecipeIds = _uniq(eventRecord?.infeedProducts?.map((x) => x.recipeId) || []);

  useEffect(() => {
    getLumberVarieties();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  const handleSubmit = (values: EventValues, { resetForm }: FormikHelpers<EventValues>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- Duration has to be ignored in order to maintain time in our dates. If removed, bryntum overrides end date.
    const { duration: _, ...others } = values;

    eventRecord.beginBatch();
    eventRecord.set({ ...others }, null);
    eventRecord.setDuration(getTaskDurationInHours(values.startDate, values.endDate), 'h');

    eventRecord.endBatch();
    eventRecord.isCreating = false;

    onSubmit(eventRecord, (updatedRecord) => resetForm({ values: mapEventDataToValues(updatedRecord, eventResource) }));
  };

  const handleChangeLockedStatus = useCallback(
    async (isLocked: boolean) => {
      if (eventRecord.eventType == EventTypeEnum.Maintenance) {
        setIsEventLocked(isLocked);
      } else {
        eventRecord.beginBatch();
        const eventValues = { manualLock: isLocked, isLocked, draggable: !isLocked, resizable: !isLocked };
        eventRecord.set(eventValues, null);
        eventRecord.endBatch();
        onClose();
      }
    },
    [eventRecord, onClose]
  );

  const handleCancel = () => {
    if (eventRecord.isCreating) eventRecord.remove();

    onClose();
  };

  const handleDelete = useCallback(async () => {
    if (await onDelete(eventRecord)) onClose();
  }, [eventRecord, onDelete, onClose]);

  const getActions = (dirty: boolean) => {
    const actions: SchwoopAction[] = [];

    if (eventRecord && !eventRecord.isCreating && !isReadonly) {
      actions.push(
        {
          label: isEventLocked ? t('unlockTask') : t('lockTask'),
          handler: () => handleChangeLockedStatus(!isEventLocked),
          icon: isEventLocked ? UnlockIcon : LockIcon,
          iconColor: 'primary',
          labelColor: 'primary',
          disabled: dirty && !isEventLocked,
          tooltipText: dirty && !isEventLocked ? t('messages.lockTaskDisabled') : '',
          testId: 'event-lock',
        },
        {
          label: t('deleteTask'),
          handler: handleDelete,
          icon: DeleteIcon,
          iconColor: 'secondary',
          labelColor: 'secondary',
          disabled: isEventLocked,
          tooltipText: isEventLocked ? t('messages.cannotModifyLockedTask') : '',
          testId: 'event-delete',
        }
      );
    }
    return actions;
  };

  const getCurrentTypeFields = (values: EventValues): ReactElement | null => {
    switch (values.eventType) {
      case EventTypeEnum.Drying:
        return <KilnEventEditor disabled={isEventLocked || isReadonly} isCreating={eventRecord?.isCreating} />;
      case EventTypeEnum.Order:
        return <OrderEventEditorFields disabled={isEventLocked || isReadonly} />;
      case EventTypeEnum.Stacking:
        return <StackingEventEditorFields disabled={isEventLocked || isReadonly} isCreating={eventRecord?.isCreating} />;
      case EventTypeEnum.Transfer:
        return <TransferEventEditorFields disabled={isEventLocked || isReadonly} isCreating={eventRecord?.isCreating} />;
      case EventTypeEnum.Maintenance:
        return <MaintenanceEventEditorFields disabled={isEventLocked || isReadonly} />;
      default:
        return null;
    }
  };

  const handleMaintenanceChange =
    (setFieldValue: (field: string, value: string | null) => void, values: EventValues) => (_: React.ChangeEvent<unknown>, checked: boolean) => {
      const resource = resources.find((x) => x.id === values.resourceId);
      setFieldValue('eventType', checked ? EventTypeEnum.Maintenance : getEventTypeFromResource(resource?.type));
    };

  return (
    <Formik
      validationSchema={getEventSchema(t)}
      initialValues={mapEventDataToValues(eventRecord || ({} as PlanifEvent), eventResource)}
      onSubmit={handleSubmit}>
      {({ dirty, setFieldValue, submitForm, values }) => (
        <Form>
          <Schwoop
            open
            disabled={isReadonly}
            onCancel={handleCancel}
            onConfirm={submitForm}
            title={eventRecord?.isCreating ? t('newTask') : `${t('task')} #${values.id}`}
            customAction={
              <FormControlLabel
                disabled={!eventRecord.isCreating || !!initialRecipeIds.length}
                control={<Switch />}
                label={t('maintenance')}
                labelPlacement="start"
                checked={values.eventType === EventTypeEnum.Maintenance}
                onChange={handleMaintenanceChange(setFieldValue, values)}
              />
            }
            actions={getActions(dirty)}>
            <EventEditorContextProvider initialRecipeIds={initialRecipeIds} resources={resources} lumberVarieties={lumberVarieties}>
              <Box m={2}>{getCurrentTypeFields(values)}</Box>
            </EventEditorContextProvider>
          </Schwoop>
        </Form>
      )}
    </Formik>
  );
};

export default EventEditor;
