import DeleteIcon from '@material-ui/icons/Delete';
import React, { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { ConfirmationDialogConfigs } from './ConfirmationDialog';

const useConfirmDeleteDialog = (): { getDeleteConfirmation: (config: ConfirmationDialogConfigs) => Promise<boolean> } => {
  const { openDialog } = useContext(AppContext);

  const getDeleteConfirmation = (config: Partial<ConfirmationDialogConfigs>): Promise<boolean> =>
    new Promise<boolean>((resolve: (value: boolean) => void) => {
      const confirmationButtonProps = { ...(config.confirmationButtonProps || {}), startIcon: <DeleteIcon color={'secondary'} /> };
      openDialog({ ...config, confirmationButtonProps, actionCallback: resolve });
    });

  return { getDeleteConfirmation };
};

export default useConfirmDeleteDialog;
