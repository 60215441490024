import { useMsal } from '@azure/msal-react';
import { AbstractCrudManager, StateTrackingManager } from '@bryntum/schedulerpro';
import { BryntumSchedulerPro, BryntumUndoRedo } from '@bryntum/schedulerpro-react';
import {
  CollectionFilterConfig,
  DateHelper,
  DependencyModel,
  EventStore,
  MenuItemConfig,
  ResourceStore,
  ResourceTimeRangeModel,
  ResourceTimeRangeStore,
  SchedulerAssignmentStore,
  SchedulerDependencyStore,
  SchedulerEventStore,
  SchedulerResourceStore,
  TimeSpan,
  ViewPreset,
} from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { Box, Button, useTheme } from '@material-ui/core';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Formik } from 'formik';
import _get from 'lodash.get';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, isWeekendInTimeRange } from '../../common/helpers/date';
import { BeforeEventDropFinalizeEvent } from '../../common/typings/extendedSchedulerTypes';
import useBearerToken from '../../hooks/useBearerToken';
import useErrorStore from '../../hooks/useErrorStore';
import useSignalr from '../../hooks/useSignalr';
import useSuccessStore from '../../hooks/useSuccessStore';
import useConfirmationDialog from '../../layout/dialog/useConfirmationDialog';
import useConfirmDeleteDialog from '../../layout/dialog/useConfirmDeleteDialog';
import { DateRangePickerField } from '../../layout/fields';
import useSchedulerStyles from '../../layout/scheduler/schedulerStyles';
import { EventTypeEnum, OptimizationStatusEnum, PlanningOptimizationStatusEnum } from '../enums';
import { EventSubtypeEnum } from '../enums/EventSubtypeEnum';
import ResourceTypeEnum, { ResourceTypeIndex } from '../enums/ResourceTypeEnum';
import { getEventTooltip } from '../eventTooltip/EventTooltip';
import { eventHasDependencies, getMainRecipeColor, getTaskDurationInHours, mapEventDatesToUtc } from '../helpers/eventHelpers';
import { useAlignResourceEvents, useCancelOptimization, useGetActivePlanningOptimization } from '../hooks';
import { balancingEventColor, maintenanceEventColor, PlanifEvent } from '../model/Event';
import { getSeverityColor } from '../model/EventFlag';
import { PlanifResource } from '../model/Resource';
import EventEditor from './eventEditor/EventEditor';
import EventFilters from './filters/EventFilters';
import OperationalResearchStatsModal from './optimization/OperationalResearchStatsModal';
import OptimizationButton from './optimization/OptimizationButton';
import OptimizationSettingEditor from './optimization/OptimizationSettingEditor';
import getSchedulerConfig from './schedulerConfig';
import { getResourceIdFromAssigmentStore, handleOptimizationFail, validateDryingEventDependencies, validateOrderEventDependencies } from './SchedulerHelpers';
import { CustomViewPreset, CustomViewPresetLocalStorageKey } from './viewPresets';
import ViewSelector from './ViewSelector';

const bryntumDurationUnit = 'h';
dayjs.extend(utc);

export type ExtendedCollectionFilterConfig = CollectionFilterConfig & { value: number };
export const dependencyFilter = 'dependencyFilter';

const Scheduler = (): JSX.Element => {
  const hubUrl = `${process.env.REACT_APP_API_URL}/api/v1/notifications`;
  const schedulerRef = useRef<typeof BryntumSchedulerPro | null>(null) as React.MutableRefObject<typeof BryntumSchedulerPro | null>;

  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const initialSchedulerDates = {
    startDate: DateHelper.add(today, -2, 'weeks'),
    endDate: DateHelper.add(today, 3, 'month'),
  };

  const [schedulerDates, setSchedulerDates] = useState(initialSchedulerDates);
  const { accounts } = useMsal();
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isOptimisationModalOpen, setIsOptimisationModalOpen] = useState(false);
  const [isOperationalResearchStatsModalOpen, setIsOperationalResearchStatsModalOpen] = useState(false);
  const [eventRecord, setEventRecord] = useState<PlanifEvent>();
  const [timeRanges, setTimeRanges] = useState<TimeSpan[]>([]);
  const [assignmentStore, setAssignmentStore] = useState<SchedulerAssignmentStore>();
  const [resourceTimeRangeStore, setResourceTimeRangeStore] = useState<ResourceTimeRangeStore>();
  const [taskStore, setTaskStore] = useState<SchedulerEventStore>();
  const [dependencyStore, setDependencyStore] = useState<SchedulerDependencyStore>();
  const [viewPreset, setViewPreset] = useState<CustomViewPreset>(
    (window.localStorage.getItem(CustomViewPresetLocalStorageKey) as CustomViewPreset) || CustomViewPreset.weekAndDayCustom
  );
  const [resourceStore, setResourceStore] = useState<SchedulerResourceStore>();
  const [alignResourceEvents] = useAlignResourceEvents();
  const resourceId = getResourceIdFromAssigmentStore(assignmentStore, eventRecord);
  const [getActivePlanningOptimization, activePlanningOptimization] = useGetActivePlanningOptimization();

  const [cancelOptimization] = useCancelOptimization();

  const [latestBearerToken, getBearerToken] = useBearerToken();
  const [, errorActions] = useErrorStore();
  const [, successActions] = useSuccessStore();

  const classes = useSchedulerStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { on } = useSignalr(hubUrl);
  const { getConfirmation } = useConfirmationDialog();
  const { getDeleteConfirmation } = useConfirmDeleteDialog();

  const [{ idTokenClaims }] = accounts as { idTokenClaims: { sub: string } }[];
  const isReadonly = !!activePlanningOptimization?.planningOptimization;

  const getLoadRequest = () => {
    if (!schedulerRef.current?.instance) return;
    const { startDate, endDate } = schedulerRef.current.instance;
    return {
      request: {
        params: { startDate: formatDate(startDate), endDate: formatDate(endDate) },
      },
    };
  };

  const handleRefreshScheduler = useCallback(
    async (impactedEvents: number[]) => {
      const { project } = schedulerRef.current.instance;

      if (isEditorOpen && impactedEvents.some((x) => x === eventRecord?.id)) {
        await getConfirmation({
          confirmationButtonProps: { autoFocus: true },
          messages: [t('planning:messages.concurrencyModification')],
          title: t('planning:invalidModification'),
          confirmationText: t('planning:refresh'),
          hideCancel: true,
        });

        setIsEditorOpen(false);
        setEventRecord(undefined);
      }

      project.load(getLoadRequest());
    },
    [isEditorOpen, t, eventRecord, getConfirmation]
  );

  useEffect(() => {
    const sub = on<{ userId: string; impactedEvents: number[] }>('refreshScheduler').subscribe(
      ({ userId, impactedEvents }: { userId: string; impactedEvents: number[] }) => {
        if (schedulerRef.current && latestBearerToken && userId !== idTokenClaims?.sub) handleRefreshScheduler(impactedEvents);
      }
    );

    return () => sub.unsubscribe();
  }, [latestBearerToken, schedulerRef, handleRefreshScheduler, idTokenClaims, on]);

  const handleInventoryImportSuccess = useCallback(() => {
    const { project } = schedulerRef.current.instance;

    if (isEditorOpen) {
      setIsEditorOpen(false);
      setEventRecord(undefined);
    }

    project.load(getLoadRequest());
  }, [isEditorOpen]);

  useEffect(() => {
    const sub = on('inventoryImportSuccess').subscribe(() => {
      if (schedulerRef.current && latestBearerToken) handleInventoryImportSuccess();
    });

    return () => sub.unsubscribe();
  }, [latestBearerToken, schedulerRef, handleInventoryImportSuccess, on]);

  const handleInventoryImportError = useCallback(() => {
    errorActions.setErrorMessage(t('planning:messages.inventoryImportError'));
  }, [errorActions, t]);

  useEffect(() => {
    const sub = on('inventoryImportError').subscribe(() => {
      if (schedulerRef.current && latestBearerToken) handleInventoryImportError();
    });

    return () => sub.unsubscribe();
  }, [latestBearerToken, schedulerRef, handleInventoryImportError, on]);

  const handleOptimizationStarted = useCallback(() => {
    if (isEditorOpen) {
      setIsEditorOpen(false);
      setEventRecord(undefined);
    }

    successActions.setSuccessMessage(t('planning:messages.optimizationStarted'));
    getActivePlanningOptimization();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- hook not memoized
  }, [isEditorOpen]);

  useEffect(() => {
    const sub = on('optimizationReceived').subscribe(() => {
      if (schedulerRef.current && latestBearerToken) getActivePlanningOptimization();
    });

    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- hook not memoized
  }, [latestBearerToken, schedulerRef, on]);

  useEffect(() => {
    const handlePlanningOptimizationAsync = async () => {
      if (activePlanningOptimization?.planningOptimization?.status === PlanningOptimizationStatusEnum.completed) {
        if (
          activePlanningOptimization.planningOptimization.optimizationStatus === OptimizationStatusEnum.Optimal ||
          activePlanningOptimization.planningOptimization.optimizationStatus === OptimizationStatusEnum.Feasible
        ) {
          successActions.setSuccessMessage(t('planning:optimization.calculationCompletedToast'));
          setIsOperationalResearchStatsModalOpen(true);
        } else {
          await handleOptimizationFail(activePlanningOptimization.planningOptimization.optimizationStatus, getConfirmation, t);
          await cancelOptimization(activePlanningOptimization.planningOptimization.id);
          getActivePlanningOptimization();
        }
      }
    };
    handlePlanningOptimizationAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- hook not memoized
  }, [activePlanningOptimization, t]);

  useEffect(() => {
    const sub = on('optimizationStarted').subscribe(() => {
      if (schedulerRef.current && latestBearerToken) handleOptimizationStarted();
    });

    return () => sub.unsubscribe();
  }, [latestBearerToken, schedulerRef, handleOptimizationStarted, on]);

  useEffect(() => {
    const sub = on('optimizationEnded').subscribe(() => {
      if (schedulerRef.current && latestBearerToken) {
        const { project } = schedulerRef.current.instance;
        project.load(getLoadRequest());
        getActivePlanningOptimization();
      }
    });

    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- hook not memoized
  }, [latestBearerToken, schedulerRef, on]);

  useEffect(() => {
    if (schedulerRef.current && latestBearerToken) {
      schedulerRef.current.instance.project.on(
        'beforeSend',
        ({ requestConfig }: { requestConfig: { headers: { Authorization: string | undefined; 'Accept-Language': string } } }) => {
          getBearerToken();
          requestConfig.headers = requestConfig.headers || {};
          requestConfig.headers.Authorization = latestBearerToken;
          requestConfig.headers['Accept-Language'] = axios.defaults.headers['Accept-Language'];
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- getBearerToken
  }, [latestBearerToken]);

  useEffect(() => {
    if (schedulerRef.current && latestBearerToken) {
      schedulerRef.current.instance.project.on({
        load() {
          resetStateTrackingManager();
        },
        sync({ response }: { response: { events: { rows: PlanifEvent[] } } }) {
          if (response.events.rows.some((x) => !!_get(x, '$PhantomId'))) resetStateTrackingManager();
        },
      });
    }
  }, [latestBearerToken]);

  useEffect(() => {
    if (latestBearerToken) getActivePlanningOptimization();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- hooks not memoized
  }, [latestBearerToken]);

  useEffect(() => {
    if (isEditorOpen && schedulerRef.current.instance?.features?.eventTooltip) {
      schedulerRef.current.instance.features.eventTooltip.tooltip.hide();
    }
  }, [isEditorOpen]);

  const showDependencies = (eventRecord: PlanifEvent) => {
    if (schedulerRef.current) {
      const { dependencyStore, features } = schedulerRef.current.instance;
      const activeDependencyFilter = dependencyStore.filters.find((x: CollectionFilterConfig) => x.id == dependencyFilter) as ExtendedCollectionFilterConfig;

      if (activeDependencyFilter?.value === eventRecord.id) dependencyStore.removeFilter(dependencyFilter);
      else {
        dependencyStore.filter({
          id: dependencyFilter,
          filterBy: (dependency: DependencyModel) => dependency.from === eventRecord.id || dependency.to === eventRecord.id,
          value: eventRecord.id,
        });
      }

      features.dependencies.disabled = !dependencyStore.filters.count;
    }
  };

  useEffect(() => {
    if (schedulerRef.current && dependencyStore) {
      schedulerRef.current.instance.features.eventMenu.items.dependenciesEvent.onItem = ({ eventRecord }: { eventRecord: PlanifEvent }) => {
        showDependencies(eventRecord);
      };

      schedulerRef.current.instance.features.eventMenu.items.alignTasksEvent.onItem = ({ eventRecord }: { eventRecord: PlanifEvent }) => {
        alignResourceEvents(getResourceIdFromAssigmentStore(assignmentStore, eventRecord), eventRecord.id).then(() => {
          const { project } = schedulerRef.current.instance;
          project.load(getLoadRequest());
        });
      };

      schedulerRef.current.instance.features.eventMenu.processItems = ({
        eventRecord,
        items,
      }: {
        eventRecord: PlanifEvent;
        items: { dependenciesEvent: MenuItemConfig };
      }) => {
        items.dependenciesEvent.disabled = !eventHasDependencies(eventRecord);
        const activeDependencyFilter = dependencyStore.filters.find((x: CollectionFilterConfig) => x.id == dependencyFilter) as ExtendedCollectionFilterConfig;
        items.dependenciesEvent.text = eventRecord.id === activeDependencyFilter?.value ? t('planning:hideLinkedTask') : t('planning:showLinkedTask');
        items.dependenciesEvent.icon = eventRecord.id === activeDependencyFilter?.value ? 'b-fa b-fa-fw b-fa-eye-slash' : 'b-fa b-fa-fw b-fa-eye';
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- ignore alignResourceEvents hook
  }, [assignmentStore, dependencyStore, t]);

  useEffect(() => {
    if (schedulerRef.current && taskStore) {
      schedulerRef.current.instance.features.eventDrag.validatorFn = ({
        startDate,
        endDate,
        eventRecords,
      }: {
        startDate: Date;
        endDate: Date;
        eventRecords: PlanifEvent[];
      }) => {
        const [draggedEvent] = eventRecords;
        if (draggedEvent.eventType === EventTypeEnum.Drying) {
          return validateDryingEventDependencies(draggedEvent, endDate);
        } else if (draggedEvent.eventType === EventTypeEnum.Order) {
          return validateOrderEventDependencies(draggedEvent, startDate, (events: PlanifEvent[]) => taskStore.max('endDate', events) as Date);
        }
        return true;
      };
    }
  }, [taskStore]);

  useEffect(() => {
    if (schedulerRef.current && taskStore) {
      schedulerRef.current.instance.features.eventResize.validatorFn = ({
        startDate,
        endDate,
        eventRecord,
      }: {
        startDate: Date;
        endDate: Date;
        eventRecord: PlanifEvent;
      }) => {
        if (eventRecord.eventType === EventTypeEnum.Drying) {
          return validateDryingEventDependencies(eventRecord, endDate);
        } else if (eventRecord.eventType === EventTypeEnum.Order) {
          return validateOrderEventDependencies(eventRecord, startDate, (events: PlanifEvent[]) => taskStore.max('endDate', events) as Date);
        }
        return true;
      };
    }
  }, [taskStore]);

  useEffect(() => {
    if (schedulerRef.current && latestBearerToken) {
      const { project } = schedulerRef.current.instance;
      project.load(getLoadRequest());
    }
  }, [latestBearerToken, schedulerDates.startDate, schedulerDates.endDate]);

  useEffect(() => {
    if (schedulerRef.current) {
      const { taskStore, resourceStore, assignmentStore, resourceTimeRangeStore, dependencyStore } = schedulerRef.current.instance;
      resourceStore?.group({
        field: 'type',
        fn: (recordA: PlanifResource, recordB: PlanifResource) => {
          return ResourceTypeIndex[recordA.type as keyof typeof ResourceTypeEnum] < ResourceTypeIndex[recordB.type as keyof typeof ResourceTypeEnum] ? -1 : 1;
        },
      });
      setResourceStore(resourceStore);
      setTaskStore(taskStore);
      setAssignmentStore(assignmentStore);
      setResourceTimeRangeStore(resourceTimeRangeStore);
      setDependencyStore(dependencyStore);
    }
  }, []);

  useEffect(() => {
    if (schedulerRef.current) {
      const { project } = schedulerRef.current.instance;
      const stm = project.stm as StateTrackingManager;
      const storeToRemove = stm.stores.filter((x) => !(x instanceof ResourceStore || x instanceof EventStore));
      storeToRemove.forEach((x) => stm.removeStore(x));
    }
  }, []);

  const resetStateTrackingManager = () => {
    if (schedulerRef.current) {
      const { project } = schedulerRef.current.instance;
      project.stm.disable();
      project.stm.resetQueue();
      project.stm.enable();
    }
  };

  const showEditor = (eventRecord: PlanifEvent) => {
    if (schedulerRef.current) {
      setEventRecord(eventRecord);
      setIsEditorOpen(true);
    }
  };

  const closeEditor = useCallback(async () => {
    const { project } = schedulerRef.current.instance;
    if (schedulerRef.current) {
      project.resumeAutoSync(true);
      setEventRecord(undefined);
      setIsEditorOpen(false);
    }
  }, []);

  const handleEventSubmit = async (eventRecord: PlanifEvent, resetForm: (eventRecord: PlanifEvent) => void): Promise<void> => {
    const { project } = schedulerRef.current.instance;

    if (!eventRecord.eventStore) {
      taskStore?.add(eventRecord);
      await project.commitAsync();
    }

    let closeEventEditor = true;
    const syncResult = await project.sync();
    if (syncResult?.response?.messages?.length) {
      resetForm(eventRecord);
      closeEventEditor = await getConfirmation({
        confirmationButtonProps: { autoFocus: true },
        messages: syncResult.response.messages,
        title: t('warning'),
        cancellationText: t('planning:modify'),
      });
    }

    if (closeEventEditor) closeEditor();
  };

  const handleOpenOptimizer = useCallback(() => {
    setIsOptimisationModalOpen(true);
  }, []);

  const handleOpenOptimizedStats = useCallback(() => {
    setIsOperationalResearchStatsModalOpen(true);
  }, []);

  const handleDelete = useCallback(
    async (eventRecord: PlanifEvent): Promise<boolean> => {
      const { project } = schedulerRef.current.instance;
      const confirmed = await getDeleteConfirmation({
        messages: [t('planning:messages.confirmDelete')],
        title: t('planning:deleteTask'),
        confirmationText: t('confirm'),
        cancellationText: t('cancel'),
      }).then(async (result: boolean) => {
        if (result) {
          eventRecord.remove();
          await project.sync();
        }
        return result;
      });

      return confirmed;
    },
    [getDeleteConfirmation, t]
  );

  const updateSelectedTimeRange = (eventRecord: PlanifEvent | null) => {
    if (!eventRecord) setTimeRanges([]);
    else {
      const { startDate, endDate, duration, durationUnit, name, id } = eventRecord;
      setTimeRanges([{ startDate, endDate, duration, durationUnit, name, id } as TimeSpan]);
    }
  };

  const getEventStyle = useCallback(
    (event: PlanifEvent): string => {
      const { eventFlag, eventType } = event;
      const mainColor = getMainRecipeColor(event);
      let colorStyle = '';
      if (mainColor)
        if (event.eventSubtype === EventSubtypeEnum.balancing)
          colorStyle = `
            background-color: #${balancingEventColor};
            color: ${theme.palette.getContrastText(`#${balancingEventColor}`)};
          `;
        else
          colorStyle = `
            background-color: #${mainColor};
            color: ${theme.palette.getContrastText(`#${mainColor}`)};
          `;
      else if (eventType === EventTypeEnum.Maintenance)
        colorStyle = `
          background-color: #${maintenanceEventColor};
          color: ${theme.palette.getContrastText(`#${maintenanceEventColor}`)};
        `;

      const borderStyle = eventFlag
        ? `
            border-bottom-color: ${getSeverityColor(eventFlag.severity)};
            border-bottom-width: thick;
            border-left: none;
            border-right: none;
            border-top: none;
            `
        : 'border: none;';

      return colorStyle + borderStyle;
    },
    [theme.palette]
  );

  const mapEventRowResponse = useCallback(
    (response: { events: { rows: PlanifEvent[] } }) => {
      response.events.rows = response.events.rows.map((event: PlanifEvent) => {
        const { isLocked, startDate, endDate } = event;
        return {
          ...event,
          style: getEventStyle(event),
          resizable: !isLocked,
          draggable: !isLocked,
          manuallyScheduled: true,
          startDate: dayjs.utc(startDate).local().toDate(),
          endDate: dayjs.utc(endDate).local().toDate(),
          durationUnit: bryntumDurationUnit,
        } as PlanifEvent;
      });
    },
    [getEventStyle]
  );

  const updateTimeRanges = useCallback((events: { rows: PlanifEvent[]; removed?: PlanifEvent[] }) => {
    if (!schedulerRef.current) return;
    const { timeRanges } = schedulerRef.current.instance;
    if (timeRanges.length) {
      const updatedEvent = events.rows.find((x) => x.id === timeRanges[0].id);
      if (updatedEvent) updateSelectedTimeRange(updatedEvent);

      const deletedEvent = events.removed?.find((x) => x.id === timeRanges[0].id);
      if (deletedEvent) updateSelectedTimeRange(null);
    }
  }, []);

  const mapLoadResponse = useCallback(
    ({ response }: { response: { events: { rows: PlanifEvent[] }; resourceTimeRanges: { rows: ResourceTimeRangeModel[] } } }) => {
      mapEventRowResponse(response);
      response.resourceTimeRanges.rows = response.resourceTimeRanges.rows.map((resourceTimeRange: ResourceTimeRangeModel) => {
        return {
          ...resourceTimeRange,
          cls: isWeekendInTimeRange(dayjs(resourceTimeRange.startDate).toDate(), dayjs(resourceTimeRange.endDate).toDate()) ? 'weekend' : 'default',
          name: '',
        } as ResourceTimeRangeModel;
      });
      updateTimeRanges(response.events);
    },
    [mapEventRowResponse, updateTimeRanges]
  );

  const mapSyncResponse = useCallback(
    ({ response }: { response: { events: { rows: PlanifEvent[]; removed: PlanifEvent[] } } }) => {
      mapEventRowResponse(response);
      updateTimeRanges(response.events);
    },
    [mapEventRowResponse, updateTimeRanges]
  );

  const handleSchedulerDateChange = (firstDate: string, lastDate: string) => {
    const startDate = new Date(firstDate);
    const endDate = new Date(lastDate);
    if (!DateHelper.isValidDate(startDate) || !DateHelper.isValidDate(endDate)) return;
    setSchedulerDates({ startDate, endDate: DateHelper.add(endDate, 1, 'day') });
  };

  const reinitializeSchedulerDates = (setFieldValue: (field: string, value: string) => void) => {
    setFieldValue('startDate', formatDate(initialSchedulerDates.startDate));
    setFieldValue('endDate', formatDate(initialSchedulerDates.endDate));
    setSchedulerDates(initialSchedulerDates);
  };

  const handleViewPresetChange = (value: CustomViewPreset) => {
    if (value) setViewPreset(value);
  };

  useEffect(() => {
    window.localStorage.setItem(CustomViewPresetLocalStorageKey, viewPreset);
    const now = new Date();
    if (schedulerRef.current && now > schedulerDates.startDate && now < schedulerDates.endDate) {
      schedulerRef.current.instance.scrollToDate(now, { block: 'start', edgeOffset: 70 });
    }
  }, [viewPreset, schedulerDates]);

  useEffect(() => {
    if (resourceTimeRangeStore != null) {
      resourceTimeRangeStore.removeFilter('LessThanAnHourFilter');

      if (viewPreset !== CustomViewPreset.dayAndHourCustom) {
        resourceTimeRangeStore.filter({
          id: 'LessThanAnHourFilter',
          filterBy: (record: ResourceTimeRangeModel) => getTaskDurationInHours(record.startDate, record.endDate) >= 1,
        });
      }
    }
  }, [viewPreset, resourceTimeRangeStore]);

  const hideDependencies = () => {
    if (schedulerRef.current) {
      const { dependencyStore, features } = schedulerRef.current.instance;
      features.dependencies.disabled = true;
      dependencyStore.removeFilter(dependencyFilter);
    }
  };

  const listeners = useMemo(
    () => ({
      presetChange: ({ to }: { to: ViewPreset }) => {
        handleViewPresetChange(to.id as CustomViewPreset);
      },
      beforeTaskEdit: ({ taskRecord }: { taskRecord: PlanifEvent }) => {
        taskRecord.durationUnit = bryntumDurationUnit;

        if (taskRecord.isCreating) {
          const startDate = taskRecord.startDate as Date;
          const endDate = taskRecord.endDate as Date;

          if (schedulerRef.current.instance.viewPreset.id == CustomViewPreset.dayAndHourCustom) {
            startDate.setMinutes(0, 0, 0);
            endDate.setMinutes(0, 0, 0);
            if (taskRecord.get('isDragCreate')) {
              endDate.setHours(endDate.getHours() + 1);
            }
          } else {
            startDate.setHours(12, 0, 0, 0);
            endDate.setHours(12, 0, 0);
          }

          taskRecord.setStartDate(startDate);
          taskRecord.setEndDate(endDate);
        }

        showEditor(taskRecord);
        return false;
      },
      beforeTaskDelete: ({ taskRecord }: { taskRecord: PlanifEvent }) => {
        if (!taskRecord.isLocked) {
          handleDelete(taskRecord);
        }
        return false;
      },
      eventSelectionChange: ({ selected }: { selected: PlanifEvent[] }) => updateSelectedTimeRange(selected[0]),
      beforeEventDropFinalize: ({ context }: BeforeEventDropFinalizeEvent<PlanifResource>) => {
        context.valid = context.newResource.type === context.resourceRecord.type;
      },
      afterEventDrop: ({ draggedRecords }: { draggedRecords: PlanifEvent[] }) => updateSelectedTimeRange(draggedRecords[0]),
      eventResizeEnd: ({ eventRecord }: { eventRecord: PlanifEvent }) => updateSelectedTimeRange(eventRecord),
      dragCreateEnd: ({ eventRecord }: { eventRecord: PlanifEvent }) => eventRecord.set('isDragCreate', true),
      eventClick: hideDependencies,
      scheduleClick: hideDependencies,
    }),
    [handleDelete]
  );

  const projectListeners = useMemo(
    () => ({
      beforeSync: ({ pack }: { pack: { events: { updated: PlanifEvent[]; added: PlanifEvent[] }; startDate: string; endDate: string } }) => {
        const { startDate, endDate } = schedulerRef.current.instance;
        pack.startDate = formatDate(startDate);
        pack.endDate = formatDate(endDate);

        if (pack.events?.updated) pack.events.updated = mapEventDatesToUtc(pack.events.updated);
        if (pack.events?.added) pack.events.added = mapEventDatesToUtc(pack.events.added);
        return true;
      },
      // Will be called after data is fetched but before it is loaded into stores
      beforeLoadApply: mapLoadResponse,
      beforeSyncApply: mapSyncResponse,
      loadFail: ({ response, source }: { response: { messages: string[] }; source: AbstractCrudManager }) => {
        if (!response) {
          getBearerToken();
          source.load(getLoadRequest());
        }
      },
      syncFail: ({ response, source }: { response: { messages: string[] }; source: AbstractCrudManager }) => {
        if (!response) {
          getBearerToken();
        }
        errorActions.setErrorMessage((response?.messages.length && response.messages[0]) || 'Error');
        source.load(getLoadRequest());
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- getBearerToken
    [errorActions, mapLoadResponse, mapSyncResponse]
  );

  const schedulerConfig = useMemo(
    () => getSchedulerConfig(t, listeners, projectListeners, getEventTooltip(showDependencies, hideDependencies, t, schedulerRef)),
    [t, listeners, projectListeners]
  );

  const closeOperationalResearchStatsModal = useCallback(() => setIsOperationalResearchStatsModalOpen(false), []);

  return (
    <Fragment>
      <Box className={classes.header}>
        <Formik initialValues={{ startDate: formatDate(schedulerDates.startDate), endDate: formatDate(schedulerDates.endDate) }} onSubmit={() => undefined}>
          {({ setFieldValue }) => (
            <>
              <Box className={classes.headerActions}>
                <Box className={classes.viewPresetSelector}>
                  <ViewSelector value={viewPreset} onChange={handleViewPresetChange} />
                </Box>
                <Box className={classes.dateFilters}>
                  <DateRangePickerField
                    startDateName="startDate"
                    startDateLabel={t('planning:from')}
                    endDateLabel={t('planning:to')}
                    endDateName="endDate"
                    onChange={handleSchedulerDateChange}
                  />
                  <Button color="primary" className={classes.button} onClick={() => reinitializeSchedulerDates(setFieldValue)}>
                    {t('planning:today')}
                  </Button>
                </Box>
                <Box className={classes.headerActions}>
                  <Box>{taskStore && resourceStore && <EventFilters eventStore={taskStore} resourceStore={resourceStore} />}</Box>
                </Box>
              </Box>
              <Box className={classes.headerActionNewTask}>
                <OptimizationButton
                  planningOptimizationStatus={activePlanningOptimization?.planningOptimization?.status}
                  optimizationStatus={activePlanningOptimization?.planningOptimization?.optimizationStatus}
                  onOpenOptimizer={handleOpenOptimizer}
                  onOpenStats={handleOpenOptimizedStats}
                />
                <Box>
                  {schedulerRef.current && (
                    <BryntumUndoRedo
                      items={{ transactionsCombo: null }}
                      project={schedulerRef.current.instance.project}
                      scheduler={schedulerRef.current.instance}
                      cls={classes.undoRedo}
                      style={{ boxShadow: 'none', border: 'none' }}
                    />
                  )}
                </Box>
              </Box>
            </>
          )}
        </Formik>
      </Box>
      <BryntumSchedulerPro
        ref={schedulerRef}
        {...schedulerConfig}
        viewPreset={viewPreset}
        timeRanges={timeRanges}
        startDate={schedulerDates.startDate}
        endDate={schedulerDates.endDate}
        barMargin={3}
        readOnly={isReadonly}
      />
      {isEditorOpen && eventRecord && (
        <EventEditor
          eventRecord={eventRecord}
          isReadonly={isReadonly}
          onDelete={handleDelete}
          resources={resourceStore?.data as PlanifResource[]}
          resourceId={resourceId}
          onClose={closeEditor}
          onSubmit={handleEventSubmit}
        />
      )}
      {isOptimisationModalOpen && <OptimizationSettingEditor onClose={() => setIsOptimisationModalOpen(false)} />}

      {isOperationalResearchStatsModalOpen && activePlanningOptimization?.planningOptimization && (
        <OperationalResearchStatsModal planningOptimization={activePlanningOptimization.planningOptimization} onClose={closeOperationalResearchStatsModal} />
      )}
    </Fragment>
  );
};

export default Scheduler;
