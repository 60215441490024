/**
 * Setup locales
 */
import EnLocale from '@bryntum/schedulerpro/locales/schedulerpro.locale.En.js';
import { LocaleManager } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { englishLocalesCode, frenchLocalesCode } from '../../i18n';
import FrLocale from './locales/custom.locale.fr-CA.js';

// register locales
LocaleManager.registerLocale(englishLocalesCode, { locale: EnLocale });
LocaleManager.registerLocale(frenchLocalesCode, { locale: FrLocale });
