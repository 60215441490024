import { Button, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LayoutPage from './layout/LayoutPage';

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      height: '37px',
      width: '30%',
      borderRadius: '2px',
      backgroundColor: '#434343',
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
      color: '#FFFFFF',
      margin: '1em',
      border: 'none',
    },
  })
);

const ErrorFallback = ({ error, resetError }: { error: Error; resetError: () => void }): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <LayoutPage>
      <Grid container className={classes.grid} spacing={1}>
        <Grid item md={12}>
          <Typography variant="h5">{t('messages.somethingWrong')}</Typography>
        </Grid>
        <Grid item md={12}>
          <pre>{error.message}</pre>
        </Grid>
        <Grid item md={12}>
          <Button color="primary" className={classes.button} onClick={resetError}>
            {t('returnToPreviousPage')}
          </Button>
        </Grid>
      </Grid>
    </LayoutPage>
  );
};

export default ErrorFallback;
