import { Box, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DataGrid } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Can } from '../../../abilities/AbilityContext';
import { CommonAbilityEnum } from '../../../abilities/CommonAbilityEnum';
import { ResourceEnum } from '../../../abilities/ResourceEnum';
import ConflictError from '../../../hooks/ConflictError';
import { useCreateRecipe, useEditRecipe, useGetRecipes, useSetStarredRecipe } from '../../hooks/recipes';
import { SetStarredRecipeRequest } from '../../hooks/recipes/useSetStarredRecipe';
import { Recipe, RecipeValues } from '../../model';
import useSettingsTabStyles from '../SettingsTabStyles';
import RecipeEditor from './RecipeEditor';
import { getColumns } from './RecipeGridColumns';

const RecipePage: React.FunctionComponent = (): React.ReactElement => {
  const classes = useSettingsTabStyles();
  const { t } = useTranslation('settings');

  const [getRecipes, { recipes = [] } = {}, isFetching] = useGetRecipes();
  const [createRecipe] = useCreateRecipe();
  const [editRecipe] = useEditRecipe();
  const [setStarredRecipe] = useSetStarredRecipe();

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [recipeToEdit, setRecipeToEdit] = useState<Recipe>({} as Recipe);

  const handleEdit = (variety: Recipe): void => {
    setRecipeToEdit(variety);
    setIsEditorOpen(true);
  };

  const handleClose = (): void => {
    setRecipeToEdit({} as Recipe);
    setIsEditorOpen(false);
  };

  const fetchRecipes = useCallback(() => {
    getRecipes(false);
  }, [getRecipes]);

  const handleSubmit = (values: RecipeValues) => {
    const submitCallBack = values.id ? editRecipe : createRecipe;
    submitCallBack(values)
      .then(() => {
        fetchRecipes();
        handleClose();
      })
      .catch((error: Error) => {
        if (!(error instanceof ConflictError)) {
          throw error;
        }
      });
  };

  const handleSetStarred = (values: SetStarredRecipeRequest) => setStarredRecipe(values).then(() => fetchRecipes());

  useEffect(() => {
    fetchRecipes();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount
  }, []);

  return (
    <>
      <Can I={CommonAbilityEnum.Create} a={ResourceEnum.Settings}>
        <Box className={classes.header}>
          <Button onClick={() => setIsEditorOpen(true)} startIcon={<AddCircleIcon />}>
            {t('recipes.addNewRecipe')}
          </Button>
        </Box>
      </Can>

      <Can I={CommonAbilityEnum.Update} a={ResourceEnum.Settings} passThrough>
        {(canUpdate: boolean) => (
          <DataGrid
            density="compact"
            rows={recipes}
            columns={getColumns(t, canUpdate, handleEdit, handleSetStarred)}
            loading={isFetching}
            autoHeight
            className={classes.grid}
          />
        )}
      </Can>

      {isEditorOpen && <RecipeEditor recipe={recipeToEdit} onClose={handleClose} onSubmit={handleSubmit} />}
    </>
  );
};

export default RecipePage;
