const LocationTypeEnum = {
  Door: 'door',
  Rail: 'rail',
  ProductionLine: 'productionLine',
  StackingLine: 'stackingLine',
  DryingLine: 'dryingLine',
  TransferLine: 'transfertLine'
};

export default LocationTypeEnum;
