import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { LumberVariety } from '../../model/LumberVariety';

const url = '/api/v1/lumber-varieties';

export type editVarietyResponse = { lumberVariety: LumberVariety };

const editLumberVariety = (lumberVarietyRequest: LumberVariety): Promise<AxiosResponse<editVarietyResponse>> => axios.put(url, lumberVarietyRequest);

const useEditLumberVariety = (): [Agent<editVarietyResponse>, editVarietyResponse | undefined, boolean, () => void] =>
  useAgent<editVarietyResponse>((lumberVariety: LumberVariety) => editLumberVariety(lumberVariety));

export default useEditLumberVariety;
