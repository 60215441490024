import { Button } from '@material-ui/core';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import React from 'react';
import formatNumberWithAccounting from '../../../../common/helpers/numberFormatHelpers';

/* eslint-disable react/display-name -- inline render cells without names */
export const getSelectedRailsColumns = (
  t: (key: string) => string,
  buttonClass: string,
  onRemoveSelectedRail: (productId: number) => void,
  disabled: boolean
): GridColDef[] => {
  const columns = [] as GridColDef[];

  if (!disabled)
    columns.push({
      field: '',
      headerName: '',
      width: 50,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Button className={buttonClass} onClick={() => onRemoveSelectedRail(row.locationId)}>
          <RemoveCircle fontSize="small" />
        </Button>
      ),
      sortable: false,
      editable: false,
    });

  columns.push(
    {
      field: 'factoryName',
      headerName: t('factory'),
      width: 125,
    },
    {
      field: 'locationName',
      headerName: t('location'),
      width: 135,
    },
    {
      field: 'productDescription',
      headerName: t('productDescription'),
      minWidth: 350,
    },
    {
      field: 'totalCapacity',
      headerName: t('totalCapacity'),
      width: 200,
      valueFormatter: ({ value }: GridValueFormatterParams) => `${formatNumberWithAccounting(Number(value), 0)}`,
    },
    {
      field: 'remainingCapacity',
      headerName: t('remainingCapacity'),
      width: 200,
      valueFormatter: ({ value }: GridValueFormatterParams) => `${formatNumberWithAccounting(Number(value), 0)}`,
    },
    {
      field: 'quantity',
      headerName: t('quantity'),
      width: 175,
      editable: !disabled,
      type: 'number',
      valueFormatter: ({ value }: GridValueFormatterParams) => `${formatNumberWithAccounting(Number(value), 0)}`,
    }
  );

  return columns;
};
/* eslint-enable react/display-name -- inline render cells without names */
