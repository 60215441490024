const airDry = {
  chartNote:
    'Note : Les chiffres présentés sont exprimées en perte d’humidité en % par jour. Il n’est pas possible d’atteindre moins que 20% d’humidité en séchage à l’air.',
};

const varieties = {
  addNewVariety: 'Nouvelle variété',
  deleteMessage: 'Voulez-vous vraiment supprimer cette variété de bois?',
  editVariety: 'Édition de variété',
  englishName: 'Nom anglais',
  printOrder: 'Ordre affichage',
  factory1Function: "Charte de séchage à l'air pour STG1", // 'Fonction STG1',
  factory2Function: "Charte de séchage à l'air pour STG2", //'Fonction STG2',
  fanDryMoistureLossPerHourOverThirtyPercent: "Perte d'humidité de séchage ventilateur > 30% / Heure (%)",
  fanDryTemperature: 'Température de séchage ventilateur',
  frenchName: 'Nom français',
  heatingDegreesPerHour: 'Réchauffement / Heure',
  initialMoisture: 'Humidité initiale (%)',
  lumberVarieties: 'Variétés de bois',
  minimalHoursUnderThirtyPercentMoisture: "Heures minimales < 30% d'Humidité",
  moistureBalancingHours: 'Heures de stabilisation',
  newVariety: 'Nouvelle variété',
  standardDryMoistureLossPerHourUnderThirtyPercent: "Perte d'humidité de séchage standard < 30% / Heure (%)",
  standardDryMoistureLossPerHourOverThirtyPercent: "Perte d'humidité de séchage standard > 30% / Heure (%)",
  standardDryTemperature: 'Température de séchage standard',
  targetMoisture: 'Humidité cible (%)',
  targetFanDryMoisture: "Objectif d'humidité séchage ventilateur (%)",
};

const recipeTypes = {
  Standard: 'Standard',
  Substrate: 'Substrat',
};

const recipes = {
  addNewRecipe: 'Nouvelle recette',
  archived: 'Archivée',
  colorInScheduler: 'Couleur dans le planificateur',
  deleteMessage: 'Voulez-vous vraiment supprimer cette recette?',
  editRecipe: 'Édition de recette',
  expectedBatchSize: 'Dimension cible de lot',
  expectedBatchSizeRequired: 'La valeur de la dimension cible de lot doit être un nombre positif',
  favorites: 'Favoris',
  lumberVariety: 'Variété de bois',
  maxMoistureDelta: 'Différence d’humidité max (%)',
  name: 'Nom',
  newRecipe: 'Nouvelle recette',
  others: 'Autres',
  '365Code': 'Code 365',
  recipes: 'Recettes',
  type: 'Type',
  recipeTypes,
};

const doors = {
  addNewDoor: 'Nouvelle porte',
  capacity: 'Capacité (MPMP)',
  comment: 'Commentaire',
  deleteMessage: 'Voulez-vous vraiment supprimer cette porte?',
  cannotDeleteDoor: 'La porte contient des transactions et ne peut pas être supprimées.',
  doors: 'Portes',
  editDoor: 'Édition de porte',
  factory: 'Usine',
  identifier: 'Identifiant',
  name: 'Nom',
  newDoor: 'Nouvelle porte',
  displayIndex: "Ordre d'affichage",
  active: "Actif",
};

const kilns = {
  addNewKiln: 'Nouveau séchoir',
  capacityPmpInThousands: 'Capacité PMP (Milliers)',
  deleteMessage: 'Voulez-vous vraiment supprimer ce séchoir?',
  displayIndex: "Ordre d'affichage",
  editKiln: 'Édition de séchoir',
  factory: 'Usine',
  kilns: 'Séchoirs',
  name: 'Nom',
  newKiln: 'Nouveau séchoir',
  remark: 'Commentaire',
  active: "Actif",
  cantdisabled: "[Il existe des tâches à venir pour ce séchoir. Impossible à désactiver.]"
};

const rails = {
  addNewRail: 'Nouveau rail',
  capacity: 'Capacité',
  comment: 'Commentaire',
  deleteMessage: 'Voulez-vous vraiment supprimer ce rail?',
  editRail: 'Édition de rail',
  factory: 'Usine',
  identifier: 'Identifiant',
  name: 'Nom',
  newRail: 'Nouveau rail',
  rails: 'Rails',
  displayIndex: "Ordre d'affichage",
};

const settings = {
  airDry,
  averageTemperature: 'Températures moyennes mensuelles (°C)',
  deleteSetting: 'Supprimer',
  equalOrHigherThan: 'La valeur doit être égale ou supérieure à {{number}}',
  equalOrLesserThan: 'La valeur doit être égale ou inférieure à {{number}}',
  isArchived: 'Est archivé?',
  isArchived_feminine: 'Est archivée?',
  lumberWeights: "Poids des essences (5 = Prioritaire, 1 = Le moins possible, 0 = N'est pas permis)",
  month: 'Mois',
  monthlyAverageTemperature: 'Températures moyennes mensuelles',
  required: 'Requis',
  settings: 'Configurations',
  weight: 'Poids',
  doors,
  kilns,
  recipes,
  rails,
  varieties,
  airDryChart: "Charte de séchage à l'air",
};

export default settings;
