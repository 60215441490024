import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../hooks/useAgent';
import { OptimizationSettingValues } from '../model';

const url = 'api/v1/optimize-planning';

const postOptimisationSettings = (opSettingsRequest: OptimizationSettingValues): Promise<AxiosResponse<void>> => axios.post(url, opSettingsRequest);

const usePostOptimizationSettings = (): [Agent<void>, void | undefined, boolean, () => void] =>
  useAgent<void>((opSettingValues: OptimizationSettingValues) => postOptimisationSettings(opSettingValues));

export default usePostOptimizationSettings;
