import { useFormikContext } from 'formik';
import _uniqBy from 'lodash.uniqby';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useGetAvailableDoors, useGetAvailableRails } from '../../../hooks';
import { EventValues, ILocation, InfeedValues, OutfeedProductValues } from '../../../model';
import { EventEditorContext } from '../EventEditorContext';

export type LocationTypeEnum = 'Rail' | 'Door' | 'Other';
export const LocationTypeEnum = {
  Rail: 'Rail' as LocationTypeEnum,
  Door: 'Door' as LocationTypeEnum,
  Other: 'Other' as LocationTypeEnum,
};

export const useAvailableLocationsHelpers = (
  locationType: LocationTypeEnum
): {
  handleSelectLocation: (availableLocation: ILocation) => void;
  refreshLocationsOutfeedProducts: (infeedProducts: InfeedValues[]) => void;
} => {
  const { setFieldValue, values } = useFormikContext<EventValues>();
  const { selectedLocationIdsMemory, recipeIds } = useContext(EventEditorContext);
  const [getAvailableDoors, { availableDoors = [] } = {}, , clearAvailableDoors] = useGetAvailableDoors();
  const [getAvailableRails, { availableRails = [] } = {}, , clearAvailableRails] = useGetAvailableRails();

  useEffect(() => {
    const clearAvailableLocation = locationType === LocationTypeEnum.Rail ? clearAvailableRails : clearAvailableDoors;
    if (!selectedLocationIdsMemory.length) clearAvailableLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- cannot include getter because useAgent is not memoized
  }, [selectedLocationIdsMemory, values.infeedProducts, locationType]);

  useEffect(() => {
    if (!selectedLocationIdsMemory.length) return; //
    const getAvailableLocation = locationType === LocationTypeEnum.Rail ? getAvailableRails : getAvailableDoors;
    getAvailableLocation(values.endDate, [], recipeIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- cannot include getter because useAgent is not memoized
  }, [selectedLocationIdsMemory, values.endDate, locationType, recipeIds]);

  const availableLocations = useMemo(() => {
    const locations = locationType === LocationTypeEnum.Rail ? availableRails : (availableDoors as ILocation[]);
    return locations.filter((x) => selectedLocationIdsMemory.includes(x.id));
  }, [selectedLocationIdsMemory, availableRails, availableDoors, locationType]);

  const generateOutfeed = useCallback(
    (id: number, quantity: number, location: ILocation, infeedProduct: InfeedValues): OutfeedProductValues => {
      const outfeedProduct = {
        id,
        factoryId: location.factoryId,
        factoryName: location.factoryName,
        productId: infeedProduct.nextProductId,
        recipeId: infeedProduct.recipeId,
        originalProductId: infeedProduct.productId,
        productDescription: infeedProduct.productDescription,
        productCharacteristic: infeedProduct.productCharacteristic,
        quantity,
        totalCapacity: 0,
        remainingCapacity: 0,
        locationId: location.id,
        locationName: location.name,
        locationType: locationType,
      } as OutfeedProductValues;

      return outfeedProduct;
    },
    [locationType]
  );

  const generateLocationOutfeedProducts = useCallback(
    (availableLocation: ILocation, initialOutfeedProducts: OutfeedProductValues[], infeedProducts: InfeedValues[]) => {
      let maxId = Math.max(...initialOutfeedProducts.map((x) => x.id), 0);
      const outfeedProducts = [...initialOutfeedProducts];
      let locationRemainingCapacity = availableLocation.capacity - availableLocation.quantity;

      _uniqBy(infeedProducts, 'productId').forEach((infeedProduct: InfeedValues) => {
        const productTotalQuantityToAssign = infeedProducts
          .filter((x) => x.productId === infeedProduct.productId)
          .reduce((acc: number, infeedProduct) => acc + infeedProduct.quantity, 0);

        // Somme les quantités déjà assignées
        const productTotalQuantityAssigned = outfeedProducts
          .filter((x) => x.productId === infeedProduct.nextProductId && x.originalProductId === infeedProduct.productId)
          .reduce((acc: number, outfeedProduct) => acc + outfeedProduct.quantity + (outfeedProduct.remainingCapacity < 0 ? outfeedProduct.remainingCapacity : 0), 0);

        const remainingProductQuantityToAssign = Math.max(productTotalQuantityToAssign - productTotalQuantityAssigned, 0);
        //const quantity = Math.min(remainingProductQuantityToAssign, locationRemainingCapacity);
        const quantity = remainingProductQuantityToAssign;
        locationRemainingCapacity -= quantity;

        outfeedProducts
          .filter((x) => x.remainingCapacity < 0 )
          .forEach((outfeedProducts: OutfeedProductValues) => {
            outfeedProducts.quantity += outfeedProducts.remainingCapacity;
            outfeedProducts.remainingCapacity = 0;
        });

        outfeedProducts.push(generateOutfeed(++maxId, quantity, availableLocation, infeedProduct));
      });

      return outfeedProducts;
    },
    [generateOutfeed]
  );

  const handleSelectLocation = (availableLocation: ILocation): void => {
    const newOutfeedProducts = generateLocationOutfeedProducts(availableLocation, values.outfeedProducts, values.infeedProducts);
    setFieldValue('outfeedProducts', newOutfeedProducts);
  };

  const refreshLocationsOutfeedProducts = useCallback(
    (infeedProducts: InfeedValues[]) => {
      let newOutfeedProducts = [] as OutfeedProductValues[];

      (availableLocations || []).forEach((availableLocation) => {
        const locationOutfeedProducts = generateLocationOutfeedProducts(availableLocation, newOutfeedProducts, infeedProducts);
        newOutfeedProducts = [...locationOutfeedProducts];
      });

      setFieldValue('outfeedProducts', newOutfeedProducts);
    },
    [availableLocations, setFieldValue, generateLocationOutfeedProducts]
  );

  return { handleSelectLocation, refreshLocationsOutfeedProducts };
};
