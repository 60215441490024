import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, PropTypes, Theme } from '@material-ui/core';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      paddingRight: 24,
      paddingBottom: 24,
    },
    confirmButton: {
      backgroundColor: '#434343',
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        backgroundColor: theme.palette.primary.dark,
      },
    },
    contentText: {
      color: theme.palette.text.secondary,
    },
    cancelButton: {
      color: theme.palette.text.secondary,
    },
  })
);

type Variant = 'text' | 'outlined' | 'contained';
export const VariantEnum = {
  Text: 'text' as Variant,
  Outlined: 'outlined' as Variant,
  Contained: 'contained' as Variant,
};

type ButtonProps = {
  color?: PropTypes.Color;
  disabled?: boolean;
  endIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
  variant?: Variant;
  autoFocus?: boolean;
};

export type ConfirmationDialogConfigs = {
  title?: string;
  messages?: string[];
  confirmationText?: string;
  cancellationText?: string;
  hideCancel?: boolean;
  cancellationButtonProps?: Partial<ButtonProps>;
  confirmationButtonProps?: Partial<ButtonProps>;
  actionCallback?: (value: boolean) => void;
};

type ConfirmationDialogProps = ConfirmationDialogConfigs & {
  open: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
};

const buildOptions = (props: Partial<ConfirmationDialogProps>, t: TFunction) => ({
  title: props.title || t('confirm'),
  messages: props.messages || [t('confirmDialog.defaultMessage')],
  confirmationText: props.confirmationText || t('confirm'),
  cancellationText: props.cancellationText || t('confirm'),
  cancellationButtonProps: { variant: VariantEnum.Outlined, autoFocus: true, ...(props.cancellationButtonProps || {}) },
  confirmationButtonProps: { variant: VariantEnum.Contained, ...(props.confirmationButtonProps || {}) },
});

const ConfirmationDialog = ({ open, onDismiss, onConfirm, hideCancel = false, ...otherProps }: ConfirmationDialogProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const options = buildOptions(otherProps, t);

  return (
    <Dialog open={open} onClose={onDismiss}>
      <DialogTitle>{options.title}</DialogTitle>
      <DialogContent>
        {(options.messages || []).map((message, index) => (
          <DialogContentText className={classes.contentText} key={index}>
            {message}
          </DialogContentText>
        ))}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {!hideCancel && (
          <Button {...options.cancellationButtonProps} className={classes.cancelButton} onClick={onDismiss}>
            {options.cancellationText}
          </Button>
        )}
        <Button {...options.confirmationButtonProps} className={classes.confirmButton} onClick={onConfirm}>
          {options.confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
