import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Recipe } from '../../model';

const url = '/api/v1/recipes';

export type editRecipeResponse = { recipe: Recipe };

const editRecipe = (recipeRequest: Recipe): Promise<AxiosResponse<editRecipeResponse>> => axios.put(url, recipeRequest);

const useEditRecipe = (): [Agent<editRecipeResponse>, editRecipeResponse | undefined, boolean, () => void] =>
  useAgent<editRecipeResponse>((recipe: Recipe) => editRecipe(recipe));

export default useEditRecipe;
