import { LumberVariety } from '../../planning/model';
import { Kiln, KilnValues } from '../model';

const emptyKiln = {
  name: null,
  factoryId: null,
  capacityPmp: null,
  remark: null,
  displayIndex: null,
  resourceLumberWeights: [],
};

export const mapKilnToValues = (kiln: Kiln, lumberVarieties: LumberVariety[]): KilnValues => {
  const values = { ...emptyKiln, ...kiln } as KilnValues;

  if (!kiln.resourceLumberWeights) {
    const newMappedLumberWeights = lumberVarieties.map((x) => ({ lumberVarietyId: x.id, weight: 5, printOrder: (x.printOrder !== undefined ? x.printOrder : 0) }));
    const sortedMappedLumberWeights = newMappedLumberWeights.sort((a, b) => a.printOrder - b.printOrder);

    return { ...values, resourceLumberWeights: sortedMappedLumberWeights };
  }
  else
  {
    for (const rlw of kiln.resourceLumberWeights) {
      const lookupValue = lumberVarieties.find((lv) => lv.id === rlw.lumberVarietyId);
      if (lookupValue !== null && lookupValue !== undefined) {
        rlw.printOrder = lookupValue.printOrder;
      }
      else {
        rlw.printOrder = 0;
      }
    }
    
    const sortedMappedLumberWeights = kiln.resourceLumberWeights.sort((a, b) => (a.printOrder && b.printOrder ? a.printOrder - b.printOrder : 0));

    return { ...values, resourceLumberWeights: sortedMappedLumberWeights };
  }

  return values;
};
