import React, { FunctionComponent, ReactElement } from 'react';
import LayoutPage from '../../layout/LayoutPage';
import TransfersList from './TransfersList';

const TransfersPage: FunctionComponent = (): ReactElement => (
  <LayoutPage>
    <TransfersList />
  </LayoutPage>
);

export default TransfersPage;
