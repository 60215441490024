import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Door } from '../../model';

const url = '/api/v1/doors';

export type createDoorResponse = { door: Door };

const createDoor = (doorRequest: Door): Promise<AxiosResponse<createDoorResponse>> => axios.post(url, doorRequest);

const useCreateDoor = (): [Agent<createDoorResponse>, createDoorResponse | undefined, boolean, () => void] =>
  useAgent<createDoorResponse>((door: Door) => createDoor(door));

export default useCreateDoor;
