import { DataField, DataFieldConfig, ResourceModel } from '@bryntum/schedulerpro/schedulerpro.umd.js';

export type Data = {
  date: string;
  quantity: number;
};

export type Dataset = {
  name: string;
  colorRgba: string;
  colorHex: string;
  data: Data[];
};

type InventoryResourceCustomFields = {
  type: string;
  datasets: Dataset[];
  factoryId: number;
  capacity: number;
  displayIndex: number;
};
export class InventoryResourceModel extends ResourceModel {
  // eslint-disable-next-line @typescript-eslint/ban-types -- type from bryntum
  static get fields(): object[] | DataField[] | Partial<DataFieldConfig>[] {
    // eslint-disable-next-line @typescript-eslint/ban-types -- this is the way
    return ['type', 'datasets', 'factoryId', 'capacity'] as unknown[] as object[];
  }
}

export type InventoryResource = InventoryResourceModel & InventoryResourceCustomFields;
