import * as yup from 'yup';
import { LumberVarietyValues } from '../model';

export const getLumberVarietySchema = (t: (key: string, values?: { number: number }) => string): yup.SchemaOf<LumberVarietyValues> =>
  yup.object().shape({
    id: yup.number().nullable(),
    frenchName: yup.string().nullable().required(t('required')),
    englishName: yup.string().nullable().required(t('required')),
    printOrder: yup
      .number()
      .min(1, t('equalOrHigherThan', { number: 1 }))
      .max(100, t('equalOrLesserThan', { number: 100 }))
      .nullable()
      .required(t('required')),
    initialMoisture: yup
      .number()
      .min(1, t('equalOrHigherThan', { number: 1 }))
      .max(200, t('equalOrLesserThan', { number: 200 }))
      .nullable()
      .required(t('required')),
    targetFanDryMoisturePercentage: yup
      .number()
      .min(30, t('equalOrHigherThan', { number: 30 }))
      .max(200, t('equalOrLesserThan', { number: 200 }))
      .nullable()
      .required(t('required')),
    targetMoisture: yup
      .number()
      .min(1, t('equalOrHigherThan', { number: 1 }))
      .max(200, t('equalOrLesserThan', { number: 200 }))
      .nullable()
      .required(t('required')),
    heatingDegreesPerHour: yup
      .number()
      .min(0, t('equalOrHigherThan', { number: 0 }))
      .max(50, t('equalOrLesserThan', { number: 50 }))
      .nullable()
      .required(t('required')),
    fanDryMoistureLossPerHourOverThirtyPercent: yup
      .number()
      .min(0.001, t('equalOrHigherThan', { number: 0.001 }))
      .max(5, t('equalOrLesserThan', { number: 5 }))
      .nullable()
      .required(t('required')),
    standardDryMoistureLossPerHourUnderThirtyPercent: yup
      .number()
      .min(0.001, t('equalOrHigherThan', { number: 0.001 }))
      .max(5, t('equalOrLesserThan', { number: 5 }))
      .nullable()
      .required(t('required')),
    standardDryMoistureLossPerHourOverThirtyPercent: yup
      .number()
      .min(0.001, t('equalOrHigherThan', { number: 0.001 }))
      .max(5, t('equalOrLesserThan', { number: 5 }))
      .nullable()
      .required(t('required')),
    fanDryTemperature: yup
      .number()
      .min(0, t('equalOrHigherThan', { number: 0 }))
      .max(100, t('equalOrLesserThan', { number: 100 }))
      .nullable()
      .required(t('required')),
    standardDryTemperature: yup
      .number()
      .min(0, t('equalOrHigherThan', { number: 0 }))
      .max(100, t('equalOrLesserThan', { number: 100 }))
      .nullable()
      .required(t('required')),
    minimalHoursUnderThirtyPercentMoisture: yup
      .number()
      .min(0, t('equalOrHigherThan', { number: 0 }))
      .max(1000, t('equalOrLesserThan', { number: 1000 }))
      .nullable()
      .required(t('required')),
    moistureBalancingHours: yup
      .number()
      .min(0, t('equalOrHigherThan', { number: 0 }))
      .max(1000, t('equalOrLesserThan', { number: 1000 }))
      .nullable()
      .required(t('required')),
    factory1FunctionId: yup.number().nullable().required(t('required')),
    factory2FunctionId: yup.number().nullable().required(t('required')),
  });
