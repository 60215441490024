import annotationPlugin from 'chartjs-plugin-annotation';
import React from 'react';
import { Bar, Chart } from 'react-chartjs-2';
import { formatDate } from '../../../common/helpers/date';
import { Data, Dataset, InventoryResource } from '../../model/InventoryResource';
import getChartOptions from './chartOptions';

Chart.register([annotationPlugin]);

const InventoryChart = ({ record, dates, totalSize }: { record: InventoryResource; dates: Date[]; totalSize: number }): JSX.Element => {
  const mapRecordData = (dates: Date[], resourceData: Data[]): number[] => {
    return dates.map((date) => resourceData.find((x) => x.date === formatDate(date))?.quantity || 0);
  };
  const mapRawData = (dates: Date[], resourceData: Data[]): (Data | undefined)[] => {
    return dates.map((date) => resourceData.find((x) => x.date === formatDate(date)));
  };

  const data = {
    labels: dates.map((date) => formatDate(date)),
    datasets: (record.datasets || []).map((dataset: Dataset) => {
      return {
        label: dataset.name,
        data: mapRecordData(dates, dataset.data),
        rawData: mapRawData(dates, dataset.data),
        capacity: record.capacity,
        hexColor: dataset.colorHex,
        backgroundColor: dataset.colorRgba,
        fill: true,
        stepped: true,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        displayIndex: record.displayIndex,
      };
    }),
  };

  return <Bar data={data} options={getChartOptions(record.capacity)} height={28} width={totalSize} style={{ marginLeft: 0, width: '100%', height: '28px' }} />;
};

export default InventoryChart;
