import { LogLevel } from '@azure/msal-browser';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export function getMsalConfig() {

  if (!process.env.REACT_APP_AUTH_CLIENT_ID) {
    throw "REACT_APP_AUTH_CLIENT_ID not set";
  }

  if (!process.env.REACT_APP_AUTH_AUTHORITY) {
    throw "REACT_APP_AUTH_AUTHORITY not set";
  }

  if (!process.env.REACT_APP_AUTH_API_REDIRECT_URI) {
    throw "REACT_APP_AUTH_API_REDIRECT_URI not set";
  }

  if (!process.env.REACT_APP_AUTH_API_SCOPE) {
    throw "REACT_APP_AUTH_API_SCOPE not set";
  }

  return {
    auth: {
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID || '',
      authority: process.env.REACT_APP_AUTH_AUTHORITY || '',
      redirectUri: process.env.REACT_APP_AUTH_API_REDIRECT_URI || '',
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: number, message: string, containsPii: boolean): void => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ['User.Read'],
};

export const apiRequest = {
  scopes: [process.env.REACT_APP_AUTH_API_SCOPE || ''],
  redirectUri: process.env.REACT_APP_AUTH_API_REDIRECT_URI,
};
