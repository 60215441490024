import { Box, Chip, FormControl, FormHelperText, IconButton, Input, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import { useFormikContext } from 'formik';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import React, { ReactElement, RefObject, useCallback } from 'react';
import { Option } from '../../common/helpers/options';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
  select: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  box: {
    display: 'inline-flex',
  },
}));

type MultiSelectFieldProps = {
  disabled?: boolean;
  classes?: ClassNameMap<string>;
  label?: string;
  inputRef?: RefObject<ReactElement>;
  name: string;
  options?: Option[];
  id?: string;
  missingOptionFormatter?: (value: string | number) => string;
};

const MultiSelectField = ({
  classes,
  label,
  name,
  options = [],
  id = 'multiple-chip',
  missingOptionFormatter = (value: string | number) => String(value),
  ...props
}: MultiSelectFieldProps): ReactElement => {
  const styles = useStyles();
  const { errors, handleChange, touched, values, setFieldValue } = useFormikContext();
  const isFieldTouched = _get(touched, name);
  const error = _get(errors, name);

  const getFilteredOptions = useCallback(() => {
      const selectedOptions: (string | number)[] = _get(values, name, []); 
      return options.filter((t: Option) => !selectedOptions.includes(t.value));
    
  }, [values, name, options]);

  const handleDelete = useCallback(
    (value) => {
          const selectedValues: (string | number)[] = _get(values, name, []);
          const index = selectedValues.indexOf(value);
      if (index !== -1) {
        selectedValues.splice(index, 1);
      }
      setFieldValue(name, selectedValues, true);
    },
    [values, name, setFieldValue]
  );

  const handleDeleteAll = useCallback(() => {
    setFieldValue(name, [], true);
  }, [name, setFieldValue]);

  return (
    <FormControl className={styles.formControl}>
      <Box className={styles.box}>
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
          labelId={`${id}-label`}
          {...props}
          multiple
          className={styles.select}
          classes={classes}
          name={name}
          onChange={handleChange}
          value={_get(values, name)}
          error={isFieldTouched && Boolean(error)}
          input={<Input id={`${id}-select`} />}
          renderValue={(selected: unknown) => (
            <div className={styles.chips}>
              {(selected as (string | number)[]).map((value: string | number) => {
                const option = options.find((x: Option) => x.value === value);
                return (
                  <Chip
                    key={value}
                    label={(option && option.label) || missingOptionFormatter(value)}
                    className={styles.chip}
                    deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                    onDelete={() => handleDelete(value)}
                  />
                );
              })}
            </div>
          )}>
          {getFilteredOptions().map(({ key, value, label }: Option) => (
            <MenuItem key={key} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {_get(values, name, []).length > 0 && (
          <IconButton size="small" aria-label="delete" onClick={handleDeleteAll}>
            <ClearIcon />
          </IconButton>
        )}
      </Box>
      {isFieldTouched && error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

MultiSelectField.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    })
  ),
};

MultiSelectField.defaultProps = {
  classes: {},
  label: null,
  name: null,
  options: [],
};

export default MultiSelectField;
