import { Dispatch, useState } from 'react';

const useModal = (initialState = false): [isModalOpen: boolean, openModal: () => void, closeModal: () => void, setIsModalOpen: Dispatch<boolean>] => {
  const [isModalOpen, setIsModalOpen] = useState(initialState);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return [isModalOpen, openModal, closeModal, setIsModalOpen];
};

export default useModal;
