import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Door } from '../../model';

const url = '/api/v1/doors';

export type updateDoorResponse = { door: Door };

const updateDoor = (updateRequest: Door): Promise<AxiosResponse<updateDoorResponse>> => axios.put(url, updateRequest);

const useUpdateDoor = (): [Agent<updateDoorResponse>, updateDoorResponse | undefined, boolean, () => void] =>
  useAgent<updateDoorResponse>((door: Door) => updateDoor(door));

export default useUpdateDoor;
