import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { LumberVariety } from '../../model/LumberVariety';

const url = '/api/v1/lumber-varieties';

export type createVarietyResponse = { lumberVariety: LumberVariety };

const createLumberVariety = (lumberVarietyRequest: LumberVariety): Promise<AxiosResponse<createVarietyResponse>> => axios.post(url, lumberVarietyRequest);

const useCreateLumberVariety = (): [Agent<createVarietyResponse>, createVarietyResponse | undefined, boolean, () => void] =>
  useAgent<createVarietyResponse>((lumberVariety: LumberVariety) => createLumberVariety(lumberVariety));

export default useCreateLumberVariety;
