import * as yup from 'yup';
import { DoorValues } from '../model';

export const getDoorSchema = (t: (key: string) => string): yup.SchemaOf<DoorValues> =>
  yup.object().shape({
    id: yup.number().nullable(),
    name: yup.string().nullable().required(t('required')),
    active: yup.boolean().required(t('required')),
    factoryId: yup.number().nullable().required(t('required')),
    identifier: yup.string().nullable().required(t('required')),
    capacity: yup.number().nullable().required(t('required')),
    comment: yup.string().nullable(),
    doorLumberWeights: yup.array().of(
      yup.object().shape({
        id: yup.number().nullable(),
        lumberVarietyId: yup.number().required(t('required')),
        doorId: yup.number().nullable(),
        weight: yup.number().required(t('required')),
        printOrder: yup.number().nullable(),
      })
    ),
    displayId: yup.number().default(0)
  });