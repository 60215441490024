import { Box, Divider, SvgIconTypeMap } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import LogoB from '../logo/LogoB';
import LogoBoaFranc from '../logo/LogoBoaFranc';
import UserHeader from './user/UserHeader';

export const drawerWidth = {
  open: 240,
  close: 56,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    togglerOpen: { position: 'absolute', top: '-15px', marginLeft: '190px' },
    togglerClose: { position: 'absolute', top: '-15px', marginLeft: '70px' },
    drawer: {
      width: drawerWidth.open,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth.open,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7) + 1,
      },
    },
    drawerHeader: {
      top: 0,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      height: theme.spacing(8),
      justifyContent: 'center',
    },
    drawerHeaderOpen: {
      height: theme.spacing(14),
    },
    text: {
      fontsize: '10px',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    selectedText: {
      fontsize: '10px',
      textDecoration: 'none',
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    button: {
      borderColor: 'black',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    selectedButton: {
      borderColor: 'black',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    logoBox: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-end',
      alignContent: 'flex-end',
      display: 'flex',
      paddingBottom: '25px',
      filter: 'invert(1);',
    },
  })
);

type menuOption = {
  i18nKey: string;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, never>, 'svg'>>;
  label: string;
  path: string;
};

const Navigation = ({ options, isOpen, onToggleNavigation }: { options: menuOption[]; isOpen: boolean; onToggleNavigation: () => void }): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const { location, push } = useHistory();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}>
        <div
          className={clsx(classes.drawerHeader, {
            [classes.drawerHeaderOpen]: isOpen,
          })}>
          <UserHeader isOpen={isOpen} />
          {isOpen ? (
            <IconButton className={classes.togglerOpen} onClick={onToggleNavigation}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          ) : (
            <IconButton className={classes.togglerClose} onClick={onToggleNavigation}>
              <ChevronRightIcon /> : <ChevronRightIcon />
            </IconButton>
          )}
        </div>

        <List>
          <Divider />
          {options.map((option: menuOption) => {
            const isCurrentPage = location.pathname.includes(option.path);
            return (
              <ListItem button key={option.i18nKey} onClick={() => push(option.path)} className={isCurrentPage ? classes.selectedButton : classes.button}>
                <ListItemIcon className={isCurrentPage ? classes.selectedText : classes.text}>
                  <option.icon />
                </ListItemIcon>
                <ListItemText className={isCurrentPage ? classes.selectedText : classes.text} primary={option.label} />
              </ListItem>
            );
          })}
        </List>

        <Box className={classes.logoBox}>{isOpen ? <LogoBoaFranc width={190} height={40} /> : <LogoB width={20} height={40} />}</Box>
      </Drawer>
    </div>
  );
};

export default Navigation;
