import { InputBase } from '@material-ui/core';
import { gridClasses, GridRenderEditCellParams, SUBMIT_FILTER_STROKE_TIME } from '@mui/x-data-grid';
import React, { FormEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react';

const CustomNumberInputValue = (params: GridRenderEditCellParams): ReactElement => {
  const { id, value, api, field, debounceMs = SUBMIT_FILTER_STROKE_TIME, error, hasFocus } = params;
  const [valueState, setValueState] = useState(value);
  const inputRef = useRef<HTMLInputElement>();

  const handleChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      api.setEditCellValue({ id, field, value: newValue, debounceMs }, event);
    },
    [api, debounceMs, field, id]
  );

  useEffect(() => {
    setValueState(value);
  }, [value]);

  useEffect(() => {
    if (hasFocus) {
      inputRef?.current?.focus();
    }
  }, [hasFocus]);

  return (
    <InputBase
      ref={inputRef}
      className={gridClasses.editInputCell}
      error={error}
      type="number"
      inputProps={{
        min: 0.0,
        max: 9.999,
        step: 0.01,
        maxLength: 5,
        'aria-invalid': 'false',
        onInput: (event: FormEvent<HTMLInputElement>) => {
          const inputTarget = event.target as HTMLInputElement;
          inputTarget.value = inputTarget.value.slice(0, inputTarget.maxLength);
        },
      }}
      fullWidth
      onChange={handleChange}
      value={(valueState as number) ?? '0.000'}
    />
  );
};

export default CustomNumberInputValue;
