const validation = {
  endDateAfterStartDate: 'End date must be after start date',
  equalOrHigherThan: 'Value must be equal or higher than {{number}}',
  equalOrLesserThan: 'Value must be equal or lesser than {{number}}',
  invalidDuration: 'Duration is either too long or too short for this lumber type',
  invalidLinks: 'The modifications are breaking the links with the other tasks',
  required: 'Required',
};

const messages = {
  cannotModifyLockedTask: 'Locked tasks cannot be modified.',
  concurrencyModification: 'The element you are trying to edit has been modified by an other user.',
  confirmDelete: 'Are you sure you want to delete this task?',
  lockTaskDisabled: 'You need to confirm or cancel your current changes before locking this task.',
  optimizationStarted: 'Optimization has successfully started',
  schedulerModified: 'Another user has modified the scheduler. Please try again.',
  longerDryingDurationRequired: 'Longer drying duration is required to estimate drying steps.',
  inventoryImportError: 'The connection to the external data source failed, the update was not completed.',
  qtyMaxReachedError: 'Transfer\'s maximum quantity reached.'
};

const optimization = {
  airDryPenalty: 'Air Drying Penalty',
  airDryPenaltyTooltip: 'Penalty for Air Drying',
  average: 'Average \u00A0(%)',
  averageDoorUsage: 'Humidity Controlled Warehouse Usage',
  averageKilnUsage: 'Kiln Usage',
  averageRailUsage: 'Rail Usage',
  calculationCompleted: 'Calculation Completed',
  calculationCompletedToast: 'Optimization calculation completed, optimal solution',
  confirmCancelOptimizationMsg: 'Are you sure you want to cancel the optimization of the schedule?',
  confirmChangeWarning: 'Warning',
  confirmChangeMsg: 'Are you sure you want to apply the changes to the schedule? Those changes are irreversible.',
  driedInventoryPenalty: 'Dry Inventory Penalty',
  driedInventoryPenaltyTooltip: 'Penalty for Keeping Dry Wood in Inventory',
  driedPenalty: 'Buying Dry Penalty',
  driedPenaltyTooltip: 'Penalty for Buying Dry Wood',
  dryingTimeByRecipe: 'Average Drying Time Per Recipe',
  error: 'Error',
  errorResponse: 'No solution that is satisfying the inputs is found. Please change the inputs and launch again the optimization',
  fanDryPenalty: 'Fan Drying Penalty',
  fanDryPenaltyTooltip: 'Penalty for Fan Drying',
  feasible: 'Feasible',
  feasibleResponse: 'Calculation Timed Out',
  greenInventoryPenalty: 'Green Inventory Penalty',
  greenInventoryPenaltyTooltip: 'Penalty for Keeping Green Wood in Inventory',
  greenPenalty: 'Buying Green Penalty',
  greenPenaltyTooltip: 'Penalty for Buying Green Wood',
  horizonLength: 'Horizon Length',
  horizonLengthTooltip: 'Quantity of Time Considered by the Optimization',
  kilnToProductionDelay: 'Time Interval Between Kiln Drying and Production Line',
  lowInventoryPenalty: 'Low Inventory Penalty',
  lowInventoryPenaltyTooltip: 'Penalty for Not Keeping Enough Inventory',
  maximum: 'Maximum \u00A0(%)',
  minimumKilnUsageHard: 'Hardwood Drying Minimal Capacity',
  minimumKilnUsageHardTooltip: 'Minimal Kiln Capacity Threshold in Percentage for Hardwood',
  minimumKilnUsageSoft: 'Softwood Drying Minimal Capacity',
  minimumKilnUsageSoftTooltip: 'Minimal Kiln Capacity Threshold in Percentage for Softwood',
  optionFanCount: 'Fan Dry Options Count ',
  optionFanCountTooltip: 'Quantity of Fan Dry Duration Options Included in the Optimization',
  productionlineUsage: 'Production Line Usage',
  recipeDistributionScore: 'Recipe Distribution Score',
  resultTitle: 'Optimization Result',
  score: 'Score \u00A0(%)',
  sixWeeks: '6 Weeks',
  solutionQuality: 'Quality of the Solution (%)',
  startDate: 'Start Date',
  startDateTooltip: 'Optimization Start Date',
  standardDryPenalty: 'Standard Drying Penalty',
  standardDryPenaltyTooltip: 'Penalty for Standard Drying',
  solutionStatus: 'Status',
  stepDays: 'Time Step',
  stepDaysTooltip: 'Smallest Unit of Time Considered by the Optimization',
  terminate: 'Terminate',
  terminateCalculation: 'If you confirm, the calculation will be stopped and it will not be possible to resume it',
  threeMonths: '3 Months',
  title: 'Optimization Parameters',
  timeLimit: 'Calculation Time Limit (Minutes)',
  timeLimitTooltip: 'Maximum operation time in minutes for the optimization process',
  twelveMonths: '12 Months',
  unfeasible: 'Unfeasible',
  unfeasibleResponse:
    'No solution that is satisfying the selected input parameters can be found. If you want to continue the optimization, review the locked tasks and the input parameters',
  weeklyPenalty: 'Delay Tolerance Penalty',
  weeklyPenaltyTooltip: 'Delay Penalty',
};

const planning = {
  messages,
  validation,
  optimization,
  alignTasks: "Align Resource's Tasks",
  allFactories: 'All Factories',
  availableDoors: 'Available Doors',
  availableProducts: 'Available Products',
  availableQuantity: 'Available Quantity',
  availableRails: 'Available Rails',
  balancing: 'Balancing',
  calculationInProgress: 'Calculation in Progress',
  cancel: 'Cancel',
  capacity: 'Capacity',
  capacityInFMB: 'Capacity (FMB)',
  clearAll: 'Clear All',
  comment: 'Comment',
  date: 'Date',
  days: 'Days',
  deleteTask: 'Delete',
  detailedComment: 'Detailed Comment',
  door: 'Door',
  doors: 'Doors',
  dry: 'Dry',
  dryingDuration: 'Drying duration (Days)',
  dryingStart: 'Drying start',
  dryingStop: 'Drying stop',
  dryingTask: 'Drying Task',
  dryStock: 'Dry Stock',
  durationInDays: 'Duration (Days)',
  editTask: 'Edit Task',
  efficiency: 'Efficiency',
  endDate: 'End Date',
  eventSubtype: 'Task Subtype',
  factory: 'Factory',
  fanDry: 'Fan Dry',
  filterBy: 'Filter By',
  fmb: 'FMB',
  from: 'From',
  green: 'Green',
  hideLinkedTask: 'Hide Associated Task',
  home: 'Home',
  hours: 'Hours',
  indoorLots: 'Indoor Lots',
  information: 'Information',
  inLocationSince: 'In Location Since',
  inStorageSince: 'In storage since',
  invalidModification: 'Invalid Modification',
  inventory: 'Inventory',
  kiln_plural: 'Kilns',
  kiln: 'Kiln',
  linked: 'Linked',
  location: 'Location',
  locked: 'Locked',
  lockTask: 'Lock Task',
  lotId: 'Lot Id',
  lotQuantity: 'Lot Quantity',
  lumberType: 'Lumber type',
  maintenance: 'Maintenance',
  minimumValue: 'Minimum value',
  modify: 'Modify',
  moisture: 'Moisture (%)',
  newTask: 'New Task',
  numberOfDays: '{{count}} days',
  onRailSince: 'On Rail Since',
  optimize: 'Optimize',
  orderLine_plural: 'Order lines',
  orderLine: 'Order line',
  overlap: 'Overlap',
  planning: 'Planning',
  processDuration: 'Process Duration (Hours)',
  productCharacteristic: 'Characteristic',
  productDescription: 'Product Description',
  productionLine: 'Production Line',
  productionLineTask: 'Production Line Task',
  productionStartDate: 'Production Start Date',
  products: 'Products',
  putInTShedDate: 'Put in T-Shed Date',
  quantity: 'Quantity',
  quantityInFMB: 'Quantity (FMB)',
  rails: 'Rails',
  recommended: 'Recommended',
  recipe: 'Recipe',
  redo: 'Redo',
  redoLastAction: 'Redo Last Action',
  ref: 'Ref',
  refresh: 'Refresh',
  regularDry: 'Regular Dry',
  remainingCapacity: 'Remaining Capacity',
  remainingQuantity: 'Remaining Quantity',
  resource: 'Resource',
  selectedDoors: 'Selected Doors',
  selectedProducts: 'Selected Products',
  selectedRails: 'Selected Rails',
  similarProducts: 'Similar Products',
  site: 'Site',
  showLinkedTask: 'Show Associated Task',
  sourceNumber: 'Source #',
  stacking: 'Stacking',
  stackingLine: 'Stacking Line',
  stackingTask: 'Stacking Line Task',
  transferLine: 'Transfer',
  startDate: 'Start Date',
  status: 'Status',
  standard: 'Standard',
  stopCalculation: 'Stop Calculation',
  substrate: 'Substrate',
  task: 'Task',
  taskNumber: 'Task Number',
  to: 'To',
  today: 'Today',
  tooltipNote: 'Tooltip Note',
  totalCapacity: 'Total Capacity',
  undo: 'Undo',
  undoLastAction: 'Undo Last Action',
  unlinked: 'Unlinked',
  unlocked: 'Unlocked',
  unlockTask: 'Unlock Task',
  usage: 'Usage (%)',
  vendor: 'Vendor',
  weeks: 'Weeks',
  woodMoisture_initial: 'Initial Wood Moisture (%)',
  woodMoisture_target: 'Target Wood Moisture (%)',
  woodMoisture: 'Wood Moisture',
  woodQuantity: 'Wood quantity (FMB)',
  woodStatus: 'Wood Status',
  workOrder: 'WO #',
  workOrderNumber: 'WO Number',
  maxQuantity: 'Maximum Quantity (FMB)'
};

export default planning;
