import * as yup from 'yup';
import { KilnValues } from '../model';

export const getKilnSchema = (t: (key: string, values?: { number: number }) => string): yup.SchemaOf<KilnValues> =>
  yup.object().shape({
    id: yup.number().nullable(),
    name: yup.string().nullable().required(t('required')),
    active: yup.boolean().required(t('required')),
    factoryId: yup.number().nullable().required(t('required')),
    remark: yup.string().nullable().required(t('required')),
    capacityPmp: yup
      .number()
      .min(0.001, t('equalOrHigherThan', { number: 0.001 }))
      .max(250, t('equalOrLesserThan', { number: 250 }))
      .nullable()
      .required(t('required')),
    displayIndex: yup.number().nullable().required(t('required')),
    resourceLumberWeights: yup.array().of(
      yup.object().shape({
        id: yup.number().nullable(),
        lumberVarietyId: yup.number().required(t('required')),
        resourceId: yup.number().nullable(),
        weight: yup.number().required(t('required')),
        printOrder: yup.number().nullable(),
      })
    ),
  });
