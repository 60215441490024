import dayjs from 'dayjs';
import * as yup from 'yup';
import { EventTypeEnum } from '../enums';

export const getEventSchema = (t: (key: string, values?: { number: number }) => string): yup.AnySchema =>
  yup.object().shape({
    eventType: yup.string().required(t('validation.required')),
    eventSubtype: yup.string().required(t('validation.required')),
    isLocked: yup.boolean().nullable(),
    manualLock: yup.boolean().nullable(),
    startDate: yup.string().nullable().required(t('validation.required')),
    endDate: yup
      .string()
      .test('end_time_after', t('validation.endDateAfterStartDate'), function isAfter(endDate?: string) {
        const { startDate } = this.parent;
        return dayjs(endDate).isAfter(dayjs(startDate));
      })
      .nullable()
      .required(t('validation.required')),
    resourceId: yup.number().required(t('validation.required')),

    initialWoodMoisture: yup
      .number()
      .nullable()
      .min(0, t('validation.equalOrHigherThan', { number: 0 }))
      .max(200, t('validation.equalOrLesserThan', { number: 200 }))
      .required(t('validation.required')),
    targetWoodMoisture: yup
      .number()
      .nullable()
      .min(0, t('validation.equalOrHigherThan', { number: 0 }))
      .max(100, t('validation.equalOrLesserThan', { number: 100 }))
      .required(t('validation.required')),
    woodQuantity: yup.number().when('eventType', {
      is: (value: string) => value != EventTypeEnum.Maintenance,
      then: (schema) => schema.min(1, t('validation.equalOrHigherThan', { number: 1 })).required(t('validation.required')),
    }),
    duration: yup
      .number()
      .nullable()
      .min(0, t('validation.equalOrHigherThan', { number: 0 }))
      .required(t('validation.required')),
    tooltipNote: yup.string().nullable(),
    detailedComment: yup.string().nullable(),
    workOrder: yup.number().nullable(),
  });
