import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ClassNameMap } from '@material-ui/styles';
import _isEqual from 'lodash.isequal';
import React, { ReactElement, RefObject } from 'react';

export type Option = {
  key: number | string;
  label: string;
  value: number | string | Record<string, unknown>;
};

type SelectInputProps = {
  classes: ClassNameMap<string>;
  disabled?: boolean;
  clearable?: boolean;
  error: boolean;
  helperText: string;
  label: string;
  inputRef?: RefObject<ReactElement>;
  name: string;
  options?: Option[];
  onChange: ({ target: { name, value } }: { target: { name: string; value: string | number | Record<string, unknown> | null } }) => void;
  value: string | number;
  missingOptionFormatter?: (value: string | number) => string;
  groupBy?: (option: Option) => string;
};

const SelectInput = ({
  classes = {},
  error = false,
  helperText,
  inputRef,
  label = '',
  name = '',
  onChange = () => void 0,
  options = [],
  value,
  disabled = false,
  clearable = true,
  missingOptionFormatter = (value: string | number) => String(value),
  groupBy = undefined,
  ...props
}: SelectInputProps): ReactElement => (
  <Autocomplete
    {...props}
    disabled={disabled}
    classes={classes}
    clearOnBlur
    key={name}
    disableClearable={!clearable}
    value={options.find((o) => _isEqual(o.value, value)) || (value && { label: missingOptionFormatter(value), key: value, value }) || null}
    getOptionLabel={(option) => option?.label || ''}
    getOptionSelected={(option, optionValue) => _isEqual(option.value, optionValue.value)}
    onChange={(event, newValue) =>
      onChange({
        target: {
          name: name,
          value: newValue?.value || null,
        },
      })
    }
    options={options}
    renderInput={(params) => <TextField {...params} label={label} error={error} helperText={helperText} inputRef={inputRef} />}
    renderOption={(option) => <span>{option.label}</span>}
    groupBy={groupBy}
    fullWidth
  />
);

export default SelectInput;
