import { TFunction } from 'i18next';

export type Option = {
  key: string | number;
  label: string;
  value: string | number;
};

export const sortOptionByLabel = (a: Option, b: Option): number => a.label.localeCompare(b.label);

export const toOption =
  (translator?: TFunction, prefix?: string) =>
  (str: string): Option => {
    if (translator) {
      return { key: str, label: prefix ? translator(`${prefix}.${str}`) : translator(str), value: str };
    } else {
      return { key: str, label: str, value: str };
    }
  };
