import React, { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SelectInput from '../../../layout/fields/SelectInput';
import { getRecipeGroups, mapRecipesToOptions, RecipeOption } from '../../../settings/helpers/recipesHelpers';
import { Recipe } from '../../../settings/model';
import { EventEditorContext } from './EventEditorContext';

type RecipeSingleSelectProps = {
  recipes: Recipe[];
  disabled: boolean;
};

const RecipeSingleSelect = ({ recipes = [], disabled }: RecipeSingleSelectProps): ReactElement => {
  const recipeOptions = useMemo(() => mapRecipesToOptions(recipes), [recipes]);
  const { recipeIds, setRecipeIds } = useContext(EventEditorContext);
  const { t } = useTranslation('planning');
  const selectedRecipe = useMemo(() => (recipeIds.length ? recipeIds[0] : 0), [recipeIds]);
  const handleRecipeChange = ({ target: { value } }: { target: { value: unknown } }) => {
    setRecipeIds(value ? [value as number] : []);
  };

  return (
    <SelectInput
      label={t('recipe')}
      name="recipeId"
      options={recipeOptions}
      value={selectedRecipe}
      helperText=""
      classes={{}}
      error={false}
      groupBy={(option) => getRecipeGroups(option as RecipeOption, t)}
      disabled={disabled}
      onChange={handleRecipeChange}
    />
  );
};

export default RecipeSingleSelect;
