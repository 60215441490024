import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortOptionByLabel } from '../../../common/helpers/options';
import { NumberField, SelectField, TextField, CheckboxField } from '../../../layout/fields';
import useGetFactories from '../../hooks/factories/useGetFactories';
import { Factory, KilnValues } from '../../model';
import useGetKilnCanBeSetInactive from '../../hooks/kilns/useGetKilnCanBeSetInactive';

const KilnEditorFormFields = (): ReactElement => {
  const { t } = useTranslation('settings');
  const { values, setFieldValue } = useFormikContext<KilnValues>();
  const [getFactories, { factories = [] } = {}] = useGetFactories();
  const factoryOptions = useMemo(() => factories.map((x: Factory) => ({ label: x.name, value: x.id, key: x.id })).sort(sortOptionByLabel), [factories]);
  const [getKilnCanBeSetInactive, inactiveAvailable] = useGetKilnCanBeSetInactive();
  const [KilnCanBeSetInactive, setKilnCanBeSetInactive] = useState(false);

  type canBeSetInactiveResponseType = {
    inactiveAvailable: boolean;
  }

  useEffect(() => {
    getFactories();
    if (values.id) {    // Si un id s�choir est pass� en param�tre, il s'agit d'une modification, donc on fait la validation.
      getKilnCanBeSetInactive(values.id);
    }
    else {    // Si c'est pour un nouveau s�choir, on peut activer ou d�sactiver d�s la cr�ation
      setKilnCanBeSetInactive(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  useEffect(() => {
    if (inactiveAvailable !== undefined) {    // si on a re�u un r�sultat qui n'est pas undefined dans la variable inactiveAvailable, on peut r�cup�rer la valeur.
      const tmp: canBeSetInactiveResponseType | undefined = inactiveAvailable;
      setKilnCanBeSetInactive(tmp?.inactiveAvailable as boolean);
    }
  }, [inactiveAvailable]);

  return (
    <>
      <Grid item md={12} sm={12}>
        <TextField label={t('kilns.name')} name="name" />
      </Grid>
      <Grid item md={6} sm={6}>
        <CheckboxField label={t('kilns.active') + "    " + ((values.id && !KilnCanBeSetInactive) ? t('kilns.cantdisabled') : "")} name="active" disabled={!KilnCanBeSetInactive} />
      </Grid>
      <Grid item md={12} sm={12}>
        <SelectField label={t('kilns.factory')} name="factoryId" options={factoryOptions} />
      </Grid>
      <Grid item md={12} sm={12}>
        <NumberField label={t('kilns.capacityPmpInThousands')} name="capacityPmp" decimalScale={3} min={0} />
      </Grid>
      <Grid item md={12} sm={12}>
        <TextField label={t('kilns.remark')} name="remark" />
      </Grid>
      <Grid item md={12} sm={12}>
        <NumberField label={t('kilns.displayIndex')} name="displayIndex" decimalScale={0} min={0} />
      </Grid>
    </>
  );
};

export default KilnEditorFormFields;
