import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../hooks/useAgent';

const url = 'api/v1/planning-optimizations';

const cancelOptimization = (planningOptimizationId: number): Promise<AxiosResponse<void>> => axios.post(`${url}/${planningOptimizationId}/cancel`);

const useCancelOptimization = (): [Agent<void>, void | undefined, boolean, () => void] =>
  useAgent<void>((planningOptimizationId: number) => cancelOptimization(planningOptimizationId));

export default useCancelOptimization;
