import React from 'react';
import Toast from './Toast';

type ErrorToastProps = {
  message?: string | null;
  onClose: () => void;
}

const ErrorToast = ({ message, onClose }: ErrorToastProps): JSX.Element => <Toast message={message} severity="error" onClose={onClose} />

export default ErrorToast;
