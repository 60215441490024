import { Button, Tooltip } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React from 'react';
import { formatDate } from '../../../../common/helpers/date';
import formatNumberWithAccounting from '../../../../common/helpers/numberFormatHelpers';
import { AvailableProduct } from '../../../model';

/* eslint-disable react/display-name -- inline render cells without names */
export const getAvailableGreenProductsColumns = (
  t: (key: string) => string,
  buttonClass: string,
  onAddAvailableGreenProduct: (availableProduct: AvailableProduct) => void
): GridColDef[] => [
    {
      field: '',
      headerName: '',
      width: 50,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Button className={buttonClass} onClick={() => onAddAvailableGreenProduct(row as AvailableProduct)}>
          <AddCircle fontSize="small" />
        </Button>
      ),
      sortable: false,
      editable: false,
    },
    {
      field: 'lotId',
      headerName: t('lotId'),
      width: 125,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'productDescription',
      headerName: t('productDescription'),
      width: 350,
      editable: false,
      renderCell: (params: GridValueFormatterParams) => (
        <Tooltip title={params.value || ''}>
          <div className="monospace">{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: 'productCharacteristic',
      headerName: t('productCharacteristic'),
      minWidth: 170,
    },
    {
      field: 'locationName',
      headerName: t('location'),
      width: 130,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'factoryName',
      headerName: t('site'),
      editable: false,
    },
    {
      field: 'inLocationSince',
      headerName: t('inLocationSince'),
      width: 200,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => (dayjs(String(params.value)).isValid() ? formatDate(String(params.value)) : 'N/A'),
    },
    {
      field: 'quantity',
      headerName: t('lotQuantity'),
      width: 200,
      editable: false,
      valueFormatter: ({ value }: GridValueFormatterParams) => `${formatNumberWithAccounting(Number(value), 0)}`,
    },
    {
      field: 'vendorName',
      headerName: t('vendor'),
      width: 195,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
  ];
/* eslint-enable react/display-name -- inline render cells without names */
