import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Door } from '../../model';

const url = '/api/v1/doors';

export type getDoorsResponse = { doors: Door[] };

const getDoors = (): Promise<AxiosResponse<getDoorsResponse>> => axios.get(url);

const useGetDoors = (): [Agent<getDoorsResponse>, getDoorsResponse | undefined, boolean, () => void] => useAgent<getDoorsResponse>(getDoors);

export default useGetDoors;
