import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';

const getUrl = (DoorId: number) => `api/v1/door/${DoorId}/can-be-set-to-inactive`;

const getDoorCanBeSetInactive = (doorId: number): Promise<AxiosResponse<getDoorCanBeSetInactiveResponse>> => axios.get(getUrl(doorId));

export type getDoorCanBeSetInactiveResponse = { inactiveAvailable: boolean };

const useGetDoorCanBeSetInactive = (): [
  Agent<getDoorCanBeSetInactiveResponse>,
  getDoorCanBeSetInactiveResponse | undefined,
  boolean,
  () => void
] => useAgent<getDoorCanBeSetInactiveResponse>((doorId: number) => getDoorCanBeSetInactive(doorId));


export default useGetDoorCanBeSetInactive;
