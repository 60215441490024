const common = {
  actions: 'Actions',
  cancel: 'Annuler',
  confirm: 'Confirmer',
  delete: 'Supprimer',
  messages: {
    accessDenied: "Désolé, vous n'avez pas la permission d'accéder à cette application.",
    somethingWrong: "Une erreur inattendue s'est produite",
  },
  no: 'Non',
  returnToPreviousPage: 'Revenir à la page précédente',
  yes: 'Oui',
};

export default common;
