import { DateHelper } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { Chip, Grid, makeStyles, Theme } from '@material-ui/core';
import { DataGrid, GridState } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../common/helpers/date';
import formatNumberWithAccounting from '../../common/helpers/numberFormatHelpers';
import { DateRangePickerField } from '../../layout/fields';
import CustomDateColumnType from '../../layout/grid/CustomDateColumnType';
import useGetFactories from '../../settings/hooks/factories/useGetFactories';
import useGetTransfersList from '../hooks/useGetTransferList';
import { getTransfersListColumns } from './TransfersListColumns';

const useTransfersListStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  chip: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  grid: {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderDraggableContainer, .MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer':
      {
        flexDirection: 'row',
      },
    '& .MuiDataGrid-columnHeaderTitle': {
      overflow: 'visible',
      lineHeight: '1rem',
      whiteSpace: 'normal',
    },
  },
  tooltipText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const TransfersList = (): ReactElement => {
  const { t } = useTranslation('transfers');
  const classes = useTransfersListStyles();

  const today = dayjs().startOf('day').toDate();
  const initialDatesRange = { startDate: today, endDate: DateHelper.add(today, 1, 'weeks') };
  const [datesRange, setDatesRange] = useState(initialDatesRange);
  const [totalQuantity, setTotalQuantity] = useState(0);

  const [getFactories, { factories = [] } = {}] = useGetFactories();
  const [getTransfersList, { transfersList = [] } = {}, isFetching] = useGetTransfersList();

  const formattedDatesRange = useMemo(() => {
    return { startDate: formatDate(datesRange.startDate), endDate: formatDate(datesRange.endDate) };
  }, [datesRange.startDate, datesRange.endDate]);

  const factorieNames = useMemo(() => factories.map((x) => x.name), [factories]);

  useEffect(() => {
    getFactories();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  useEffect(() => {
    getTransfersList(datesRange.startDate, dayjs(datesRange.endDate).endOf('day').utc().toDate());
    // eslint-disable-next-line react-hooks/exhaustive-deps -- cannot include getter because useAgent is not memoized
  }, [datesRange]);

  const mappedTransfers = useMemo(() => {
    return transfersList.map((transfer, index) => {
      transfer.id = index;
      transfer.transferDate = dayjs.utc(transfer.transferDate).local().toDate();
      return transfer;
    });
  }, [transfersList]);

  const handleDateRangeChange = (firstDate: string, lastDate: string) => {
    const startDate = new Date(firstDate);
    const endDate = new Date(lastDate);
    if (!DateHelper.isValidDate(startDate) || !DateHelper.isValidDate(endDate)) return;
    setDatesRange({ startDate, endDate });
  };

  const handleStateChange = useCallback(
    (state: GridState) => {
      const visibleTransfers = state.visibleRows?.visibleRows
        ? mappedTransfers.filter((x) => state.visibleRows.visibleRows?.includes(x.id.toString()))
        : mappedTransfers;
      const totalVisibleQuantity = visibleTransfers.reduce((acc: number, tranfer) => acc + tranfer.quantity, 0);
      setTotalQuantity(totalVisibleQuantity);
    },
    [mappedTransfers, setTotalQuantity]
  );

  return (
    <>
      <Grid container justifyContent="space-between" className={classes.header}>
        <Grid item>
          <Formik initialValues={formattedDatesRange} onSubmit={() => undefined}>
            <DateRangePickerField
              startDateName="startDate"
              startDateLabel={t('planning:from')}
              endDateLabel={t('planning:to')}
              endDateName="endDate"
              onChange={handleDateRangeChange}
            />
          </Formik>
        </Grid>
        <Grid item className={classes.chip}>
          <Chip label={`${t('totalQuantityToMove')}: ${formatNumberWithAccounting(totalQuantity, 0)} ${t('fmb')}`} variant="outlined" />
        </Grid>
      </Grid>

      <DataGrid
        density="compact"
        rows={mappedTransfers}
        columnTypes={{ customDate: CustomDateColumnType }}
        columns={getTransfersListColumns(t, factorieNames, classes)}
        onStateChange={handleStateChange}
        loading={isFetching}
        autoHeight
        className={classes.grid}
        disableSelectionOnClick
      />
    </>
  );
};

export default TransfersList;
