import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutPage from './layout/LayoutPage';

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

const UnauthorizedPage = (): ReactElement => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <LayoutPage>
      <div className={classes.box}>
        <Typography variant="h5">{t('messages.accessDenied')}</Typography>
      </div>
    </LayoutPage>
  );
};

export default UnauthorizedPage;
