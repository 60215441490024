import { Tooltip } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRangeIcon from '@material-ui/icons/DateRange';
import TodayIcon from '@material-ui/icons/Today';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomViewPreset } from './viewPresets';

type ViewSelectorProps = {
  value: CustomViewPreset;
  onChange: (value: CustomViewPreset) => void;
};

const ViewSelector = ({ value, onChange }: ViewSelectorProps): ReactElement => {
  const { t } = useTranslation('planning');

  return (
    <ToggleButtonGroup size="small" value={value} exclusive onChange={(_, value) => onChange(value)} aria-label="text alignment">
      <ToggleButton value={CustomViewPreset.dayAndHourCustom} aria-label="left aligned">
        <Tooltip title={t('hours') as string} arrow>
          <AccessTimeIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value={CustomViewPreset.weekAndDayCustom} aria-label="centered">
        <Tooltip title={t('days') as string} arrow>
          <TodayIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton value={CustomViewPreset.weekCustom} aria-label="right aligned">
        <Tooltip title={t('weeks') as string} arrow>
          <DateRangeIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ViewSelector;
