import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing'; // must be after @sentry/react
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { getMsalConfig } from './authConfig';
import './index.scss';
import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- This variable is to ensure a new build changing only config files will generate a new hash to avoid caching problem.
const gitHash = process.env.REACT_APP_GIT_SHA;

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(getMsalConfig());

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_APP_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [`${process.env.REACT_APP_SENTRY_TRACING_ORIGIN}`, /^\//],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],

  release: process.env.REACT_APP_RELEASE,
  tracesSampleRate: 0.25,
});

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
