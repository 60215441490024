import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxField, ColorPickerField, NumberField, SelectField, TextField } from '../../../layout/fields';
import { useGetLumberVarieties } from '../../hooks/lumberVarieties';
import { RecipeValues } from '../../model';
import RecipeType from '../../model/RecipeType';
import LumberVarietySelectField from '../lumberVariety/LumberVarietySelectField';
import useSettingsTabStyles from '../SettingsTabStyles';

const RecipeEditorFormFields = ({ isNewRecipe }: { isNewRecipe: boolean }): ReactElement => {
  const [getLumberVarieties, { lumberVarieties = [] } = {}] = useGetLumberVarieties();
  const { values, setFieldValue } = useFormikContext<RecipeValues>();
  const { t } = useTranslation('settings');
  const classes = useSettingsTabStyles();

  useEffect(() => {
    getLumberVarieties({ hideArchivedVarieties: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount
  }, []);

  useEffect(() => {
    if (values.recipeType === RecipeType.Substrate) {
      setFieldValue('recipeCode', '');
      setFieldValue('lumberVarietyId', null);
    }
  }, [values.recipeType, setFieldValue]);

  const IsStandardRecipe = useMemo(() => values.recipeType === RecipeType.Standard, [values.recipeType]);
  const recipeTypeOptions = useMemo(
    () =>
      Object.values(RecipeType).map((x) => ({
        key: x,
        label: t(`recipes.recipeTypes.${x}`),
        value: x,
      })),
    [t]
  );

  return (
    <Grid container spacing={2} className={classes.gridFields}>
      <Grid item md={12} sm={12}>
        <TextField label={t('recipes.name')} name="name" />
      </Grid>
      <Grid item md={12} sm={12}>
        <SelectField label={t('recipes.type')} name="recipeType" options={recipeTypeOptions} disabled={!isNewRecipe} />
      </Grid>
      <Grid item md={12} sm={12}>
        <LumberVarietySelectField label={t('recipes.lumberVariety')} name="lumberVarietyId" lumberVarieties={lumberVarieties} disabled={!IsStandardRecipe} />
      </Grid>
      <Grid item md={12} sm={12}>
        <TextField label={t('recipes.365Code')} name="recipeCode" disabled={!IsStandardRecipe} />
      </Grid>
      {!isNewRecipe && (
        <Grid item md={6} sm={6}>
          <CheckboxField label={t('isArchived', { context: 'feminine' })} name="isArchived" />
        </Grid>
      )}
      <Grid item md={6} sm={6}>
        <ColorPickerField label={t('recipes.colorInScheduler')} name="color" />
      </Grid>
      <Grid item md={6} sm={6}>
        <NumberField label={t('recipes.maxMoistureDelta')} name="maxMoistureDelta" />
      </Grid>
      <Grid item md={6} sm={6}>
        <NumberField label={t('recipes.expectedBatchSize')} name="expectedBatchSize" suffix=" PMP" />
      </Grid>
    </Grid>
  );
};

export default RecipeEditorFormFields;
