import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';

const getUrl = (KilnId: number) => `api/v1/kiln/${KilnId}/can-be-set-to-inactive`;

const getKilnCanBeSetInactive = (doorId: number): Promise<AxiosResponse<getKilnCanBeSetInactiveResponse>> => axios.get(getUrl(doorId));

export type getKilnCanBeSetInactiveResponse = { inactiveAvailable: boolean };

const useGetDoorCanBeSetInactive = (): [
  Agent<getKilnCanBeSetInactiveResponse>,
  getKilnCanBeSetInactiveResponse | undefined,
  boolean,
  () => void
] => useAgent<getKilnCanBeSetInactiveResponse>((doorId: number) => getKilnCanBeSetInactive(doorId));


export default useGetDoorCanBeSetInactive;
