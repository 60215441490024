import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../hooks/useAgent';

const getUrl = (resourceId: number, firstEventId: number) => `/api/v1/resources/${resourceId}/align-events/${firstEventId}`;

const alignResourceEvents = (resourceId: number, firstEventId: number): Promise<AxiosResponse<void>> => axios.post(getUrl(resourceId, firstEventId));

const useAlignResourceEvents = (): [Agent<void>, void | undefined, boolean, () => void] =>
  useAgent<void>((resourceId: number, firstEventId: number) => alignResourceEvents(resourceId, firstEventId));

export default useAlignResourceEvents;
