const ResourceTypeEnum = {
  Stacking: 'Stacking',
  Kiln: 'Kiln',
  Order: 'Order',
  Transfer: 'Transfer',
};
export const ResourceTypeIndex = {
  Stacking: 0,
  Kiln: 1,
  Order: 2,
  Transfer: 3
};

export default ResourceTypeEnum;
