import { Chip, ChipTypeMap, makeStyles, Theme } from '@material-ui/core';
import { DefaultComponentProps } from '@material-ui/core/OverridableComponent';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { ReactElement } from 'react';

type ColoredChipProps = DefaultComponentProps<ChipTypeMap> & {
  customColor: string;
};

const useChipStyles = makeStyles<Theme, Partial<ColoredChipProps>>((theme: Theme) => ({
  chip: {
    backgroundColor: (props) => props.customColor,
    color: (props) => theme.palette.getContrastText(props.customColor || ''),
  },
  cancel: {
    color: (props) => theme.palette.getContrastText(props.customColor || ''),
    height: '15px',
  },
}));

const ColoredChip = ({ customColor, className, ...props }: ColoredChipProps): ReactElement => {
  const styles = useChipStyles({ customColor });
  return (
    <Chip
      {...props}
      className={className}
      classes={{
        root: styles.chip,
      }}
      deleteIcon={<CancelIcon className={styles.cancel} onMouseDown={(event) => event.stopPropagation()} />}
    />
  );
};

export default ColoredChip;
