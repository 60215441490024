import { createStyles, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField } from '../../../../layout/fields';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'baseline',
    },
    tooltipGrid: {
      alignItems: 'baseline',
      justifyContent: 'space-between',
      width: '200px',
    },
    tooltipTextBox: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    tooltipLabel: {
      fontWeight: 'bold',
    },
    tooltipRecommendedText: {
      fontWeight: 'bold',
      color: '#A2DF3F',
    },
    tooltipMinimumText: {
      fontWeight: 'bold',
      color: 'orange',
    },
  })
);

type DurationFieldProps = {
  label: string;
  name: string;
  disabled: boolean;
  duration: number;
  minimalDuration: number;
  recommendedDuration: number;
};

const DurationField = ({ label, name, disabled, duration, minimalDuration, recommendedDuration }: DurationFieldProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('planning');

  const getDurationTooltip = () => {
    return (
      <Grid container className={classes.tooltipGrid}>
        <Grid item>
          <Typography variant="h6" className={classes.tooltipLabel}>
            {`${t('recommended')}:`}
          </Typography>
        </Grid>
        <Grid item className={classes.tooltipTextBox}>
          <Typography className={classes.tooltipRecommendedText}>{t('numberOfDays', { count: recommendedDuration })}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" className={classes.tooltipLabel}>
            {`${t('minimumValue')}:`}
          </Typography>
        </Grid>
        <Grid item className={classes.tooltipTextBox}>
          <Typography className={classes.tooltipMinimumText}>{t('numberOfDays', { count: minimalDuration })}</Typography>
        </Grid>
      </Grid>
    );
  };

  const getDurationStatusColor = (): string => {
    if (duration == recommendedDuration) return '#A2DF3F';
    if (duration > recommendedDuration) return 'yellow';
    if (duration >= minimalDuration) return 'orange';
    if (duration < minimalDuration) return 'red';
    return '';
  };

  return (
    <div className={classes.root}>
      <NumberField label={label} name={name} disabled={disabled} />
      {!!(recommendedDuration && minimalDuration) && (
        <Tooltip title={getDurationTooltip()} placement="top" arrow style={{ maxWidth: '225px' }}>
          <FiberManualRecordIcon data-testid="duration-flag" style={{ color: getDurationStatusColor() }} />
        </Tooltip>
      )}
    </div>
  );
};

export default DurationField;
