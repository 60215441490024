import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import useConfirmationDialog from '../../../layout/dialog/useConfirmationDialog';
import Schwoop from '../../../layout/schwoop/Schwoop';
import { useCancelOptimization, useConfirmOptimization } from '../../hooks';
import { PlanningOptimization } from '../../model';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: theme.palette.primary.dark,
  },
  typography: {
    fontWeight: 'bolder',
    fontSize: 14,
    color: theme.palette.primary.main,
    paddingLeft: 10,
  },
  typographySub: {
    color: 'gray',
    fontSize: 14,
  },
}));

type OperationalResearchResultModalProps = {
  planningOptimization: PlanningOptimization;
  onClose: () => void;
};

const OperationalResearchStatsModal = ({ planningOptimization, onClose }: OperationalResearchResultModalProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('planning');
  const { getConfirmation } = useConfirmationDialog();
  const [confirmOptimization] = useConfirmOptimization();
  const [cancelOptimization] = useCancelOptimization();

  const handleConfirm = async () => {
    const isConfirmed = await getConfirmation({
      confirmationButtonProps: { autoFocus: true },
      messages: [t('optimization.confirmChangeMsg')],
      title: t('optimization.confirmChangeWarning'),
      confirmationText: t('confirm'),
      cancellationText: t('cancel'),
    });
    if (isConfirmed) {
      confirmOptimization(planningOptimization.id);
      onClose();
    }
  };

  const handleCancel = async () => {
    const isConfirmed = await getConfirmation({
      confirmationButtonProps: { autoFocus: true },
      messages: [t('optimization.confirmCancelOptimizationMsg')],
      title: t('optimization.confirmChangeWarning'),
      confirmationText: t('confirm'),
      cancellationText: t('cancel'),
    });
    if (isConfirmed) {
      cancelOptimization(planningOptimization.id);
      onClose();
    }
  };

  return (
    <Schwoop open onConfirm={handleConfirm} onCancel={handleCancel} isSmall title={t('optimization.resultTitle')}>
      <Box>
        <Divider className={classes.divider} />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item sm={6} md={6}>
            <Typography noWrap className={classes.typography}>
              {t('optimization.solutionQuality')}
            </Typography>
          </Grid>
          <Grid item container spacing={1} sm={3} md={3} justifyContent="flex-end" direction="column">
            <Grid item>
              <Typography className={classes.typographySub} align="right">
                {t('optimization.score')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.typographySub} align="right">
                {t('optimization.solutionStatus')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1} sm={3} md={3} justifyContent="flex-end" direction="column">
            <Grid item>
              <Typography align="left" className={classes.typography}>
                {planningOptimization.score}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.typography}>{planningOptimization.optimizationStatus}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item sm={6} md={6}>
            <Typography className={classes.typography} noWrap>
              {t('optimization.productionlineUsage')}
            </Typography>
          </Grid>
          <Grid item container spacing={1} sm={3} md={3} justifyContent="flex-end" direction="column">
            <Grid item>
              <Typography className={classes.typography}>{planningOptimization.score}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item sm={6} md={6}>
            <Typography className={classes.typography} noWrap>
              {t('optimization.recipeDistributionScore')}
            </Typography>
          </Grid>
          <Grid item container spacing={1} sm={3} md={3} justifyContent="flex-end" direction="column">
            <Grid item>
              <Typography className={classes.typography}>{planningOptimization.recipeDistributionScore}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <Grid container spacing={2} justifyContent="space-between">
          <Grid item sm={6} md={6}>
            <Typography className={classes.typography} noWrap>
              {t('optimization.dryingTimeByRecipe')}
            </Typography>
          </Grid>
          <Grid item container spacing={1} sm={3} md={3} justifyContent="flex-end" direction="column">
            <Grid item>
              {planningOptimization.dryingDays.length
                ? planningOptimization.dryingDays.map((entries) => (
                    <>
                      <Typography align="right" className={classes.typographySub}>
                        {entries.recipeName}
                      </Typography>
                    </>
                  ))
                : 'N/A'}
            </Grid>
          </Grid>
          <Grid item container spacing={1} sm={3} md={3} justifyContent="flex-end" direction="column">
            <Grid item>
              {planningOptimization.dryingDays.length
                ? planningOptimization.dryingDays.map((entries) => (
                    <>
                      <Typography className={classes.typography}>{entries.dryingDays}</Typography>
                    </>
                  ))
                : 'N/A'}
            </Grid>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container spacing={2} justifyContent="space-between">
          <Grid item sm={6} md={6}>
            <Typography className={classes.typography} noWrap>
              {t('optimization.averageKilnUsage')}
            </Typography>
          </Grid>
          <Grid item container spacing={1} sm={3} md={3} justifyContent="flex-end" direction="column">
            <Grid item>
              <Typography align="right" className={classes.typographySub}>
                {t('optimization.average')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="right" className={classes.typographySub}>
                {t('optimization.maximum')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1} sm={3} md={3} justifyContent="flex-end" direction="column">
            <Grid item>
              <Typography className={classes.typography}>{planningOptimization.kilnUsagePercentage}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.typography}>{'N/A'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Grid container spacing={2} justifyContent="space-between">
          <Grid item sm={6} md={6}>
            <Typography className={classes.typography} noWrap>
              {t('optimization.averageDoorUsage')}
            </Typography>
          </Grid>
          <Grid item container spacing={1} sm={3} md={3} justifyContent="flex-end" direction="column">
            <Grid item>
              <Typography align="right" className={classes.typographySub}>
                {t('optimization.average')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="right" className={classes.typographySub}>
                {t('optimization.maximum')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1} sm={3} md={3} justifyContent="flex-end" direction="column">
            <Grid item>
              <Typography className={classes.typography}>{planningOptimization.controlledInventoryUsagePercentage}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.typography}>{'N/A'}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item sm={6} md={6}>
            <Typography className={classes.typography} noWrap>
              {t('optimization.averageRailUsage')}
            </Typography>
          </Grid>
          <Grid item container spacing={1} sm={3} md={3} justifyContent="flex-end" direction="column">
            <Grid item>
              <Typography align="right" className={classes.typographySub}>
                {t('optimization.average')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="right" className={classes.typographySub}>
                {t('optimization.maximum')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1} sm={3} md={3} justifyContent="flex-end" direction="column">
            <Grid item>
              <Typography className={classes.typography}>{planningOptimization.railUsagePercentage}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.typography}>{'N/A'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Schwoop>
  );
};

export default OperationalResearchStatsModal;
