import dayjs, { ManipulateType } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import _groupBy from 'lodash.groupby';
import _orderBy from 'lodash.orderby';
import { formatDateTime } from '../../common/helpers/date';
import { LumberVariety } from '../../settings/model';
import { EventTypeEnum, ResourceTypeEnum, UnitTypeEnum } from '../enums';
import { EventSubtypeEnum } from '../enums/EventSubtypeEnum';
import { EventValues } from '../model';
import { PlanifEvent, PlanifEventModel } from '../model/Event';
import { PlanifResource } from '../model/Resource';

dayjs.extend(utc);
dayjs.extend(duration);

export const getEventTypeFromResource = (resourceType?: string): string => {
  switch (resourceType) {
    case ResourceTypeEnum.Kiln:
      return EventTypeEnum.Drying;
    case ResourceTypeEnum.Order:
      return EventTypeEnum.Order;
    case ResourceTypeEnum.Stacking:
      return EventTypeEnum.Stacking;
    case ResourceTypeEnum.Transfer:
      return EventTypeEnum.Transfer;  
    default:
      return EventTypeEnum.Stacking;
  }
};

export const getTaskDurationInDays = (startDate: string | Date | null, endDate: string | Date | null): number => {
  const duration = Math.ceil(dayjs.duration(dayjs(endDate).diff(dayjs(startDate))).asDays());
  return Math.max(duration, 0);
};

export const getTaskDurationInHours = (startDate: string | Date | null, endDate: string | Date | null): number => {
  const duration = Math.ceil(dayjs.duration(dayjs(endDate).diff(dayjs(startDate))).asHours());
  return Math.max(duration, 0);
};

export const mapEventDataToValues = (eventData: PlanifEvent, eventResource: PlanifResource): EventValues => {
  const {
    isLocked,
    startDate,
    endDate,
    woodQuantity,
    initialWoodMoisture,
    targetWoodMoisture,
    tooltipNote,
    detailedComment,
    id,
    workOrder,
    infeedProducts = [],
    outfeedProducts = [],
    eventSubtype,
    maxQuantity
  } = eventData || ({} as PlanifEvent);

  const noonToday = dayjs().hour(12).minute(0).second(0).millisecond(0).toDate();

  const eventType = eventData.eventType || getEventTypeFromResource(eventResource?.type);
  const durationInDays = getTaskDurationInDays(startDate, endDate);
  const durationInHours = getTaskDurationInHours(startDate, endDate);

  return {
    id,
    eventType,
    eventSubtype: eventSubtype || getDefaultTaskSubtype(eventType),
    isLocked,
    manualLock: null,
    resourceId: eventResource?.id,
    startDate: startDate || noonToday,
    endDate,
    woodQuantity: woodQuantity || 0,
    initialWoodMoisture: initialWoodMoisture || 0,
    targetWoodMoisture: targetWoodMoisture || 7,
    tooltipNote,
    detailedComment,
    duration: eventType === EventTypeEnum.Drying || eventType === EventTypeEnum.Maintenance ? durationInDays : durationInHours,
    infeedProducts: infeedProducts.map((x, index) => ({ ...x, id: index, availableQuantity: 0 })),
    outfeedProducts: outfeedProducts.map((x, index) => ({ ...x, id: index, remainingCapacity: 0, totalCapacity: 0 })),
    workOrder,
    maxQuantity: maxQuantity || 50000,
  };
};

const getDefaultTaskSubtype = (eventType: string): string => ([EventTypeEnum.Stacking, EventTypeEnum.Transfer].includes(eventType) ? EventSubtypeEnum.green : EventSubtypeEnum.standard);

const commonFields = [...PlanifEventModel.fields, 'startDate', 'endDate', 'resourceId'];
const usedFieldsDrying = commonFields.filter((x) => !['isLocked'].includes(x.toString()));
const usedFieldsOrder = commonFields.filter((x) => !['isLocked', 'targetWoodMoisture', 'initialWoodMoisture'].includes(x.toString()));

export const areAnyEventEditorFieldsNotFilled = (record: PlanifEvent): boolean => {
  const requiredFields = record.eventType === EventTypeEnum.Drying ? usedFieldsDrying : usedFieldsOrder;
  // eslint-disable-next-line @typescript-eslint/ban-types -- object comes from bryntum
  return requiredFields.map((field: string | object) => record.get(field.toString())).some((value) => !value);
};

export const eventHasDependencies = (record: PlanifEvent): boolean => !!record.dependencyStore.getEventDependencies(record).length;

export const formatProductionTask = (productionTask: number | undefined): number[] => (productionTask ? [productionTask] : []);

// This value will eventually come from the resource.
export const defaultEfficiency = 4000;

export const getNewEndDateFromVolume = (startDate: Date | string, endDate: Date | string, newQuantity: number): Date | string => {
  if (newQuantity === 0) return endDate;
  const newEndDate = dayjs(startDate)
    .add(newQuantity / defaultEfficiency, 'hour')
    .toDate();
  return newEndDate;
};

export const setVolumeFieldFromDates = (
  setFieldValue: (field: string, value: number) => void,
  startDate: string | Date | null,
  endDate: string | Date | null
): void => {
  if (startDate && endDate) {
    const newWoodQuantity = dayjs(endDate).diff(startDate, 'hour') * defaultEfficiency;
    setFieldValue('woodQuantity', newWoodQuantity);
  }
};

export const setEndDateOrVolumeFromStartDate = (
  setFieldValue: (field: string, value: string | number) => void,
  newStartDate: string | Date | null,
  endDate: string | Date | null,
  woodQuantity: number
): void | string => {
  if (woodQuantity !== 0 && woodQuantity && newStartDate) {
    const newEndDate = formatDateTime(
      dayjs(newStartDate)
        .add(woodQuantity / defaultEfficiency, 'hour')
        .toDate()
    );
    setFieldValue('endDate', newEndDate);
    return newEndDate;
  } else {
    setVolumeFieldFromDates(setFieldValue, newStartDate, endDate);
  }
};

const getDryingHoursUnderThirtyPercent = (lumberVarietyParams: LumberVarietyParams, initialWoodMoisture: number, targetWoodMoisture: number) =>
  (initialWoodMoisture - targetWoodMoisture) / lumberVarietyParams.standardDryMoistureLossPerHourUnderThirtyPercent +
  getStandardDryHeatingTime(lumberVarietyParams.fanDryTemperature, lumberVarietyParams.standardDryTemperature, lumberVarietyParams.heatingDegreesPerHour);

const getDryingHoursOverThirtyPercent = (lumberVarietyParams: LumberVarietyParams, initialWoodMoisture: number, targetWoodMoisture: number) =>
  (initialWoodMoisture - 30) / lumberVarietyParams.standardDryMoistureLossPerHourOverThirtyPercent +
  (30 - targetWoodMoisture) / lumberVarietyParams.standardDryMoistureLossPerHourUnderThirtyPercent +
  getStandardDryHeatingTime(lumberVarietyParams.fanDryTemperature, lumberVarietyParams.standardDryTemperature, lumberVarietyParams.heatingDegreesPerHour);

const getDryingHoursOverFanDryTarget = (
  lumberVarietyParams: LumberVarietyParams,
  initialWoodMoisture: number,
  targetWoodMoisture: number,
  currentMonthAverageTemperature: number
) =>
  getFanDryingHours(lumberVarietyParams, initialWoodMoisture, currentMonthAverageTemperature) +
  (lumberVarietyParams.targetFanDryMoisturePercentage - 30) / lumberVarietyParams.standardDryMoistureLossPerHourOverThirtyPercent +
  (30 - targetWoodMoisture) / lumberVarietyParams.standardDryMoistureLossPerHourUnderThirtyPercent;

const getFanDryingHours = (lumberVarietyParams: LumberVarietyParams, initialWoodMoisture: number, monthAverageTemperature: number) =>
  (initialWoodMoisture - lumberVarietyParams.targetFanDryMoisturePercentage) / lumberVarietyParams.fanDryMoistureLossPerHourOverThirtyPercent +
  getFanDryHeatingTime(lumberVarietyParams.fanDryTemperature, lumberVarietyParams.heatingDegreesPerHour, monthAverageTemperature);

const getFanDryHeatingTime = (fanDryTemperature: number, heatingDegreesPerHour: number, currentMonthAverageTemperature: number): number =>
  (fanDryTemperature - currentMonthAverageTemperature) / heatingDegreesPerHour;

const getStandardDryHeatingTime = (fanDryTemperature: number, standardDryTemperature: number, heatingDegreesPerHour: number): number =>
  (standardDryTemperature - fanDryTemperature) / heatingDegreesPerHour;

export type LumberVarietyDuration = {
  recommended: number;
  minimum: number;
  fanDryPercentage: number;
};

export type LumberVarietyParams = {
  standardDryMoistureLossPerHourUnderThirtyPercent: number;
  standardDryMoistureLossPerHourOverThirtyPercent: number;
  targetFanDryMoisturePercentage: number;
  fanDryMoistureLossPerHourOverThirtyPercent: number;
  minimalHoursUnderThirtyPercentMoisture: number;
  moistureBalancingHours: number;
  fanDryTemperature: number;
  heatingDegreesPerHour: number;
  standardDryTemperature: number;
};

export const mergeLumberVarietiesData = (lumberVarieties: LumberVariety[]): LumberVarietyParams => {
  return {
    standardDryMoistureLossPerHourUnderThirtyPercent: Math.min(...lumberVarieties.map((x) => x.standardDryMoistureLossPerHourUnderThirtyPercent)),
    standardDryMoistureLossPerHourOverThirtyPercent: Math.min(...lumberVarieties.map((x) => x.standardDryMoistureLossPerHourOverThirtyPercent)),
    targetFanDryMoisturePercentage: Math.min(...lumberVarieties.map((x) => x.targetFanDryMoisturePercentage)),
    fanDryMoistureLossPerHourOverThirtyPercent: Math.min(...lumberVarieties.map((x) => x.fanDryMoistureLossPerHourOverThirtyPercent)),
    minimalHoursUnderThirtyPercentMoisture: Math.max(...lumberVarieties.map((x) => x.minimalHoursUnderThirtyPercentMoisture)),
    moistureBalancingHours: Math.max(...lumberVarieties.map((x) => x.moistureBalancingHours)),
    fanDryTemperature: Math.max(...lumberVarieties.map((x) => x.fanDryTemperature)),
    heatingDegreesPerHour: Math.min(...lumberVarieties.map((x) => x.heatingDegreesPerHour)),
    standardDryTemperature: Math.max(...lumberVarieties.map((x) => x.standardDryTemperature)),
  };
};

export const getLumberVarietyDryingDuration = (
  lumberVarieties: LumberVariety[],
  initialWoodMoisture: number,
  targetWoodMoisture: number,
  currentMonthAverageTemperature: number
): LumberVarietyDuration => {
  const lumberVarietyParams = mergeLumberVarietiesData(lumberVarieties);
  let recommendedDurationHours = 0;
  let minimumDurationHours = 0;
  let fanDryPercentage = 0;

  if (initialWoodMoisture <= 30) {
    recommendedDurationHours = getDryingHoursUnderThirtyPercent(lumberVarietyParams, initialWoodMoisture, targetWoodMoisture);
    minimumDurationHours = Math.max(recommendedDurationHours, lumberVarietyParams.minimalHoursUnderThirtyPercentMoisture);
    if (minimumDurationHours > recommendedDurationHours) recommendedDurationHours = minimumDurationHours;
  } else {
    minimumDurationHours += getDryingHoursOverThirtyPercent(lumberVarietyParams, initialWoodMoisture, targetWoodMoisture);

    if (initialWoodMoisture <= lumberVarietyParams.targetFanDryMoisturePercentage) {
      recommendedDurationHours = getDryingHoursOverThirtyPercent(lumberVarietyParams, initialWoodMoisture, targetWoodMoisture);
    } else {
      const fanDryDurationHours = getFanDryingHours(lumberVarietyParams, initialWoodMoisture, currentMonthAverageTemperature);
      recommendedDurationHours = getDryingHoursOverFanDryTarget(lumberVarietyParams, initialWoodMoisture, targetWoodMoisture, currentMonthAverageTemperature);
      fanDryPercentage = Math.floor((fanDryDurationHours / recommendedDurationHours) * 100);
    }
  }

  const recommended = Math.max(Math.ceil(dayjs.duration({ hours: recommendedDurationHours + lumberVarietyParams.moistureBalancingHours }).asDays()), 0);
  const minimum = Math.max(Math.ceil(dayjs.duration({ hours: minimumDurationHours + lumberVarietyParams.moistureBalancingHours }).asDays()), 0);

  return {
    recommended,
    minimum,
    fanDryPercentage,
  };
};

export const getLumberVarietyBalancingDuration = (lumberVarieties: LumberVariety[]): number => {
  const lumberVarietyParams = mergeLumberVarietiesData(lumberVarieties);
  const durationSumInHours =
    lumberVarietyParams.minimalHoursUnderThirtyPercentMoisture +
    lumberVarietyParams.moistureBalancingHours +
    getStandardDryHeatingTime(lumberVarietyParams.fanDryTemperature, lumberVarietyParams.standardDryTemperature, lumberVarietyParams.heatingDegreesPerHour);

  return Math.ceil(dayjs.duration({ hours: durationSumInHours }).asDays());
};

export const setEndDateFromStartDateAndDuration = (
  setFieldValue: (field: string, value: Date | string) => void,
  newStartDate: string | Date | null,
  duration: number,
  eventType: string
): Date | void => {
  const durationUnit = eventType === EventTypeEnum.Drying ? UnitTypeEnum.day : UnitTypeEnum.hour;
    if (newStartDate) {
    const newEndDate = dayjs(newStartDate).add(duration, durationUnit as ManipulateType).toDate();
    setFieldValue('endDate', newEndDate);
    return newEndDate;
  }
};

export const setDatesFromDuration = (
  setFieldValue: (field: string, value: Date | string) => void,
  startDate: string | Date | null,
  endDate: string | Date | null,
  duration: string | number,
  eventType: string
): void => {
    const durationUnit = eventType === EventTypeEnum.Drying || eventType === EventTypeEnum.Maintenance ? UnitTypeEnum.day : UnitTypeEnum.hour;

  if (startDate) {
    setFieldValue(
      'endDate',
      dayjs(startDate)
        .add(duration as number, durationUnit as ManipulateType)
        .toDate()
    );
  } else if (!startDate && endDate) {
    setFieldValue(
      'startDate',
      dayjs(endDate)
          .subtract(duration as number, durationUnit as ManipulateType)
        .toDate()
    );
  }
};

export const mapEventDatesToUtc = (events: PlanifEvent[]): PlanifEvent[] =>
  events.map(
    (event) =>
      ({
        ...event,
        startDate: dayjs.utc(event.startDate).toDate(),
        endDate: dayjs.utc(event.endDate).toDate(),
      } as PlanifEvent)
  );

export const setDurationFromDate = (
  setFieldValue: (name: string, value: number) => void,
  startDate: string | Date | null,
  endDate: string | Date | null
): void => {
  if (dayjs(startDate).isValid() && dayjs(endDate).isValid()) {
    setFieldValue('duration', getTaskDurationInDays(startDate, endDate));
  }
};

export const getRecipeColors = (event: PlanifEvent): string[] => {
  const { infeedProducts } = event;
  const goupedRecipe = Object.entries(_groupBy(infeedProducts, 'recipeId')).map(([key, infeeds]) => ({
    key,
    color: infeeds[0].recipeColor,
    quantity: infeeds.reduce((acc, infeed) => acc + infeed.quantity, 0),
  }));
  return _orderBy(goupedRecipe, 'quantity', 'desc')
    .map((x) => x.color)
    .filter(Boolean);
};

export const getMainRecipeColor = (event: PlanifEvent): string | undefined => {
  const recipeColors = getRecipeColors(event);
  return recipeColors.length ? recipeColors.shift() : undefined;
};

export const getSecondaryRecipeColors = (event: PlanifEvent): string[] => {
  const recipeColors = getRecipeColors(event);
  if (recipeColors.length > 1) recipeColors.shift();
  return recipeColors;
};
