import { useFormikContext } from 'formik';
import { get as _get } from 'lodash';
import React, { Ref } from 'react';
import TextInput from './TextInput';

type TextFieldProps = {
  disabled?: boolean;
  inputRef?: ((el: HTMLInputElement) => void) | Ref<HTMLInputElement> | undefined;
  label: string;
  name: string;
};

const TextField = ({ inputRef, label = '', name, disabled = false }: TextFieldProps): JSX.Element => {
  const { handleChange, errors, values, touched } = useFormikContext();

  const currentError = _get(errors, name);
  const isFieldTouched = _get(touched, name);

  return (
    <TextInput
      disabled={disabled}
      inputRef={inputRef}
      label={label}
      name={name}
      onChange={handleChange}
      value={_get(values, name) || ''}
      error={isFieldTouched && Boolean(currentError)}
      helperText={isFieldTouched && currentError}
    />
  );
};

export default TextField;
