import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../hooks/useAgent';
import { PlanningOptimization } from '../model';

const url = '/api/v1/planning-optimizations/active';

export type getActivePlanningOptimizationResponse = { planningOptimization: PlanningOptimization };

const getActivePlanningOptimization = (): Promise<AxiosResponse<getActivePlanningOptimizationResponse>> => axios.get(url);

const useGetActivePlanningOptimization = (): [
  Agent<getActivePlanningOptimizationResponse>,
  getActivePlanningOptimizationResponse | undefined,
  boolean,
  () => void
] => useAgent<getActivePlanningOptimizationResponse>(() => getActivePlanningOptimization());

export default useGetActivePlanningOptimization;
