import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import useAgent, { Agent } from '../../hooks/useAgent';
import { AvailableProduct } from '../model';

const url = '/api/v1/available-dried-products';

export type getAvailableDriedProductsResponse = { availableProducts: AvailableProduct[] };

const getAvailableDriedProducts = (
  recipeIds: number[],
  eventStartDate: string | Date,
  eventId: number
): Promise<AxiosResponse<getAvailableDriedProductsResponse>> =>
  axios.get(url, {
    params: { eventStartDate, recipeIds, eventId: Number.isInteger(eventId) ? eventId : null },
    paramsSerializer: (params) => qs.stringify(params),
  });

const useGetAvailableDriedProducts = (): [Agent<getAvailableDriedProductsResponse>, getAvailableDriedProductsResponse | undefined, boolean, () => void] =>
  useAgent<getAvailableDriedProductsResponse>(getAvailableDriedProducts);

export default useGetAvailableDriedProducts;
