import { Box, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Can } from '../../../abilities/AbilityContext';
import { CommonAbilityEnum } from '../../../abilities/CommonAbilityEnum';
import { ResourceEnum } from '../../../abilities/ResourceEnum';
import ConflictError from '../../../hooks/ConflictError';
import { useCreateDoor, useGetDoors, useUpdateDoor, useDeleteDoor } from '../../hooks/doors';
import { Door, DoorValues } from '../../model';
import useSettingsTabStyles from '../SettingsTabStyles';
import DoorEditor from './DoorEditor';
import { getColumns } from './DoorGridColumns';
import useConfirmDeleteDialog from '../../../layout/dialog/useConfirmDeleteDialog';

const DoorPage: React.FunctionComponent = (): React.ReactElement => {
  const classes = useSettingsTabStyles();
  const { t } = useTranslation(['settings','layout']);

  const [getDoors, { doors = [] } = {}, isFetching] = useGetDoors();
  const [createDoor] = useCreateDoor();
  const [editDoor] = useUpdateDoor();
  const [deleteDoor] = useDeleteDoor();

  const { getDeleteConfirmation } = useConfirmDeleteDialog();

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [doorToEdit, setDoorToEdit] = useState<Door>({} as Door);

  const handleEdit = (door: Door): void => {
    setDoorToEdit(door);
    setIsEditorOpen(true);
  };

  const handleClose = (): void => {
    setDoorToEdit({} as Door);
    setIsEditorOpen(false);
  };

  const handleSubmit = (values: DoorValues) => {
    const submitCallBack = values.id ? editDoor : createDoor;

    submitCallBack(values)
      .then(() => {
        getDoors();
        handleClose();
      })
      .catch((error: Error) => {
        if (!(error instanceof ConflictError)) {
          throw error;
        }
      });
  };

  const handleDelete = useCallback(
    async (doorToDelete: Door): Promise<boolean> => {
      const confirmed = await getDeleteConfirmation({
        messages: [t('doors.deleteMessage')],
        title: t('deleteSetting'),
        confirmationText: t('confirm'),
        cancellationText: t('cancel'),
      }).then(async (result: boolean) => {
        if (result) {
          deleteDoor(doorToDelete.id);
          handleClose();
          getDoors();
        }
        return result;
      });

      return confirmed;
    },
    [getDeleteConfirmation, t]
  );

  useEffect(() => {
    getDoors();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);
 

  return (
    <>
      <Can I={CommonAbilityEnum.Create} a={ResourceEnum.Settings}>
        <Box className={classes.header}>
          <Button onClick={() => setIsEditorOpen(true)} startIcon={<AddCircleIcon />}>
            {t('doors.addNewDoor')}
          </Button>
        </Box>
      </Can>
      
      <Can I={CommonAbilityEnum.Update} a={ResourceEnum.Settings} passThrough>
        {(canUpdate: boolean) => (
          <DataGrid
            density="compact"
            rows={doors}
            columns={getColumns(t, canUpdate, handleEdit)}
            loading={isFetching}
            autoHeight
            disableColumnFilter
            className={classes.grid}
          />
        )}
      </Can>

      {isEditorOpen && <DoorEditor door={doorToEdit} onClose={handleClose} onSubmit={handleSubmit} onDelete={handleDelete} />}
    </>
  );
};

export default DoorPage;
