import { useFormikContext } from 'formik';
import { get as _get } from 'lodash';
import React, { Ref } from 'react';
import NumberInput from './NumberInput';

type NumberFieldProps = {
  inputRef?: ((el: HTMLInputElement) => void) | Ref<HTMLDivElement> | undefined;
  disabled?: boolean;
  label: string;
  name: string;
  suffix?: string;
  prefix?: string;
  min?: number;
  max?: number;
  decimalScale?: number;
  onChange?: (value: string) => void;
};

const NumberField = ({
  inputRef,
  label = '',
  name,
  prefix,
  suffix,
  min,
  max,
  decimalScale = 0,
  disabled = false,
  onChange = () => void 0,
}: NumberFieldProps): JSX.Element => {
  const { handleChange, errors, values, touched } = useFormikContext();

  const currentError = _get(errors, name);
  const isFieldTouched = _get(touched, name);

  const handleValueChange = ({ target }: { target: { name: string; value: string } }): void => {
    onChange(target.value);
    handleChange({ target });
  };

  return (
    <NumberInput
      decimalScale={decimalScale}
      prefix={prefix}
      suffix={suffix}
      min={min}
      max={max}
      inputRef={inputRef}
      label={label}
      name={name}
      onChange={handleValueChange}
      value={_get(values, name)}
      error={isFieldTouched && Boolean(currentError)}
      helperText={isFieldTouched && currentError}
      disabled={disabled}
    />
  );
};

export default NumberField;
