import { makeStyles, Theme, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import _get from 'lodash.get';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    borderColor: theme.palette.secondary.main,
  },
}));

type ToggleFieldProps = {
  label?: string;
  name: string;
  onChange?: (value: number | string) => void;
  options: { label: string; value: number | string }[];
};

const ToggleField = ({ label, name, onChange = () => undefined, options }: ToggleFieldProps): ReactElement => {
  const classes = useStyles();
  const { errors, setFieldValue, touched, values } = useFormikContext();

  const currentError = _get(errors, name);
  const isFieldTouched = _get(touched, name);

  const handleChange = (value: string | number) => {
    if (!value) return;
    onChange(value);
    setFieldValue(name, value);
  };

  return (
    <>
      <Typography variant="body2">{label}</Typography>
      <ToggleButtonGroup onChange={(_, value) => handleChange(value)} size="medium" value={_get(values, name)} exclusive>
        {options.map((option) => (
          <ToggleButton classes={{ root: currentError && isFieldTouched ? classes.error : '' }} key={option.value} value={option.value}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
};

export default ToggleField;
