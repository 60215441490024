import { Button } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React from 'react';
import { formatDate } from '../../../../common/helpers/date';
import formatNumberWithAccounting from '../../../../common/helpers/numberFormatHelpers';
import { EventSubtypeEnum } from '../../../enums/EventSubtypeEnum';
import { AvailableProduct } from '../../../model';

//J'ai ajouté ça juste pour empêcher VS d'effacer le React dans la ligne "import React, ...".
type _react = typeof React

/* eslint-disable react/display-name -- inline render cells without names */
export const getAvailableStackedProductsColumns = (
  t: (key: string) => string,
  buttonClass: string,
  onAddAvailableStackedProduct: (availableProduct: AvailableProduct) => void,
  subtype: EventSubtypeEnum
): GridColDef[] => [
    {
      field: '',
      headerName: '',
      width: 50,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Button className={buttonClass} onClick={() => onAddAvailableStackedProduct(row as AvailableProduct)}>
          <AddCircle fontSize="small" />
        </Button>
      ),
      sortable: false,
      editable: false,
    },
    {
      field: 'productDescription',
      headerName: t('productDescription'),
      renderCell: (params: GridRenderCellParams) => {
        return (<div className="monospace">{params.value}</div>);
      },
      minWidth: 350,
      sortable: false,
      editable: false,
    },
    {
      field: 'productCharacteristic',
      headerName: t('productCharacteristic'),
      minWidth: 170,
      sortable: false,
      editable: false,
    },
    {
      field: 'woodMoisture',
      headerName: t('moisture'),
      width: 155,
      sortable: true,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => (params.value ? Number(params.value).toFixed(0) : 'N/A'),
    },
    {
      field: 'locationName',
      headerName: t('location'),
      width: 130,
      sortable: false,
      editable: false,
      valueFormatter: ({ value }: GridValueFormatterParams) => value || 'N/A',
    },
/*    {
      field: 'locationDisplayIndex',
      headerName: "locationDisplayIndex",
      minWidth: 170,
    },*/
    {
      field: 'factoryName',
      headerName: t('factory'),
      width: 110,
      sortable: false,
      editable: false,
    },
    {
      field: 'inLocationSince',
      headerName: subtype === EventSubtypeEnum.balancing ? t('date') : t('putInTShedDate'),
      width: 170,
      sortable: false,
      editable: false,
      valueFormatter: ({ value }: GridValueFormatterParams) => (dayjs(String(value)).isValid() ? formatDate(String(value)) : 'N/A'),
    },
    {
      field: 'quantity',
      headerName: t('quantity'),
      type: 'number',
      width: 120,
      sortable: false,
      editable: false,
      valueFormatter: ({ value }: GridValueFormatterParams) => (Number(value) !== 0 ? `${formatNumberWithAccounting(Number(value), 0)}` : 'N/A'),
    },
    {
      field: 'dryingDuration',
      headerName: t('dryingDuration'),
      type: 'number',
      width: 220,
      editable: false,
    },
  ];
/* eslint-enable react/display-name -- inline render cells without names */
