import { Chip, Grid, Typography } from '@material-ui/core';
import { DataGrid, GridCellEditCommitParams, GridCellValue, GridEditRowsModel } from '@mui/x-data-grid';
import { useFormikContext } from 'formik';
import _uniq from 'lodash.uniq';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import formatNumberWithAccounting from '../../../../common/helpers/numberFormatHelpers';
import usePrevious from '../../../../hooks/usePrevious';
import customDateColumnType from '../../../../layout/grid/CustomDateColumnType';
import { useGetMonthlyAverageTemperature } from '../../../../settings/hooks/averageTemperature';
import { Recipe } from '../../../../settings/model';
import { EventSubtypeEnum } from '../../../enums/EventSubtypeEnum';
import { getLumberVarietyDryingDuration } from '../../../helpers/eventHelpers';
import { getResourceUsageInPercentage, isSelectedQuantityValid } from '../../../helpers/infeedHelpers';
import { useGetAvailableStackedProducts } from '../../../hooks';
import useGetAvailableProductsToBalance from '../../../hooks/useGetAvailableProductsToBalance';
import { AvailableProduct, EventValues, InfeedValues } from '../../../model';
import { EventEditorContext } from '../EventEditorContext';
import { LocationTypeEnum, useAvailableLocationsHelpers } from '../common/useAvailableLocationsHelpers';
import useListStyles from '../listStyles';
import { getAvailableStackedProductsColumns } from './AvailableStackedProductsColumns';
import { getSelectedStackedProductsColumns } from './SelectedStackedProductsColumns';

type ProductKey = {
  factoryId: number;
  locationId: number;
  locationType: string;
  productId: number;
};

type AvailableStackedProductsProps = {
  disabled?: boolean;
  resourceCapacity?: number;
  recipes?: Recipe[];
};

const AvailableStackedProducts = ({ disabled = false, resourceCapacity = 0, recipes = []}: AvailableStackedProductsProps): ReactElement => {
  const classes = useListStyles();
  const { t } = useTranslation('planning');
  const { setFieldValue, values } = useFormikContext<EventValues>();
  const { recipeIds, selectedLocationIdsMemory, setSelectedLocationIdsMemory, lumberVarieties } = useContext(EventEditorContext);
  const previousWoodQuantity = usePrevious(values.woodQuantity);
  const [editRowsModel, setEditRowsModel] = React.useState<GridEditRowsModel>({});
  const [getAvailableStackedProducts, { availableProducts: availableStackedProducts = [] } = {}, isFetchingStacked, clearAvailableStackingProducts] =
    useGetAvailableStackedProducts();
  const [getAvailableProductsToBalance, { availableProducts: availableProductsToBalance = [] } = {}, isFetchingBalance, clearAvailableProductsToBalance] =
    useGetAvailableProductsToBalance();
  const { refreshLocationsOutfeedProducts } = useAvailableLocationsHelpers(LocationTypeEnum.Door);
  const [getAverageTemperature, { monthlyAverageTemperature = [] } = {}] = useGetMonthlyAverageTemperature();
  const totalSelectedQuantity = useMemo(() => values.infeedProducts.reduce((acc, product) => acc + product.quantity, 0), [values.infeedProducts]);

  const isSameProduct = useCallback((x: ProductKey, infeedProduct: ProductKey) => {
    return (
      infeedProduct.productId === x.productId &&
      infeedProduct.locationId === x.locationId &&
      infeedProduct.locationType === x.locationType &&
      infeedProduct.factoryId === x.factoryId
    );
  }, []);

  const handleSelectProduct = (availableProduct: AvailableProduct): void => {
    let maxId = Math.max(...values.infeedProducts.map((x) => x.id), 0);   // trouve l'ID max dans la liste des infeedProducts 
    const newInfeedProducts = [
      { ...availableProduct, id: ++maxId, availableQuantity: availableProduct.quantity, quantity: availableProduct.quantity },  // Cr�e une copie de availableProduct en modifiant certaines propri�t�s
      ...values.infeedProducts,
    ] as InfeedValues[];    // nouvel array va contenir le nouvel object AvailableProduct + le reste de l'array d�j� existant dansvalues.infeedProducts
    setFieldValue('infeedProducts', newInfeedProducts);   // Affecte le nouvel array cr�� dans la propri�t� infeedProducts de values.

    if (selectedLocationIdsMemory.length) {
      refreshLocationsOutfeedProducts(newInfeedProducts);
    }
  };

  const handleRemoveProduct = (productId: number): void => {
    if (!selectedLocationIdsMemory.length) setSelectedLocationIdsMemory(_uniq(values.outfeedProducts.map((x) => x.locationId as number)));

    const updatedInfeedProducts = values.infeedProducts.filter((x) => x.id !== productId);
    setFieldValue('infeedProducts', updatedInfeedProducts);

    const filteredOutfeedProducts = values.outfeedProducts.filter((x) =>
      updatedInfeedProducts.some((infeedProduct) => infeedProduct.productId === x.originalProductId)
    );
    if (filteredOutfeedProducts.length !== values.outfeedProducts.length) {
      setFieldValue('outfeedProducts', filteredOutfeedProducts);
    }

    const totalQuantityInKiln = updatedInfeedProducts.reduce((a, b) => a + (b.quantity || 0), 0);
    setFieldValue('woodQuantity', totalQuantityInKiln);

    refreshLocationsOutfeedProducts(updatedInfeedProducts);
  };

  const handleEditRowsModelChange = useCallback(
    (newModel: GridEditRowsModel) => {
      const updatedModel = { ...newModel };

      Object.entries(updatedModel).forEach(([id, row]) => {
        if (row.quantity?.value)
          row.quantity = {
            ...updatedModel[id].quantity,
            error: !isSelectedQuantityValid(Number(row.quantity?.value)),
          };
      });

      setEditRowsModel(updatedModel);
    },
    [setEditRowsModel]
  );

  const updateFormikOnCellCommit = useCallback(
    (id: string | number, value: GridCellValue): void => {
      const formikIndex = values.infeedProducts.findIndex((x) => x.id === id);
      setFieldValue(`infeedProducts.${formikIndex}.quantity`, value);
    },
    [values.infeedProducts, setFieldValue]
  );

  useEffect(() => {
    if (!recipeIds.length) {
      clearAvailableStackingProducts();
      clearAvailableProductsToBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- cannot include getter because useAgent is not memoized
  }, [recipeIds]);

  const availableProducts = useMemo(
    () => (values.eventSubtype === EventSubtypeEnum.balancing ? availableProductsToBalance : availableStackedProducts),
    [availableProductsToBalance, availableStackedProducts, values.eventSubtype]
  );

  const filteredAvailableStackedProducts = useMemo(
    () => availableProducts.filter((x) => !values.infeedProducts.some((y) => isSameProduct(x, y))),
    [availableProducts, values.infeedProducts, isSameProduct]
  );

  useEffect(() => {

    const startDateToDate = new Date(values.startDate);
    const currentMonthAverageTemperature = startDateToDate && monthlyAverageTemperature.find((x) => x.month === startDateToDate.getMonth())?.averageTemperature;

    availableProducts.forEach(p => {
      // Récupère la recette du produit
      const productRecipe = recipes.find((recipe) => recipe.id === p.recipeId);
      if (!productRecipe) return;
  
      // Récupère la lumberVariety du produit
      const productLumberVariety = lumberVarieties.filter((x) => x.id === productRecipe.lumberVarietyId);
  
      p.dryingDuration = getLumberVarietyDryingDuration(
        productLumberVariety,
        p.woodMoisture,
        values.targetWoodMoisture || 0,
        currentMonthAverageTemperature || 0
      ).recommended;
    });

  }, [availableProducts, monthlyAverageTemperature, values.startDate, recipes, lumberVarieties]);

  useEffect(() => {
    if (recipeIds.length && values.startDate) {
      values.eventSubtype === EventSubtypeEnum.balancing
        ? getAvailableProductsToBalance(recipeIds, values.startDate, values.id)
        : getAvailableStackedProducts(recipeIds, values.startDate, values.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- cannot include getter because useAgent is not memoized
  }, [recipeIds, values.startDate, values.eventSubtype, values.id]);

  useEffect(() => {
    if ((previousWoodQuantity === values.woodQuantity && values.woodQuantity === totalSelectedQuantity) || totalSelectedQuantity === 0) return;
    setFieldValue('woodQuantity', totalSelectedQuantity);
  }, [previousWoodQuantity, values.woodQuantity, totalSelectedQuantity, setFieldValue]);

  const getFieldValuesFromAvailableStackedProducts = useCallback(
    (infeedValues: InfeedValues, field: string): string | null | number | Date => {
      const availableProduct = availableProducts.find((x) => isSameProduct(x, infeedValues));

      if (!availableProduct) return null;

      switch (field) {
        case 'inLocationSince':
          return availableProduct.inLocationSince;
        default:
          return null;
      }
    },
    [availableProducts, isSameProduct]
  );
  const isFetching = useMemo(() => isFetchingStacked || isFetchingBalance, [isFetchingStacked, isFetchingBalance]);

  const isSelectedProductAvailable = useCallback(
    (selectedProduct: InfeedValues) => {
      if (isFetching) return true;
      return availableProducts.some((x) => isSameProduct(x, selectedProduct) && x.quantity >= selectedProduct.quantity);
    },
    [availableProducts, isFetching, isSameProduct]
  );

  return (
    <>
      <Grid container justifyContent="space-between" className={classes.listTitle}>
        <Grid item>
          <Typography variant="h6">{t('selectedProducts')}</Typography>
        </Grid>
        <Grid item>
          <Chip className={classes.chips} label={`${getResourceUsageInPercentage(totalSelectedQuantity, resourceCapacity)}%`} variant="outlined" />
          <Chip
            className={classes.chips}
            label={`${formatNumberWithAccounting(totalSelectedQuantity, 0)} / ${formatNumberWithAccounting(resourceCapacity, 0)} PMP`}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <DataGrid
        style={{height:140}}
        editRowsModel={editRowsModel}
        onEditRowsModelChange={handleEditRowsModelChange}
        columnTypes={{ customDate: customDateColumnType }}
        density="compact"
        rows={values.infeedProducts}
        className={classes.grid}
        loading={isFetching}
        getRowClassName={({ row }) => (!isSelectedProductAvailable(row as InfeedValues) ? 'kplanner-infeed-missing' : '')}
        columns={getSelectedStackedProductsColumns(
          t,
          classes,
          handleRemoveProduct,
          getFieldValuesFromAvailableStackedProducts,
          isSelectedProductAvailable,
          disabled,
          values.eventSubtype as EventSubtypeEnum
        )}
        onCellEditCommit={({ id, value }: GridCellEditCommitParams) => updateFormikOnCellCommit(id, value)}
        hideFooter
        disableColumnSelector
        disableSelectionOnClick
      />

      {!disabled && (
        <>
          <Typography variant="h6" className={classes.listTitle}>
            {t('availableProducts')}
          </Typography>

          <DataGrid
            style={{height:150}}
            density="compact"
            rows={filteredAvailableStackedProducts}
            className={classes.grid}
            columnTypes={{ customDate: customDateColumnType }}
            loading={isFetching}
            columns={getAvailableStackedProductsColumns(t, classes.button, handleSelectProduct, values.eventSubtype as EventSubtypeEnum)}
            hideFooter
            disableColumnSelector
            disableSelectionOnClick
          />
        </>
      )}
    </>
  );
};

export default AvailableStackedProducts;
