import { Grid } from '@material-ui/core';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sortOptionByLabel } from '../../../common/helpers/options';
import { NumberField, SelectField, TextField } from '../../../layout/fields';
import useGetFactories from '../../hooks/factories/useGetFactories';
import { Factory } from '../../model';

const RailEditorFormFields = (): ReactElement => {
  const { t } = useTranslation('settings');
  const [getFactories, { factories = [] } = {}] = useGetFactories();

  const factoryOptions = useMemo(() => factories.map((x: Factory) => ({ label: x.name, value: x.id, key: x.id })).sort(sortOptionByLabel), [factories]);

  useEffect(() => {
    getFactories();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- agents aren't memoized, only fetch on mount
  }, []);

  return (
    <>
      <Grid item md={12} sm={12}>
        <TextField label={t('rails.name')} name="name" />
      </Grid>
      <Grid item md={12} sm={12}>
        <TextField label={t('rails.identifier')} name="identifier" />
      </Grid>
      <Grid item md={12} sm={12}>
        <SelectField label={t('rails.factory')} name="factoryId" options={factoryOptions} />
      </Grid>
      <Grid item md={12} sm={12}>
        <NumberField label={t('rails.capacity')} name="capacity" min={0} />
      </Grid>
      <Grid item md={12} sm={12}>
        <TextField label={t('rails.comment')} name="comment" />
      </Grid>
      <Grid item md={12} sm={12}>
        <NumberField label={t('rails.displayIndex')} name="displayIndex" />
      </Grid>
    </>
  );
};

export default RailEditorFormFields;
