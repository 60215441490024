import { Button } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React from 'react';
import { formatDate } from '../../../../common/helpers/date';
import formatNumberWithAccounting from '../../../../common/helpers/numberFormatHelpers';
import { AvailableDoor } from '../../../model';

/* eslint-disable react/display-name -- inline render cells without names */
export const getAvailableDoorsColumns = (
  t: (key: string) => string,
  buttonClass: string,
  onAddAvailableDoor: (availableDoor: AvailableDoor) => void
): GridColDef[] => [
  {
    field: '',
    headerName: '',
    width: 50,
    renderCell: ({ row }: GridRenderCellParams) => (
      <Button className={buttonClass} onClick={() => onAddAvailableDoor(row as AvailableDoor)}>
        <AddCircle fontSize="small" />
      </Button>
    ),
    sortable: false,
    editable: false,
  },
  {
    field: 'factoryName',
    headerName: t('factory'),
    width: 125,
  },
  {
    field: 'name',
    headerName: t('location'),
    width: 135,
    },
/*    {
      field: 'displayIndex',
      headerName: "displayIndex",
      width: 135,
    },*/
  {
    field: 'quantity',
    headerName: t('quantityInFMB'),
    type: 'number',
    width: 175,
    valueFormatter: ({ value }: GridValueFormatterParams) => `${formatNumberWithAccounting(Number(value), 0)}`,
  },
  {
    field: 'capacity',
    headerName: t('capacityInFMB'),
    type: 'number',
    width: 175,
    valueFormatter: ({ value }: GridValueFormatterParams) => `${formatNumberWithAccounting(Number(value), 0)}`,
  },
  {
    field: 'remainingCapacity',
    headerName: t('remainingCapacity'),
    type: 'number',
    width: 200,
    valueFormatter: ({ value }: GridValueFormatterParams) => `${formatNumberWithAccounting(Number(value), 0)}`,
  },
  {
    field: 'usage',
    headerName: t('usage'),
    type: 'number',
    width: 200,
    valueFormatter: ({ value }: GridValueFormatterParams) => Number(value).toFixed(0),
  },
  {
    field: 'duration',
    headerName: t('durationInDays'),
    type: 'number',
    width: 175,
    valueFormatter: ({ value }: GridValueFormatterParams) => Number(value).toFixed(0),
  },
  {
    field: 'inventoryFrom',
    headerName: t('inStorageSince'),
    type: 'customDate',
    width: 185,
    valueFormatter: (params: GridValueFormatterParams) => (dayjs(String(params.value)).isValid() ? formatDate(String(params.value)) : 'N/A'),
  },
  {
    field: 'products',
    headerName: t('products'),
    minWidth: 370,
    flex: 1,
  },
  {
    field: 'areProductsSimilarToInfeed',
    headerName: t('similarProducts'),
    minWidth: 190,
    valueFormatter: (params: GridValueFormatterParams) => (params.value ? t('common:yes') : t('common:no')),
  },
];
/* eslint-enable react/display-name -- inline render cells without names */
