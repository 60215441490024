import { Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

const PageWithTabs = ({
  children,
  tabs,
  currentTab,
}: {
  children: ReactElement;
  tabs: { path: string; label: string }[];
  currentTab: string;
}): React.ReactElement => {
  const history = useHistory();
  const handleClick = (_: unknown, path: string) => history.push(path);

  return (
    <>
      <Tabs value={currentTab} onChange={handleClick} textColor="primary" indicatorColor="primary">
        {tabs.map((option) => (
          <Tab key={option.path} value={option.path} label={option.label} />
        ))}
      </Tabs>
      {children}
    </>
  );
};

PageWithTabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, path: PropTypes.string })).isRequired,
  currentTab: PropTypes.string,
};

export default PageWithTabs;
