const shouldDisplayFeature = (name: string) : boolean => {
  const value = process.env[`REACT_APP_${name}`];
  if (typeof value === 'boolean') return value;
  if (typeof value === 'string') {
    return value.toLowerCase() === true.toString();
  }

  return false;
};

export default shouldDisplayFeature;
