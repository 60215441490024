import { Box, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Can } from '../../../abilities/AbilityContext';
import { CommonAbilityEnum } from '../../../abilities/CommonAbilityEnum';
import { ResourceEnum } from '../../../abilities/ResourceEnum';
import ConflictError from '../../../hooks/ConflictError';
import { useCreateLumberVariety, useEditLumberVariety, useGetLumberVarieties } from '../../hooks/lumberVarieties';
import { LumberVariety, LumberVarietyValues } from '../../model';
import useSettingsTabStyles from '../SettingsTabStyles';
import LumberVarietyEditor from './LumberVarietyEditor';
import { getColumns } from './LumberVarietyGridColumns';

const LumberVarietyPage: React.FunctionComponent = (): React.ReactElement => {
  const classes = useSettingsTabStyles();
  const { t } = useTranslation('settings');

  const [getLumberVarieties, { lumberVarieties = [] } = {}, isFetching] = useGetLumberVarieties();
  const [createLumberVariety] = useCreateLumberVariety();
  const [editLumberVariety] = useEditLumberVariety();

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [varietyToEdit, setVarietyToEdit] = useState<LumberVariety>({} as LumberVariety);

  const handleEdit = (variety: LumberVariety): void => {
    setVarietyToEdit(variety);
    setIsEditorOpen(true);
  };

  const handleClose = (): void => {
    setVarietyToEdit({} as LumberVariety);
    setIsEditorOpen(false);
  };

  const handleSubmit = (values: LumberVarietyValues) => {
    const submitCallBack = values.id ? editLumberVariety : createLumberVariety;
    submitCallBack(values)
      .then(() => {
        getLumberVarieties();
        handleClose();
      })
      .catch((error: Error) => {
        if (!(error instanceof ConflictError)) {
          throw error;
        }
      });
  };

  useEffect(() => {
    getLumberVarieties({ hideArchivedVarieties: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  return (
    <>
      <Can I={CommonAbilityEnum.Create} a={ResourceEnum.Settings}>
        <Box className={classes.header}>
          <Button onClick={() => setIsEditorOpen(true)} startIcon={<AddCircleIcon />}>
            {t('varieties.addNewVariety')}
          </Button>
        </Box>
      </Can>

      <Can I={CommonAbilityEnum.Update} a={ResourceEnum.Settings} passThrough>
        {(canUpdate: boolean) => (
          <DataGrid
            density="compact"
            rows={lumberVarieties}
            columns={getColumns(t, canUpdate, handleEdit)}
            loading={isFetching}
            autoHeight
            disableColumnFilter
            className={classes.grid}
          />
        )}
      </Can>

      {isEditorOpen && <LumberVarietyEditor lumberVariety={varietyToEdit} onClose={handleClose} onSubmit={handleSubmit} />}
    </>
  );
};

export default LumberVarietyPage;
