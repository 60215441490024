import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortOptionByLabel } from '../../../common/helpers/options';
import { NumberField, SelectField, TextField, CheckboxField } from '../../../layout/fields';
import useGetFactories from '../../hooks/factories/useGetFactories';
import { Factory, DoorValues } from '../../model';
import useGetDoorCanBeSetInactive from '../../hooks/doors/useGetDoorCanBeSetInactive';

const DoorEditorFormFields = (): ReactElement => {
  const { t } = useTranslation('settings');
  const { values, setFieldValue } = useFormikContext<DoorValues>();
  const [getFactories, { factories = [] } = {}] = useGetFactories();
  const factoryOptions = useMemo(() => factories.map((x: Factory) => ({ label: x.name, value: x.id, key: x.id })).sort(sortOptionByLabel), [factories]);
  const [getDoorCanBeSetInactive, inactiveAvailable] = useGetDoorCanBeSetInactive();
  const [doorCanBeSetInactive, setDoorCanBeSetInactive] = useState(false);

  type canBeSetInactiveResponseType = {
    inactiveAvailable: boolean;
  }

  useEffect(() => {
    getFactories();
    if (values.id) {    // Si un id porte est pass� en param�tre, il s'agit d'une modification, donc on fait la validation.
      getDoorCanBeSetInactive(values.id);
    }
    else {    // Si c'est poue une nouvelle porte, on peut activer ou d�sactiver d�s la cr�ation
      setDoorCanBeSetInactive(true);  
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  useEffect(() => {
    if (inactiveAvailable !== undefined) {    // si on a re�u un r�sultat qui n'est pas undefined dans la variable inactiveAvailable, on peut r�cup�rer la valeur.
      const tmp: canBeSetInactiveResponseType | undefined = inactiveAvailable;
      setDoorCanBeSetInactive(tmp?.inactiveAvailable as boolean);
    }
  }, [inactiveAvailable]);


  return (
    <>
      <Grid item md={6} sm={6}>
        <TextField label={t('doors.name')} name="name" />
      </Grid>
      <Grid item md={6} sm={6}>
        <CheckboxField label={t('doors.active', { context: 'feminine' })} name="active" disabled={!doorCanBeSetInactive} />
      </Grid>
      <Grid item md={6} sm={6}>
        <SelectField label={t('doors.factory')} name="factoryId" options={factoryOptions} />
      </Grid>
      <Grid item md={6} sm={6}>
        <TextField label={t('doors.identifier')} name="identifier" />
      </Grid>
      <Grid item md={6} sm={6}>
        <NumberField label={t('doors.capacity')} name="capacity" decimalScale={0} />
      </Grid>
      <Grid item md={12} sm={12}>
        <TextField label={t('doors.comment')} name="comment" />
      </Grid>
      <Grid item md={12} sm={12}>
        <NumberField label={t('doors.displayIndex')} name="displayIndex" />
      </Grid>
    </>
  );
};

export default DoorEditorFormFields;
