import { LumberVariety } from '../../planning/model';
import { Rail, RailValues } from '../model';

const emptyRail = {
  name: null,
  factoryId: null,
  capacity: null,
  comment: null,
  identifier: null,
  railLumberWeights: [],
};

export const mapRailToValues = (rail: Rail, lumberVarieties: LumberVariety[]): RailValues => {
  const values = { ...emptyRail, ...rail } as RailValues;

  if (!rail.railLumberWeights) {
    const newMappedLumberWeights = lumberVarieties.map((x) => ({ lumberVarietyId: x.id, weight: 5, printOrder: (x.printOrder !== undefined ? x.printOrder : 0) }));
    const sortedMappedLumberWeights = newMappedLumberWeights.sort((a, b) => a.printOrder - b.printOrder);

    return { ...values, railLumberWeights: sortedMappedLumberWeights };
  }
  else {  // Si ce rail poss�de d�j� des �l�ments dans son array Poids desssences
    for (const rlw of rail.railLumberWeights) {  // on boucle sur chaque �l�ment pour aller r�cup�rer le printOrder de la vari�t� de bois
      const lookupValue = lumberVarieties.find((lv) => lv.id === rlw.lumberVarietyId);  // Trouver la vari�t� de bois dans le array
      if (lookupValue !== null && lookupValue !== undefined) {
        rlw.printOrder = lookupValue.printOrder;
      }
      else {
        rlw.printOrder = 0;
      }
    }
    // Maintenant que le printOrder est rempli dans tous les �l�ments, on peut trier le tableau.
    const sortedMappedLumberWeights = rail.railLumberWeights.sort((a, b) => (a.printOrder && b.printOrder ? a.printOrder - b.printOrder : 0));

    return { ...values, railLumberWeights: sortedMappedLumberWeights };
  }

  return values;
};
