const transfers = {
  arrivalFactory: 'Arrival Factory',
  arrivalLocation: 'Arrival Location',
  arrivalLocationType: 'Arrival Location Type',
  dateAndTime: 'Date and Time',
  departureFactory: 'Departure Factory',
  departureLocation: 'Departure Location',
  departureLocationType: 'Departure Location Type',
  door: 'Door',
  dryingLine: 'Drying',
  factoryTransfer: 'Factory Transfer',
  fmb: 'FMB',
  productCharacteristic: 'Characteristic',
  productDescription: 'Product Description',
  productionLine: 'Production',
  quantityToMove: 'Quantity to Move',
  rail: 'Rail',
  relatedWorkOrder: 'Related WO',
  stackingLine: 'Stacking',
  totalQuantityToMove: 'Total Quantity to Move',
  transferLine: 'Transfer'
};
export default transfers;
