import { Tooltip } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { GridColDef, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React from 'react';
import { formatDateTime } from '../../common/helpers/date';
import formatNumberWithAccounting from '../../common/helpers/numberFormatHelpers';
import LocationTypeEnum from '../enums/LocationTypeEnum';

/* eslint-disable react/display-name -- inline render cells without names */
export const getTransfersListColumns = (t: (key: string) => string, factoryOptions: string[], classes: ClassNameMap): GridColDef[] => [
  {
    field: 'needFactoryTransfer',
    headerName: t('factoryTransfer'),
    width: 160,
    valueGetter: (params: GridValueGetterParams) => (params.value ? t('common:yes') : t('common:no')),
    editable: false,
    type: 'singleSelect',
    valueOptions: [t('common:yes'), t('common:no')],
  },
  {
    field: 'transferDate',
    headerName: t('dateAndTime'),
    width: 160,
    type: 'customDate',
    valueFormatter: (params: GridValueFormatterParams) => (dayjs(String(params.value)).isValid() ? formatDateTime(String(params.value)) : 'N/A'),
    editable: false,
  },
  {
    field: 'departureFactory',
    headerName: t('departureFactory'),
    width: 160,
    editable: false,
    type: 'singleSelect',
    valueOptions: factoryOptions,
  },
  {
    field: 'departureLocationType',
    headerName: t('departureLocationType'),
    width: 200,
    valueGetter: (params: GridValueGetterParams) => (params.value ? t(params.value.toString()) : 'N/A'),
    editable: false,
    type: 'singleSelect',
    valueOptions: Object.values(LocationTypeEnum).map((value) => t(value)),
  },
  {
    field: 'departureLocation',
    headerName: t('departureLocation'),
    width: 160,
    filterable: false,
    editable: false,
  },
  {
    field: 'arrivalFactory',
    headerName: t('arrivalFactory'),
    width: 160,
    editable: false,
    type: 'singleSelect',
    valueOptions: factoryOptions,
  },
  {
    field: 'arrivalLocationType',
    headerName: t('arrivalLocationType'),
    width: 200,
    valueGetter: (params: GridValueGetterParams) => (params.value ? t(params.value.toString()) : 'N/A'),
    editable: false,
    type: 'singleSelect',
    valueOptions: Object.values(LocationTypeEnum).map((value) => t(value)),
  },
  {
    field: 'arrivalLocation',
    headerName: t('arrivalLocation'),
    width: 160,
    filterable: false,
    editable: false,
  },
  {
    field: 'productDescription',
    headerName: t('productDescription'),
    width: 150,
    filterable: false,
    editable: false,
    renderCell: (params: GridValueFormatterParams) => (
      <Tooltip title={params.value || ''}>
        <span className={classes.tooltipText}>{params.value}</span>
      </Tooltip>
    ),
  },
  {
    field: 'productCharacteristic',
    headerName: t('productCharacteristic'),
    width: 150,
    filterable: false,
    editable: false,
  },
  {
    field: 'relatedWorkOrder',
    headerName: t('relatedWorkOrder'),
    width: 150,
    filterable: false,
    editable: false,
  },
  {
    field: 'quantity',
    headerName: t('quantityToMove'),
    minWidth: 160,
    valueFormatter: ({ value }: GridValueFormatterParams) => `${formatNumberWithAccounting(Number(value), 0)}`,
    filterable: false,
    editable: false,
  },
];
/* eslint-enable react/display-name -- inline render cells without names */
