import { SchedulerEventModel, SchedulerEventStore } from '@bryntum/schedulerpro';
import { CollectionFilterConfig, SchedulerResourceModel, SchedulerResourceStore } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { Box, Grid, MenuItem, Popover, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Form, Formik } from 'formik';
import React, { Fragment, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sortOptionByLabel } from '../../../common/helpers/options';
import MultiSelectField from '../../../layout/fields/MultiSelectField';
import useFilterStyles from '../../../layout/scheduler/filterStyles';
import { mapRecipesToOptions } from '../../../settings/helpers/recipesHelpers';
import useGetFactories from '../../../settings/hooks/factories/useGetFactories';
import { useGetRecipes } from '../../../settings/hooks/recipes';
import { Factory } from '../../../settings/model';
import { eventHasDependencies } from '../../helpers/eventHelpers';
import { PlanifEvent } from '../../model/Event';
import { PlanifResource } from '../../model/Resource';

const allFactoriesKey = 'allFactories';
const allFactoriesValue = 99;

const availableEventFilters = {
  statuses: 'statuses',
};

const availableResourceFilters = {
  factory: 'factory',
  resourceIds: 'resourceIds',
  recipeIds: 'recipeIds',
};

const availableStatuses = {
  locked: 'locked',
  unlocked: 'unlocked',
  linked: 'linked',
  unlinked: 'unlinked',
  overlap: 'overlap',
};

interface Values {
  resourceIds: number[];
  recipeIds: number[];
  statuses: string[];
}

type EventFiltersProps = {
  eventStore: SchedulerEventStore;
  resourceStore: SchedulerResourceStore;
};

const EventFilters = ({ eventStore, resourceStore }: EventFiltersProps): ReactElement => {
  const classes = useFilterStyles();
  const { t } = useTranslation('planning');
  const [getRecipes, { recipes = [] } = {}] = useGetRecipes();
  const [getFactories, { factories = [] } = {}] = useGetFactories();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const resourceOptions = useMemo(
    () => ((resourceStore.data || []) as PlanifResource[]).map((x: PlanifResource) => ({ key: x.id, label: x.name, value: x.id })).sort(sortOptionByLabel),
    [resourceStore.data]
  );
  const factoryOptions = useMemo(
    () => [
      { label: t(allFactoriesKey), value: allFactoriesValue, key: allFactoriesValue },
      ...factories.map((x: Factory) => ({ label: x.name, value: x.id, key: x.id })).sort(sortOptionByLabel),
    ],
    [factories, t]
  );

  const statusOptions = useMemo(() => Object.values(availableStatuses).map((status: string) => ({ label: t(status), value: status, key: status })), [t]);

  const activeFilters = useMemo(() => {
    const filters = {} as Values;

    if (isOpen) {
      eventStore.filters.forEach((filter: CollectionFilterConfig) => {
        filters[filter.id as keyof Values] = filter.value;
      });
      resourceStore.filters.forEach((filter: CollectionFilterConfig) => {
        filters[filter.id as keyof Values] = filter.value;
      });
    }

    return filters;
  }, [eventStore.filters, resourceStore.filters, isOpen]);

  const initialValues = useMemo(() => {
    return {
      [availableResourceFilters.resourceIds]: [],
      [availableResourceFilters.recipeIds]: [],
      [availableEventFilters.statuses]: [],
      ...activeFilters,
    } as Values;
  }, [activeFilters]);

  const isEventOverlapping = useCallback(
    (eventRecord: PlanifEvent): boolean => {
      const resourceEvents = eventStore.getEventsForResource(eventRecord.resourceId);
      return !!resourceEvents.find(
        (value: SchedulerEventModel) =>
          value.id !== eventRecord.id &&
          ((value.startDate >= eventRecord.startDate && value.startDate < eventRecord.endDate) ||
            (value.endDate > eventRecord.startDate && value.endDate <= eventRecord.endDate))
      );
    },
    [eventStore]
  );

  useEffect(() => {
    getRecipes();
    getFactories();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (values: Values) => {
    Object.values(availableEventFilters).forEach((filter: string) => eventStore.removeFilter(filter));
    Object.values(availableResourceFilters).forEach((filter: string) => resourceStore.removeFilter(filter));

    if (values.resourceIds.length) {
      resourceStore.filter({
        id: availableResourceFilters.resourceIds,
        filterBy: (record: SchedulerResourceModel) => values.resourceIds.includes(record.id as number),
        value: values.resourceIds,
      });
    }

    if (values.recipeIds.length) {
      resourceStore.filter({
        id: availableResourceFilters.recipeIds,
        filterBy: (resource: SchedulerResourceModel) =>
          resource
            .getEvents()
            .map((x: SchedulerEventModel) => x as PlanifEvent)
            .some(
              (x: PlanifEvent) => x.endDate >= new Date() && x.infeedProducts.map((x) => x.recipeId).some((recipeId) => values.recipeIds.includes(recipeId))
            ),
        value: values.recipeIds,
      });
    }

    if (values.statuses.length) {
      eventStore.filter({
        id: availableEventFilters.statuses,
        filterBy: (record: PlanifEvent) => {
          let result = true;
          if (values.statuses.includes(availableStatuses.locked)) result = result && record.isLocked;
          if (values.statuses.includes(availableStatuses.unlocked)) result = result && !record.isLocked;
          if (values.statuses.includes(availableStatuses.linked)) result = result && eventHasDependencies(record);
          if (values.statuses.includes(availableStatuses.unlinked)) result = result && !eventHasDependencies(record);
          if (values.statuses.includes(availableStatuses.overlap)) result = result && isEventOverlapping(record);
          return result;
        },
        value: values.statuses,
      });
    }

    setAnchorEl(null);
  };

  const handleClearAll = () => {
    Object.values(availableEventFilters).forEach((filter: string) => eventStore.removeFilter(filter));
    Object.values(availableResourceFilters).forEach((filter: string) => resourceStore.removeFilter(filter));
    setAnchorEl(null);
  };

  const handleFactoryChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    resourceStore.removeFilter(availableResourceFilters.factory);

    if (event.target.value != allFactoriesValue) {
      resourceStore.filter({
        id: availableResourceFilters.factory,
        property: 'factoryId',
        value: event.target.value,
        operator: '=',
      });
    }
  };

  return (
    <Fragment>
      <Box className={classes.boxContent}>
        <Button className={classes.button}>
          <Select defaultValue={allFactoriesValue} onChange={handleFactoryChange} disableUnderline className={classes.select}>
            {factoryOptions.map(({ label, value, key }: { label: string; value: number; key: number }) => (
              <MenuItem key={key} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Button>
        <Button
          className={classes.button}
          onClick={handleClickOpen}
          variant="outlined"
          startIcon={<FilterListIcon />}
          endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
          {t('filterBy')}
        </Button>
      </Box>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <Grid className={classes.content} container spacing={2}>
              <Grid item>
                <MultiSelectField
                  label={t('resource')}
                  name={availableResourceFilters.resourceIds}
                  id={availableResourceFilters.resourceIds}
                  options={resourceOptions}
                />
              </Grid>
              <Grid item>
                <MultiSelectField
                  label={t('recipe')}
                  name={availableResourceFilters.recipeIds}
                  id={availableResourceFilters.recipeIds}
                  options={mapRecipesToOptions(recipes)}
                />
              </Grid>
              <Grid item>
                <MultiSelectField label={t('status')} name={availableEventFilters.statuses} id={availableEventFilters.statuses} options={statusOptions} />
              </Grid>
            </Grid>
            <Box className={classes.footer}>
              <Button className={classes.button} onClick={handleClearAll} startIcon={<CancelIcon />}>
                {t('clearAll')}
              </Button>
              <Button className={classes.button} variant="contained" color="primary" type="submit">
                {t('apply')}
              </Button>
            </Box>
          </Form>
        </Formik>
      </Popover>
    </Fragment>
  );
};

export default EventFilters;
