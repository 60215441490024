import { Box, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Can } from '../../../abilities/AbilityContext';
import { CommonAbilityEnum } from '../../../abilities/CommonAbilityEnum';
import { ResourceEnum } from '../../../abilities/ResourceEnum';
import ConflictError from '../../../hooks/ConflictError';
import { useCreateKiln, useEditKiln, useGetKilns } from '../../hooks/kilns';
import { Kiln, KilnValues } from '../../model';
import useSettingsTabStyles from '../SettingsTabStyles';
import KilnEditor from './KilnEditor';
import { getColumns } from './KilnGridColumns';

const KilnPage: React.FunctionComponent = (): React.ReactElement => {
  const classes = useSettingsTabStyles();
  const { t } = useTranslation('settings');

  const [getKilns, { kilns = [] } = {}, isFetching] = useGetKilns();
  const [createKiln] = useCreateKiln();
  const [editKiln] = useEditKiln();

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [kilnToEdit, setKilnToEdit] = useState<Kiln>({} as Kiln);

  const handleEdit = (kiln: Kiln): void => {
    setKilnToEdit(kiln);
    setIsEditorOpen(true);
  };

  const handleClose = (): void => {
    setKilnToEdit({} as Kiln);
    setIsEditorOpen(false);
  };

  const handleSubmit = (values: KilnValues) => {
    const submitCallBack = values.id ? editKiln : createKiln;
    submitCallBack(values)
      .then(() => {
        getKilns();
        handleClose();
      })
      .catch((error: Error) => {
        if (!(error instanceof ConflictError)) {
          throw error;
        }
      });
  };

  useEffect(() => {
    getKilns();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  return (
    <>
      <Can I={CommonAbilityEnum.Create} a={ResourceEnum.Settings}>
        <Box className={classes.header}>
          <Button onClick={() => setIsEditorOpen(true)} startIcon={<AddCircleIcon />}>
            {t('kilns.addNewKiln')}
          </Button>
        </Box>
      </Can>
      
      <Can I={CommonAbilityEnum.Update} a={ResourceEnum.Settings} passThrough>
        {(canUpdate: boolean) => (
          <DataGrid
            density="compact"
            rows={kilns}
            columns={getColumns(t, canUpdate, handleEdit)}
            loading={isFetching}
            autoHeight
            disableColumnFilter
            className={classes.grid}
          />
        )}
      </Can>

      {isEditorOpen && <KilnEditor kiln={kilnToEdit} onClose={handleClose} onSubmit={handleSubmit} />}
    </>
  );
};

export default KilnPage;
