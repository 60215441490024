import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react';
import { CollectionFilterConfig, Toolbar, WidgetConfig } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { createStyles, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { TFunction } from 'i18next';
import _uniq from 'lodash.uniq';
import _uniqBy from 'lodash.uniqby';
import React, { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../common/helpers/date';
import formatNumberWithAccounting from '../../common/helpers/numberFormatHelpers';
import CssTemplate from '../../CssTemplate';
import { englishLocalesCode } from '../../i18n';
import { dependencyFilter, ExtendedCollectionFilterConfig } from '../components/Scheduler';
import { EventTypeEnum } from '../enums';
import { PlanifEvent } from '../model/Event';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
    },
    quantity: {
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    grid: {
      backgroundColor: theme.palette.primary.light,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    divider: {
      marginTop: theme.spacing(1),
      background: theme.palette.primary.dark,
      height: '2px',
    },
    text: {
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    label: {
      color: theme.palette.text.secondary,
      display: 'inline',
      whiteSpace: 'nowrap',
    },
  })
);

type PlanifEventWithResourceName = PlanifEvent & { resourceName: string };

const getStackingFields = (eventRecord: PlanifEvent, classes: ClassNameMap, formatLabel: (label: string) => string): ReactElement => {
  const rails = _uniqBy(eventRecord.outfeedProducts, 'locationName')
    .map((x) => x.locationName)
    .filter(Boolean)
    .join(', ');
  return (
    <>
      <Grid item sm={3}>
        <Typography className={classes.label}>{formatLabel('rails')}</Typography>
      </Grid>
      <Grid item sm={9}>
        <Typography className={classes.text} variant="body2">
          {rails}
        </Typography>
      </Grid>
    </>
  );
};

const getRelatedEvents = (eventRecord: PlanifEvent) =>
  (eventRecord.dependencyStore.getEventDependencies(eventRecord) || []).map((x) => {
    const dependencyEvent = (x.from === eventRecord.id ? x.toEvent : x.fromEvent) as PlanifEvent;
    const resource = dependencyEvent.getResource(dependencyEvent.resourceId as string);
    return {
      startDate: dependencyEvent.getData('startDate'),
      workOrder: dependencyEvent.getData('workOrder'),
      resourceName: resource.name,
      id: dependencyEvent.getData('id'),
    } as PlanifEventWithResourceName;
  });

const getDryingFields = (eventRecord: PlanifEvent, classes: ClassNameMap, formatLabel: (label: string) => string): ReactElement => {
  const doors = _uniqBy(eventRecord.outfeedProducts, 'locationName')
    .map((x) => x.locationName)
    .filter(Boolean)
    .join(', ');
  const relatedProductionEvents = getRelatedEvents(eventRecord);
  return (
    <>
      <Grid item sm={3}>
        <Typography className={classes.label}>{formatLabel('doors')}</Typography>
      </Grid>
      <Grid item sm={9}>
        <Typography className={classes.text} variant="body2">
          {doors}
        </Typography>
      </Grid>
      {!!relatedProductionEvents.length && (
        <Grid item sm={12}>
          <Divider className={classes.divider} />
        </Grid>
      )}
      {relatedProductionEvents.map((relatedProductionEvent) => (
        <>
          <Grid item sm={3}>
            <Typography className={classes.label}>{formatLabel('productionLine')}</Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography className={classes.text} variant="body2">
              {relatedProductionEvent.resourceName}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography className={classes.label}>{formatLabel('productionLineTask')}</Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography className={classes.text} variant="body2">
              {`#${relatedProductionEvent.id}`}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography className={classes.label}>{formatLabel('productionStartDate')}</Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography className={classes.text} variant="body2">
              {formatDateTime(relatedProductionEvent.startDate)}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography className={classes.label}>{formatLabel('workOrderNumber')}</Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography className={classes.text} variant="body2">
              {relatedProductionEvent.workOrder}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Divider className={classes.divider} />
          </Grid>
        </>
      ))}
    </>
  );
};

const getOrderFields = (eventRecord: PlanifEvent, classes: ClassNameMap, formatLabel: (label: string) => string): ReactElement => {
  const doors = _uniqBy(eventRecord.infeedProducts, 'location')
    .map((x) => x.locationName)
    .filter(Boolean)
    .join(', ');
  const relatedDryingEvents = getRelatedEvents(eventRecord);
  return (
    <>
      <Grid item sm={3}>
        <Typography className={classes.label}>{formatLabel('doors')}</Typography>
      </Grid>
      <Grid item sm={9}>
        <Typography className={classes.text} variant="body2">
          {doors}
        </Typography>
      </Grid>
      {!!relatedDryingEvents.length && (
        <Grid item sm={12}>
          <Divider className={classes.divider} />
        </Grid>
      )}
      {relatedDryingEvents.map((relatedDryingEvent) => (
        <>
          <Grid item sm={3}>
            <Typography className={classes.label}>{formatLabel('kiln')}</Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography className={classes.text} variant="body2">
              {relatedDryingEvent.resourceName}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography className={classes.label}>{formatLabel('dryingTask')}</Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography className={classes.text} variant="body2">
              {`#${relatedDryingEvent.id}`}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography className={classes.label}>{formatLabel('dryingStop')}</Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography className={classes.text} variant="body2">
              {formatDateTime(relatedDryingEvent.endDate)}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography className={classes.label}>{formatLabel('workOrderNumber')}</Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography className={classes.text} variant="body2">
              {relatedDryingEvent.workOrder}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Divider className={classes.divider} />
          </Grid>
        </>
      ))}
    </>
  );
};

const getEventTypeSpecificFields = (eventRecord: PlanifEvent, classes: ClassNameMap, formatLabel: (label: string) => string): ReactElement | null => {
  switch (eventRecord.eventType) {
    case EventTypeEnum.Drying:
      return getDryingFields(eventRecord, classes, formatLabel);
    case EventTypeEnum.Order:
      return getOrderFields(eventRecord, classes, formatLabel);
    case EventTypeEnum.Stacking:
      return getStackingFields(eventRecord, classes, formatLabel);
    default:
      return null;
  }
};

const EventTooltipTemplate = ({ eventRecord }: { eventRecord: PlanifEvent }): ReactElement => {
  const { t } = useTranslation('planning');
  const classes = useStyles();

  const formatLabel = (label: string): string => `${t(label)}:`;
  const isMaintenanceTask = eventRecord.eventType === EventTypeEnum.Maintenance;

  return (
    <Grid container className={classes.grid} spacing={1}>
      {isMaintenanceTask ? (
        <Grid item sm={12}>
          <Typography className={classes.title} variant="h6">
            {eventRecord.name}
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item sm={9}>
            <Typography className={classes.title} variant="h6">
              {eventRecord.name}
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography className={classes.quantity} variant="h6">
              {` ${formatNumberWithAccounting(eventRecord.woodQuantity || 0, 0)} ${t('fmb')}`}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item sm={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item sm={3}>
        <Typography className={classes.label}>{formatLabel('startDate')}</Typography>
      </Grid>
      <Grid item sm={9}>
        <Typography className={classes.text} variant="body2">
          {formatDateTime(eventRecord.startDate)}
        </Typography>
      </Grid>
      <Grid item sm={3}>
        <Typography className={classes.label}>{formatLabel('endDate')}</Typography>
      </Grid>
      <Grid item sm={9}>
        <Typography className={classes.text} variant="body2">
          {formatDateTime(eventRecord.endDate)}
        </Typography>
      </Grid>
      {!isMaintenanceTask && (
        <>
          <Grid item sm={3}>
            <Typography className={classes.label}>{formatLabel('recipe')}</Typography>
          </Grid>
          <Grid item sm={9}>
            <Typography className={classes.text} variant="body2">
              {_uniq(eventRecord.infeedProducts.map((x) => x.recipeName)).join(', ')}
            </Typography>
          </Grid>
          {getEventTypeSpecificFields(eventRecord, classes, formatLabel)}
        </>
      )}
      <Grid item sm={3}>
        <Typography className={classes.label}>{formatLabel('comment')}</Typography>
      </Grid>
      <Grid item sm={9}>
        <Typography className={classes.text} variant="body2">
          {eventRecord.tooltipNote}
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <Divider className={classes.divider} />
      </Grid>
    </Grid>
  );
};

export const getEventTooltip = (
  showDependencies: (eventRecord: PlanifEvent) => void,
  hideDependencies: () => void,
  t: TFunction,
  schedulerRef: React.MutableRefObject<typeof BryntumSchedulerPro | null>
): unknown => ({
  allowOver: true,
  hoverDelay: 1000,
  style: 'max-width: 40em',
  header: {
    cls: 'tooltip-header',
  },
  bbar: {
    contentElementCls: 'tooltip-footer',
    itemCls: 'b-transparent',
    items: [
      {
        text: t('planning:showLinkedTask'),
        icon: 'b-fa b-fa-fw b-fa-eye',
        onClick() {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- no idea where up comes from here
          // @ts-ignore
          const tooltip = this.up('tooltip');
          showDependencies(tooltip.eventRecord);
          tooltip.hide();
        },
      },
      {
        text: t('planning:hideLinkedTask'),
        icon: 'b-fa b-fa-fw b-fa-eye-slash',
        onClick() {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- no idea where up comes from here
          // @ts-ignore
          const tooltip = this.up('tooltip');
          hideDependencies();
          tooltip.hide();
        },
      },
    ],
  },
  listeners: {
    beforeShow: ({ source }: { source: { eventRecord: PlanifEvent; bbar: Toolbar } }) => {
      const [firstItem, secondItem] = source.bbar.items as WidgetConfig[];

      if (source.eventRecord.eventType === EventTypeEnum.Maintenance || source.eventRecord.eventType === EventTypeEnum.Stacking) {
        firstItem.hidden = false;
        firstItem.disabled = true;
        secondItem.hidden = true;
      } else {
        const { dependencyStore } = schedulerRef.current?.instance;
        const activeDependencyFilter = dependencyStore?.filters.find((x: CollectionFilterConfig) => x.id == dependencyFilter) as ExtendedCollectionFilterConfig;
        const isSelected = activeDependencyFilter?.value === source.eventRecord.id;
        firstItem.hidden = isSelected;
        firstItem.disabled = false;
        secondItem.hidden = !isSelected;
      }
    },
  },
  template: ({ eventRecord }: { eventRecord: PlanifEvent }) => {
    return ReactDOMServer.renderToString(
      <CssTemplate languageCode={englishLocalesCode}>
        <EventTooltipTemplate eventRecord={eventRecord} />
      </CssTemplate>
    );
  },
});
