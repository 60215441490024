import { Box, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Can } from '../../../abilities/AbilityContext';
import { CommonAbilityEnum } from '../../../abilities/CommonAbilityEnum';
import { ResourceEnum } from '../../../abilities/ResourceEnum';
import ConflictError from '../../../hooks/ConflictError';
import { useCreateRail, useEditRail, useGetRails } from '../../hooks/rails';
import { Rail, RailValues } from '../../model';
import useSettingsTabStyles from '../SettingsTabStyles';
import RailEditor from './RailEditor';
import { getColumns } from './RailGridColumns';

const RailPage: React.FunctionComponent = (): React.ReactElement => {
  const classes = useSettingsTabStyles();
  const { t } = useTranslation('settings');

  const [getRails, { rails = [] } = {}, isFetching] = useGetRails();
  const [createRail] = useCreateRail();
  const [editRail] = useEditRail();

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [railToEdit, setRailToEdit] = useState<Rail>({} as Rail);

  const handleEdit = (rail: Rail): void => {
    setRailToEdit(rail);
    setIsEditorOpen(true);
  };

  const handleClose = (): void => {
    setRailToEdit({} as Rail);
    setIsEditorOpen(false);
  };

  const handleSubmit = (values: RailValues) => {
    const submitCallBack = values.id ? editRail : createRail;
    submitCallBack(values)
      .then(() => {
        getRails();
        handleClose();
      })
      .catch((error: Error) => {
        if (!(error instanceof ConflictError)) {
          throw error;
        }
      });
  };

  useEffect(() => {
    getRails();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- agents aren't memoized, only fetch on mount
  }, []);

  return (
    <>
      <Can I={CommonAbilityEnum.Create} a={ResourceEnum.Settings}>
        <Box className={classes.header}>
          <Button onClick={() => setIsEditorOpen(true)} startIcon={<AddCircleIcon />}>
            {t('rails.addNewRail')}
          </Button>
        </Box>
      </Can>

      <Can I={CommonAbilityEnum.Update} a={ResourceEnum.Settings} passThrough>
        {(canUpdate: boolean) => (
          <DataGrid
            density="compact"
            rows={rails}
            columns={getColumns(t, canUpdate, handleEdit)}
            loading={isFetching}
            autoHeight
            disableColumnFilter
            className={classes.grid}
          />
        )}
      </Can>

      {isEditorOpen && <RailEditor rail={railToEdit} onClose={handleClose} onSubmit={handleSubmit} />}
    </>
  );
};

export default RailPage;
