import { SchedulerResourceStore } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { Box, Button, MenuItem, Select } from '@material-ui/core';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sortOptionByLabel } from '../../../common/helpers/options';
import useFilterStyles from '../../../layout/scheduler/filterStyles';
import useGetFactories from '../../../settings/hooks/factories/useGetFactories';
import { Factory } from '../../../settings/model';

const allFactoriesKey = 'allFactories';
const allFactoriesValue = 99;

const availableResourceFilters = {
  factory: 'factory',
};

type EventFiltersProps = {
  resourceStore: SchedulerResourceStore;
};

const ResourceFilters = ({ resourceStore }: EventFiltersProps): ReactElement => {
  const classes = useFilterStyles();
  const { t } = useTranslation('inventory');
  const [getFactories, { factories = [] } = {}] = useGetFactories();
  const factoryOptions = useMemo(
    () => [
      { label: t(allFactoriesKey), value: allFactoriesValue, key: allFactoriesValue },
      ...factories.map((x: Factory) => ({ label: x.name, value: x.id, key: x.id })).sort(sortOptionByLabel),
    ],
    [factories, t]
  );

  useEffect(() => {
    getFactories();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  const handleFactoryChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    resourceStore.removeFilter(availableResourceFilters.factory);

    if (event.target.value != allFactoriesValue) {
      resourceStore.filter({
        id: availableResourceFilters.factory,
        property: 'factoryId',
        value: event.target.value,
        operator: '=',
      });
    }
  };

  return (
    <Box className={classes.boxContent}>
      <Button className={classes.button}>
        <Select defaultValue={allFactoriesValue} onChange={handleFactoryChange} disableUnderline className={classes.select}>
          {factoryOptions.map(({ label, value, key }: { label: string; value: number; key: number }) => (
            <MenuItem key={key} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Button>
    </Box>
  );
};

export default ResourceFilters;
