import { DataField, DataFieldConfig, EventModel } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { EventFlag, OutfeedProduct } from '.';
import { InfeedProduct } from './InfeedProduct';

type PlanifEventCustomFields = {
  detailedComment: string;
  eventType: string;
  eventSubtype: string;
  initialWoodMoisture: number | undefined;
  isLocked: boolean;
  manualLock: boolean | null;
  targetWoodMoisture: number | undefined;
  tooltipNote: string;
  woodQuantity: number | undefined;
  infeedProducts: InfeedProduct[];
  outfeedProducts: OutfeedProduct[];
  workOrder: number | undefined;
  eventFlag: EventFlag | undefined;
  maxQuantity: number | undefined;
};

export class PlanifEventModel extends EventModel {
  // eslint-disable-next-line @typescript-eslint/ban-types -- type from bryntum
  static get fields(): object[] | DataField[] | Partial<DataFieldConfig>[] {
    return [
      'detailedComment',
      'initialWoodMoisture',
      'isLocked',
      'eventType',
      'eventSubtype',
      'targetWoodMoisture',
      'tooltipNote',
      'woodQuantity',
      'infeedProducts',
      'outfeedProducts',
      'workOrder',
      'eventFlag',
      'maxQuantity'
      // eslint-disable-next-line @typescript-eslint/ban-types -- this is the way
    ] as unknown[] as object[];
  }
}

export const maintenanceEventColor = '373737';
export const balancingEventColor = 'c4c1c1';

export type PlanifEvent = PlanifEventCustomFields & PlanifEventModel;
