/* eslint-disable react/display-name -- pure function returning TSX */
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';
import { Rail } from '../../model';

export const getColumns = (
  t: (key: string) => string,
  canUpdate: boolean,
  onEditClick: (row: Rail) => void,
): GridColDef[] => {
  const columns: GridColDef[] = [];

  if (canUpdate) {
    columns.push({
      field: '',
      headerName: t('common:actions'),
      width: 100,
      sortable: false,
      editable: false,
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          {canUpdate && (
            <Button onClick={() => onEditClick(row as Rail)}>
              <EditIcon fontSize="small" />
            </Button>
          )}
        </>
      ),
    });
  }

  columns.push(
    {
      field: 'name',
      headerName: t('rails.name'),
      width: 125,
      sortable: false,
      editable: false,
    },
    {
      field: 'factoryName',
      headerName: t('rails.factory'),
      width: 125,
      sortable: false,
      editable: false,
    },
    {
      field: 'identifier',
      headerName: t('rails.identifier'),
      width: 125,
      align: 'right',
      sortable: false,
      editable: false,
    },
    {
      field: 'capacity',
      headerName: t('rails.capacity'),
      width: 125,
      type: 'number',
      sortable: false,
      editable: false,
    },
    {
      field: 'displayIndex',
      headerName: t('rails.displayIndex'),
      width: 300,
      sortable: true,
      editable: false,
    },
    {
      field: 'comment',
      headerName: t('rails.comment'),
      width: 300,
      sortable: false,
      editable: false,
    },
  );

  return columns;
};

export default { getColumns };

/* eslint-enable react/display-name -- reactivate */
