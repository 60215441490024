import DayJsUtils from '@date-io/dayjs';
import { makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import _get from 'lodash.get';
import React from 'react';
import KeyboardDatePickerField from './KeyboardDatePickerField';

const fromDatePickerStyle = makeStyles(() => ({
  root: {
    paddingRight: '0.25rem',
    maxWidth: '50%',
  },
}));

const toDatePickerStyle = makeStyles(() => ({
  root: {
    paddingLeft: '0.25rem',
    maxWidth: '50%',
  },
}));

const toLabelStyle = makeStyles(() => ({
  root: {
    paddingLeft: '0.25rem',
  },
}));

type DateRangePickerFieldProps = {
  endDateLabel: string;
  endDateName: string;
  startDateLabel: string;
  startDateName: string;
  onChange?: (startDate: string, endDate: string) => void;
};
const DateRangePickerField = ({
  endDateLabel,
  endDateName,
  startDateLabel,
  startDateName,
  onChange = () => undefined,
}: DateRangePickerFieldProps): JSX.Element => {
  const { values } = useFormikContext();
  const handleChange = (startDate = _get(values, startDateName), endDate = _get(values, endDateName)) => onChange(startDate, endDate);
  return (
    <MuiPickersUtilsProvider utils={DayJsUtils}>
      <KeyboardDatePickerField
        classes={fromDatePickerStyle()}
        label={startDateLabel}
        maxDate={_get(values, endDateName)}
        name={startDateName}
        onChange={(date) => handleChange(date)}
      />
      <KeyboardDatePickerField
        classes={toDatePickerStyle()}
        label={endDateLabel}
        labelClasses={toLabelStyle()}
        minDate={_get(values, startDateName)}
        name={endDateName}
        onChange={(date) => handleChange(undefined, date)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateRangePickerField;
