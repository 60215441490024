import { makeStyles, Theme } from '@material-ui/core';

const useListStyles = makeStyles((theme: Theme) => ({
  grid: {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderDraggableContainer, .MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer':
      {
        flexDirection: 'row',
      },
    '& .Mui-error': {
      backgroundColor: `rgb(126,10,150, 0.1)`,
      color: '#ff4343',
    },
    '& .kplanner-infeed-missing': {
      borderBottomStyle: 'inset',
      borderWidth: '3px',
      borderTopStyle: 'none',
      borderLeftStyle: 'none',
      borderRightStyle: 'none',
      borderColor: theme.palette.secondary.main,
    },
  },
  listTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  chips: {
    marginLeft: theme.spacing(0.5),
  },
  button: {
    minWidth: 0,
  },
  productDescription: {
    display: 'flex',
    alignItems: 'center',
  },
  missingIcon: {
    marginLeft: theme.spacing(0.5),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

export default useListStyles;
