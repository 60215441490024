import { makeStyles, Snackbar } from '@material-ui/core';
import Alert, { Color } from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';

const alertStyles = makeStyles({
  root: {
    bottom: '4.5rem',
  },
});

type ToastProps = {
  message?: string | null;
  severity?: Color;
  onClose: () => void;
}

const Toast = ({ message, severity, onClose }: ToastProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = (): void => {
    onClose();
    setIsOpen(false);
  };

  useEffect(() => {
    if (message) setIsOpen(true);
  }, [message]);

  return (
    <Snackbar
      classes={alertStyles()}
      open={isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert onClose={handleClose} severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
