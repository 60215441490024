import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { AirDryKiln } from '../../model';

const url = '/api/v1/air-dry-kiln';

export type updateAirDryKilnResponse = { airDryKiln: AirDryKiln };

const updateAirDryKiln = (updateRequest: AirDryKiln): Promise<AxiosResponse<updateAirDryKilnResponse>> => axios.put(url, updateRequest);

const useUpdateAirDryKilns = (): [Agent<updateAirDryKilnResponse>, updateAirDryKilnResponse | undefined, boolean, () => void] =>
  useAgent<updateAirDryKilnResponse>((airDryKiln: AirDryKiln) => updateAirDryKiln(airDryKiln));

export default useUpdateAirDryKilns;
