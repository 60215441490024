import { Typography } from '@material-ui/core';
import { DataGrid, GridCellEditCommitParams, GridCellValue, GridEditRowsModel } from '@mui/x-data-grid';
import { useFormikContext } from 'formik';
import _uniq from 'lodash.uniq';
import React, { ReactElement, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import customDateColumnType from '../../../../layout/grid/CustomDateColumnType';
import { EventSubtypeEnum } from '../../../enums/EventSubtypeEnum';
import { useGetAvailableGreenProducts, useGetAvailableGreenProductsToBalance } from '../../../hooks';
import { AvailableProduct, EventValues, InfeedProduct, InfeedValues } from '../../../model';
import { EventEditorContext } from '../EventEditorContext';
import { LocationTypeEnum, useAvailableLocationsHelpers } from '../common/useAvailableLocationsHelpers';
import useListStyles from '../listStyles';
import { getAvailableGreenProductsColumns } from './AvailableProductsColumns';
import { getSelectedGreenProductsColumns } from './SelectedProductsColumns';

type productKey = {
  factoryId: number;
  locationId: number;
  locationType: string;
  productId: number;
  lotId: string;
};

type AvailableGreenProductsProps = {
  disabled?: boolean;
};

const AvailableGreenProducts = ({ disabled = false }: AvailableGreenProductsProps): ReactElement => {
  const classes = useListStyles();
  const { t } = useTranslation('planning');
  const { setFieldValue, values } = useFormikContext<EventValues>();
  const { recipeIds, selectedLocationIdsMemory, setSelectedLocationIdsMemory } = useContext(EventEditorContext);
  const [editRowsModel, setEditRowsModel] = React.useState<GridEditRowsModel>({});
  const { refreshLocationsOutfeedProducts: refreshRailsOutfeedProducts } = useAvailableLocationsHelpers(LocationTypeEnum.Rail);
  const { refreshLocationsOutfeedProducts: refreshDoorsOutfeedProducts } = useAvailableLocationsHelpers(LocationTypeEnum.Door);
  const [getAvailableGreenProducts, { availableProducts: availableGreenProducts = [] } = {}, isFetchingGreen, clearAvailableGreenProducts] =
    useGetAvailableGreenProducts();
  const [
    getAvailableProductsToBalance,
    { availableProducts: availableGreenProductsToBalance = [] } = {},
    isFetchingBalance,
    clearAvailableGreenToBalanceProducts,
  ] = useGetAvailableGreenProductsToBalance();

  const totalSelectedQuantity = useMemo(() => values.infeedProducts.reduce((acc, product) => acc + product.quantity, 0), [values.infeedProducts]);

  const isSameProduct = useCallback((x: productKey, infeedProduct: productKey) => {
    return (
      infeedProduct.productId === x.productId &&
      x.locationId === infeedProduct.locationId &&
      x.locationType === infeedProduct.locationType &&
      infeedProduct.factoryId === x.factoryId &&
      x.lotId === infeedProduct.lotId
    );
  }, []);

  useEffect(() => {
    if (values.woodQuantity !== totalSelectedQuantity && values.infeedProducts.length) setFieldValue('woodQuantity', totalSelectedQuantity);
  }, [values.woodQuantity, values.infeedProducts, totalSelectedQuantity, setFieldValue]);

  useEffect(() => {
    if (!recipeIds.length) {
      clearAvailableGreenProducts();
      clearAvailableGreenToBalanceProducts();
    }
    if (recipeIds.length && values.startDate) {
      if (values.eventSubtype === EventSubtypeEnum.dry) getAvailableProductsToBalance(recipeIds, values.startDate, values.id);
      else getAvailableGreenProducts(recipeIds, values.startDate, values.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- cannot include getter because useAgent is not memoized
  }, [recipeIds, values.startDate, values.id, values.eventSubtype]);

  const availableProducts = useMemo(
    () => (values.eventSubtype === EventSubtypeEnum.dry ? availableGreenProductsToBalance : availableGreenProducts),
    [availableGreenProductsToBalance, availableGreenProducts, values.eventSubtype]
  );

  const handleEditRowsModelChange = useCallback(
    (newModel: GridEditRowsModel) => {
      const updatedModel = { ...newModel };
      let value = 0;

      Object.entries(updatedModel).forEach(([id, row]) => {
        if (row.quantity?.value) value = Number(row.quantity?.value);
        row.quantity = {
          ...updatedModel[id].quantity,
          error: !(Number.isInteger(value) && value >= 0),
        };
      });

      setEditRowsModel(updatedModel);
    },
    [setEditRowsModel]
  );

  const getSelectedLocationIds = () => {
    return _uniq(values.outfeedProducts.map((x) => x.locationId));
  };

  const handleRemoveSelectedGreenProduct = (infeedProduct: InfeedProduct): void => {
    if (!selectedLocationIdsMemory.length) setSelectedLocationIdsMemory(getSelectedLocationIds());

    const updatedInfeedProducts = values.infeedProducts.filter((x) => !isSameProduct(x, infeedProduct));
    setFieldValue('infeedProducts', updatedInfeedProducts);

    const filteredOutfeedProducts = values.outfeedProducts.filter((x) =>
      updatedInfeedProducts.some((infeedProduct) => infeedProduct.productId === x.productId)
    );

    if (filteredOutfeedProducts.length !== values.outfeedProducts.length) setFieldValue('outfeedProducts', filteredOutfeedProducts);
  };

  const updateFormikOnCellCommit = useCallback(
    (id: string | number, value: GridCellValue): void => {
      const formikIndex = values.infeedProducts.findIndex((x) => x.id === id);
      setFieldValue(`infeedProducts.${formikIndex}.quantity`, value);
    },
    [values.infeedProducts, setFieldValue]
  );

  const refreshLocation = (newInfeedProducts: InfeedValues[]) => {
    if (values.eventSubtype === EventSubtypeEnum.dry) {
      refreshDoorsOutfeedProducts(newInfeedProducts);
    } else {
      refreshRailsOutfeedProducts(newInfeedProducts);
    }
  };

  const handleAddSelectedGreenProduct = (availableProduct: AvailableProduct): void => {
    let maxId = Math.max(...values.infeedProducts.map((x) => x.id), 0);
    const newInfeedProducts = [
      {
        ...availableProduct,
        id: ++maxId,
        location: availableProduct.locationName,
        availableQuantity: availableProduct.quantity,
        quantity: availableProduct.quantity,
      },
      ...values.infeedProducts,
    ] as InfeedValues[];
    setFieldValue('infeedProducts', newInfeedProducts);

    if (selectedLocationIdsMemory.length) {
      refreshLocation(newInfeedProducts);
    }
  };

  const selectableProducts = useMemo(
    () => availableProducts.filter((x) => !values.infeedProducts.some((y) => isSameProduct(x, y))),
    [values.infeedProducts, availableProducts, isSameProduct]
  );

  const isFetching = useMemo(() => isFetchingGreen || isFetchingBalance, [isFetchingGreen, isFetchingBalance]);

  const getFieldValuesFromAvailableGreenProducts = useCallback(
    (infeedValues: InfeedValues, field: string): string | undefined | number | Date => {
      const uniqueAvailableProduct = availableProducts.find((x) => isSameProduct(x, infeedValues));

      if (!uniqueAvailableProduct) return undefined;

      switch (field) {
        case 'vendorName':
          return uniqueAvailableProduct.vendorName;
        case 'availableQuantity':
          return uniqueAvailableProduct.quantity;
        case 'inLocationSince':
          return uniqueAvailableProduct.inLocationSince;
        default:
          return undefined;
      }
    },
    [availableProducts, isSameProduct]
  );

  return (
    <>
      <Typography variant="h6" className={classes.listTitle}>
        {t('selectedProducts')}
      </Typography>

      <DataGrid
        style={{height:150}}
        editRowsModel={editRowsModel}
        onEditRowsModelChange={handleEditRowsModelChange}
        columnTypes={{ customDate: customDateColumnType }}
        density="compact"
        rows={values.infeedProducts}
        className={classes.grid}
        loading={isFetching}
        columns={getSelectedGreenProductsColumns(t, classes.button, handleRemoveSelectedGreenProduct, getFieldValuesFromAvailableGreenProducts, disabled)}
        onCellEditCommit={({ id, value }: GridCellEditCommitParams) => updateFormikOnCellCommit(id, value)}
        hideFooter
        disableColumnSelector
        disableSelectionOnClick
      />

      {!disabled && (
        <>
          <Typography variant="h6" className={classes.listTitle}>
            {t('availableProducts')}
          </Typography>

          <DataGrid
            style={{height:220}}          
            density="compact"
            rows={selectableProducts}
            className={classes.grid}
            columnTypes={{ customDate: customDateColumnType }}
            loading={isFetching}
            columns={getAvailableGreenProductsColumns(t, classes.button, handleAddSelectedGreenProduct)}            
            hideFooter
            disableColumnSelector
            disableSelectionOnClick
          />
        </>
      )}
    </>
  );
};

export default AvailableGreenProducts;
