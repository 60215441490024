import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../hooks/useAgent';

const url = '/api/v1/planning-optimizations/terminate';

const terminateOptimization = (): Promise<AxiosResponse<void>> => axios.post(url);

const useTerminateOptimization = (): [Agent<void>, void | undefined, boolean, () => void] => useAgent<void>(() => terminateOptimization());

export default useTerminateOptimization;
