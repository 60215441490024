import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Kiln } from '../../model';

const url = '/api/v1/kilns';

export type getKilnsResponse = { kilns: Kiln[] };

const getKilns = (): Promise<AxiosResponse<getKilnsResponse>> => axios.get(url);

const useGetKilns = (): [Agent<getKilnsResponse>, getKilnsResponse | undefined, boolean, () => void] => useAgent<getKilnsResponse>(getKilns);

export default useGetKilns;
