import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Rail } from '../../model';

const url = '/api/v1/rails';

export type getRailsResponse = { rails: Rail[] };

const getRails = (): Promise<AxiosResponse<getRailsResponse>> => axios.get(url);

const useGetRails = (): [Agent<getRailsResponse>, getRailsResponse | undefined, boolean, () => void] => useAgent<getRailsResponse>(getRails);

export default useGetRails;
