import { Form, Formik } from 'formik';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Schwoop from '../../../layout/schwoop/Schwoop';
import { mapRecipeToValues } from '../../helpers/recipesHelpers';
import { Recipe, RecipeValues } from '../../model';
import { getRecipeSchema } from '../../schemas/RecipeSchema';
import RecipeEditorFormFields from './RecipeEditorFormFields';

type RecipeEditorProps = {
  recipe: Recipe;
  onClose: () => void;
  onSubmit: (values: RecipeValues) => void;
};

const RecipeEditor = ({ recipe, onClose, onSubmit }: RecipeEditorProps): ReactElement => {
  const { t } = useTranslation('settings');

  const isNewRecipe: boolean = useMemo(() => !recipe.id, [recipe]);

  return (
    <Formik enableReinitialize validationSchema={getRecipeSchema(t)} initialValues={mapRecipeToValues(recipe)} onSubmit={onSubmit}>
      {({ submitForm }) => (
        <Form>
          <Schwoop open onCancel={onClose} onConfirm={submitForm} title={!isNewRecipe ? t('recipes.editRecipe') : t('recipes.newRecipe')}>
            <RecipeEditorFormFields isNewRecipe={isNewRecipe} />
          </Schwoop>
        </Form>
      )}
    </Formik>
  );
};

export default RecipeEditor;
