/* eslint-disable react/display-name -- inline render cells without names */
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import React from 'react';
import LumberVariety from '../../model/LumberVariety';

export const getColumns = (
  t: (key: string) => string,
  canUpdate: boolean,
  onEditClick: (row: LumberVariety) => void,
): GridColDef[] => {
  const columns: GridColDef[] = [];

  if (canUpdate) {
    columns.push({
      field: '',
      headerName: t('common:actions'),
      width: 100,
      sortable: false,
      editable: false,
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          {canUpdate && (
            <Button onClick={() => onEditClick(row as LumberVariety)}>
              <EditIcon fontSize="small" />
            </Button>
          )}
        </>
      ),
    });
  }

  columns.push(
    {
      field: 'frenchName',
      headerName: t('varieties.frenchName'),
      width: 170,
      sortable: false,
      editable: false,
    },
    {
      field: 'englishName',
      headerName: t('varieties.englishName'),
      width: 170,
      sortable: false,
      editable: false,
      },
    {
      field: 'printOrder',
      headerName: t('varieties.printOrder'),
      type: 'number',
      width: 120,
      sortable: true,
      editable: false,
    },
    {
      field: 'initialMoisture',
      headerName: t('varieties.initialMoisture'),
      type: 'number',
      width: 160,
      sortable: false,
      editable: false,
    },
    {
      field: 'targetFanDryMoisturePercentage',
      headerName: t('varieties.targetFanDryMoisture'),
      type: 'number',
      width: 200,
      sortable: false,
      editable: false,
    },
    {
      field: 'targetMoisture',
      headerName: t('varieties.targetMoisture'),
      type: 'number',
      sortable: false,
      editable: false,
      width: 160,
    },
    {
      field: 'heatingDegreesPerHour',
      headerName: t('varieties.heatingDegreesPerHour'),
      type: 'number',
      editable: false,
      sortable: false,
      width: 200,
    },
    {
      field: 'fanDryMoistureLossPerHourOverThirtyPercent',
      headerName: t('varieties.fanDryMoistureLossPerHourOverThirtyPercent'),
      type: 'number',
      sortable: false,
      editable: false,
      width: 225,
      valueFormatter: ({ value }: GridValueFormatterParams) => Number(value).toFixed(3),
    },
    {
      field: 'standardDryMoistureLossPerHourUnderThirtyPercent',
      headerName: t('varieties.standardDryMoistureLossPerHourUnderThirtyPercent'),
      type: 'number',
      sortable: false,
      editable: false,
      width: 225,
      valueFormatter: ({ value }: GridValueFormatterParams) => Number(value).toFixed(3),
    },
    {
      field: 'standardDryMoistureLossPerHourOverThirtyPercent',
      headerName: t('varieties.standardDryMoistureLossPerHourOverThirtyPercent'),
      type: 'number',
      sortable: false,
      editable: false,
      width: 225,
      valueFormatter: ({ value }: GridValueFormatterParams) => Number(value).toFixed(3),
    },
    {
      field: 'fanDryTemperature',
      headerName: t('varieties.fanDryTemperature'),
      type: 'number',
      sortable: false,
      editable: false,
      width: 270,
    },
    {
      field: 'standardDryTemperature',
      headerName: t('varieties.standardDryTemperature'),
      type: 'number',
      sortable: false,
      editable: false,
      width: 275,
    },
    {
      field: 'moistureBalancingHours',
      headerName: t('varieties.moistureBalancingHours'),
      type: 'number',
      sortable: false,
      editable: false,
      width: 225,
    },
    {
      field: 'minimalHoursUnderThirtyPercentMoisture',
      headerName: t('varieties.minimalHoursUnderThirtyPercentMoisture'),
      type: 'number',
      sortable: false,
      editable: false,
      width: 270,
    },
    {
      field: 'factory1FunctionId',
      headerName: t('varieties.factory1Function'),
      type: 'number',
      sortable: false,
      editable: false,
      width: 160,
    },
    {
      field: 'factory2FunctionId',
      headerName: t('varieties.factory2Function'),
      type: 'number',
      sortable: false,
      editable: false,
      width: 160,
    }
  );

  return columns;
};

/* eslint-enable react/display-name -- inline render cells without names */

export default { getColumns };
