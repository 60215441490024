import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Form, Formik } from 'formik';
import React, { ReactElement, useEffect, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Schwoop, { SchwoopAction } from '../../../layout/schwoop/Schwoop';
import { mapDoorToValues } from '../../helpers/doorsHelpers';
import { useGetLumberVarieties } from '../../hooks/lumberVarieties';
import useGetDoorCanBeDelete from '../../hooks/doors/useGetDoorCanBeDelete';
import { Door, DoorValues } from '../../model';
import { getDoorSchema } from '../../schemas/DoorSchema';
import LumberWeightField from '../LumberWeightField';
import useSettingsTabStyles from '../SettingsTabStyles';
import DoorEditorFormFields from './DoorEditorFormFields';

type DoorEditorProps = {
  door: Door;
  onClose: () => void;
  onSubmit: (values: DoorValues) => void;
  onDelete: (doorToDelete: Door) => Promise<boolean>;
};



const DoorEditor = ({ door, onClose, onSubmit, onDelete }: DoorEditorProps): ReactElement => {
  const [getLumberVarieties, { lumberVarieties = [] } = {}] = useGetLumberVarieties();
  const classes = useSettingsTabStyles();
  const { t } = useTranslation('settings');
  const [getDoorCanBeDelete, DeleteAvailable] = useGetDoorCanBeDelete();
  const [doorCanBeDelete, setDoorCanBeDelete] = useState(false);  

  type doorCanBeDeleteResponseType = {
    DeleteAvailable: boolean;
  }

  useEffect(() => {
    getLumberVarieties({ hideIsArchived: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- agents aren't memoized, only fetch on mount
    if (door.id) {    // Si un id porte est pass� en param�tre, il s'agit d'une modification, donc on fait la validation.
      getDoorCanBeDelete(door.id);
    }
    else {    // Si c'est pour une nouvelle porte, on ne peut pas effacer (pas cr��)
      setDoorCanBeDelete(false);
    }
  }, []);

  useEffect(() => {
    if (DeleteAvailable !== undefined) {    // si on a re�u un r�sultat qui n'est pas undefined dans la variable inactiveAvailable, on peut r�cup�rer la valeur.
      const tmp: doorCanBeDeleteResponseType | undefined = DeleteAvailable;
      setDoorCanBeDelete(tmp?.DeleteAvailable as boolean);
    }
  }, [DeleteAvailable]);


  const isNewDoor: boolean = useMemo(() => !door.id, [door]);


  const getActions = () => {
    const actions: SchwoopAction[] = [];

    if (!isNewDoor) {
      actions.push(
        {
          label: t('deleteSetting'),
          handler: handleDelete,
          icon: DeleteIcon,
          iconColor: 'secondary',
          labelColor: 'secondary',
          disabled: doorCanBeDelete,
          tooltipText: doorCanBeDelete ? t('doors.cannotDeleteDoor') : '',
          testId: 'event-delete',
        }
      );
    }
    return actions;
  };

  const handleDelete = useCallback(async () => {
    if (await onDelete(door)) onClose();
  }, [door, onDelete, onClose]);


  return (
    <Formik enableReinitialize validationSchema={getDoorSchema(t)} initialValues={mapDoorToValues(door, lumberVarieties)} onSubmit={onSubmit} >
      {({ submitForm, values }) => (
        <Form>
          <Schwoop open onCancel={onClose} onConfirm={submitForm} title={!isNewDoor ? t('doors.editDoor') : t('doors.newDoor')} actions={getActions()}>
            <Grid container spacing={2} className={classes.gridFields}>
              <DoorEditorFormFields />
              <LumberWeightField lumberVarieties={lumberVarieties} lumberWeights={values.doorLumberWeights} prefix="doorLumberWeights" />
            </Grid>
          </Schwoop>
        </Form>
      )}
    </Formik>
  );
};

export default DoorEditor;
