import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../hooks/useAgent';

const url = 'api/v1/planning-optimizations';

const confirmOptimization = (planningOptimizationId: number): Promise<AxiosResponse<void>> => axios.post(`${url}/${planningOptimizationId}/confirm`);

const useConfirmOptimization = (): [Agent<void>, void | undefined, boolean, () => void] =>
  useAgent<void>((planningOptimizationId: number) => confirmOptimization(planningOptimizationId));

export default useConfirmOptimization;
