import { AccountInfo } from '@azure/msal-common';
import { Ability, AbilityBuilder, ExtractSubjectType, MongoQuery, Subject, SubjectRawRule } from '@casl/ability';
import { isInRole } from './abilities/abilityHelpers';
import { RoleEnum } from './abilities/RoleEnum';

export const appAccessAction = 'Access';
export const appSubject = 'App';
export default function getRules(account: AccountInfo): SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery>[] {
  const { rules, can } = new AbilityBuilder<Ability>(Ability);
  const { Admin, Planner, Operator } = RoleEnum;

  if (isInRole(account, [Admin, Planner, Operator])) {
    can([appAccessAction], appSubject);
  }

  return rules;
}
