import { Grid } from '@material-ui/core';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardDateTimePickerField, NumberField, SelectField, TextField } from '../../../../../layout/fields';
import { EventValues } from '../../../../model';
import RecipeMultiSelect from '../../RecipeMultiSelect';
import DurationField from '../DurationField';
import { KilnEventEditorProps } from '../KilnEventEditor';

const KilnSubstrateEventEditorFields = ({ disabled = false, resourceOptions, recipes = [] }: KilnEventEditorProps): ReactElement => {
  const { t } = useTranslation('planning');
  const { values } = useFormikContext<EventValues>();

  return (
    <>
      <Grid item md={2}>
        <SelectField label={t('kiln')} name="resourceId" options={resourceOptions} disabled={disabled} />
      </Grid>
      <Grid item md={4}>
        <RecipeMultiSelect recipes={recipes} disabled={disabled} />
      </Grid>

      <Grid item md={4}>
        <NumberField label={t('woodQuantity')} name="woodQuantity" min={0} disabled={disabled || !!values.infeedProducts.length} />
      </Grid>

      <Grid item md={4}>
        <KeyboardDateTimePickerField name="startDate" label={t('dryingStart')} disabled={disabled} />
      </Grid>

      <Grid item md={4}>
        <KeyboardDateTimePickerField
          name="endDate"
          label={t('dryingStop')}
          minDate={values.startDate && dayjs(values.startDate).add(1, 'd')}
          disabled={disabled}
        />
      </Grid>

      <Grid item md={2}>
        <DurationField
          label={t('dryingDuration')}
          name="duration"
          disabled={disabled}
          duration={values.duration || 0}
          recommendedDuration={0}
          minimalDuration={0}
        />
      </Grid>

      <Grid item md={2}>
        <NumberField label={t('workOrder')} name="workOrder" disabled />
      </Grid>

      <Grid item md={6}>
        <TextField label={t('tooltipNote')} name="tooltipNote" disabled={disabled} />
      </Grid>

      <Grid item md={6}>
        <TextField label={t('detailedComment')} name="detailedComment" disabled={disabled} />
      </Grid>
    </>
  );
};

export default KilnSubstrateEventEditorFields;
