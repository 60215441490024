import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';

const url = '/api/v1/doors';

const deleteDoor = (doorId: number): Promise<AxiosResponse> => axios.delete(`${url}/${doorId}`);

const useDeleteDoor = (): [Agent<AxiosResponse>, AxiosResponse | undefined, boolean, () => void] =>
  useAgent<AxiosResponse>((doorId: number) => deleteDoor(doorId));

export default useDeleteDoor;
