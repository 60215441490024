import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useFilterStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      whiteSpace: 'nowrap',
      height: '37px',
      paddingLeft: 16,
      paddingRight: 16,
      borderRadius: '2px',
      backgroundColor: '#434343',
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
      marginLeft: '1em',
      border: 'none',
      color: 'white',
    },
    content: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      width: '425px',
    },
    footer: {
      display: 'flex',
      height: '100%',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      padding: theme.spacing(2),
    },
    boxContent: {
      marginTop: '10px',
      display: 'flex',
      width: '400px',
    },
    select: {
      fontSize: '14px',
      width: '100%',
    },
  })
);

export default useFilterStyles;
