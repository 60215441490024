const inventory = {
  allFactories: 'Toutes les usines',
  doors: 'Portes',
  fmb: 'PMP',
  incomingFrom: 'En provenance de',
  inventory: 'Inventaire',
  kilns: 'Séchoirs',
  levelOfStock: "Niveau d'inventaire",
  product: 'Produit',
  productionDate: 'Date de production',
  quantity: 'Quantité',
  rails: 'Rails',
  toBeDeliveredTo: 'Délivré vers',
  usage: 'Usage',
};

export default inventory;
