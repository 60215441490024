import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Kiln } from '../../model';

const url = '/api/v1/kilns';

export type createKilnResponse = { kiln: Kiln };

const createKiln = (kilnRequest: Kiln): Promise<AxiosResponse<createKilnResponse>> => axios.post(url, kilnRequest);

const useCreateKiln = (): [Agent<createKilnResponse>, createKilnResponse | undefined, boolean, () => void] =>
  useAgent<createKilnResponse>((kiln: Kiln) => createKiln(kiln));

export default useCreateKiln;
