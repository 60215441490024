const inventory = {
  allFactories: 'All Factories',
  doors: 'Doors',
  fmb: 'FMB',
  incomingFrom: 'Incoming From',
  inventory: 'Inventory',
  kilns: 'Kilns',
  levelOfStock: 'Level of Stock',
  product: 'Product',
  productionDate: 'Production Date',
  quantity: 'Quantity',
  rails: 'Rails',
  toBeDeliveredTo: 'To Be Delivered to',
  usage: 'Usage',
};

export default inventory;
