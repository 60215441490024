import PropTypes from 'prop-types';
import React from 'react';
import Toast from './Toast';

type SuccessToastProps = {
  message?: string | null;
  onClose: () => void;
};

const SuccessToast = ({ message, onClose }: SuccessToastProps): JSX.Element => {
  return <Toast message={message} severity="success" onClose={onClose} />;
};

SuccessToast.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
};

export default SuccessToast;
