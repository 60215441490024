/* eslint-disable react/display-name -- inline render cells without names */
import * as Sentry from '@sentry/react';
import React, { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import PageWithTabs from '../../layout/PageWithTabs';
import AirDryChartPage from './airDryChart/AirDryChartPage';
import AverageTemperaturePage from './averageTemperature/AverageTemperaturePage';
import DoorPage from './door/DoorPage';
import KilnPage from './kiln/KilnPage';
import LumberVarietyPage from './lumberVariety/LumberVarietyPage';
import RailPage from './rail/RailPage';
import RecipePage from './recipe/RecipePage';

const ROUTES = {
  lumberVariety: {
    component: LumberVarietyPage,
    path: '/lumber-variety',
  },
  recipe: {
    component: RecipePage,
    path: '/recipe',
  },
  door: {
    component: DoorPage,
    path: '/door',
  },
  kiln: {
    component: KilnPage,
    path: '/kiln',
  },
  rail: {
    component: RailPage,
    path: '/rail',
  },
  airDryChart: {
    component: AirDryChartPage,
    path: '/airDryChart',
  },
  averageTemperature: {
    component: AverageTemperaturePage,
    path: '/average-temperature',
  },
};

const SentryRoute = Sentry.withSentryRouting(Route);

const SettingsRouter = (): ReactElement => {
  const { path, url } = useRouteMatch();

  const { t } = useTranslation('settings');

  const tabs = useMemo(
    () => [
      { label: t('varieties.lumberVarieties'), path: `${url}/lumber-variety` },
      { label: t('recipes.recipes'), path: `${url}/recipe` },
      { label: t('kilns.kilns'), path: `${url}/kiln` },
      { label: t('doors.doors'), path: `${url}/door` },
      { label: t('rails.rails'), path: `${url}/rail` },
      { label: t('settings:airDryChart'), path: `${url}/airDryChart` },
      { label: t('settings:monthlyAverageTemperature'), path: `${url}/average-temperature` },
    ],
    [t, url]
  );

  return (
    <Switch>
      {Object.values(ROUTES).map((route) => {
        return (
          <SentryRoute
            key={route.path}
            path={`${path}${route.path}`}
            render={(routeProps) => (
              <PageWithTabs currentTab={routeProps.match.url} tabs={tabs}>
                {React.createElement(route.component)}
              </PageWithTabs>
            )}
          />
        );
      })}
      <SentryRoute path="/">
        <Redirect to={`${url}/lumber-variety`} />
      </SentryRoute>
    </Switch>
  );
};

/* eslint-enable react/display-name -- inline render cells without names */

export default SettingsRouter;
