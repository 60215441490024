import { AccountInfo } from '@azure/msal-common';
import _ from 'lodash';

export const isInRole = (account: AccountInfo, roles: string[]): boolean => {
  const accountRoles: string[] = (account.idTokenClaims as { roles: string[] })?.roles || [];
  return _.some(roles, (r) => _.includes(accountRoles as string[], r));
};

export const isNotInRole = (account: AccountInfo, roles: string[]): boolean => {
  const accountRoles: string[] = (account.idTokenClaims as { roles: string[] })?.roles || [];
  return _.some(roles, (r) => !_.includes(accountRoles, r));
};
