class ConflictError extends Error {
  Conflict: string[];
  constructor(msg: string) {
    super(msg);
    this.Conflict = [];
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ConflictError.prototype);
  }
}

export default ConflictError;
