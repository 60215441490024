import React, { createContext, FC, ReactNode, useState } from 'react';
import CssTemplate from '../CssTemplate';
import { englishLocalesCode, frenchLocalesCode, lngLocalStorageKey } from '../i18n';
import ConfirmationDialog, { ConfirmationDialogConfigs } from '../layout/dialog/ConfirmationDialog';
import useChangeLanguage from '../layout/navigation/language/useChangeLanguage';

type appState = {
  isNavigationOpen: boolean;
  toggleNavigation: () => void;
  openDialog: (config: Partial<ConfirmationDialogConfigs>) => void;
  toggleLanguage: () => void;
  languageCode: string;
};

export const AppContext = createContext<appState>({
  isNavigationOpen: false,
  toggleNavigation: () => void 0,
  openDialog: () => void 0,
  toggleLanguage: () => void 0,
  languageCode: englishLocalesCode,
});

const AppContextProvider: FC = ({ children }: { children?: ReactNode }) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [languageCode, setLanguageCode] = useState(window.localStorage.getItem(lngLocalStorageKey) || englishLocalesCode);
  const [changeLanguage] = useChangeLanguage();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<Partial<ConfirmationDialogConfigs> | undefined>();

  const toggleLanguage = () => {
    const newLanguageCode = languageCode === englishLocalesCode ? frenchLocalesCode : englishLocalesCode;
    setLanguageCode(newLanguageCode);
    changeLanguage(newLanguageCode);
  };

  const toggleNavigation = () => {
    setIsNavigationOpen(!isNavigationOpen);
  };

  const openDialog = (config: Partial<ConfirmationDialogConfigs>) => {
    setDialogOpen(true);
    setDialogConfig(config);
  };

  const onConfirm = () => {
    setDialogOpen(false);
    dialogConfig?.actionCallback && dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    setDialogOpen(false);
    dialogConfig?.actionCallback && dialogConfig.actionCallback(false);
  };

  return (
    <AppContext.Provider value={{ isNavigationOpen, toggleNavigation, openDialog, toggleLanguage, languageCode }}>
      <CssTemplate languageCode={languageCode}>
        <>
          <ConfirmationDialog {...dialogConfig} open={isDialogOpen} onConfirm={onConfirm} onDismiss={onDismiss} />
          {children}
        </>
      </CssTemplate>
    </AppContext.Provider>
  );
};

export default AppContextProvider;
