import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../hooks/useAgent';
import { TransfersListValues } from '../model/TransfersListValues';

const url = 'api/v1/transfer-list';

export type getGetTransfersListResponse = { transfersList: TransfersListValues[] };

const getTransfersList = (fromDate: string, toDate: string): Promise<AxiosResponse<getGetTransfersListResponse>> =>
  axios.get(url, { params: { fromDate, toDate } });

const useGetTransfersList = (): [Agent<getGetTransfersListResponse>, getGetTransfersListResponse | undefined, boolean, () => void] =>
  useAgent<getGetTransfersListResponse>(getTransfersList);

export default useGetTransfersList;
