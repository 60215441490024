import { LocaleManager } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { lngLocalStorageKey } from '../../../i18n';

const useChangeLanguage = (): [(lng: string) => void] => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    axios.defaults.headers['Accept-Language'] = lng;
    i18n.changeLanguage(lng);
    LocaleManager.applyLocale(lng);
    window.localStorage.setItem(lngLocalStorageKey, lng);
  };

  return [changeLanguage];
};

export default useChangeLanguage;
