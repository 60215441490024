import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { apiRequest } from '../authConfig';

const useSetupAxios = (axiosInstance: AxiosInstance): boolean[] => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isAxiosReady, setAxiosIsReady] = useState(false);

  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_URL;

  const setAxios = useCallback(
    (getToken) => {
      axiosInstance.interceptors.request.use(
        async function (config: AxiosRequestConfig) {
          try {
            const tokenResponse = await getToken();
            config.headers.common['Authorization'] = `Bearer ${tokenResponse.accessToken}`;
            return config;
          } catch (e) {
            return Promise.reject(e);
          }
        },
        function (error: string) {
          return Promise.reject(error);
        }
      );

      setAxiosIsReady(true);
    },
    [axiosInstance.interceptors.request]
  );

  useEffect(() => {
    if (isAuthenticated) {
      setAxios(async () => {
        const silentTokenRequest = {
          ...apiRequest,
          account: accounts[0],
        };
        return instance.acquireTokenSilent(silentTokenRequest).catch((error) => {
          console.error('acquire silent token failed', error);
          if (error instanceof InteractionRequiredAuthError) {
            console.error('Acquire token redirect');
            return instance.acquireTokenRedirect({ ...apiRequest, loginHint: accounts[0].username });
          }
        });
      });
    }
  }, [isAuthenticated, setAxios, instance, accounts]);

  return [isAxiosReady];
};

export default useSetupAxios;
