import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Rail } from '../../model';

const url = '/api/v1/rails';

export type createRailResponse = { rail: Rail };

const createRail = (railRequest: Rail): Promise<AxiosResponse<createRailResponse>> => axios.post(url, railRequest);

const useCreateRail = (): [Agent<createRailResponse>, createRailResponse | undefined, boolean, () => void] =>
  useAgent<createRailResponse>((rail: Rail) => createRail(rail));

export default useCreateRail;
