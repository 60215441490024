const validation = {
  endDateAfterStartDate: 'La date de fin doit être après la date de début',
  equalOrHigherThan: 'La valeur doit être égale ou supérieure à {{number}}',
  equalOrLesserThan: 'La valeur doit être égale ou inférieure à {{number}}',
  invalidDuration: 'La durée est trop courte ou trop longue pour cette essence de bois',
  invalidLinks: 'Les modifications apportées rendent les liens invalides',
  required: 'Requis',
};

const messages = {
  cannotModifyLockedTask: 'Les tâches barrées ne peuvent pas être modifiées.',
  concurrencyModification: "L'élément que vous tentez d'éditer a été mis à jour par un autre utilisateur.",
  confirmDelete: 'Voulez-vous vraiment supprimer cette tâche?',
  lockTaskDisabled: 'Vous devez confirmer ou annuler vos changements avant de barrer cette tâche.',
  optimizationStarted: 'L’optimisation a débutée avec succès',
  schedulerModified: 'Un autre utilisateur a modifié la cédule. Veuillez réessayer.',
  longerDryingDurationRequired: 'Une durée de séchage plus longue est nécessaire pour estimer les étapes de séchage.',
  inventoryImportError: 'La connexion à la source de donnée externe a été interrompue, la mise à jour n’a pas été complétée.',
  qtyMaxReachedError: 'La quantité maximale pour ce transfert est atteinte.'
};

const optimization = {
  airDryPenalty: 'Pénalité séchage air dry',
  airDryPenaltyTooltip: 'Pénalité séché de façon air dry',
  average: 'Moyenne \u00A0 (%)',
  averageDoorUsage: "Taux occupation d'entrepots contrôlés",
  averageKilnUsage: "Taux d'utilisation des séchoirs",
  averageRailUsage: "Taux d'occupation des rails",
  calculationCompleted: 'Calcul terminé',
  calculationCompletedToast: 'Le calcul d’optimisation est terminé, solution optimale',
  confirmCancelOptimizationMsg: 'Êtes-vous sûr de vouloir annuler l’optimisation de la planification?',
  confirmChangeWarning: 'Avertissement',
  confirmChangeMsg: 'Êtes-vous sûr de vouloir appliquer les changements à la planification? Ces changements sont irréversibles.',
  driedInventoryPenalty: 'Pénalité inventaire sec',
  driedInventoryPenaltyTooltip: 'Pénalité pour garder en inventaire du bois sec',
  driedPenalty: 'Pénalité achat sec',
  driedPenaltyTooltip: 'Pénalité pour acheter du bois sec',
  dryingTimeByRecipe: 'Temps moyen de séchage par recette',
  error: 'Erreur',
  errorResponse: 'Aucune solution d’optimisation obtenue avec les données d’entrée. Modifiez les paramètres et lancez de nouveau l’optimisation',
  fanDryPenalty: 'Pénalité séchage fan dry',
  fanDryPenaltyTooltip: 'Pénalité séché de façon fan dry',
  feasible: 'Faisable',
  feasibleResponse: 'Limite de temps de calcul atteinte',
  greenInventoryPenalty: 'Pénalité inventaire vert',
  greenInventoryPenaltyTooltip: 'Pénalité pour garder en inventaire du bois vert',
  greenPenalty: 'Pénalité achat vert',
  greenPenaltyTooltip: 'Pénalité pour acheter du bois vert',
  horizonLength: 'Durée de l’horizon',
  horizonLengthTooltip: 'Quantité de temps considérée par l’optimisation',
  kilnToProductionDelay: 'Intervalle de temps entre séchoir et production',
  lowInventoryPenalty: 'Pénalité sous-inventaire',
  lowInventoryPenaltyTooltip: 'Pénalité en dessous de l’intentaire minimal de sécurité',
  maximum: 'Maximum \u00A0(%)',
  minimumKilnUsageHard: 'Capacité min séchoir bois dur',
  minimumKilnUsageHardTooltip: 'Pourcentage minimal de capacité de séchoir pour bois dur',
  minimumKilnUsageSoft: 'Capacité min séchoir bois mou ',
  minimumKilnUsageSoftTooltip: 'Pourcentage minimal de capacité de séchoir pour bois mou',
  optionFanCount: 'Options de fan dry',
  optionFanCountTooltip: 'Quantité d’options de durée de fan dry considérées',
  productionlineUsage: "Niveau d'utilisation des lignes de production",
  recipeDistributionScore: 'Score de distribution des recettes',
  resultTitle: "Résultat d'optimisation",
  score: 'Score \u00A0(%)',
  sixWeeks: '6 semaines',
  solutionQuality: 'Niveau de la qualité de la solution',
  startDate: 'Date de début',
  startDateTooltip: 'Date de début de l’optimisation',
  standardDryPenalty: 'Pénalité séchage standard',
  standardDryPenaltyTooltip: 'Pénalité séché de façon standard',
  solutionStatus: 'Statut',
  stepDays: 'Intervalle de temps',
  stepDaysTooltip: 'Plus petite unité de temps considérée par l’optimisation',
  terminate: 'Terminer',
  terminateCalculation: 'Si vous confirmez, le calcul sera terminé et il ne sera plus possible de le continuer',
  threeMonths: '3 mois',
  title: "Paramètres d'optimisation",
  timeLimit: 'Temps limite de calcul (Minutes)',
  timeLimitTooltip: "Temps maximal en minutes pour le calcul d'optimisation",
  twelveMonths: '12 mois',
  unfeasible: 'Impraticable',
  unfeasibleResponse:
    'Aucune solution d’optimisation possible avec les données d’entrée. Si vous désirez continuer l’optimisation, révisez les tâches barrées et les paramètres d’entrées',
  weeklyPenalty: 'Pénalité retard',
  weeklyPenaltyTooltip: 'Pénalité de retard',
};

const planning = {
  messages,
  validation,
  optimization,
  alignTasks: 'Aligner les tâches de la ressource',
  allFactories: 'Toutes les Usines',
  availableDoors: 'Portes disponibles',
  availableProducts: 'Produits disponibles',
  availableQuantity: 'Quantité disponible',
  availableRails: 'Rails disponibles',
  balancing: 'Équilibrage',
  calculationInProgress: 'Calcul en cours',
  cancel: 'Annuler',
  capacity: 'Capacité',
  capacityInFMB: 'Capacité (PMP)',
  clearAll: 'Effacer les filtres',
  comment: 'Commentaire',
  date: 'Date',
  days: 'Jours',
  deleteTask: 'Supprimer',
  detailedComment: 'Commentaire détaillé',
  door: 'Porte',
  doors: 'Portes',
  dry: 'Sec',
  dryingDuration: 'Durée du séchage (Jours)',
  dryingStart: 'Début de séchage',
  dryingStop: 'Fin de séchage',
  dryingTask: 'Tâche de séchage',
  dryStock: 'Stock sec',
  durationInDays: 'Durée (Jours)',
  editTask: 'Modifier la tâche',
  efficiency: 'Productivité',
  endDate: 'Date de fin',
  eventSubtype: 'Sous-type de tâche',
  factory: 'Usine',
  fanDry: "Séchage à l'air",
  filterBy: 'Filtrer par',
  fmb: 'PMP',
  from: 'De',
  green: 'Vert',
  hideLinkedTask: 'Masquer tâche reliée',
  home: 'Accueil',
  hours: 'Heures',
  indoorLots: 'Lots intérieurs',
  information: 'Information',
  inLocationSince: "Date d'entreposage",
  inStorageSince: 'Entreposé depuis',
  invalidModification: 'Modification invalide',
  inventory: 'Inventaire',
  kiln_plural: 'Séchoirs',
  kiln: 'Séchoir',
  linked: 'Liée',
  location: 'Location',
  locked: 'Barrée',
  lockTask: 'Barrer la tâche',
  lotId: 'ID du lot',
  lotQuantity: 'Quantité du lot',
  lumberType: 'Essence de bois',
  maintenance: 'Maintenance',
  minimumValue: 'Valeur minimale',
  modify: 'Modifier',
  moisture: 'Humidité (%)',
  newTask: 'Nouvelle tâche',
  numberOfDays: '{{count}} jours',
  onRailSince: 'Sur le rail depuis',
  optimize: 'Optimiser',
  orderLine_plural: 'Lignes de commande',
  orderLine: 'Ligne de commande',
  overlap: 'Chevauche',
  planning: 'Planification',
  processDuration: 'Durée de transformation (Heures)',
  productCharacteristic: 'Caractéristique',
  productDescription: 'Description du produit',
  productionLine: 'Ligne de production',
  productionLineTask: 'Tâche de ligne de production',
  productionStartDate: 'Date de début de production',
  products: 'Produits',
  putInTShedDate: 'Entreposé depuis',
  quantity: 'Quantité',
  quantityInFMB: 'Quantité (PMP)',
  rails: 'Rails',
  recommended: 'Recommandé',
  recipe: 'Recette',
  redo: 'Rétablir',
  redoLastAction: 'Rétablir la dernière action',
  ref: 'Ref',
  refresh: 'Rafraichir',
  regularDry: 'Séchage régulier',
  remainingCapacity: 'Capacitée restante',
  remainingQuantity: 'Quantité restante',
  resource: 'Ressource',
  selectedDoors: 'Portes sélectionnées',
  selectedProducts: 'Produits sélectionnés',
  selectedRails: 'Rails sélectionnés',
  similarProducts: 'Produits similaires',
  site: 'Site',
  showLinkedTask: 'Afficher tâche reliée',
  sourceNumber: '# de source',
  stacking: 'Lattage',
  stackingLine: 'Ligne de lattage',  
  stackingTask: 'Tâche de ligne de lattage',
  transferLine: 'Transfert',
  standard: 'Standard',
  startDate: 'Date de début',
  status: 'État',
  stopCalculation: 'Arrêter le calcul',
  task: 'Tâche',
  substrate: 'Substrat',
  taskNumber: 'Numéro de tâche',
  to: "Jusqu'à",
  today: "Aujourd'hui",
  tooltipNote: 'Note informative',
  totalCapacity: 'Capacitée totale',
  undo: 'Annuler',
  undoLastAction: 'Annuler la dernière action',
  unlinked: 'Non liée',
  unlocked: 'Débarrée',
  unlockTask: 'Débarrer la tâche',
  usage: 'Usage (%)',
  vendor: 'Fournisseur',
  weeks: 'Semaines',
  woodMoisture_initial: 'Humidité initiale du bois (%)',
  woodMoisture_target: 'Humidité cible du bois (%)',
  woodMoisture: 'Humidité du bois',
  woodQuantity: 'Quantité de bois (PMP)',
  woodStatus: 'Statut du bois',
  workOrder: '# OF',
  workOrderNumber: "Numéro d'OF",
  maxQuantity: 'Quantité maximum (PMP)'
};

export default planning;
