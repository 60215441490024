import React from 'react';
import LayoutPage from '../../layout/LayoutPage';
import InventoryScheduler from './InventoryScheduler';

const InventoryPage = (): JSX.Element => (
  <LayoutPage>
    <InventoryScheduler />
  </LayoutPage>
);

export default InventoryPage;
