import { Box, FormControl, IconButton, Input, InputLabel, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import _groupBy from 'lodash.groupby';
import React, { ReactElement, useCallback, useState } from 'react';
import { Option } from '../../common/helpers/options';
import ColoredChip from './ColoredChips';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
  },
    select: {
        width: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
        height: '15px',
    },
    box: {
        display: 'inline-flex',
    },
    item: {
        paddingLeft: theme.spacing(3),
    },
    group: {        
        ///todo ne compile pas fontWeight: theme.typography.fontWeightMedium,
        opacity: 1,
    },
}));

type ColoredOption = Option & {
  color: string;
};

type MultiSelectProps = {
  label: string;
  id: string;
  disabled?: boolean;
  options: ColoredOption[];
  selected: number[];
  setSelected: (selected: number[]) => void;
  groupBy?: (option: ColoredOption) => string;
};

const MultiSelect = ({ id, label, options = [], selected = [], setSelected, groupBy, disabled = false }: MultiSelectProps): ReactElement => {
  const styles = useStyles();

  const handleDelete = useCallback(
    (value) => {
      const index = selected.indexOf(value);
      if (index !== -1) {
        selected.splice(index, 1);
      }
      setSelected([...selected]);
    },
    [selected, setSelected]
  );

  // Pour que le MultiSelect puisse contr�ler lui-m�me son �tat.
  const [open, setOpen] = useState(false);

  const handleDeleteAll = useCallback(() => {
    setSelected([]);
  }, [setSelected]);

  return (
    <FormControl className={styles.formControl}>
      <Box className={styles.box}>
        <InputLabel id={`multi-${id}-label`}>{label}</InputLabel>
        <Select
          labelId={`multi-${id}-label`}
          id={`multi-${id}`}
          multiple
          value={selected}
          className={styles.select}
          disabled={disabled}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={({ target }) => {
            setOpen(false);

            (async () => {
              // Pour que le menu s'update avant que le UI se rerender.  
              // J'aimerais conna�tre une meilleure mani�re.
              await new Promise((resolve) => setTimeout(resolve, 100));
              setSelected(target.value as [])
            })();
          }}
          input={<Input id={`multi-${id}-input`} />}
          renderValue={(selected: unknown) => (
            <div className={styles.chips}>
              {(selected as (string | number)[]).map((value: string | number) => {
                const option = options.find((x: Option) => x.value === value);
                return (
                  <ColoredChip
                    customColor={`#${option?.color}`}
                    className={styles.chip}
                    key={option?.value}
                    label={option?.label}
                    onDelete={() => handleDelete(value)}
                  />
                );
              })}
            </div>
          )}>
          {groupBy
            ? Object.entries(_groupBy(options, groupBy))
              .map(([key, optionsGroup]) => [
                { type: 'group', label: key, style: styles.group, key: key, value: key },
                ...optionsGroup.map((x) => ({ type: 'item', style: styles.item, ...x })),
              ])
              .flat()
              .map((x) => (
                <MenuItem disabled={x.style === styles.group} key={x.key} className={x.style} value={x.value}>
                  {x.label}
                </MenuItem>
              ))
            : options.map((option) => (
              <MenuItem key={option.key} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        {selected.length > 0 && (
          <IconButton size="small" aria-label="delete" onClick={handleDeleteAll}>
            <ClearIcon />
          </IconButton>
        )}
      </Box>
    </FormControl>
  );
};

export default MultiSelect;
