import React, { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useState } from 'react';
import { LumberVariety } from '../../../settings/model';
import { PlanifResource } from '../../model/Resource';

type eventEditorState = {
  recipeIds: number[];
  setRecipeIds: Dispatch<SetStateAction<number[]>>;
  selectedLocationIdsMemory: number[];
  setSelectedLocationIdsMemory: Dispatch<SetStateAction<number[]>>;
  resources: PlanifResource[];
  lumberVarieties: LumberVariety[];
};

export const EventEditorContext = createContext<eventEditorState>({
  recipeIds: [],
  setRecipeIds: () => void 0,
  selectedLocationIdsMemory: [],
  setSelectedLocationIdsMemory: () => void 0,
  resources: [],
  lumberVarieties: [],
});

export const useEventEditorContext = (): eventEditorState => useContext(EventEditorContext);
export interface EventEditorContextProviderProps {
  initialRecipeIds: number[];
  resources: PlanifResource[];
  lumberVarieties: LumberVariety[];
}

const EventEditorContextProvider: FC<PropsWithChildren<EventEditorContextProviderProps>> = ({
  children,
  initialRecipeIds,
  resources,
  lumberVarieties,
}: PropsWithChildren<EventEditorContextProviderProps>) => {
  const [recipeIds, setRecipeIds] = useState<number[]>(initialRecipeIds);
  const [selectedLocationIdsMemory, setSelectedLocationIdsMemory] = useState<number[]>([]);
  return (
    <EventEditorContext.Provider value={{ recipeIds, setRecipeIds, resources, lumberVarieties, selectedLocationIdsMemory, setSelectedLocationIdsMemory }}>
      {children}
    </EventEditorContext.Provider>
  );
};

export default EventEditorContextProvider;
