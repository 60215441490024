import { isSaturday, isSunday } from 'date-fns';
import dayjs from 'dayjs';

export const formatDate = (date: string | Date): string => dayjs(date).format('YYYY-MM-DD');

export const formatTime = (date: string | Date): string => dayjs(date).format('HH:mm');

export const formatDateTime = (date: string | Date): string => dayjs(date).format('YYYY-MM-DD HH:mm');

export const isWeekendInTimeRange = (startDate: Date, endDate: Date): boolean => {
  return (isSunday(startDate) || isSaturday(startDate)) && (isSunday(endDate) || isSaturday(endDate));
};

export const getLastDayOfWeek = (date: Date): string => dayjs(date).endOf('week').format('YYYY-MM-DD');

export const getFirstDayOfWeek = (date: Date): string => dayjs(date).startOf('week').format('YYYY-MM-DD');

export const splitDateAndTime = (date: string | Date): { date: string; time: string } => ({ date: formatDate(date), time: formatTime(date) });
