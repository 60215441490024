import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Factory } from '../../model';

const url = '/api/v1/factories';

export type getFactoriesResponse = { factories: Factory[] };

const getFactories = (): Promise<AxiosResponse<getFactoriesResponse>> => axios.get(url);

const useGetFactories = (): [Agent<getFactoriesResponse>, getFactoriesResponse | undefined, boolean, () => void] =>
  useAgent<getFactoriesResponse>(getFactories);

export default useGetFactories;
