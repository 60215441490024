import { Tooltip, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import useDryingDistributionBarStyles from './dryingDistributionBarStyles';

type DryingDistributionBarProps = {
  duration: number;
  fanDryPercentage: number;
};

const DryingDistributionBar = ({ duration, fanDryPercentage }: DryingDistributionBarProps): ReactElement => {
  const classes = useDryingDistributionBarStyles(fanDryPercentage)();
  const { t } = useTranslation('planning');
  const fanDryDuration = Math.ceil(duration * (fanDryPercentage / 100));
  const regularDryDuration = duration - fanDryDuration;
  const regularDryPercentage = 100 - fanDryPercentage;

  const getFanDryBar = () => {
    const label = `${t('fanDry')}:`;
    const value = `${t('numberOfDays', { count: fanDryDuration })} (${fanDryPercentage}%)`;
    return (
      <Tooltip title={`${label} ${value}`}>
        <div className={classes.fanDryBar}>
          <Typography className={classes.dryingTypeText} variant="h6">
            {label}
          </Typography>
          <Typography className={classes.dryingDetailText}>{value}</Typography>
        </div>
      </Tooltip>
    );
  };

  const getRegularDryBar = () => {
    const label = `${t('regularDry')}:`;
    const value = `${t('numberOfDays', { count: regularDryDuration })} (${regularDryPercentage}%)`;
    return (
      <Tooltip title={`${label} ${value}`}>
        <div className={classes.standardDryBar}>
          <Typography className={classes.dryingTypeText} variant="h6">
            {label}
          </Typography>
          <Typography className={classes.dryingDetailText}>{value}</Typography>
        </div>
      </Tooltip>
    );
  };

  const getErrorBar = () => {
    return (
      <div className={classes.errorBar}>
        <ErrorIcon />
        <Typography className={classes.dryingDetailText}>{t('messages.longerDryingDurationRequired')}</Typography>
      </div>
    );
  };

  return (
    <div className={classes.bar}>
      {duration > 0 ? (
        <>
          {fanDryDuration > 0 && getFanDryBar()}
          {getRegularDryBar()}
        </>
      ) : (
        getErrorBar()
      )}
    </div>
  );
};

export default DryingDistributionBar;
