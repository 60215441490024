import { DataGrid } from '@mui/x-data-grid';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../context/AppContext';
import { toMonthName } from '../../helpers/AverageTemperatureHelper';
import { useGetMonthlyAverageTemperature } from '../../hooks/averageTemperature';
import useSettingsTabStyles from '../SettingsTabStyles';
import { getColumns } from './AverageTemperatureColumn';

const AverageTemperaturePage = (): React.ReactElement => {
  const classes = useSettingsTabStyles();
  const { t } = useTranslation('settings');
  const { languageCode } = useContext(AppContext);

  const [getMonthlyAverageTemperature, { monthlyAverageTemperature = [] } = {}, isFetching] = useGetMonthlyAverageTemperature();

  useEffect(() => {
    getMonthlyAverageTemperature(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- compo did mount only
  }, []);

  const averageTemperatures = useMemo(
    () =>
      monthlyAverageTemperature.map((value) => ({
        id: value.id,
        month: toMonthName(value.month, languageCode),
        averageTemperature: value.averageTemperature,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only if data or language changes
    [monthlyAverageTemperature, languageCode]
  );

  return (
    <>
      <DataGrid
        density="compact"
        rows={averageTemperatures}
        columns={getColumns(t)}
        loading={isFetching}
        autoHeight
        disableColumnFilter
        className={classes.grid}
      />
    </>
  );
};

export default AverageTemperaturePage;
