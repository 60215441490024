import DayjsUtils from '@date-io/dayjs';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getGridDateOperators, GridColTypeDef, GridFilterInputValueProps } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../common/helpers/date';

const CustomDateInputValue = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;
  const { t } = useTranslation('layout');

  const handleDateChange = (date: MaterialUiPickersDate) => {
    const value = date?.isValid() ? formatDate(date.toDate()) : '';
    applyValue({ ...item, value });
  };

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <KeyboardDatePicker
        name="custom-date-filter-operator"
        label={t('grid.customDateLabel')}
        placeholder={t('grid.customDatePlaceholder')}
        invalidDateMessage={t('grid.invalidDateMessage')}
        value={item.value}
        onChange={handleDateChange}
        format="YYYY-MM-DD"
      />
    </MuiPickersUtilsProvider>
  );
};

const CustomDateColumnType: GridColTypeDef = {
  extendType: 'date',
  filterOperators: getGridDateOperators().map((operator) => {
    return {
      ...operator,
      InputComponent: CustomDateInputValue,
    };
  }),
};

export default CustomDateColumnType;
