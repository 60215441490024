import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { AirDryKiln } from '../../model';

const url = '/api/v1/air-dry-kiln';

export type getAirDryKilnsResponse = { airDryKilns: AirDryKiln[] };

const getAirDryKilns = (): Promise<AxiosResponse<getAirDryKilnsResponse>> => axios.get(url);

const useGetAirDryKilns = (): [Agent<getAirDryKilnsResponse>, getAirDryKilnsResponse | undefined, boolean, () => void] =>
  useAgent<getAirDryKilnsResponse>(getAirDryKilns);

export default useGetAirDryKilns;
