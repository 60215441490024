import { useMsal } from '@azure/msal-react';
import { Avatar, Box, Button, createStyles, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageToggler from '../language/LanguageToggler';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '10px',
    },
    avatar: {
      alignSelf: 'center',
      width: theme.spacing(5),
      height: theme.spacing(5),
      backgroundColor: theme.palette.primary.light,
    },
    text: {
      color: theme.palette.primary.main,
    },
    avatarOpen: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    avatarIcon: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    avatarIconOpen: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  })
);

const UserHeader = ({ isOpen }: { isOpen: boolean }): ReactElement => {
  const classes = useStyles();
  const { instance, accounts } = useMsal();
  const { t } = useTranslation('layout');
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
  const name = accounts[0] && accounts[0].name;

  const handleClick = (event: { currentTarget: Element }) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      account: accounts[0],
      postLogoutRedirectUri: '/',
    });
  };

  return (
    <Box className={classes.root}>
      <Avatar
        className={clsx(classes.avatar, {
          [classes.avatarOpen]: isOpen,
        })}>
        <Button onClick={handleClick}>
          <PersonIcon
            color={'primary'}
            className={clsx(classes.avatarIcon, {
              [classes.avatarIconOpen]: isOpen,
            })}
          />
        </Button>
        <Menu keepMounted anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleLogout}>{t('signout')}</MenuItem>
          <LanguageToggler />
        </Menu>
      </Avatar>
      {isOpen && <Typography className={classes.text}>{name}</Typography>}
    </Box>
  );
};

export default UserHeader;
