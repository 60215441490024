import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import useAgent, { Agent } from '../../hooks/useAgent';
import { AvailableDoor } from '../model';

const url = '/api/v1/available-doors';

export type getAvailableDoorsResponse = { availableDoors: AvailableDoor[] };

const getAvailableDoors = (date: Date, productIds: string[], recipeIds: number[]): Promise<AxiosResponse<getAvailableDoorsResponse>> =>
  axios.get(url, { params: { date, productIds, recipeIds }, paramsSerializer: (params) => qs.stringify(params) });

const useGetAvailableDoors = (): [Agent<getAvailableDoorsResponse>, getAvailableDoorsResponse | undefined, boolean, () => void] =>
  useAgent<getAvailableDoorsResponse>(getAvailableDoors);

export default useGetAvailableDoors;
