import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { LocaleManager } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import axios from 'axios';
import React, { useEffect, useMemo } from 'react';
import { defineAbilitiesFor } from './abilities/abilities';
import AbilityContext from './abilities/AbilityContext';
import { loginRequest } from './authConfig';
import AppContextProvider from './context/AppContext';
import useI18next from './hooks/useI18next';
import useSetupAxios from './hooks/useSetupAxios';
import i18nConfig, { englishLocalesCode, lngLocalStorageKey } from './i18n';
import './planning/localization/schedulerLocales.js';
import Router from './Router';
import Alerts from './alerts/components/Alerts';

const App = (): JSX.Element | null => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [initI18n, isI18nConfigurated] = useI18next();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
    }
  }, [instance, isAuthenticated, inProgress]);

  const ability = useMemo(() => defineAbilitiesFor(accounts && accounts[0]), [accounts]);

  useEffect(() => {
    if (!isI18nConfigurated) {
      const lng = window.localStorage.getItem(lngLocalStorageKey) || englishLocalesCode;
      axios.defaults.headers['Accept-Language'] = lng;
      initI18n(i18nConfig(lng));
      LocaleManager.applyLocale(lng);
    }
  }, [initI18n, isI18nConfigurated]);

  const [isAxiosReady] = useSetupAxios(axios);

  return isAxiosReady ? (
    <div className="App">
      <AuthenticatedTemplate>
        <AbilityContext.Provider value={ability}>
          <AppContextProvider>
            <Router />
            <Alerts />
          </AppContextProvider>
        </AbilityContext.Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h5 className="card-title">Please sign-in to see your profile information.</h5>
      </UnauthenticatedTemplate>
    </div>
  ) : null;
};

export default App;
