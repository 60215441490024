import { AccountInfo } from '@azure/msal-common';
import { Ability, AbilityBuilder, ExtractSubjectType, MongoQuery, Subject, SubjectRawRule } from '@casl/ability';
import { isInRole } from '../abilities/abilityHelpers';
import { CommonAbilityEnum } from '../abilities/CommonAbilityEnum';
import { ResourceEnum } from '../abilities/ResourceEnum';
import { RoleEnum } from '../abilities/RoleEnum';

export default function getRules(account: AccountInfo): SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery>[] {
  const { rules, can } = new AbilityBuilder<Ability>(Ability);
  const { Read } = CommonAbilityEnum;
  const { Admin, Planner, Operator } = RoleEnum;
  const { Event } = ResourceEnum;

  if (isInRole(account, [Admin, Planner, Operator])) {
    can([Read], Event);
  }

  return rules;
}
