import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';

const getUrl = (DoorId: number) => `api/v1/door/${DoorId}/can-be-delete`;

const getDoorCanBeDelete = (doorId: number): Promise<AxiosResponse<getDoorCanBeDeleteResponse>> => axios.get(getUrl(doorId));

export type getDoorCanBeDeleteResponse = { DeleteAvailable: boolean };

const useGetDoorCanBeDelete = (): [
  Agent<getDoorCanBeDeleteResponse>,
  getDoorCanBeDeleteResponse | undefined,
  boolean,
  () => void
] => useAgent<getDoorCanBeDeleteResponse>((doorId: number) => getDoorCanBeDelete(doorId));


export default useGetDoorCanBeDelete;
