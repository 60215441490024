import accounting from 'accounting';

export const nonBreakingSpace = '\u00A0';

const numberFormatOptions = {
  decimal: '.',
  format: `%s%v`,
  thousand: nonBreakingSpace,
};

export const formatNumberWithAccounting = (value: number | null, precision = 2, options = numberFormatOptions): string => {
  if (!value && value !== 0) return '';

  const cleanedNumber = accounting.unformat(String(value));
  return accounting.formatNumber(cleanedNumber, { ...options, precision });
};

export default formatNumberWithAccounting;
