/* eslint-disable react/display-name -- inline render cells without names */
import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import StarIcon from '@material-ui/icons/Star';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import React from 'react';
import { SetStarredRecipeRequest } from '../../hooks/recipes/useSetStarredRecipe';
import { Recipe } from '../../model';

export const getColumns = (
  t: (key: string) => string,
  canUpdate: boolean,
  onEditClick: (row: Recipe) => void,
  onStarClick: (row: SetStarredRecipeRequest) => void
): GridColDef[] => {
  const columns: GridColDef[] = [];

  if (canUpdate) {
    columns.push({
      field: '',
      headerName: t('common:actions'),
      width: 150,
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: ({ row }: GridRenderCellParams) => (
        <>
          {canUpdate && (
            <>
              <Button onClick={() => onStarClick({ isStarred: !row.isStarred, id: row.id })}>
                <StarIcon fontSize="small" color={row.isStarred ? 'secondary' : 'primary'} />
              </Button>
              <Button onClick={() => onEditClick(row as Recipe)}>
                <EditIcon fontSize="small" />
              </Button>
            </>
          )}
        </>
      ),
    });
  }

  columns.push(
    {
      field: 'isArchived',
      headerName: t('recipes.archived'),
      width: 100,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => (params.value ? t('common:yes') : t('common:no')),
      type: 'singleSelect',
      valueOptions: [t('common:yes'), t('common:no')],
    },
    {
      field: 'recipeType',
      headerName: t('recipes.type'),
      width: 100,
      editable: false,
      filterable: false,
      valueGetter: (params: GridValueGetterParams) => (params.value ? t(params.value.toString()) : ''),
    },
    {
      field: 'name',
      headerName: t('recipes.name'),
      width: 400,
      sortable: false,
      editable: false,
      filterable: false,
    },
    {
      field: 'lumberVarietyName',
      headerName: t('recipes.lumberVariety'),
      width: 300,
      sortable: false,
      editable: false,
      filterable: false,
    },
    {
      field: 'recipeCode',
      headerName: t('recipes.365Code'),
      width: 150,
      sortable: false,
      editable: false,
      filterable: false,
    },
    {
      field: 'color',
      headerName: t('recipes.colorInScheduler'),
      width: 225,
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        return row.color ? <input className="color-field" type="color" defaultValue={`#${row.color}`} disabled /> : <div />;
      },
    },
    {
      field: 'maxMoistureDelta',
      headerName: t('recipes.maxMoistureDelta'),
      width: 245,
      sortable: false,
      editable: false,
      filterable: false,
    },
    {
      field: 'expectedBatchSize',
      headerName: t('recipes.expectedBatchSize'),
      minWidth: 185,
      sortable: false,
      editable: false,
      filterable: false,
    }
  );

  return columns;
};
/* eslint-enable react/display-name -- inline render cells without names */

export default { getColumns };
