import _ from 'lodash';
import _get from 'lodash.get';
import { OptimizationSettingValues } from '../model';

export const optimizationSettingsInitialValues = {
  startDate: new Date(),
  timeLimit: 20,
  horizonDays: 42,
  stepDays: 7,
  optionFanCount: 4,
  greenPenalty: 5,
  driedPenalty: 5,
  greenInventoryPenalty: 5,
  driedInventoryPenalty: 5,
  weeklyPenalty: 5,
  standardDryPenalty: 5,
  fanDryPenalty: 5,
  airDryPenalty: 5,
  lowInventoryPenalty: 5,
  minimumKilnUsageHard: 80,
  minimumKilnUsageSoft: 10,
  kilnToProductionDelay: 1,
};

export const settingsMultipliers = {
  greenPenalty: 0.8,
  standardDryPenalty: 0.6,
  fanDryPenalty: 0.6,
  airDryPenalty: 0.2,
  lowInventoryPenalty: 0.2,
};

export const horizonDaysOptions = (t: (key: string) => string): { label: string; value: string | number }[] => {
  return [
    { label: t('optimization.sixWeeks'), value: 42 },
    { label: t('optimization.threeMonths'), value: 90 },
    { label: t('optimization.twelveMonths'), value: 365 },
  ];
};

export const stepDaysOption = (): { label: string; value: string | number }[] => {
  const stepValues = [1, 7, 14];
  return stepValues.map((v) => ({ label: String(v), value: v }));
};

export const kilnToProductionDelayOptions = Array.from({ length: 5 }, (_, i) => i + 1).map((x) => ({ label: String(x), value: x }));

export const applyMultiplicationFactors = (settingsValues: OptimizationSettingValues): OptimizationSettingValues => {
  const result = { ...settingsValues };
  Object.entries(settingsValues).forEach(([key, value]) => {
    const multiplier = _get(settingsMultipliers, key, 1);
    const formattedValue = typeof value === 'number' ? multiplier * value : value;

    _.set(result, key, formattedValue);
  });

  return result;
};
