import { Button } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import { GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React from 'react';
import { formatDate } from '../../../../common/helpers/date';
import formatNumberWithAccounting from '../../../../common/helpers/numberFormatHelpers';
import { AvailableProduct } from '../../../model';

/* eslint-disable react/display-name -- inline render cells without names */
export const getAvailableDriedProductsColumns = (
  t: (key: string) => string,
  buttonClass: string,
  onAddAvailableDriedProduct: (availableProduct: AvailableProduct) => void
): GridColDef[] =>
  [
    {
      field: '',
      headerName: '',
      width: 50,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Button className={buttonClass} onClick={() => onAddAvailableDriedProduct(row as AvailableProduct)}>
          <AddCircle fontSize="small" />
        </Button>
      ),
      sortable: false,
      editable: false,
    },
    {
      field: 'sourceEventId',
      headerName: t('sourceNumber'),
      width: 125,
      sortable: false,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'sourceResource',
      headerName: t('resource'),
      width: 125,
      sortable: false,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'sourceWorkOrder',
      headerName: t('ref'),
      sortable: true,
      editable: false,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'productDescription',
      headerName: t('productDescription'),
      minWidth: 350,
      sortable: false,
      editable: false,
      renderCell: ({ value }: GridRenderCellParams) => (<div className="monospace">{value}</div>),
    },
    {
      field: 'productCharacteristic',
      headerName: t('productCharacteristic'),
      minWidth: 170,
      sortable: false,
      editable: false,
    },
    {
      field: 'factoryName',
      headerName: t('site'),
      sortable: false,
      editable: false,
      width: 125,
    },
    {
      field: 'locationName',
      headerName: t('door'),
      sortable: false,
      editable: false,
      width: 110,
      valueFormatter: (params: GridValueFormatterParams) => params.value || 'N/A',
    },
    {
      field: 'inLocationSince',
      headerName: t('date'),
      sortable: false,
      editable: false,
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) => (dayjs(String(params.value)).isValid() ? formatDate(String(params.value)) : 'N/A'),
    },
    {
      field: 'quantity',
      headerName: t('quantity'),
      type: 'number',
      width: 150,
      sortable: false,
      editable: false,
      valueFormatter: ({ value }: GridValueFormatterParams) => (Number(value) !== 0 ? `${formatNumberWithAccounting(Number(value), 0)}` : 'N/A'),
    },
  ];
/* eslint-enable react/display-name -- inline render cells without names */
