import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import BarChartIcon from '@material-ui/icons/BarChart';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SettingsIcon from '@material-ui/icons/Settings';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { ComponentType } from 'react';
import { Abilities } from './abilities/AbilityContext';
import { appAccessAction, appSubject } from './appUserAbility';
import { InventoryPage } from './inventory/components';
import { PlanningPage } from './planning/components';
import { SettingsPage } from './settings/components';
import { TransfersPage } from './transfers/components';

export type AppRoute = {
  path: string;
  component: ComponentType;
  i18nKey: string;
  icon: OverridableComponent<SvgIconTypeMap>;
};

const ROUTES = [
  {
    component: PlanningPage,
    path: '/planning',
    i18nKey: 'planning',
    icon: DateRangeIcon,
  },
  {
    component: InventoryPage,
    path: '/inventory',
    i18nKey: 'inventory',
    icon: BarChartIcon,
  },
  {
    component: TransfersPage,
    path: '/transfers',
    i18nKey: 'transfers',
    icon: SwapHorizIcon,
  },
  {
    component: SettingsPage,
    path: '/settings',
    i18nKey: 'settings',
    icon: SettingsIcon,
  },
];

export const getRoutes = (abilities: Abilities): AppRoute[] => (abilities.can(appAccessAction, appSubject) ? ROUTES : []);
