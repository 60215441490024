import React, { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MultiSelect from '../../../layout/fields/MultiSelect';
import { getRecipeGroups, mapRecipesToOptions, RecipeOption } from '../../../settings/helpers/recipesHelpers';
import { Recipe } from '../../../settings/model';
import { EventEditorContext } from './EventEditorContext';

type RecipeMultiSelectProps = {
  recipes: Recipe[];
  disabled: boolean;
};

const RecipeMultiSelect = ({ recipes = [], disabled }: RecipeMultiSelectProps): ReactElement => {
  const recipeOptions = useMemo(() => mapRecipesToOptions(recipes), [recipes]);
  const { recipeIds, setRecipeIds } = useContext(EventEditorContext);
  const { t } = useTranslation('planning');
  return (
    <MultiSelect
      options={recipeOptions}
      selected={recipeIds}
      setSelected={setRecipeIds}
      label={t('recipe')}
      id="recipe"
      groupBy={(option) => getRecipeGroups(option as RecipeOption, t)}
      disabled={disabled}
    />
  );
};

export default RecipeMultiSelect;
