import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Kiln } from '../../model';

const url = '/api/v1/kilns';

export type editKilnResponse = { kiln: Kiln };

const editKiln = (kilnRequest: Kiln): Promise<AxiosResponse<editKilnResponse>> => axios.put(url, kilnRequest);

const useEditKiln = (): [Agent<editKilnResponse>, editKilnResponse | undefined, boolean, () => void] =>
  useAgent<editKilnResponse>((kiln: Kiln) => editKiln(kiln));

export default useEditKiln;
