import React from 'react';
import globalHook, { Store } from 'use-global-hook';

type State = { errorMessage?: string | null };
export type Action = {
  setErrorMessage: (errorMessage: string) => void;
  removeErrorMessage: () => void;
};

const initialState = { errorMessage: null };

export const setErrorMessage = (store: Store<State, Action>, errorMessage: string): void =>
  store.setState({ errorMessage });

const removeErrorMessage = (store: Store<State, Action>) => store.setState(initialState);
const actions = { setErrorMessage, removeErrorMessage };

// eslint-disable-next-line react-hooks/rules-of-hooks -- global hook requirement
const useErrorStore = globalHook<State, Action>(React, initialState, actions);

export default useErrorStore;
