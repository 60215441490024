import { Form, Formik } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Schwoop from '../../../layout/schwoop/Schwoop';
import { applyMultiplicationFactors, optimizationSettingsInitialValues } from '../../helpers/optimizationSettingsHelpers';
import { usePostOptimizationSettings } from '../../hooks';
import { OptimizationSettingValues } from '../../model';
import OptimizationFormFields from './OptimizationFormFields';
import { getOptimizationSchema } from '../../schemas/OptimizationSchemas'

type OptimizationSettingEditorProps = {
  onClose: () => void;
};
const OptimizationSettingEditor = ({ onClose }: OptimizationSettingEditorProps): ReactElement => {
  const { t } = useTranslation('planning');
  const [postOptimizationSettings] = usePostOptimizationSettings();

  const handleSubmit = (values: OptimizationSettingValues) => {
    postOptimizationSettings(applyMultiplicationFactors(values));
    onClose();
  };

  return (
    <Formik initialValues={optimizationSettingsInitialValues} onSubmit={handleSubmit} validationSchema={getOptimizationSchema(t)}>
      {({ submitForm }) => (
        <Form>
          <Schwoop open onConfirm={submitForm} onCancel={onClose} title={t('optimization.title')} isSmall>
            <OptimizationFormFields />
          </Schwoop>
        </Form>
      )}
    </Formik>
  );
};

export default OptimizationSettingEditor;
