import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import ConflictError from './ConflictError';
import useErrorStore from './useErrorStore';
import useIsMounted from './useIsMounted';

const errorHandler = (error: { response: { data: string | ConflictError } }, dispatcher: { setNotification: (message: string) => void }) => {
  if (error && error.response) {
    const errorData = error.response.data;

    if (errorData) {
      if (typeof errorData === 'string') dispatcher.setNotification(errorData);
      else if (errorData.Conflict) {
        const conflictError = new ConflictError(errorData.Conflict.join('\n'));
        dispatcher.setNotification(conflictError.message);
        throw conflictError;
      }
    }
    console.error(error.response);
  }
  throw error;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- to avoid any here we would have to define a base type which all our models would have to extend
export type Agent<T = any> = (...params: any[]) => Promise<AxiosResponse<T> | undefined>;
const defaultHandlers = { successHandler: () => undefined };

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- to avoid any here we would have to define a base type which all our models would have to extend
const useAgent = <T = any>(
  agent: Agent<T>,
  { successHandler }: { successHandler: (result: { data: T }) => void } = defaultHandlers
): [Agent<T>, T | undefined, boolean, () => void] => {
  const [state, setState] = useState({ result: undefined, isPending: false } as { result?: T; isPending: boolean });

  const isMounted = useIsMounted();
  const [, errorActions] = useErrorStore();
  const clearResult = useCallback(() => setState({ result: undefined, isPending: false }), [setState]);

  const call = useCallback(
    async (...params) => {
      setState({ isPending: true });
      try {
        const result = await agent(...params);
        const data = result?.data;
        if (isMounted()) {
          setState({ result: data, isPending: false });
        }

        successHandler && successHandler(result as AxiosResponse<T>);

        return result;
      } catch (error) {
          const err = error as Error;
        if (isMounted()) {
          setState({ result: undefined, isPending: false });
          }
          
          errorHandler({ response: { data: err.message } }, { setNotification: errorActions.setErrorMessage });
      }
    },
    [agent, isMounted, successHandler, errorActions.setErrorMessage]
  );

  return [call, state.result, state.isPending, clearResult];
};

export default useAgent;
