import { Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactElement, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AbilityContext, { Abilities } from '../abilities/AbilityContext';
import { AppContext } from '../context/AppContext';
import useErrorStore from '../hooks/useErrorStore';
import useSuccessStore from '../hooks/useSuccessStore';
import { getRoutes } from '../Routes';
import Navigation, { drawerWidth } from './navigation/Navigation';
import ErrorToast from './toast/ErrorToast';
import SuccessToast from './toast/SuccessToast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: drawerWidth.close,
      width: `calc(100% - ${drawerWidth.close}px)`,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth.open,
      width: `calc(100% - ${drawerWidth.open}px)`,
    },
  })
);

type LayoutPageProps = {
  children: ReactElement;
};

const LayoutPage = ({ children }: LayoutPageProps): JSX.Element => {
  const { t } = useTranslation();
  const { isNavigationOpen, toggleNavigation } = useContext(AppContext);
  const classes = useStyles();
  const [{ errorMessage }, errorActions] = useErrorStore();
  const [{ successMessage }, successAction] = useSuccessStore();

  const getMenuOptions = useCallback(
    (abilities: Abilities) => {
      return getRoutes(abilities).map((route) => ({ ...route, label: t(route.i18nKey) }));
    },
    [t]
  );

  return (
    <>
      <AbilityContext.Consumer>
        {(abilities: Abilities) => <Navigation options={getMenuOptions(abilities)} isOpen={isNavigationOpen} onToggleNavigation={toggleNavigation} />}
      </AbilityContext.Consumer>
      <Container
        className={clsx(classes.content, {
          [classes.contentShift]: isNavigationOpen,
        })}
        maxWidth={false}>
        {children}
      </Container>
      <ErrorToast message={errorMessage} onClose={errorActions.removeErrorMessage} />
      <SuccessToast message={successMessage} onClose={successAction.removeSuccessMessage} />
    </>
  );
};

export default LayoutPage;
