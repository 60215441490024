import { Button } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSchedulerStyles from '../../../layout/scheduler/schedulerStyles';
import { OptimizationStatusEnum, PlanningOptimizationStatusEnum } from '../../enums';
import OptimizationInProgressButton from './OptimizationInProgressButton';

type OptimizationButtonProps = {
  planningOptimizationStatus?: string;
  optimizationStatus?: string;
  onOpenOptimizer: () => void;
  onOpenStats: () => void;
};

const OptimizationButton = ({ planningOptimizationStatus, optimizationStatus, onOpenOptimizer, onOpenStats }: OptimizationButtonProps): JSX.Element => {
  const classes = useSchedulerStyles();
  const { t } = useTranslation();

  const getOptimizationButtonType = () => {
    switch (planningOptimizationStatus) {
      case PlanningOptimizationStatusEnum.requested:
        return <OptimizationInProgressButton />;

      case PlanningOptimizationStatusEnum.completed: {
        if (optimizationStatus && (optimizationStatus === OptimizationStatusEnum.Error || optimizationStatus === OptimizationStatusEnum.Unfeasible))
          return (
            <Button startIcon={<ErrorOutlineIcon className={classes.errorIcon} />} className={classes.completedButton}>
              {t('planning:optimization.error')}
            </Button>
          );
        else
          return (
            <Button onClick={onOpenStats} startIcon={<CheckCircle className={classes.checkIcon} />} className={classes.completedButton}>
              {t('planning:optimization.calculationCompleted')}
            </Button>
          );
      }

      default:
        return (
          <Button onClick={onOpenOptimizer} startIcon={<CachedIcon />} className={classes.button}>
            {t('planning:optimize')}
          </Button>
        );
    }
  };

  return <>{getOptimizationButtonType()}</>;
};

export default OptimizationButton;
