const confirmDialog = {
  defaultMessage: 'Êtes-vous certain?',
};

const grid = {
  customDateLabel: 'Date',
  customDatePlaceholder: 'Filtrer la date',
  invalidDateMessage: 'Format de date invalide',
};

const layout = {
  confirmDialog,
  grid,
  home: 'Accueil',
  english: 'English',
  french: 'Français',
  inventory: 'Inventaire',
  planning: 'Planification',
  dashboard: 'Tableau de bord',
  kilns: 'Séchoirs',
  programs: 'Programmes',
  profile: 'Profil',
  settings: 'Configuration',
  signout: 'Se déconnecter',
  close: 'Fermer',
  cancel: 'Annuler',
  confirm: 'Confirmer',
  warning: 'Avertissement',
  transfers: 'Transfert de matière',
};

export default layout;
