import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../hooks/useAgent';

const url = 'api/v1/import-inventory';

const postImportInventory = (): Promise<AxiosResponse<void>> => axios.post(url);

const usePostImportInventory = (): [Agent<void>, void | undefined, boolean, () => void] => useAgent<void>(() => postImportInventory());

export default usePostImportInventory;
