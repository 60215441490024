import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useState } from 'react';
import { apiRequest } from '../authConfig';

const useBearerToken = (): [string | null, () => Promise<string | null | undefined>] => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [latestBearerToken, setLatestBearerToken] = useState<string | null>(null);

  const getBearerToken = useCallback(async () => {
    try {
      if (isAuthenticated) {
        const silentTokenRequest = {
          ...apiRequest,
          account: accounts[0],
        };
        const tokenResponse = await instance.acquireTokenSilent(silentTokenRequest).catch((error) => {
          console.error('acquire silent token failed', error);
          if (error instanceof InteractionRequiredAuthError) {
            console.error('Acquire token redirect');
            return instance.acquireTokenRedirect({ ...apiRequest, loginHint: accounts[0].username });
          }
        });
        const bearerToken = tokenResponse ? `Bearer ${tokenResponse.accessToken}` : null;
        setLatestBearerToken(bearerToken);
        return bearerToken;
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }, [isAuthenticated, instance, accounts]);

  useEffect(() => {
    getBearerToken();
  }, [isAuthenticated, getBearerToken, instance, accounts]);

  return [latestBearerToken, getBearerToken];
};

export default useBearerToken;
