import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import MonthlyAverageTemperature from '../../model/MonthlyAverageTemperature';

const getUrl = (factoryId: number) => `api/v1/factories/${factoryId}/monthly-average-temperatures`;

export type getMonthlyAverageTemperatureResponse = { monthlyAverageTemperature: MonthlyAverageTemperature[] };

const getMonthlyAverageTemperature = (factoryId: number): Promise<AxiosResponse<getMonthlyAverageTemperatureResponse>> => axios.get(getUrl(factoryId));

const useGetMonthlyAverageTemperature = (): [
  Agent<getMonthlyAverageTemperatureResponse>,
  getMonthlyAverageTemperatureResponse | undefined,
  boolean,
  () => void
] => useAgent<getMonthlyAverageTemperatureResponse>((factoryId: number) => getMonthlyAverageTemperature(factoryId));

export default useGetMonthlyAverageTemperature;
