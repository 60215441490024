import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../../hooks/useAgent';
import { Recipe } from '../../model';

const url = '/api/v1/recipes';

export type SetStarredRecipeResponse = { recipe: Recipe };
export type SetStarredRecipeRequest = { isStarred: boolean; id: number };

const setStarredRecipe = (recipeRequest: SetStarredRecipeRequest): Promise<AxiosResponse<SetStarredRecipeResponse>> => axios.patch(url, recipeRequest);

const useSetStarredRecipe = (): [Agent<SetStarredRecipeResponse>, SetStarredRecipeResponse | undefined, boolean, () => void] =>
  useAgent<SetStarredRecipeResponse>(setStarredRecipe);

export default useSetStarredRecipe;
