import axios, { AxiosResponse } from 'axios';
import useAgent, { Agent } from '../../hooks/useAgent';

const getUrl = (id: number) => `api/v1/alerts/${id}/acknowledge`;

const acknowledgeAlert = (alertId: number): Promise<AxiosResponse<void>> => axios.post(getUrl(alertId));

const useAcknowledgeAlert = (): [Agent<void>, void | undefined, boolean, () => void] => useAgent<void>((alertId: number) => acknowledgeAlert(alertId));

export default useAcknowledgeAlert;
